import { GroupWorkOutlined } from '@mui/icons-material';
import { Handle, Position } from 'reactflow';

export default function DetailedProcessNode({ data, id }: any) {
  return (
    <div>
      <Handle type="target" id="top" position={Position.Top} />
      <Handle type="target" id="left" position={Position.Left} />
      <div
        className="bg-white p-4 w-3 border border-primary rounded text-body12 shadow-shadow1cards detailed-process-node"
        data-type="process"
        data-id={id}
        style={{ height: data?.height ? data.height : '' }}>
        <div className="flex flex-wrap items-center">
          <GroupWorkOutlined />
          {data.preLabel ? data.preLabel : data.label}
          {data.preLabel && (
            <span className="font-bold" data-type="process" data-id={id} data-origin="process-name">
              {data.label}
            </span>
          )}
        </div>
      </div>
      <Handle type="source" position={Position.Right} id="right" />
      <Handle type="source" position={Position.Bottom} id="bottom" />
    </div>
  );
}
