import { gql } from 'graphql-request';

export const DOCUMENT_FIELDS = gql`
  fragment DocumentFields on DocumentDto {
    documentId
    name
    categoryId
    owner
    status
    uri
    fileType
    createdAt
    lastUpdatedAt
  }
`;

export const getDocument = gql`
  query getDocument($id: String!) {
    getDocument(id: $id) {
      ...DocumentFields
    }
  }
  ${DOCUMENT_FIELDS}
`;

export const getDocuments = gql`
  query getDocuments($documents: GetDocumentsInput!) {
    getDocuments(documents: $documents) {
      documents {
        ...DocumentFields
      }
      count
    }
  }
  ${DOCUMENT_FIELDS}
`;

export const uploadDocument = gql`
  mutation uploadDocument($document: UploadDocumentInput!) {
    uploadDocument(document: $document) {
      ...DocumentFields
    }
  }
  ${DOCUMENT_FIELDS}
`;

export const updateDocumentContent = gql`
  mutation updateDocumentContent($document: UpdateDocumentContentInput!) {
    updateDocumentContent(document: $document) {
      affected
    }
  }
`;

export const updateDocumentMetadata = gql`
  mutation updateDocumentMetadata($document: UpdateDocumentMetadataInput!) {
    updateDocumentMetadata(document: $document) {
      affected
    }
  }
`;

export const deleteDocument = gql`
  mutation deleteDocument($id: String!) {
    deleteDocument(id: $id) {
      affected
      messages
    }
  }
`;

const DocumentsGraphQL = {
  queries: {
    getDocument,
    getDocuments,
  },
  mutations: {
    uploadDocument,
    updateDocumentMetadata,
    updateDocumentContent,
    deleteDocument,
  },
};

export default DocumentsGraphQL;
