import CustomModal from '@components/CustomModal';
import GridListSubheader from '@components/GridListSubheader';
import CreateEditOrganizationModal from '@components/modals/CreateEditOrganizationModal';
import { graphQlClient } from '@config/graphqlClient';
import OrganizationsGraphQL from '@graphql/organization.queries';
import { useDebounce } from '@hooks/useDebounce';
import { useLoader } from '@hooks/useLoader';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import {
  createOrUpdateOrganizationRequest,
  disableOrEnableOrganizationRequest,
  getOrganizationsRequest,
  Organization,
} from '@models/organization.model';
import { Box } from '@mui/material';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useCallback, useEffect, useState } from 'react';
import OrganizationsList from './list';

interface OrganizationsPageProps {}

const OrganizationsPage: React.FC<OrganizationsPageProps> = () => {
  // common
  const dispatch = useAppDispatch();
  const { setLoading } = useLoader();
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');
  const localeOrganizations = useTranslation('organizations');

  // list
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [count, setCount] = useState<number>(0);
  // search
  const [search, setSearch] = useState<string>('');
  const debouncedSearchValue = useDebounce(search, 500);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  // filter
  const [filter, setFilter] = useState<string>('Active');
  const handleFilterChange = (filter: string) => {
    setFilter(filter);
  };
  // sort
  const [orderBy, setOrderBy] = useState<string | null>('name');
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // pagination
  const [page, setPage] = useState<number>(0);
  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(event.target.value as unknown as number);
    setPage(0);
  };

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);

  const getOrganizations = useCallback(async () => {
    try {
      setLoading(true);
      const data: getOrganizationsRequest = await graphQlClient.request(OrganizationsGraphQL.queries.getOrganizations, {
        organizations: {
          page,
          limit: rowsPerPage,
          search: debouncedSearchValue,
          filter,
          orderBy,
          orderDirection,
        },
      });
      setOrganizations(data?.getOrganizations?.organizations || []);
      setCount(data?.getOrganizations?.count || 0);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter, localeCommon, orderBy, orderDirection, page, rowsPerPage, debouncedSearchValue]);

  // initialize
  useEffect(() => {
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, debouncedSearchValue, filter, orderBy, orderDirection, getOrganizations]);

  const handleAddOrganization = () => {
    setShowCreateModal(true);
  };

  const toggleEnableDisableOrganization = async (organizationId: string, enable: boolean = false) => {
    if (!organizationId) return;
    try {
      const query = enable
        ? OrganizationsGraphQL.mutations.enableOrganization
        : OrganizationsGraphQL.mutations.disableOrganization;
      const result: disableOrEnableOrganizationRequest = await graphQlClient.request(query, {
        id: organizationId,
      });
      if (result?.disableOrganization?.organizationId || result?.enableOrganization?.organizationId) {
        dispatch(
          appendActionMessage({
            message: enable
              ? localeActionMessages['organizationEnabledSuccessfully']
              : localeActionMessages['organizationDisabledSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      getOrganizations();
    }
  };

  const modalClose = (): void => {
    setShowCreateModal(false);
  };

  const createOrganization = async (
    name: string,
    email: string,
    firstName: string,
    lastName: string,
    organizationId?: string,
    description?: string,
  ) => {
    if (!name) return;

    // If it has an organizationId, it's an edit
    const isEdit = !!organizationId;
    setSaving(true);
    try {
      const query = isEdit
        ? OrganizationsGraphQL.mutations.updateOrganization
        : OrganizationsGraphQL.mutations.createOrganization;
      const params: any = {
        organization: {
          name: name,
          ownerEmail: email,
          ownerFirstName: firstName,
          ownerLastName: lastName,
          description: description,
        },
      };
      if (isEdit) {
        delete params.organization.ownerEmail;
        delete params.organization.ownerFirstName;
        delete params.organization.ownerLastName;
        params.organization.organizationId = organizationId;
      }
      const data: createOrUpdateOrganizationRequest = await graphQlClient.request(query, params);
      if (data?.createOrganization?.organizationId || data?.updateOrganization?.organizationId) {
        dispatch(
          appendActionMessage({
            message: isEdit
              ? localeActionMessages['organizationEditedSuccessfully']
              : localeActionMessages['organizationCreatedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setSaving(false);
      getOrganizations();
      setShowCreateModal(false);
    }
  };

  return (
    <Box className="w-full">
      <Box className="sticky top-0 z-30 bg-backgroundUI">
        <GridListSubheader
          title={localeOrganizations['title']}
          buttonText={localeOrganizations['addOrganization']}
          isGridView={false}
          onToggleView={() => {}}
          onDrawerOpen={handleAddOrganization}
          icon="organization"
          showFavGrid={false}
        />
      </Box>{' '}
      <OrganizationsList
        handleDisableOrganization={toggleEnableDisableOrganization}
        handleEnableOrganization={(id) => toggleEnableDisableOrganization(id, true)}
        handleEditOrganization={createOrganization}
        count={count}
        organizations={organizations}
        page={page}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        search={search}
        handleSearchChange={handleSearchChange}
        orderDirection={orderDirection}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        filter={filter}
        handleFilterChange={handleFilterChange}
      />
      <CustomModal
        isOpen={!!showCreateModal}
        onClose={modalClose}
        title={localeOrganizations['addOrganization']}
        maxWidth="600px">
        <CreateEditOrganizationModal modalClose={modalClose} createOrganization={createOrganization} saving={saving} />
      </CustomModal>
    </Box>
  );
};

export default OrganizationsPage;
