import { ArrowBackOutlined, CloseOutlined } from '@mui/icons-material';
import { Box, Drawer, IconButton, Typography } from '@mui/material';

export interface CustomDrawerProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: 'small' | 'normal' | 'large';
  showBack?: boolean;
  showClose?: boolean;
}

const drawerWidths = {
  small: 450,
  normal: 650,
  large: 960,
};

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  title,
  isOpen,
  onClose,
  children,
  size = 'normal',
  showBack = false,
  showClose = true,
}) => {
  const drawerWidth = drawerWidths[size];

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box width={drawerWidth} maxWidth={drawerWidth} minWidth={drawerWidth} className="flex flex-col h-full">
        {/* Header with Title and Close Icon */}
        <Box className="flex justify-between items-center my-12 px-8">
          <Box className="flex items-center">
            {showBack && (
              <IconButton color="primary" onClick={onClose} className="!pl-0">
                <ArrowBackOutlined />
              </IconButton>
            )}
            <Typography variant="h3semibold">{title}</Typography>
          </Box>
          {showClose && (
            <IconButton color="primary" onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          )}
        </Box>

        {/* Content */}
        <Box flexGrow={1} px={4} overflow="auto" marginBottom={'90px'}>
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;
