import { Process } from '@models/process.model';
import { StepType } from '@models/step.model';
import { filterStepsInsideSwitchRepeat } from '@utils/filterStepsInsideSwitchRepeat';
import { drawGenericNode } from '@utils/processMap/drawGenericNode';
import { drawExternalProcessWithEdge } from '@utils/processMapDetailed/drawExternalProcessWithEdge';
import { drawProcessWithEdge } from '@utils/processMapDetailed/drawProcessWithEdge';
import { drawSubProcessWithEdge } from '@utils/processMapDetailed/drawSubProcessWithEdge';

export function drawDetailedProcess(
  allData: Process[],
  mainProcess: any,
  rowIndex: number,
  serviceName: string,
  areaName: string,
  branchIdx?: number,
  data?: any,
  lineStyle?: string,
  startingVerticalOffset?: number,
  idPrefix?: string,
) {
  const nodes: any[] = [];
  const edges: any[] = [];
  const mainSteps = filterStepsInsideSwitchRepeat(mainProcess.steps);

  //Filter switches and add extra space (since they have condition) for each option
  let extraSpace = 0;

  mainSteps
    .filter((item: any) => item.stepType === StepType.SWITCH)
    .forEach((item: any) => {
      const steps = item?.stepData?.stepsIds.split(',').length;
      extraSpace += steps * 50;
    });

  //Filter repeats and add space for each option
  mainSteps
    .filter((item: any) => item.stepType === StepType.REPEAT)
    .forEach((item: any) => {
      const steps = item?.stepData?.stepsIds.split(',').length;
      extraSpace += steps.length * 30;
    });

  const height =
    mainSteps.filter((item: any) => item.stepType === StepType.PLAIN_TEXT).length * 30 +
    mainSteps.filter((item: any) => item.stepData?.executeProcessId).length * 50 +
    mainSteps.filter((item: any) => item.stepType === StepType.SWITCH).length * 30 +
    mainSteps.filter((item: any) => item.stepType === StepType.REPEAT).length * 30 +
    120 +
    extraSpace; // Base Height (100) + for each step + extraStep for minorsteps;
  const mainN = drawGenericNode(
    idPrefix + 'main' + mainProcess.processId,
    500 * rowIndex + 1,
    500 * (branchIdx || 0) + 102 + (startingVerticalOffset || 0),
    mainProcess.name,
    'DetailedProcess',
    undefined,
    undefined,
    undefined,
    areaName + ' - ' + serviceName + ' - ',
    height,
  );

  nodes.push(mainN);
  const allSteps = mainProcess.steps.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));

  let lastN;
  let majCt = 0;
  let minCt = 0;
  for (let i = 0; i < allSteps.length; i++) {
    const step = allSteps[i];
    let auxNode;
    let auxNode2;
    let auxEdge;
    let auxEdge2;
    if (mainSteps.some((item: any) => item.stepId === step.stepId)) {
      majCt++;
      if (step.stepType === StepType.SWITCH) {
        minCt = 0;
        auxNode = drawGenericNode(
          idPrefix + step.stepId,
          mainN.position.x + 50,
          mainN.position.y + 40 + 30 * (i + 1),
          i + 1 + '. ' + step.name,
          'SwitchStepInside',
          mainN.id,
          mainN.id,
        );
      } else if (step.stepType === StepType.REPEAT) {
        minCt = 0;
        auxNode = drawGenericNode(
          idPrefix + step.stepId,
          mainN.position.x + 50,
          lastN && ['SwitchStepInside', 'ProcessStepInside'].includes(lastN.type)
            ? lastN.position.y + 60
            : lastN
              ? lastN.position.y + 50
              : mainN.position.y + 40 + 30 * (i + 1),
          i + 1 + '. ' + step.name,
          'RepeatStepInside',
          mainN.id,
          mainN.id,
        );
      } else if (step?.stepData?.executeProcessId) {
        if (!data.some((process: Process) => process.processId === step.stepData?.executeProcessId)) {
          const linked = allData.find((item) => item.processId === step.stepData.executeProcessId);
          const preLabel =
            linked?.service?.area?.name.slice(0, 15) +
            ((linked?.service?.area?.name || '').length > 15 ? '...' : '') +
            ' - ' +
            linked?.service?.name?.slice(0, 15) +
            ((linked?.service?.name || '').length > 15 ? '...' : '') +
            ' - ';
          const { node, edge, externalNode } = drawExternalProcessWithEdge(
            mainN,
            mainProcess,
            step,
            i,
            false,
            majCt,
            minCt,
            lastN,
            linked?.name,
            preLabel,
            lineStyle,
          );
          auxNode = node;
          auxNode2 = externalNode;
          auxEdge = edge;
        } else {
          const { node, edge, linkNode, linkEdge } = drawProcessWithEdge(mainN, step, i, lastN, lineStyle, idPrefix);
          auxNode = node;
          auxEdge = edge;
          auxNode2 = linkNode;
          auxEdge2 = linkEdge;
        }
      } else {
        auxNode = drawGenericNode(
          idPrefix + step.stepId,
          mainN.position.x + 50,
          mainN.position.y + 40 + 30 * (i + 1),
          i + 1 + '. ' + step.name,
          'StepInside',
          mainN.id,
          mainN.id,
          undefined,
          undefined,
        );
      }
    } else {
      minCt++;
      if (step?.stepData?.executeProcessId) {
        if (!data.some((process: Process) => process.processId === step.stepData?.executeProcessId)) {
          const linked = allData.find((item) => item.processId === step.stepData.executeProcessId);
          const preLabel =
            linked?.service?.area?.name.slice(0, 15) +
            ((linked?.service?.area?.name || '').length > 15 ? '...' : '') +
            ' - ' +
            linked?.service?.name?.slice(0, 15) +
            ((linked?.service?.name || '').length > 15 ? '...' : '') +
            ' - ';
          const { node, edge, externalNode } = drawExternalProcessWithEdge(
            mainN,
            mainProcess,
            step,
            i,
            true,
            majCt,
            minCt,
            lastN,
            linked?.name,
            preLabel,
            lineStyle,
          );
          auxNode = node;
          auxNode2 = externalNode;
          auxEdge = edge;
        } else {
          const { node, edge, linkEdge, linkNode } = drawSubProcessWithEdge(
            mainN,
            step,
            i,
            majCt,
            minCt,
            lastN,
            lineStyle,
            idPrefix,
          );
          auxNode = node;
          auxEdge = edge;
          auxNode2 = linkNode;
          auxEdge2 = linkEdge;
        }
      } else {
        auxNode = drawGenericNode(
          idPrefix + step.stepId,
          mainN.position.x + 70,
          lastN && ['SwitchStepInside', 'ProcessStepInside', 'SubStepInside'].includes(lastN.type)
            ? lastN.position.y + 50
            : lastN
              ? lastN.position.y + 50
              : 50 * (i + 1) + mainN.position.y,
          majCt + '.' + minCt + step.name,
          'SubStepInside',
          mainN.id,
          mainN.id,
          step?.stepData?.condition,
        );
      }
    }
    lastN = auxNode;
    if (auxNode) {
      nodes.push(auxNode);
    }
    if (auxNode2) {
      nodes.push(auxNode2);
    }
    if (auxEdge) {
      edges.push(auxEdge);
    }
    if (auxEdge2) {
      edges.push(auxEdge2);
    }
  }

  return { nodes, edges };
}
