import { gql } from 'graphql-request';

export const STEP_FIELDS = gql`
  fragment StepFields on StepDto {
    stepId
    name
    notes
    order
    stepType
    stepData
  }
`;

export const createStep = gql`
  mutation createStep($step: StepCreateInput!) {
    createStep(step: $step) {
      ...StepFields
    }
  }
  ${STEP_FIELDS}
`;

export const reorderSteps = gql`
  mutation reorderSteps($steps: StepReorderInput!) {
    reorderSteps(steps: $steps) {
      error
      messages
    }
  }
`;

export const updateStep = gql`
  mutation updateStep($step: StepUpdateInput!) {
    updateStep(step: $step) {
      ...StepFields
    }
  }
  ${STEP_FIELDS}
`;

export const updateStepsIds = gql`
  mutation updateStep($step: StepUpdateInput!) {
    updateStep(step: $step) {
      stepId
      stepData
    }
  }
`;

export const deleteStep = gql`
  mutation deleteStep($id: String!) {
    deleteStep(id: $id) {
      affected
      messages
    }
  }
`;

const StepsGraphQL = {
  queries: {},
  mutations: {
    createStep,
    updateStep,
    updateStepsIds,
    reorderSteps,
    deleteStep,
  },
};

export default StepsGraphQL;
