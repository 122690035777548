import ActionMessages from '@components/ActionMessages';
import LoginForm from '@components/LoginForm';
import LoginPromo from '@components/LoginPromo';
import { graphQlClient } from '@config/graphqlClient';
import UserGraphQL from '@graphql/user.queries';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { getSelfWithOrganizationRequest, UserLocale } from '@models/user.model';
import { Grid } from '@mui/material';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { setLocale } from '@redux/reducers/locale.reducer';
import { setOrganizationData } from '@redux/reducers/organization.reducer';
import { logoutUser, setUserData, setUserDetails } from '@redux/reducers/user.reducer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');

  const [showReset, setShowReset] = useState<boolean>(false);
  const [showConfirmUser, setShowConfirmUser] = useState<boolean>(false);
  const [showInitial, setShowInitial] = useState<boolean>(false);
  const [confirmToken, setConfirmToken] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const forgotPassword = async (email: string) => {
    setErrorMessage('');
    if (!email) {
      return dispatch(
        appendActionMessage({
          message: localeActionMessages['loginNoEmail'],
          type: SnackType.ERROR,
        }),
      );
    }

    const url = process.env.REACT_APP_BACKEND_URL + 'auth/forgotPassword';
    const payload = {
      email,
    };
    if (url) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.message === 'INITIAL_LOGIN') {
          dispatch(
            appendActionMessage({ message: 'Welcome message re-sent to ' + data.destination, type: SnackType.SUCCESS }),
          );
          setShowInitial(true);
        } else if (data.destination) {
          dispatch(appendActionMessage({ message: 'Reset code sent to ' + data.destination, type: SnackType.SUCCESS }));
          setShowReset(true);
        } else {
          if (data.errorMessage) {
            dispatch(appendActionMessage({ message: data.errorMessage, type: SnackType.ERROR }));
            setErrorMessage(data.errorMessage);
          }
        }
      } catch {
        dispatch(appendActionMessage({ message: localeCommon['requestError'], type: SnackType.ERROR }));
      }
    }
  };

  const attemptResetPassword = async (email: string, confirmationCode: string, newPassword: string) => {
    setErrorMessage('');
    if (!email) {
      return dispatch(appendActionMessage({ message: 'No username', type: SnackType.ERROR }));
    }
    if (!confirmationCode) {
      return dispatch(appendActionMessage({ message: 'No confirmation code', type: SnackType.ERROR }));
    }
    if (!newPassword) {
      return dispatch(appendActionMessage({ message: 'No new password', type: SnackType.ERROR }));
    }

    const url = process.env.REACT_APP_BACKEND_URL + 'auth/confirmForgotPassword';
    const payload = {
      email,
      confirmationCode: confirmationCode,
      newPassword: newPassword,
    };
    if (url) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        const data = await response.json();

        if (data.confirmation && data.confirmation === '200') {
          dispatch(appendActionMessage({ message: 'Password reset', type: SnackType.SUCCESS }));
          setShowReset(false);
        } else {
          if (data.errorMessage) {
            dispatch(appendActionMessage({ message: data.errorMessage, type: SnackType.ERROR }));
            setErrorMessage(data.errorMessage);
          }
        }
      } catch {
        dispatch(appendActionMessage({ message: localeCommon['requestError'], type: SnackType.ERROR }));
      }
    }
  };

  const attemptLogin = async (email: string, password: string, rememberMe: boolean) => {
    setErrorMessage('');
    const url = process.env.REACT_APP_BACKEND_URL + 'auth/signIn';
    const payload = {
      email: email,
      password: password,
    };
    if (url) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.token) {
          handleTokenLogin(data.token, rememberMe);
        } else if (data.message && data.message === 'FORCE_CHANGE_PASSWORD') {
          setConfirmToken(data.confirmToken);
          setShowConfirmUser(true);
        } else {
          if (data.errorMessage) {
            dispatch(appendActionMessage({ message: data.errorMessage, type: SnackType.ERROR }));
            setErrorMessage(data.errorMessage);
          }
        }
      } catch (e) {
        dispatch(appendActionMessage({ message: localeCommon['requestError'], type: SnackType.ERROR }));
        dispatch(logoutUser());
      }
    }
  };

  const attemptInitialLogin = async (email: string, password: string, rememberMe: boolean) => {
    setErrorMessage('');
    const url = process.env.REACT_APP_BACKEND_URL + 'auth/signInWithConfirm';
    const payload = {
      email: email,
      password: password,
      confirmToken: confirmToken,
    };
    if (url) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        const data = await response.json();

        if (data.token) {
          handleTokenLogin(data.token, rememberMe);
        } else {
          if (data.errorMessage) {
            dispatch(appendActionMessage({ message: data.errorMessage, type: SnackType.ERROR }));
            setErrorMessage(data.errorMessage);
          }
        }
      } catch {
        dispatch(appendActionMessage({ message: localeCommon['requestError'], type: SnackType.ERROR }));
        dispatch(logoutUser());
      }
    }
  };

  const handleTokenLogin = async (token: string, rememberMe: boolean) => {
    dispatch(setUserData(token, rememberMe));
    const userData: getSelfWithOrganizationRequest = await graphQlClient.request(
      UserGraphQL.queries.getSelfWithOrganization,
    );
    dispatch(setOrganizationData(userData.getSelfWithOrganization.organization));
    dispatch(setUserDetails(userData.getSelfWithOrganization.user));
    dispatch(setLocale(UserLocale.EN));
    const from = sessionStorage.getItem('redirectUrl') || '/';
    sessionStorage.removeItem('redirectUrl');
    if (from && from !== '/logout') {
      history.push(from);
    }
  };

  const navigateToGoogleAuth = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'auth/ssoGoogle');
    const data = await response.json();
    window.location.href = data.url;
  };

  return (
    <Grid container sx={{ height: '100vh' }}>
      <ActionMessages />
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}>
        <LoginForm
          googleLogin={navigateToGoogleAuth}
          forgotPassword={forgotPassword}
          signIn={attemptLogin}
          resetPassword={attemptResetPassword}
          confirmUserPassword={attemptInitialLogin}
          showReset={showReset}
          showConfirm={showConfirmUser}
          showInitial={showInitial}
          errorMessage={errorMessage}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LoginPromo />
      </Grid>
    </Grid>
  );
};

export default LoginPage;
