import { Handle, Position } from 'reactflow';

export default function SwitchNode({ data, id }: any) {
  return (
    <div>
      <Handle type="target" id="left" position={Position.Left} />
      <div className="switch-node flex justify-center items-center text-center" data-type="process" data-id={id}>
        {data.label}
      </div>
      <Handle type="source" position={Position.Right} id="right" />
    </div>
  );
}
