import { AreaIcon24 } from '@components/ui/WolfIcons';
import { Avatar, Box, Theme, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { isColorDark } from '@utils/isColorDark';

interface WolfSectionColorProps {
  color: string;
  icon?: React.ReactNode;
}

const ColorBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

const ColorCircle = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
}));

const WolfSectionColor: React.FC<WolfSectionColorProps> = ({ color, icon }) => {
  const theme: Theme = useTheme();
  const foregroundColor = isColorDark(color) ? theme.palette.common.white : theme.palette.primary.main;

  return (
    <ColorBox>
      <ColorCircle style={{ backgroundColor: color, color: foregroundColor }}>{icon || <AreaIcon24 />}</ColorCircle>
    </ColorBox>
  );
};

export default WolfSectionColor;
