import { Box, Tooltip, Typography } from '@mui/material';

const SplitListCell = ({ list, showDetail }: { list: string; showDetail?: (email: string) => void }) => {
  const listArray = list.split(',').map((item) => item.trim());

  return (
    <>
      {listArray.map((item, index) => {
        return (
          <Box onClick={showDetail ? () => showDetail(item) : () => {}}>
            <Tooltip key={index} title={item}>
              <Typography>{item.slice(0, 25) + (item.length > 25 ? '...' : '')} </Typography>
            </Tooltip>
          </Box>
        );
      })}
    </>
  );
};

export default SplitListCell;
