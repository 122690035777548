import { styled } from '@mui/system';

interface WolfBadgeProps {
  color?: 'default' | 'dark' | 'info' | 'success' | 'warning' | 'error' | 'new';
  size?: '32px' | '24px';
  children: React.ReactNode;
  onBadgeClick?: (e: any, badge: string) => void;
}

const StyledBadge = styled('div')<WolfBadgeProps>(({ theme, color, size }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 12px',
  borderRadius: '16px',
  margin: '0',
  fontSize: '14px',
  height: size,
  maxHeight: size,
  boxSizing: 'border-box',
  minWidth: 'fit-content',
  wordBreak: 'keep-all',
  backgroundColor: {
    default: theme.palette.primaryFrozen.main,
    dark: theme.palette.primaryDark.main,
    info: theme.palette.utilityInfo.main,
    success: theme.palette.utilityGreen.main,
    warning: theme.palette.utilityYellow.main,
    error: theme.palette.utilityRed.main,
    new: theme.palette.utilityPink.main,
  }[color || 'default'],
  color: color === 'dark' ? theme.palette.common.white : theme.palette.primaryDark.main,
}));

const WolfBadge: React.FC<WolfBadgeProps> = ({ color = 'default', size = '32px', children, onBadgeClick }) => {
  const _onBadgeClick = (e: any) => {
    onBadgeClick && onBadgeClick(e, children as string);
  };

  return (
    <StyledBadge color={color} size={size} onClick={_onBadgeClick}>
      {children}
    </StyledBadge>
  );
};

export default WolfBadge;
