export function blobToBase64(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve((reader.result as string).split(',')[1]);
      } else {
        reject(new Error('FileReader result is null'));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
