import StaticFramework from '@components/StaticFramework';
import Layout from '@layouts/Layout';
import { UserRoles } from '@models/user.model';
import AreaDescription from '@pages/areas/description/description';
import AreaList from '@pages/areas/list';
import AuthPage from '@pages/auth';
import DocumentsList from '@pages/documents';
import IntegrationsCallbackPage from '@pages/integrations/callback';
import IntegrationList from '@pages/integrations/integrations';
import LandingPage from '@pages/landing';
import LoginPage from '@pages/login';
import LogoutPage from '@pages/logout';
import OrganizationDescription from '@pages/organizations/description/description';
import OrganizationsPage from '@pages/organizations/list/organizations';
import PolicyDescription from '@pages/policies/description/description';
import PoliciesList from '@pages/policies/list';
import ProcessDescription from '@pages/processes/description/description';
import ProcessList from '@pages/processes/list';
import ProcessMap from '@pages/processMap';
import ServiceDescription from '@pages/services/description/description';
import ServiceList from '@pages/services/list';
import SettingsPage from '@pages/settings';
import UsersPage from '@pages/users';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { useAppSelector } from './app/redux/hooks';
import { RootState } from './app/redux/store';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const App: React.FC = () => {
  const { isLoggedIn, role } = useAppSelector((state: RootState) => state.user);

  const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => (
    <Layout>
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn ? (
            <Component {...props} />
          ) : (
            (() => {
              if (window.location.pathname !== '/logout') {
                sessionStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
              }
              return <Redirect to="/auth" />;
            })()
          )
        }
      />
    </Layout>
  );

  return (
    <Router>
      {/* // TODO: Uncomment if scrollTop is still an issue */}
      {/* <ScrollTop /> */}
      <Switch>
        <Route exact path="/login">
          {isLoggedIn ? <Redirect to="/dashboard" /> : <LoginPage />}
        </Route>

        {/* Redirect from /auth/code... to /dashboard if logged in */}
        <Route path="/auth/*">{isLoggedIn ? <Redirect to="/dashboard" /> : <AuthPage />}</Route>

        {/* Specific route for the authentication page */}
        <Route exact path="/auth">
          {isLoggedIn ? <Redirect to="/dashboard" /> : <AuthPage />}
        </Route>

        {/* We use this as a landing after enabling an integration (the flow opens in a new tab) */}
        <Route exact path="/integrations/callback">
          {<IntegrationsCallbackPage />}
        </Route>

        <Route exact path="/logout">
          <LogoutPage />
        </Route>

        <Route path="/framework">
          <StaticFramework />
        </Route>

        {/* PrivateRoute for Dashboard and all other authenticated routes */}
        {/* PrivateRoute for Dashboard and all other authenticated routes */}
        {role === UserRoles.SUPER_ADMIN && <PrivateRoute path="/organizations" component={OrganizationsPage} />}
        {[UserRoles.OWNER, UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(role as UserRoles) && (
          <PrivateRoute path="/organization/:organizationId" component={OrganizationDescription} />
        )}
        {[UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.ADMIN].includes(role as UserRoles) && (
          <PrivateRoute path="/users" component={UsersPage} />
        )}
        <PrivateRoute exact path="/areas" component={AreaList} />
        <PrivateRoute path="/areas/:areaId" component={AreaDescription} />
        <PrivateRoute exact path="/services" component={ServiceList} />
        <PrivateRoute path="/services/:serviceId" component={ServiceDescription} />
        <PrivateRoute exact path="/processes" component={ProcessList} />
        <PrivateRoute path="/processes/:processId" component={ProcessDescription} />
        <PrivateRoute exact path="/policies" component={PoliciesList} />
        <PrivateRoute path="/policies/:policyId" component={PolicyDescription} />
        <PrivateRoute path="/documents" component={DocumentsList} />
        <PrivateRoute path="/processMap" component={ProcessMap} />
        <PrivateRoute path="/integrations" component={IntegrationList} />
        <PrivateRoute path="/settings" component={SettingsPage} />

        {/* Optionally handle the root URL explicitly */}
        <Route path="/">{isLoggedIn ? <Redirect to="/areas" /> : <LandingPage />}</Route>
      </Switch>
    </Router>
  );
};

export default App;
