export function drawGenericNode(
  id: string,
  xPos: number,
  yPos: number,
  label: string,
  type: string,
  parentNode?: string,
  dataParentId?: string,
  dataCondition?: string,
  preLabel?: string,
  height?: number,
) {
  return {
    id,
    position: { x: xPos, y: yPos },
    data: { label, parentId: dataParentId, condition: dataCondition, preLabel, height },
    parent: parentNode,
    type,
  };
}
