import { useTranslation } from '@hooks/useTranslation';
import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import WolfTextLink from './WolfTextLink';

interface WolfSearchBorderlessProps {
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

const WolfSearchBorderless: React.FC<WolfSearchBorderlessProps> = ({
  placeholder,
  value,
  onChange,
  onFocus,
  onClear,
}) => {
  const localeCommon = useTranslation('common');

  return (
    <TextField
      className="h-10"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      variant="standard"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {value && (
              <WolfTextLink onClick={onClear} sx={{ ml: 2 }} color="link">
                {localeCommon['clear']}
              </WolfTextLink>
            )}
          </>
        ),
        disableUnderline: true,
      }}
      sx={{
        padding: '8px 16px',
        borderRadius: '4px',
        width: '100%',
      }}
    />
  );
};

export default WolfSearchBorderless;
