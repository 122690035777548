import { ElectricBoltOutlined, EmailOutlined, QuestionMarkOutlined } from '@mui/icons-material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Icon } from '@mui/material';
import { Handle, Position } from 'reactflow';

export default function ProcessLinkNode({ data }: any) {
  return (
    <div data-type="process-link" data-id={data?.id} data-description={data?.description}>
      <div
        className="process-link-node flex flex-wrap items-center justify-between"
        data-type="process-link"
        data-id={data?.id}
        data-description={data?.description}>
        {data.type === 'CALENDAR' && <CalendarMonthOutlinedIcon />}
        {data.type === 'EMAIL' && <EmailOutlined />}
        {data.type === 'EVENT' && <ElectricBoltOutlined />}
        <div data-type="process-link" data-id={data?.id}>
          <Icon className="pointer-events-none">
            <QuestionMarkOutlined />
          </Icon>
        </div>
        <div
          className="flex flex-wrap items-center justify-center m-auto"
          title={data.label}
          data-type="process-link"
          data-id={data?.id}
          data-description={data?.description}>
          {data?.label?.slice(0, 20) + (data?.label?.length > 20 ? '...' : '')}
        </div>
      </div>
      <Handle type="target" position={Position.Left} id="left" />
      <Handle type="source" position={Position.Right} id="right" />
    </div>
  );
}
