import WolfInput from '@components/ui/WolfInput';
import { graphQlClient } from '@config/graphqlClient';
import AreasGraphQL from '@graphql/area.queries';
import DocumentCategoriesGraphQL from '@graphql/document.category.queries';
import { useTranslation } from '@hooks/useTranslation';
import { getAreasRequest } from '@models/area.model';
import { SnackType } from '@models/common.model';
import { getDocumentCategoriesRequest } from '@models/document.categories.model';
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import WolfButton from './ui/WolfButton';

interface UploadDocumentModalProps {
  modalClose: () => void;
  fileName: string | null;
  handleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  preview: string | null;
  showFile: File | null;
  uploadFile: (category?: string, owner?: string) => void;
  saving: boolean;
}

const UploadDocumentModal: React.FC<UploadDocumentModalProps> = ({
  fileName,
  handleNameChange,
  preview,
  showFile,
  modalClose,
  uploadFile,
  saving,
}) => {
  const [documentCategory, setDocumentCategory] = useState<string>('');
  const [documentOwner, setDocumentOwner] = useState<any>(null);
  const [owners, setOwners] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);

  const localeCommon = useTranslation('common');
  const localeDocuments = useTranslation('documents');
  const dispatch = useDispatch();

  useEffect(() => {
    getCategories();
    getAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setDocumentCategory(event.target.value as string);
  };

  const handleOwnerChange = (event: any, selected: any) => {
    if (selected) {
      setDocumentOwner(selected);
    } else {
      setDocumentOwner(null);
    }
  };

  const getAreas = async () => {
    try {
      const data: getAreasRequest = await graphQlClient.request(AreasGraphQL.queries.getAreas);
      const arrangedServices = data.getAreas.flatMap((area) =>
        area.services.map((service) => ({
          ...service,
          area: { name: data.getAreas.find((a) => a.areaId === service.areaId)?.name || '' },
          areaOrServiceId: service.serviceId,
        })),
      );
      const arrangedAreas: any[] = data.getAreas.map((area: any) => {
        area.areaOrServiceId = area.areaId;
        return area;
      });
      setOwners([...arrangedAreas, ...arrangedServices]);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const getCategories = async () => {
    const data: getDocumentCategoriesRequest = await graphQlClient.request(
      DocumentCategoriesGraphQL.queries.getDocumentCategories,
    );
    try {
      setCategories(data.getDocumentCategories);
    } catch (e: any) {
      setCategories([]);
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  return (
    <Box>
      <Typography variant="h5">{localeDocuments['addDocument']}</Typography>

      <Box>
        <WolfInput
          fullWidth
          value={fileName}
          autoComplete="off"
          onChange={handleNameChange}
          label={localeCommon['name']}
          placeholder={localeCommon['name']}
        />
        {/* // TODO: refactor with our input components */}
        <FormControl fullWidth>
          <InputLabel id="documentCategoryLabel">{localeCommon['category']}</InputLabel>
          <Select
            labelId="documentCategoryLabel"
            id="documentCategory"
            value={documentCategory}
            onChange={handleCategoryChange}>
            <MenuItem value={''}>{localeCommon['none']}</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.documentCategoryId} value={category?.documentCategoryId}>
                {category?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* // TODO: refactor with our input components */}

        <FormControl fullWidth>
          <InputLabel id="documentOwnerLabel">{localeCommon['owner']}</InputLabel>
          <Autocomplete
            value={documentOwner}
            onChange={handleOwnerChange}
            options={owners}
            renderInput={(params) => <TextField {...params} label={localeDocuments['owner']} variant="outlined" />}
            isOptionEqualToValue={(option, value) => option.areaOrServiceId === value.areaOrServiceId}
            filterOptions={(options, { inputValue }) => {
              return options.filter(
                (option) =>
                  option?.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option?.area?.name?.toLowerCase().includes(inputValue.toLowerCase()),
              );
            }}
            getOptionLabel={(option) => {
              const areaName = option?.area?.name || '';
              const serviceName = option?.name || '';
              return areaName + (areaName && serviceName ? ' - ' : '') + serviceName;
            }}
          />
        </FormControl>
      </Box>

      <Box>
        <Typography>{localeDocuments['previewText']}</Typography>
        <Box className="overflow-scroll">
          {preview && showFile?.type.includes('image') && !showFile?.type.includes('heic') ? (
            <img src={preview} alt="preview" className="h-96" />
          ) : showFile?.type.includes('video') ? (
            <div className="flex items-center justify-center">
              <video width="480" height="320" controls>
                <source src={preview || ''} type="video/mp4" />
                {localeDocuments['noVideoTag']}
              </video>
            </div>
          ) : (
            <Typography>{localeDocuments['noPreview']}</Typography>
          )}
        </Box>
      </Box>

      <WolfButton onClick={modalClose} color="secondary" variant="outlined">
        {localeCommon['cancel']}
      </WolfButton>
      <WolfButton
        onClick={() => uploadFile(documentCategory, documentOwner?.serviceId)}
        color="primary"
        variant="outlined"
        autoFocus
        disabled={saving}>
        {localeDocuments['upload']}
      </WolfButton>
    </Box>
  );
};

export default UploadDocumentModal;
