import fileLink from '@assets/file_link.png';
import { graphQlClient } from '@config/graphqlClient';
import AreasGraphQL from '@graphql/area.queries';
import DocumentsGraphQL from '@graphql/document.queries';
import { useTranslation } from '@hooks/useTranslation';
import { getAreasRequest } from '@models/area.model';
import { SnackType } from '@models/common.model';
import { DocumentCategory } from '@models/document.categories.model';
import { Document, updateDocumentMetadataRequest } from '@models/document.model';
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { triggerFileDownload } from '@utils/triggerFileDownload';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import WolfButton from '../ui/WolfButton';

interface PreviewUpdateDocumentModalProps {
  showUpdate: boolean;
  showFile: Document | null;
  categories: DocumentCategory[];
  onClose: (refresh?: boolean) => void;
}

const PreviewUpdateDocumentModal: React.FC<PreviewUpdateDocumentModalProps> = ({
  showUpdate,
  showFile,
  categories,
  onClose,
}) => {
  const [documentCategory, setDocumentCategory] = useState<string>(showFile?.categoryId || '');
  const [documentOwner, setDocumentOwner] = useState<any>(null);
  const [documentName, setDocumentName] = useState<string>(showFile?.name || '');
  const [owners, setOwners] = useState<any[]>([]);
  const [saving, setSaving] = useState<boolean>(false);

  const localeCommon = useTranslation('common');
  const localeDocuments = useTranslation('documents');
  const localeActionMessages = useTranslation('actionMessages');
  const dispatch = useDispatch();

  useEffect(() => {
    getAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDocumentName(showFile?.name || '');
    setDocumentCategory(showFile?.categoryId || '');
    setDocumentOwner(owners.find((item) => item.areaOrServiceId === showFile?.owner) || null);
  }, [showFile]);

  const handleNameChange = (event: any) => {
    setDocumentName(event.target.value);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setDocumentCategory(event.target.value as string);
  };

  const handleOwnerChange = (event: any, selected: any) => {
    if (selected) {
      setDocumentOwner(selected);
    } else {
      setDocumentOwner(null);
    }
  };

  const getAreas = async () => {
    try {
      const data: getAreasRequest = await graphQlClient.request(AreasGraphQL.queries.getAreas);
      const arrangedServices = data.getAreas.flatMap((area) =>
        area.services.map((service) => ({
          ...service,
          area: { name: data.getAreas.find((a) => a.areaId === service.areaId)?.name || '' },
          areaOrServiceId: service.serviceId,
        })),
      );
      const arrangedAreas: any[] = data.getAreas.map((area: any) => {
        area.areaOrServiceId = area.areaId;
        return area;
      });
      setOwners([...arrangedAreas, ...arrangedServices]);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const updateDocument = async () => {
    setSaving(true);
    try {
      const updateResult: updateDocumentMetadataRequest = await graphQlClient.request(
        DocumentsGraphQL.mutations.updateDocumentMetadata,
        {
          document: {
            documentId: showFile?.documentId,
            name: documentName,
            categoryId: documentCategory || null,
            owner: documentOwner?.areaOrServiceId || null,
          },
        },
      );
      if (updateResult?.updateDocumentMetadata?.affected > 0) {
        dispatch(
          appendActionMessage({
            message: localeActionMessages['documentUpdatedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        onClose(true);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setSaving(false);
    }
  };

  return (
    <Box>
      <Box className="pt-5">
        {/* // TODO: Update form input */}
        <FormControl>
          <TextField
            autoFocus
            id="documentName"
            label={localeCommon['name']}
            value={documentName || ''}
            placeholder=""
            onChange={handleNameChange}
            fullWidth
            disabled={!showUpdate}
          />
        </FormControl>
        {showUpdate && (
          <FormControl fullWidth>
            <InputLabel id="documentCategoryLabel">{localeCommon['category']}</InputLabel>
            <Select
              labelId="documentCategoryLabel"
              id="documentCategory"
              value={documentCategory}
              onChange={handleCategoryChange}>
              <MenuItem value={''}>{localeCommon['none']}</MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.documentCategoryId} value={category?.documentCategoryId}>
                  {category?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {showUpdate && (
          <FormControl fullWidth>
            <Autocomplete
              value={documentOwner}
              onChange={handleOwnerChange}
              options={owners}
              renderInput={(params) => <TextField {...params} label={localeDocuments['owner']} variant="outlined" />}
              isOptionEqualToValue={(option, value) => option.areaOrServiceId === value.areaOrServiceId}
              filterOptions={(options, { inputValue }) => {
                return options.filter(
                  (option) =>
                    option?.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
                    option?.area?.name?.toLowerCase().includes(inputValue.toLowerCase()),
                );
              }}
              getOptionLabel={(option) => {
                const areaName = option?.area?.name || '';
                const serviceName = option?.name || '';
                return areaName + (areaName && serviceName ? ' - ' : '') + serviceName;
              }}
            />
          </FormControl>
        )}

        {showFile?.fileType.includes('image') && !showFile?.fileType.includes('heic') ? (
          <div className="flex items-center justify-center">
            <img src={showFile?.uri} alt="preview" className="max-w-lg max-h-full" />
          </div>
        ) : showFile?.fileType.includes('video') ? (
          <div className="flex items-center justify-center">
            <video width="480" height="320" controls>
              <source src={showFile?.uri} type="video/mp4" />
              {localeDocuments['noVideoTag']}
            </video>
          </div>
        ) : (
          <div className="flex flex-wrap items-center justify-center">
            <Box className="w-full text-center" onClick={() => triggerFileDownload(showFile?.uri || '')}>
              <Typography>{localeDocuments['downloadFile']}</Typography>
            </Box>
            <img src={fileLink} alt="FILE" className="max-w-xl max-h-xl w-fit h-fit" />
          </div>
        )}
      </Box>
      <Box className="flex w-full !justify-between">
        <WolfButton onClick={onClose} variant="outlined" color="secondary">
          {!showUpdate ? localeCommon['close'] : localeCommon['cancel']}
        </WolfButton>
        {showUpdate && (
          <WolfButton onClick={updateDocument} variant="contained" color="primary" disabled={saving}>
            {localeCommon['update']}
          </WolfButton>
        )}
      </Box>
    </Box>
  );
};

export default PreviewUpdateDocumentModal;
