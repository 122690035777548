import { QuestionMarkOutlined } from '@mui/icons-material';
import { Icon } from '@mui/material';
import { Handle, Position } from 'reactflow';

export default function ProcessNode({ data, id }: any) {
  return (
    <div>
      <Handle type="target" id="top" position={Position.Top} />
      <Handle type="target" id="left" position={Position.Left} />
      <div
        className={data.isExternal ? 'process-node process-node-external' : 'process-node'}
        data-type="process"
        data-id={id}>
        {data.preLabel ? data.preLabel : data.label}
        {data.preLabel && (
          <span className="font-bold" data-type="process" data-id={id} data-origin="process-name" title={data.label}>
            {data.label.slice(0, 60) + (data.label.length > 60 ? '...' : '')}
          </span>
        )}
        {!data.isExternal && (
          <div className="text-right" data-type="process" data-id={id} data-origin="question-mark">
            <Icon className="pointer-events-none">
              <QuestionMarkOutlined />
            </Icon>
          </div>
        )}
      </div>
      <Handle type="source" position={Position.Right} id="right" />
      <Handle type="source" position={Position.Bottom} id="bottom" />
    </div>
  );
}
