import WolfAvatar from '@components/ui/WolfAvatar';
import { useTranslation } from '@hooks/useTranslation';
import { UserRoles } from '@models/user.model';
import { Box, IconButton, Menu, MenuItem, styled } from '@mui/material';
import { RootState } from '@redux/store';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import WolfTooltip from './ui/WolfTooltip';

const YellowDot = styled(Box)(({ theme }) => ({
  width: '15px',
  height: '15px',
  borderRadius: '50%',
  backgroundColor: theme.palette.warning.main,
  position: 'absolute',
  bottom: 45,
  right: 5,
}));

interface AvatarDropdownProps {
  avatar: string;
}

const AvatarDropdown: React.FC<AvatarDropdownProps> = ({ avatar }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const localeMenu = useTranslation('menu');
  const { role } = useSelector((state: RootState) => state.user);
  const { organizationId, lastProfileUpdate } = useSelector((state: RootState) => state.organization);

  const options = [
    { text: localeMenu['settings'], link: '/settings' },
    { text: localeMenu['logout'], link: '/logout' },
  ];

  if ([UserRoles.OWNER, UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(role as UserRoles)) {
    options.unshift({ text: localeMenu['myOrganization'], link: '/organization/' + organizationId });
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path: string) => {
    history.push(path);
    handleClose();
  };

  const showYellowDot = useMemo(() => {
    if (!lastProfileUpdate) return false;
    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
    return new Date(lastProfileUpdate) < ninetyDaysAgo;
  }, [lastProfileUpdate]);

  return (
    <Box className="flex items-center">
      <IconButton onClick={handleClick} className="relative">
        <WolfAvatar size="48px" src={avatar || ''} />
        {/* Show yellow dot if lastProfileUpdate was more than 90 days ago */}
        <WolfTooltip title={localeMenu['updateProfile']}>
          <span>{showYellowDot && <YellowDot />}</span>
        </WolfTooltip>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <Box className="shadow-md bg-backgroundUI">
          {options.map((option) => (
            <MenuItem key={option.text} onClick={() => handleMenuItemClick(option.link)}>
              {option.text}
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Box>
  );
};

export default AvatarDropdown;
