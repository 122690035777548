import CustomModal from '@components/CustomModal';
import WolfProgressBar from '@components/ui/WolfProgressBar';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography } from '@mui/material';

interface ReplaceDocumentModalProps {
  fileName: string | null;
  preview: string | null;
  fileType: string | null;
  saving: boolean;
}

const ReplaceDocumentModal: React.FC<ReplaceDocumentModalProps> = ({ fileName, preview, fileType, saving }) => {
  const localeCommon = useTranslation('common');
  const localeDocuments = useTranslation('documents');

  return (
    <>
      <Box>
        <Box>
          <Typography variant="h5">{localeDocuments['replaceDocument']}</Typography>
          <Box>
            <Typography>
              {localeCommon['name'] + ': '} {fileName}
            </Typography>
          </Box>

          <Box>
            <Typography>{localeDocuments['previewUpdateText']}</Typography>
            <Box className="overflow-scroll">
              {preview && fileType?.includes('image') && !fileType?.includes('heic') ? (
                <img src={preview} alt="preview" className="h-96" />
              ) : (
                <Typography>{localeDocuments['noPreview']}</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <CustomModal isOpen={saving} onClose={() => {}} hideClose={true} maxWidth="400px">
        <WolfProgressBar duration={2000} />
      </CustomModal>
    </>
  );
};

export default ReplaceDocumentModal;
