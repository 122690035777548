import WolfChip from '@components/ui/WolfChip';
import { AreaIcon24, ServicesIcon24 } from '@components/ui/WolfIcons';
import WolfSearchBorderless from '@components/ui/WolfSearchBorderless';
import { graphQlClient } from '@config/graphqlClient';
import AreasGraphQL from '@graphql/area.queries';
import { useTranslation } from '@hooks/useTranslation';
import { getAreasNameWServProcRequest } from '@models/area.model';
import { Service } from '@models/service.model';
import { CloseOutlined, GroupWorkOutlined, PolicyOutlined } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { isColorDark } from '@utils/isColorDark';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface WolfSearchModalProps {
  open: boolean;
  onClose: () => void;
}

enum ResultType {
  AREA = 'Area',
  SERVICE = 'Service',
  PROCESS = 'Process',
  POLICY = 'Policy',
}

const WolfSearchModal: React.FC<WolfSearchModalProps> = ({ open, onClose }) => {
  const localeMenu = useTranslation('menu');
  const localeCommon = useTranslation('common');
  const history = useHistory();
  const theme = useTheme();
  const modalRef = useRef<HTMLDivElement>(null);

  const categories: { text: string; value: ResultType | 'All' }[] = [
    { text: localeCommon['all'], value: 'All' },
    { text: localeMenu['areas'], value: ResultType.AREA },
    { text: localeMenu['services'], value: ResultType.SERVICE },
    { text: localeMenu['processes'], value: ResultType.PROCESS },
    { text: localeMenu['policies'], value: ResultType.POLICY },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<{ text: string; value: ResultType | 'All' }>({
    text: localeCommon['all'],
    value: 'All',
  });
  const [searchArray, setSearchArray] = useState<any[]>([]);
  const [allResults, setAllResults] = useState<any[]>([]);
  const [resultsByCategory, setResultsByCategory] = useState<any[]>([]);
  const [dataTime, setDataTime] = useState<number>(0);
  const [resultsMessage, setResultsMessage] = useState(localeMenu['noResults']);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setAllResults([]);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  const handleFocus = () => {
    if (isDataStale()) {
      getData();
    }
  };

  const REFRESH_PERIOD = 5 * 60 * 1000; // 5 minutes

  const isDataStale = () => {
    const currentTime = new Date().getTime();
    return currentTime - dataTime > REFRESH_PERIOD;
  };

  const getData = async () => {
    try {
      const data: getAreasNameWServProcRequest = await graphQlClient.request(
        AreasGraphQL.queries.getAreasNameWServProc,
      );
      setDataTime(new Date().getTime());
      setSearchArray(data.getAreas.sort((a: any, b: any) => (a.order > b.order ? 1 : -1)));
    } catch (e: any) {}
  };

  const highlightSearchTerm = (text: string) => {
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === searchTerm.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: theme.palette.utilityGreen.main, fontWeight: 'bold' }}>
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </span>
    );
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length === 0) {
      setAllResults([]);
      return;
    }

    if (term.length < 3) {
      setResultsMessage(localeMenu['searchResultsMinLength']);
      setAllResults([]);
      return;
    }
    setResultsMessage(localeMenu['noResults']);
    const contextAreaId = new URLSearchParams(window.location.search).get('areaId');
    const contextServiceId = new URLSearchParams(window.location.search).get('serviceId');
    const results: { name: string; type: ResultType; id: string; score: number; color: string; category: string }[] =
      [];

    searchArray.forEach((area) => {
      if (area?.name?.toLowerCase().includes(term.toLowerCase())) {
        results.push({
          name: area.name || '',
          type: ResultType.AREA,
          id: area.areaId || '',
          score: 1,
          color: area.color || '',
          category: ResultType.AREA,
        });
      }
      area?.services?.forEach((service: Partial<Service>) => {
        let serviceScore = 3;
        if (service.areaId === contextAreaId) {
          serviceScore += 10;
        }
        if (service?.name?.toLowerCase().includes(term.toLowerCase())) {
          results.push({
            name: service.name || '',
            type: ResultType.SERVICE,
            id: service.serviceId || '',
            score: serviceScore,
            color: service.color || '',
            category: ResultType.SERVICE,
          });
        }
        service?.processes?.forEach((process) => {
          let processScore = 5;
          if (process.serviceId === contextServiceId || service?.areaId === contextAreaId) {
            processScore += 20;
          }
          if (process.name.toLowerCase().includes(term.toLowerCase())) {
            results.push({
              name: process.name,
              type: ResultType.PROCESS,
              id: process.processId,
              score: processScore,
              color: service.color || '',
              category: ResultType.PROCESS,
            });
          }
          if (process.policies) {
            process.policies.forEach((policy) => {
              if (policy.name.toLowerCase().includes(term.toLowerCase())) {
                results.push({
                  name: policy.name,
                  type: ResultType.POLICY,
                  id: policy.policyId,
                  score: 2,
                  color: '',
                  category: ResultType.POLICY,
                });
              }
            });
          }
        });
      });
    });
    setAllResults(results);
  };

  const allSearchResults = (selectedCategory.value === 'All' ? allResults : resultsByCategory)
    .sort((a, b) => a.category.localeCompare(b.category)) // Sort by category
    .sort((a, b) => (a.score > b.score ? -1 : 1)) // Sort by score
    .reduce((acc, element, index, array) => {
      // Check if this is the first element of a new category and count items
      if (index === 0 || array[index - 1].category !== element.category) {
        const count = array.filter((item) => item.category === element.category).length;
        acc.push(
          <div key={`divider-${element.category}`}>
            <Typography variant="body12semibold" color="textSecondary">
              {element.category} ({count})
            </Typography>
            <Divider />
          </div>,
        );
      }
      acc.push(
        <ListItem button key={index} onClick={() => handleListItemClick(element)}>
          {element.type && (
            <Box
              className="flex items-center justify-between mr-2 p-0.5 rounded-full"
              sx={{
                backgroundColor: element.color,
                color: isColorDark(element.color) ? theme.palette.common.white : theme.palette.primary.main,
              }}>
              <Icon className="!flex">
                {element.type === 'Area' ? (
                  <AreaIcon24 />
                ) : element.type === 'Policy' ? (
                  <PolicyOutlined />
                ) : element.type === 'Service' ? (
                  <ServicesIcon24 />
                ) : element.type === 'Process' ? (
                  <GroupWorkOutlined />
                ) : null}
              </Icon>
            </Box>
          )}
          <ListItemText className="text-body14semibold font-semibold" primary={highlightSearchTerm(element.name)} />
        </ListItem>,
      );
      return acc;
    }, [] as React.ReactNode[]);

  const handleListItemClick = (result: any) => {
    const getPlural = (type: string) => {
      if (type === 'area') {
        return 'areas';
      } else if (type === 'service') {
        return 'services';
      } else if (type === 'process') {
        return 'processes';
      } else if (type === 'policy') {
        return 'policies';
      }
    };

    const url = `/${getPlural(result.type.toLowerCase())}/${result.id}`;
    history.push(url);
    setSearchTerm('');
    setAllResults([]);
    onClose();
  };

  const handleCategoryChange = (category: { text: string; value: ResultType | 'All' }) => {
    setSelectedCategory(category);
    setResultsByCategory(allResults.filter((result) => result.category === category.value));
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setAllResults([]);
  };

  return (
    <Dialog
      ref={modalRef}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={false}
      sx={{
        '& .MuiDialog-paper': {
          width: '650px',
          maxWidth: '100%',
          margin: 'auto',
          top: 128,
          position: 'absolute',
          minHeight: 310,
          maxHeight: 'calc(100vh - 256px)',
          overflowY: 'auto',
        },
      }}>
      <Box className="flex justify-between">
        <Box className="flex w-full items-center justify-between">
          <WolfSearchBorderless
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            onClear={handleClearSearch}
            onFocus={handleFocus}
          />
        </Box>
        <Box className="">
          <IconButton onClick={onClose} color="primary">
            <CloseOutlined />
          </IconButton>
        </Box>
      </Box>
      <Box className="flex flex-wrap justify-between bg-primaryFrozen border border-solid border-primaryIcy p-2">
        {categories.map((category) => (
          <WolfChip
            key={category.value}
            label={category.text}
            selected={selectedCategory.value === category.value}
            onClick={() => handleCategoryChange(category)}
            customColor={
              selectedCategory.value === category.value ? theme.palette.common.white : theme.palette.primaryDark.main
            }
            customBgColor={
              selectedCategory.value === category.value ? theme.palette.primaryDark.main : theme.palette.common.white
            }
            customPadding={theme.spacing(2)}
          />
        ))}
      </Box>

      <DialogContent className="!p-0">
        {allResults.length === 0 ? (
          <Box className="flex justify-center items-center h-48">
            <Typography>{resultsMessage}</Typography>
          </Box>
        ) : (
          <Paper className="px-2">
            <List>{allSearchResults}</List>
          </Paper>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default WolfSearchModal;
