import WolfButton from '@components/ui/WolfButton';
import WolfIconButton from '@components/ui/WolfIconButton';
import { AreaIcon24, ServicesIcon24 } from '@components/ui/WolfIcons';
import WolfSectionColor from '@components/ui/WolfSectionColor';
import { VisuallyHiddenInput } from '@components/VisuallyHiddenInput';
import { useTranslation } from '@hooks/useTranslation';
import {
  AttachFileOutlined,
  ContactPageOutlined,
  CorporateFareOutlined,
  GroupWorkOutlined,
  HubOutlined,
  PolicyOutlined,
} from '@mui/icons-material';
import SpaceDashboardOutlined from '@mui/icons-material/SpaceDashboardOutlined';
import ViewList from '@mui/icons-material/ViewList';
import ViewModule from '@mui/icons-material/ViewModule';
import { Box, Theme, Typography, useTheme } from '@mui/material';
import { LegacyRef } from 'react';
import { useHistory } from 'react-router-dom';
import WolfTextLink from './ui/WolfTextLink';
import WolfTooltip from './ui/WolfTooltip';

interface GridListSubheaderProps {
  title: string;
  titleLink?: string;
  subTitle?: string;
  subTitleLink?: string;
  subSubTitle?: string;
  subSubTitleLink?: string;
  buttonText: string | null;
  buttonTooltip?: string | null;
  onDrawerOpen: () => void;
  onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleView: () => void;
  isGridView: boolean;
  inputRef?: LegacyRef<HTMLInputElement> | undefined;
  icon?: 'dashboard' | 'area' | 'policy' | 'service' | 'process' | 'organization' | 'user' | 'integration' | 'document';
  iconColor?: string;
  showFavGrid?: boolean;
}

const GridListSubheader: React.FC<GridListSubheaderProps> = ({
  title,
  titleLink,
  subTitle,
  subTitleLink,
  subSubTitle,
  subSubTitleLink,
  buttonText,
  buttonTooltip,
  onDrawerOpen,
  onFileChange,
  onToggleView,
  isGridView,
  icon = 'dashboard',
  iconColor,
  showFavGrid = true,
  inputRef,
}) => {
  const history = useHistory();
  const localeCommon = useTranslation('common');
  const theme: Theme = useTheme();
  return (
    <>
      <Box className="flex flex-wrap justify-between items-center mb-8">
        <Box className="flex items-center">
          <Box className="mx-2 flex">
            <WolfSectionColor
              color={iconColor || theme.palette.primary.main}
              icon={
                icon === 'area' ? (
                  <AreaIcon24 />
                ) : icon === 'policy' ? (
                  <PolicyOutlined />
                ) : icon === 'service' ? (
                  <ServicesIcon24 />
                ) : icon === 'process' ? (
                  <GroupWorkOutlined />
                ) : icon === 'organization' ? (
                  <CorporateFareOutlined />
                ) : icon === 'user' ? (
                  <ContactPageOutlined />
                ) : icon === 'integration' ? (
                  <HubOutlined />
                ) : icon === 'document' ? (
                  <AttachFileOutlined />
                ) : (
                  <SpaceDashboardOutlined />
                )
              }
            />
          </Box>
          <WolfTextLink
            variant="h3"
            className="mx-2 cursor-pointer"
            onClick={() => {
              history.push(titleLink || '');
            }}>
            {title}
          </WolfTextLink>
          {subTitle && (
            <Typography variant="h3" className="mx-2">
               / 
            </Typography>
          )}
          <WolfTextLink
            variant="h3"
            className="mx-2 cursor-pointer"
            onClick={() => {
              history.push(subTitleLink || '');
            }}>
            {subTitle}
          </WolfTextLink>
          {subSubTitle && (
            <Typography variant="h3" className="mx-2">
               / 
            </Typography>
          )}
          <WolfTextLink
            variant="h3semibold"
            className="ml-2 cursor pointer"
            onClick={() => {
              history.push(subSubTitleLink || '');
            }}>
            {subSubTitle}
          </WolfTextLink>
        </Box>
        {buttonText && (
          <>
            <WolfTooltip title={buttonTooltip || localeCommon['clickTo'] + ' ' + buttonText}>
              <span>
                <WolfButton variant="contained" color="primary" onClick={onDrawerOpen}>
                  {buttonText}
                </WolfButton>
              </span>
            </WolfTooltip>
            {inputRef && <VisuallyHiddenInput type="file" onChange={onFileChange} ref={inputRef} />}
          </>
        )}
      </Box>
      {showFavGrid && (
        // If screen is below md, we hide it since both look the same anyway
        <Box className="hidden items-center mb-8 md:flex">
          {/* // TODO: Enable fav functionality */}
          {/* <WolfChip label={localeCommon['favorites']} leftIcon={<FavoriteBorderOutlined />} selected={false} /> */}
          <Box marginLeft="auto">
            <WolfIconButton
              icon={<ViewModule />}
              onClick={onToggleView}
              className="!mr-2"
              selected={isGridView}
              tooltip={localeCommon['clickViewGrid']}
            />
            <WolfIconButton
              icon={<ViewList />}
              onClick={onToggleView}
              selected={!isGridView}
              tooltip={localeCommon['clickViewList']}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default GridListSubheader;
