import { gql } from 'graphql-request';

export const DOCUMENT_CATEGORY_FIELDS = gql`
  fragment DocumentCategoryFields on DocumentCategoryDto {
    documentCategoryId
    name
  }
`;

export const getDocumentCategories = gql`
  query getDocumentCategories {
    getDocumentCategories {
      ...DocumentCategoryFields
    }
  }
  ${DOCUMENT_CATEGORY_FIELDS}
`;

export const createDocumentCategory = gql`
  mutation createDocumentCategory($category: DocumentCategoryCreateInput!) {
    createDocumentCategory(category: $category) {
      ...DocumentCategoryFields
    }
  }
  ${DOCUMENT_CATEGORY_FIELDS}
`;

export const updateDocumentCategory = gql`
  mutation updateDocumentCategory($category: DocumentCategoryUpdateInput!) {
    updateDocumentCategory(category: $category) {
      ...DocumentCategoryFields
    }
  }
  ${DOCUMENT_CATEGORY_FIELDS}
`;

export const deleteDocumentCategory = gql`
  mutation deleteDocumentCategory($id: String!) {
    deleteDocumentCategory(id: $id) {
      affected
      messages
    }
  }
`;

const DocumentCategoriesGraphQL = {
  queries: {
    getDocumentCategories,
  },
  mutations: {
    createDocumentCategory,
    updateDocumentCategory,
    deleteDocumentCategory,
  },
};

export default DocumentCategoriesGraphQL;
