import login from '@assets/login.png';
import logo from '@assets/logos/mrwolf-black-simple-160@2x.png';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography } from '@mui/material';

const LoginPromo: React.FC = () => {
  const localeLogin = useTranslation('login');

  return (
    <Box className="flex flex-col bg-backgroundUI h-screen">
      <Box>
        <img src={logo} alt="Logo" className="w-20 h-20 pl-20 mt-10" />
      </Box>
      <Typography
        align="left"
        variant="h1"
        sx={{ mb: 2, fontSize: '48px', padding: '25px 80px 35px 80px', lineHeight: 1 }}>
        {localeLogin['aiAssistedMessage']}
      </Typography>
      <Box className="w-full h-1">
        <img src={login} alt="Promo" className="w-full max-h-screen object-contain" />
      </Box>
      <Box className="h-1/5"></Box>
    </Box>
  );
};

export default LoginPromo;
