import GridListSubheader from '@components/GridListSubheader';
import ItemWithSummaryDrag from '@components/ItemWithSummaryDrag';
import { graphQlClient } from '@config/graphqlClient';
import AreasGraphQL from '@graphql/area.queries';
import IntegrationsGraphQL from '@graphql/integration.queries';
import { useLoader } from '@hooks/useLoader';
import { useTranslation } from '@hooks/useTranslation';
import { Area, getAreaByIDRequest } from '@models/area.model';
import { Box, Typography } from '@mui/material';
import { checkForIntegrations } from '@utils/checkForIntegrations';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const AreaDescription: React.FC = () => {
  const [area, setArea] = useState<Area>();
  const locale = useTranslation('common');
  const localeArea = useTranslation('areas');
  const { setLoading } = useLoader();
  const history = useHistory();

  const { areaId } = useParams<{ areaId: string }>(); // TODO BRIAN types

  useEffect(() => {
    getAreaById(areaId);
    getIntegrations();
  }, [areaId]);

  const [hasCalendar, setHasCalendar] = useState(false);
  const [hasStorage, setHasStorage] = useState(false);

  const getAreaById = async (id: string) => {
    try {
      setLoading(true);
      const variables = { id };
      const data: getAreaByIDRequest = await graphQlClient.request(AreasGraphQL.queries.getAreaByID, variables);
      setArea(data?.getAreaByID);
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  const getIntegrations = async () => {
    try {
      const { calendar, storage } = checkForIntegrations(
        await graphQlClient.request(IntegrationsGraphQL.queries.getIntegrations),
      );
      setHasCalendar(calendar);
      setHasStorage(storage);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  //TODO: Reuse methods from main page
  const placeholderFn = () => {};

  return (
    <Box>
      <Box className="flex flex-wrap items-center">
        <Box className="sticky top-0 z-30 bg-backgroundUI">
          <GridListSubheader
            title={locale['area']}
            subTitle={''}
            subTitleLink=""
            buttonText={null}
            showFavGrid={false}
            isGridView={false}
            onDrawerOpen={placeholderFn}
            onToggleView={placeholderFn}
            icon="service"
            iconColor={area?.color}
          />
        </Box>
        {area && (
          <>
            <ItemWithSummaryDrag
              elements={[area]}
              elementIdName="areaId"
              showDrag={false}
              hasCalendar={hasCalendar}
              hasStorage={hasStorage}
              droppableId={'serviceUnique'}
              handleDragEnd={placeholderFn}
              drawerEditOpen={placeholderFn}
              handleCardClick={placeholderFn}
            />
            <Box className="my-4 w-full">
              <Typography variant="h3">{localeArea['servicesInside']}</Typography>
            </Box>
            <Box className="flex flex-wrap items-center w-full">
              <ItemWithSummaryDrag
                elements={area.services}
                elementIdName="serviceId"
                showDrag={false}
                showEdit={false}
                hasCalendar={hasCalendar}
                hasStorage={hasStorage}
                isGridView={true}
                droppableId={'processUnique'}
                handleDragEnd={placeholderFn}
                drawerEditOpen={placeholderFn}
                handleCardClick={(id) => history.push(`/services/${id}`)}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AreaDescription;
