export interface WolfieMessage {
  id: string;
  role: string;
  content: string;
}

export enum WolfieMode {
  BLANK_PROCESS = 'blankProcess',
  BLANK_AREA = 'blankArea',
  BLANK_SERVICE = 'blankService',
  NO_AREAS = 'noAreas',
  FTUE = 'firstTimeUser',
  EXPAND_AREA = 'expandArea',
  RESET_FTUE = 'resetFtue',
}

export enum WolfieStatus {
  RESEARCHING = 'researching',
  DONE = 'done',
  DECLINED = 'declined',
}

export enum WolfieFTUEStatus {
  SHOW = 'show',
  MESSAGE = 'message',
  IGNORE = 'ignore',
}

export enum WolfieSuggestions {
  DONE = "Looks fine, we're done here",
  CLEAN_CLOSE = 'Remove all suggestions',
}

export type PreProcessActionType =
  | 'Restart'
  | 'Add'
  | 'Edit'
  | 'Delete'
  | 'Navigate'
  | 'Clarify'
  | 'Conversational'
  | 'Done'
  | 'Declined'
  | 'Multiple';
