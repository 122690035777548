import WolfCard from '@components/ui/WolfCard';
import { useTranslation } from '@hooks/useTranslation';
import { SummaryCardPoliciesProps } from '@models/components.model';
import { PolicyOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, Fade } from '@mui/material';
import enhanceHTML from '@utils/enhanceHtml';
import sanitizeHTML from '@utils/sanitize-html';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const SummaryCardPolicies: React.FC<SummaryCardPoliciesProps> = ({
  color,
  name,
  description,
  content,
  handleEdit,
  hideActions = false,
  location,
}) => {
  const localeCommon = useTranslation('common');
  const localeSummaryCard = useTranslation('summaryCard');
  const history = useHistory();

  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [expandCard, setExpandCard] = useState<boolean>(false);
  const [enhancedHtml, setEnhancedHtml] = useState({ __html: '' });

  const toggleCard = () => {
    setExpandCard(!expandCard);
  };

  const closeModal = () => {
    setShowIntegrationModal(false);
  };

  const closeModalAndRedirect = () => {
    setShowIntegrationModal(false);
    history.push(`/integrations`);
  };

  useEffect(() => {
    const fetchAndEnhanceHTML = async () => {
      const enhanced = await enhanceHTML(sanitizeHTML(content));
      setEnhancedHtml(enhanced);
    };
    if (expandCard) {
      fetchAndEnhanceHTML();
    }
  }, [content, expandCard]);

  return (
    <Box className="flex justify-center">
      <WolfCard
        key={'1'}
        color={color}
        name={name}
        description={description}
        expandedContent={enhancedHtml}
        showCalendar={false}
        showStorage={false}
        badges={[]}
        headerIcon={<PolicyOutlined />}
        showDocument={!!location}
        documentLocation={location}
        showEditButton={true}
        showDragIcon={false}
        showFavIcon={false}
        showMapIcon={false}
        showExpandIcon={true}
        editAction={() => handleEdit()}
        expandToggledAction={toggleCard}
      />

      {/* //Modal for offering integration */}
      <Dialog
        className="flex justify-center items-center"
        open={showIntegrationModal}
        onClose={closeModal}
        maxWidth="xl"
        closeAfterTransition>
        <Fade in={showIntegrationModal}>
          <div className="bg-white m-10">
            <div className="flex justify-between items-center mb-5">
              <h2>{localeSummaryCard['integration']}</h2>
              <Button variant="contained" color="warning" onClick={closeModal}>
                X
              </Button>
            </div>
            <div>
              <p className="mb-5">{localeSummaryCard['integrationInactiveMessage']}</p>
              <p className="mb-5">{localeSummaryCard['integrationOfferMessage']}</p>
              <div className="flex justify-between items-center">
                <Button variant="contained" color="primary" onClick={closeModalAndRedirect}>
                  {localeCommon['yes']}
                </Button>
                <Button variant="contained" color="warning" onClick={closeModal}>
                  {localeCommon['notNow']}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Dialog>
    </Box>
  );
};

export default SummaryCardPolicies;
