import { Integration, IntegrationStatus, IntegrationType, getIntegrationRequest } from '@models/integration.model';

export const checkForIntegrations = (data: getIntegrationRequest) => {
  const integrations: any = {};
  if (
    data.getIntegrations.filter(
      (integration: Integration) =>
        integration.integrationType === IntegrationType.CALENDAR &&
        (integration.status === IntegrationStatus.ENABLED || integration.status === IntegrationStatus.GENERATED),
    ).length > 0
  ) {
    integrations['calendar'] = true;
  } else {
    integrations['calendar'] = false;
  }
  if (
    data.getIntegrations.filter(
      (integration: Integration) =>
        integration.integrationType === IntegrationType.STORAGE &&
        (integration.status === IntegrationStatus.ENABLED || integration.status === IntegrationStatus.GENERATED),
    ).length > 0
  ) {
    integrations['storage'] = true;
  } else {
    integrations['storage'] = false;
  }

  return integrations;
};
