import WolfBadge from '@components/ui/WolfBadge';
import WolfSectionColor from '@components/ui/WolfSectionColor';
import { useTranslation } from '@hooks/useTranslation';
import {
  AccountTreeOutlined,
  AssignmentOutlined,
  CalendarMonthOutlined,
  DragIndicator,
  DriveFolderUploadOutlined,
  EditOutlined,
  ExpandOutlined,
  FavoriteBorderOutlined,
} from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, Dialog, Fade, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BASE_DOCS_LINK_URL, CALENDAR_URL, DRIVE_FOLDER_URL } from '@utils/constants';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import WolfTooltip from './WolfTooltip';

interface WolfCardProps {
  name: string;
  description: string;
  goal?: string;
  color: string;
  expandedContent?: { __html: string };
  showEditButton?: boolean;
  showDragIcon?: boolean;
  showCalendar?: boolean;
  showStorage?: boolean;
  showMapIcon?: boolean;
  showFavIcon?: boolean;
  showExpandIcon?: boolean;
  showDescriptionLabel?: boolean;
  isWolfieGenerated?: boolean;
  storageLocation?: string;
  showDocument?: boolean;
  documentLocation?: string;
  calendarLink?: string;
  badges?: string[];
  badgeColor?: 'default' | 'dark' | 'info' | 'success' | 'warning' | 'error' | 'new';
  summaryCounts?: { icon: React.ReactNode; tooltip: string; count: number }[];
  customCardClass?: string;
  headerIcon?: React.ReactNode;
  emptyMessage?: string;
  editAction?: (e: any) => void;
  titleClickAction?: () => void;
  cardClickAction?: () => void;
  expandToggledAction?: () => void;
  handleBadgeClick?: (e: any, badge: string) => void;
}

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'border 0.3s',
  maxWidth: '684px',
  border: '1px solid ' + theme.palette.primaryFrozen.main,
  boxShadow: 'none',
  '&:hover': {
    outline: `2px solid ${theme.palette.primaryIcy.main}`,
    boxShadow: 'none',
    '& .drag-icon': {
      display: 'inline-flex',
    },
  },
}));

const CardActionsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  top: 8,
  right: 8,
  padding: 0,
});

const DragIcon = styled(IconButton)({
  display: 'none',
});

const WolfCard: React.FC<WolfCardProps> = ({
  name,
  description,
  goal,
  color,
  expandedContent,
  showEditButton = false,
  showDragIcon = false,
  showCalendar = false,
  showStorage = false,
  showMapIcon = true,
  showFavIcon = false,
  showDocument = false,
  showExpandIcon = false,
  showDescriptionLabel = false,
  isWolfieGenerated,
  storageLocation,
  documentLocation,
  calendarLink,
  badges = [],
  badgeColor,
  summaryCounts,
  customCardClass,
  headerIcon,
  emptyMessage = false,
  editAction,
  titleClickAction,
  cardClickAction,
  expandToggledAction,
  handleBadgeClick,
}) => {
  const history = useHistory();
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [showExpandedContent, setShowExpandedContent] = useState(false);

  const localeCommon = useTranslation('common');
  const localeSummaryCard = useTranslation('summaryCard');

  const handleCalendarClick = () => {
    if (calendarLink) {
      history.push(CALENDAR_URL);
    } else {
      setShowIntegrationModal(true);
    }
  };

  const handleStorageClick = () => {
    if (showStorage && storageLocation) {
      window.open(DRIVE_FOLDER_URL + storageLocation, '_blank');
    } else if (showStorage) {
      return;
    } else {
      setShowIntegrationModal(true);
    }
  };

  const handleDocumentClick = () => {
    if (showDocument) {
      window.open(BASE_DOCS_LINK_URL + documentLocation + '/edit', '_blank');
    } else {
      setShowIntegrationModal(true);
    }
  };

  const handleExpandClick = () => {
    if (expandToggledAction && !showExpandedContent) {
      expandToggledAction();
    }
    setShowExpandedContent(!showExpandedContent);
  };

  const closeModal = () => {
    setShowIntegrationModal(false);
  };

  const closeModalAndRedirect = () => {
    setShowIntegrationModal(false);
    history.push(`/integrations`);
  };

  return (
    <>
      <StyledCard
        className={
          'w-full relative flex flex-wrap ' +
          customCardClass +
          (isWolfieGenerated ? ' rounded-sm bg-primaryDark !opacity-30' : '')
        }
        onClick={cardClickAction}>
        <CardContent className="flex flex-wrap h-full w-full">
          <Box mb={2} className="w-full">
            <Box mb={2}>
              <Box className="flex items-center">
                <WolfSectionColor color={color} icon={headerIcon} />
                {emptyMessage && (
                  <WolfTooltip title={emptyMessage}>
                    <Box className="ml-1 !w-3 !h-3 bg-feedbackWarning  rounded-lg"></Box>
                  </WolfTooltip>
                )}
              </Box>
            </Box>
            <Typography
              variant="body16semibold"
              className="cursor-pointer"
              component="div"
              mb={2}
              onClick={titleClickAction}>
              {name}
            </Typography>

            {showDescriptionLabel && description && (
              <Box>
                <Typography variant="body14semibold" color="primary" mb={2}>
                  {localeCommon['description']}
                </Typography>
              </Box>
            )}
            <Typography variant="body14" color="primary" mb={2}>
              {description}
            </Typography>
            {goal && (
              <Box className="pt-6">
                {' '}
                <Box>
                  <Typography variant="body14semibold" color="primary" mb={2}>
                    {localeCommon['goal']}
                  </Typography>
                </Box>
                <Typography variant="body14" color="primary" mb={2}>
                  {goal}
                </Typography>
              </Box>
            )}
          </Box>
          {showExpandedContent && <div className="reset-base-styles" dangerouslySetInnerHTML={expandedContent}></div>}
          {badges && badges?.length > 0 && (
            <Box className="flex flex-wrap gap-2 mt-auto w-full">
              {badges?.map((badge) => (
                <WolfBadge color={badgeColor || 'default'} key={badge} onBadgeClick={handleBadgeClick}>
                  {badge}
                </WolfBadge>
              ))}
            </Box>
          )}
          {summaryCounts && summaryCounts?.length > 0 && (
            <Box className="flex flex-wrap px-2 justify-between mt-auto w-full">
              {summaryCounts?.map(({ icon, count, tooltip }, idx) => (
                <Box key={idx}>
                  <WolfTooltip title={tooltip}>
                    <Box className="flex flex-wrap">
                      {icon}
                      <Typography>{count}</Typography>
                    </Box>
                  </WolfTooltip>
                </Box>
              ))}
            </Box>
          )}
        </CardContent>
        <CardActions className="!p-0" disableSpacing>
          <CardActionsContainer>
            {showDragIcon && (
              <DragIcon aria-label="drag" className="drag-icon">
                <WolfTooltip title={localeCommon['dragToReorder']}>
                  <DragIndicator />
                </WolfTooltip>
              </DragIcon>
            )}
            {showExpandIcon && (
              <IconButton aria-label="storage" onClick={handleExpandClick}>
                <WolfTooltip title={localeCommon['expand']}>
                  <ExpandOutlined />
                </WolfTooltip>
              </IconButton>
            )}
            {showDocument && (
              <IconButton aria-label="storage" onClick={handleDocumentClick}>
                <WolfTooltip title={localeCommon['clickShowDoc']}>
                  <AssignmentOutlined />
                </WolfTooltip>
              </IconButton>
            )}
            {showStorage && (
              <IconButton aria-label="storage" onClick={handleStorageClick}>
                <WolfTooltip title={localeCommon['clickShowStorage']}>
                  <DriveFolderUploadOutlined />
                </WolfTooltip>
              </IconButton>
            )}
            {showCalendar && (
              <IconButton aria-label="calendar" onClick={handleCalendarClick}>
                <WolfTooltip title={localeCommon['clickShowCalendar']}>
                  <CalendarMonthOutlined />
                </WolfTooltip>
              </IconButton>
            )}
            {showFavIcon && (
              <IconButton aria-label="add to favorites">
                <FavoriteBorderOutlined />
              </IconButton>
            )}
            {showMapIcon && (
              <IconButton aria-label="process map">
                <WolfTooltip title={localeCommon['clickShowMap']}>
                  <AccountTreeOutlined />
                </WolfTooltip>
              </IconButton>
            )}{' '}
            {showEditButton && (
              <IconButton aria-label="edit" onClick={editAction}>
                <WolfTooltip title={localeCommon['clickEdit']}>
                  <EditOutlined />
                </WolfTooltip>
              </IconButton>
            )}
          </CardActionsContainer>
        </CardActions>
      </StyledCard>

      {/* TODO: Move to a separate component */}
      <Dialog
        className="flex justify-center items-center"
        open={showIntegrationModal}
        onClose={closeModal}
        maxWidth="xl"
        closeAfterTransition>
        <Fade in={showIntegrationModal}>
          <div className="bg-white m-10">
            <div className="flex justify-between items-center mb-5">
              <h2>{localeSummaryCard['integration']}</h2>
              <Button variant="contained" color="warning" onClick={closeModal}>
                X
              </Button>
            </div>
            <div>
              <p className="mb-5">{localeSummaryCard['integrationInactiveMessage']}</p>
              <p className="mb-5">{localeSummaryCard['integrationOfferMessage']}</p>
              <div className="flex justify-between items-center">
                <Button variant="contained" color="primary" onClick={closeModalAndRedirect}>
                  {localeCommon['yes']}
                </Button>
                <Button variant="contained" color="warning" onClick={closeModal}>
                  {localeCommon['notNow']}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Dialog>
    </>
  );
};

export default WolfCard;
