import { MarkerType, Position } from 'reactflow';

export function drawEdge(id: string, target: string, source: string) {
  return {
    id,
    target,
    source,
    targetHandle: Position.Left,
    targetSource: Position.Right,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    type: 'simplebezier',
  };
}
