import { useTranslation } from '@hooks/useTranslation';
import { Integration, IntegrationType } from '@models/integration.model';
import { Box, Typography } from '@mui/material';

interface GenerateStructureIntegrationModalProps {
  integration: Integration;
}

const GenerateStructureIntegrationModal: React.FC<GenerateStructureIntegrationModalProps> = ({ integration }) => {
  const localeIntegrations = useTranslation('integrations');

  return (
    <Box>
      <Typography variant="body16semibold">
        {localeIntegrations['confirmGenerateMessage']
          .replace('{type}', integration.integrationType)
          .replace('{provider}', integration.integrationProvider)}
      </Typography>
      <Typography variant="body16">
        {integration.integrationType === IntegrationType.CALENDAR &&
          localeIntegrations['confirmGenerateMessageCalendar']}
        {integration.integrationType === IntegrationType.STORAGE && localeIntegrations['confirmGenerateMessageStorage']}
      </Typography>
    </Box>
  );
};

export default GenerateStructureIntegrationModal;
