import { MarkerType, Position } from 'reactflow';

export function drawExternalProcessWithEdge(
  mainNode: any,
  mainProcess: any,
  step: any,
  i: number,
  inner: boolean = false,
  majCt?: number,
  minCt?: number,
  lastNode?: any,
  label: string = '',
  preLabel: string = '',
  style: string = 'simplebezier',
) {
  const node = {
    id: step.stepId,
    position: {
      x: mainNode.position.x + (inner ? 70 : 50),
      y: lastNode ? lastNode.position.y + 50 : mainNode.position.y + 50 * (i + 1),
    },
    parent: mainNode.id,
    data: {
      label: inner ? majCt + '. ' + minCt + ' ' + step.name : i + 1 + '. ' + step.name,
      parentId: mainNode.id,
    },
    type: 'ProcessStepInside',
    onNodeClick: () => {
      console.log('clicked');
    },
  };
  const edge = {
    id: step?.stepId + 'procEdge',
    source: step?.stepId,
    target: 'external' + step?.stepId,
    targetHandle: Position.Left,
    sourceHandle: Position.Right,
    zIndex: 100,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    type: style,
    parent: mainNode.id,
  };
  const externalNode = {
    id: 'external' + step.stepId,
    position: {
      x: mainNode.position.x + 350,
      y: inner ? lastNode.position.y + 150 : lastNode ? lastNode.position.y + 50 : mainNode.position.y + 100 * (i + 1),
    },
    data: {
      label,
      preLabel,
      isExternal: true,
    },
    type: 'Process',
    onNodeClick: () => {
      console.log('clicked');
    },
  };

  return { node, edge, externalNode };
}
