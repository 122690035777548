import { gql } from 'graphql-request';

export const INTEGRATIONS_FIELDS = gql`
  fragment IntegrationFields on IntegrationDto {
    integrationId
    integrationType
    integrationProvider
    status
  }
`;

export const getIntegrations = gql`
  query getIntegrations {
    getIntegrations {
      ...IntegrationFields
    }
  }

  ${INTEGRATIONS_FIELDS}
`;

export const generateStructure = gql`
  mutation generateStructure($integration: IntegrationGenerateStructureInput!) {
    generateStructure(integration: $integration)
  }
`;

export const deleteIntegration = gql`
  mutation deleteIntegration($id: String!) {
    deleteIntegration(id: $id) {
      affected
      messages
    }
  }
`;

const IntegrationsGraphQL = {
  queries: {
    getIntegrations,
  },
  mutations: {
    generateStructure,
    deleteIntegration,
  },
};

export default IntegrationsGraphQL;
