export const triggerFileDownload = (uri: string) => {
  // Create a new anchor element
  const link = document.createElement('a');
  link.href = uri;
  link.target = "_blank";
  link.download = 'true'; // This attribute prompts the file to be downloaded

  // Append to the document and click
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
}