import GridListSubheader from '@components/GridListSubheader';
import ItemWithSummaryDrag from '@components/ItemWithSummaryDrag';
import { graphQlClient } from '@config/graphqlClient';
import IntegrationsGraphQL from '@graphql/integration.queries';
import ServicesGraphQL from '@graphql/service.queries';
import { useLoader } from '@hooks/useLoader';
import { useTranslation } from '@hooks/useTranslation';
import { Area } from '@models/area.model';
import { Process, ProcessCategory } from '@models/process.model';
import { getServiceByIDRequest, Service } from '@models/service.model';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { checkForIntegrations } from '@utils/checkForIntegrations';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const ServiceDescription: React.FC = () => {
  const locale = useTranslation('common');
  const localeServices = useTranslation('services');
  const [service, setService] = useState<Service>();
  const [selectedArea, setSelectedArea] = useState<Area>();
  const { serviceId } = useParams<{ serviceId: string }>();
  const { setLoading } = useLoader();
  const history = useHistory();

  useEffect(() => {
    getServiceById(serviceId);
    getIntegrations();
  }, [serviceId]);

  const [hasCalendar, setHasCalendar] = useState(false);
  const [hasStorage, setHasStorage] = useState(false);

  const getServiceById = async (id: string) => {
    setLoading(true);
    try {
      const variables = { id };
      const data: getServiceByIDRequest = await graphQlClient.request(
        ServicesGraphQL.queries.getServiceByID,
        variables,
      );
      setService(data?.getServiceByID);
      setSelectedArea(data?.getServiceByID?.area);
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  const getCategories = (service: Service, includeAll = false) => {
    const uniqueCatArray: ProcessCategory[] = [];
    const uniqueCat: { [key: string]: boolean } = {};

    if (!service || !service.processes || service.processes.length === 0) return uniqueCatArray;

    service.processes.forEach((process: Process) => {
      if (!process.category) {
        process.category = 'Uncategorized';
      }
      if (!uniqueCat[process.category]) {
        uniqueCat[process.category] = true;
        uniqueCatArray.push({ name: process.category, processCategoryId: process.processId });
      }
    });

    if (uniqueCatArray.length > 0 && includeAll) {
      uniqueCatArray.push({ name: 'All', processCategoryId: '-1' });
    }

    return uniqueCatArray.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const getIntegrations = async () => {
    try {
      const { calendar, storage } = checkForIntegrations(
        await graphQlClient.request(IntegrationsGraphQL.queries.getIntegrations),
      );
      setHasCalendar(calendar);
      setHasStorage(storage);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  //TODO: Reuse methods from main page (if at all needed)
  const placeholderFn = () => {};

  return (
    <Box>
      <Box className="flex flex-wrap items-center">
        <Box className="sticky top-0 z-30 bg-backgroundUI">
          <GridListSubheader
            title={locale['areas']}
            subTitle={selectedArea?.name || locale['allAreas']}
            subTitleLink={'/areas/' + selectedArea?.areaId}
            buttonText={null}
            showFavGrid={false}
            isGridView={false}
            onDrawerOpen={placeholderFn}
            onToggleView={placeholderFn}
            icon="service"
            iconColor={selectedArea?.color}
          />
        </Box>

        {service && (
          <>
            <ItemWithSummaryDrag
              elements={[service]}
              elementIdName="serviceId"
              showDrag={false}
              showEdit={false}
              hasCalendar={hasCalendar}
              hasStorage={hasStorage}
              droppableId={'serviceUnique'}
              handleDragEnd={placeholderFn}
              drawerEditOpen={placeholderFn}
              handleCardClick={placeholderFn}
            />
            <Box className="!my-4 w-full">
              <Typography variant="h3">{localeServices['processesInside']}</Typography>
            </Box>
            <Box className="flex flex-wrap items-center w-full">
              {getCategories(service).map((category) => (
                <div className="w-full" key={category.processCategoryId}>
                  <Typography key={category.processCategoryId} variant="h4" className="!my-2">
                    {category.name}
                  </Typography>
                  <ItemWithSummaryDrag
                    elements={service.processes.filter(
                      (process) => category.processCategoryId === '-1' || process.category === category.name,
                    )}
                    elementIdName="processId"
                    showDrag={false}
                    showEdit={false}
                    hasCalendar={hasCalendar}
                    hasStorage={hasStorage}
                    isGridView={true}
                    droppableId={'processUnique'}
                    handleDragEnd={placeholderFn}
                    drawerEditOpen={placeholderFn}
                    handleCardClick={(id) => history.push(`/processes/${id}`)}
                  />
                </div>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ServiceDescription;
