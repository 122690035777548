import { graphQlClient } from '@config/graphqlClient';
import UserGraphQL from '@graphql/user.queries';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { Organization } from '@models/organization.model';
import { getAllowedRolesRequest, UserLocale, UserRoles, UserStatus } from '@models/user.model';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { RootState } from '@redux/store';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import WolfButton from '../ui/WolfButton';
import WolfInput from '../ui/WolfInput';

interface CreateEditUserModalProps {
  modalClose: () => void;
  createUser?: (
    organizationId: string,
    status: UserStatus,
    email: string,
    firstName: string,
    lastName: string,
    role: UserRoles,
  ) => void;
  editUser?: (
    organizationId: string,
    status: UserStatus,
    email: string,
    firstName: string,
    lastName: string,
    role: UserRoles,
    userId: string,
  ) => void;
  organizations: Organization[];
  saving: boolean;
  editing?: boolean;
  initialData?:
    | { email: string; firstName: string; lastName: string; userId: string; role: UserRoles; locale: UserLocale }
    | null
    | undefined;
}

const CreateEditUserModal: React.FC<CreateEditUserModalProps> = ({
  modalClose,
  createUser,
  editUser,
  organizations,
  saving,
  editing = false,
  initialData,
}) => {
  const localeCommon = useTranslation('common');
  const localeOrganizations = useTranslation('organizations');
  const localeUsers = useTranslation('users');
  const localeSettings = useTranslation('settings');
  const [userEmail, setUserEmail] = useState<string | null>('');
  const [firstName, setFirstName] = useState<string | null>('');
  const [lastName, setLastName] = useState<string | null>('');
  const [organizationId, setOrganizationId] = useState<string | null>('');
  const [userRole, setUserRole] = useState<UserRoles>(UserRoles.USER);
  const [roles, setRoles] = useState<UserRoles[]>([]);
  const [userStatus, setUserStatus] = useState<UserStatus>(UserStatus.ACTIVE);
  const [userLocale, setUserLocale] = useState<UserLocale>(UserLocale.EN);

  const dispatch = useDispatch();

  const { role } = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    if (editing && initialData) {
      setUserEmail(initialData.email);
      setUserRole(initialData.role);
      setFirstName(initialData.firstName);
      setLastName(initialData.lastName);
      setUserLocale(initialData.locale);
    }
  }, [editing, initialData]);

  useEffect(() => {
    getAllowedRoles();
  }, []);

  const getAllowedRoles = async () => {
    try {
      const data: getAllowedRolesRequest = await graphQlClient.request(UserGraphQL.queries.getAllowedRoles);
      setRoles(data?.getAllowedRoles || []);
      if (
        editing &&
        initialData?.role &&
        !data?.getAllowedRoles.includes(initialData?.role) &&
        !(role === UserRoles.SUPER_ADMIN)
      ) {
        dispatch(
          appendActionMessage({
            message: localeUsers['notAllowedToEdit'],
            type: SnackType.ERROR,
          }),
        );
        modalClose();
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const handleEmailChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setUserEmail(event.target.value);
  };

  const handleFirstNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setLastName(event.target.value);
  };

  const submit = () => {
    if (editing && initialData) {
      editUser &&
        editUser(
          '',
          UserStatus.ACTIVE,
          userEmail || '',
          firstName || '',
          lastName || '',
          userRole,
          initialData?.userId || '',
        );
    } else {
      // The organizationId will only be relevant if the user is a super admin, since other roles can only act in their own tenant
      // The backend will also block attemps by no super-owner roles to create users in other organizations
      createUser &&
        createUser(organizationId || '', userStatus, userEmail || '', firstName || '', lastName || '', userRole);
    }
    modalClose();
  };

  return (
    <Box>
      <WolfInput
        label={localeCommon['user'] + ' ' + localeUsers['firstName']}
        id="firstName-input"
        placeholder={localeCommon['user'] + ' ' + localeUsers['firstName']}
        name="firstName"
        value={firstName}
        onChange={handleFirstNameChange}
        fullWidth
      />
      <WolfInput
        label={localeCommon['user'] + ' ' + localeUsers['lastName']}
        id="lastName-input"
        placeholder={localeCommon['user'] + ' ' + localeUsers['lastName']}
        name="lastName"
        value={lastName}
        onChange={handleLastNameChange}
        fullWidth
      />
      <WolfInput
        label={localeCommon['user'] + ' ' + localeCommon['email']}
        id="email-input"
        placeholder={localeCommon['user'] + ' ' + localeCommon['email']}
        name="email"
        value={userEmail}
        onChange={handleEmailChange}
        fullWidth
      />
      <Box>
        {/* // TODO: Refactor using ui component */}
        <Typography variant="body14semibold">{localeCommon['user'] + ' ' + localeUsers['role']}</Typography>
        <TextField
          id="role-input"
          select
          placeholder={localeCommon['user'] + ' ' + localeUsers['role']}
          name="role"
          className="border rounded border-gray-400"
          value={userRole}
          onChange={(event) => setUserRole(roles.find((r) => r === event.target.value) || UserRoles.USER)}
          fullWidth>
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {/* We only want to show the organization select if we are not editing, since users are not allowed to change their organization */}
      {/* And we only want to show the organization select if the user is a super admin, since other roles can only act in their own tenant */}
      {!editing && role === UserRoles.SUPER_ADMIN && (
        <Box>
          {/* // TODO: Refactor using ui component */}
          <Typography variant="body14semibold">
            {localeCommon['user'] + ' ' + localeOrganizations['organization']}
          </Typography>
          <TextField
            id="organization-input"
            select
            placeholder={localeCommon['user'] + ' ' + localeOrganizations['organization']}
            name="organization"
            className="border rounded border-gray-400"
            value={organizationId}
            onChange={(event) => setOrganizationId(event.target.value)}
            fullWidth>
            {organizations.map((organization) => (
              <MenuItem key={organization.organizationId} value={organization.organizationId}>
                {organization.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}
      {!editing && (
        <Box>
          {/* // TODO: Refactor using ui component */}
          <Typography variant="body14semibold">{localeCommon['user'] + ' ' + localeCommon['status']}</Typography>
          <TextField
            id="status-input"
            select
            placeholder={localeCommon['user'] + ' ' + localeCommon['status']}
            name="status"
            className="border rounded border-gray-400"
            value={userStatus}
            onChange={(event) => setUserStatus(event.target.value as UserStatus)}
            fullWidth>
            <MenuItem value={UserStatus.ACTIVE}>{localeCommon['enabled']}</MenuItem>
            <MenuItem value={UserStatus.DISABLED}>{localeCommon['disabled']}</MenuItem>
          </TextField>
        </Box>
      )}
      {
        <Box>
          <Typography variant="body14semibold">{localeCommon['user'] + ' ' + localeUsers['locale']}</Typography>
          <TextField
            id="locale-input"
            select
            placeholder={localeUsers['locale']}
            name="locale"
            className="border rounded border-gray-400"
            value={userLocale}
            onChange={(event) => setUserLocale(event.target.value as UserLocale)}
            fullWidth>
            <MenuItem value={UserLocale.EN}>{localeSettings['english']}</MenuItem>
            <MenuItem value={UserLocale.ES}>{localeSettings['spanish']}</MenuItem>
          </TextField>
        </Box>
      }
      <Box className="flex justify-between my-4">
        <WolfButton onClick={modalClose} color="secondary" variant="outlined">
          {localeCommon['cancel']}
        </WolfButton>
        <WolfButton onClick={submit} color="primary" autoFocus disabled={saving} variant="outlined">
          {editing ? localeCommon['edit'] : localeCommon['create']}
        </WolfButton>
      </Box>
    </Box>
  );
};

export default CreateEditUserModal;
