import tinycolor from 'tinycolor2';

function generateColorVariations(selectedColor: string, exclude: string[], quantity = 10) {
  const selectedColorObj = tinycolor(selectedColor);
  const variations = [];
  const minLux = 0.2;
  const maxLux = 0.9;
  const getLight = (i: number) => ((i / 2) * (maxLux - minLux)) / (quantity - 1) + minLux;

  let i = 0;
  while (variations.length < quantity && i < 100) {
    const originalColor = selectedColorObj.clone().toHsl();
    const resultColor = { ...originalColor, l: getLight(i) };
    const variationColor = tinycolor(resultColor).toHexString();
    if (!exclude.includes(variationColor)) {
      variations.push(variationColor);
    }
    i++;
  }
  return variations;
}

export default generateColorVariations;
