import { CalendarRepetition } from '@models/integration.model';

const getRepeatRule = (
  repeatType: CalendarRepetition,
  repeatEvery: number,
  selectedDays: string[],
  selectedDay: string,
  selectedMonth?: number,
  position?: string | null,
  iterations?: number,
  endDate?: string,
) => {
  let repeat: string | null = '';
  switch (repeatType) {
    case CalendarRepetition.DAILY:
      repeat = `RRULE:FREQ=DAILY;INTERVAL=${repeatEvery};`;
      break;
    case CalendarRepetition.WEEKLY:
      repeat = `RRULE:FREQ=WEEKLY;INTERVAL=${repeatEvery};BYDAY=${selectedDays.join(',')};`;
      break;
    case CalendarRepetition.MONTHLY:
      if (position) {
        repeat = `RRULE:FREQ=MONTHLY;BYSETPOS=${position};BYDAY=${selectedDay};INTERVAL=${repeatEvery};`;
      } else {
        repeat = `RRULE:FREQ=MONTHLY;BYMONTHDAY=${selectedDay};INTERVAL=${repeatEvery};`;
      }
      break;
    case CalendarRepetition.YEARLY:
      if (position) {
        repeat = `RRULE:FREQ=YEARLY;BYSETPOS=${position};BYDAY=${selectedDay};BYMONTH=${selectedMonth};`;
      } else {
        repeat = `RRULE:FREQ=YEARLY;BYMONTH=${selectedMonth};BYMONTHDAY=${selectedDay}`;
      }
      break;
    case CalendarRepetition.NO_REPEAT:
      repeat = null;
      break;
    default:
      break;
  }

  if (iterations) {
    repeat += `COUNT=${iterations};`;
  }

  if (endDate) {
    repeat += `UNTIL=${new Date(endDate).toISOString().replace(/-|:|\.\d+/g, '')};`;
  }

  return repeat;
};

export default getRepeatRule;

export const translateRepeatRule = (rrule: string) => {
  if (!rrule) {
    return null;
  }
  const values = rrule.replace('RRULE:', '').split(';');

  let repeatType = values[0];
  let repeatEvery: number = 1;
  let selectedDays: string[] = ['1'];
  let selectedDay: string = '';
  let selectedMonth: number = 1;
  let position: string = '';
  const hasPosition = rrule.includes('BYSETPOS');
  switch (repeatType) {
    case 'FREQ=DAILY':
      repeatType = CalendarRepetition.DAILY;
      repeatEvery = Number(values[1].replace('INTERVAL=', ''));
      break;
    case 'FREQ=WEEKLY':
      repeatType = CalendarRepetition.WEEKLY;
      repeatEvery = Number(values[1].replace('INTERVAL=', ''));
      selectedDays = values[2].replace('BYDAY=', '').split(',');
      break;
    case 'FREQ=MONTHLY':
      repeatType = CalendarRepetition.MONTHLY;
      if (hasPosition) {
        position = values[1].replace('BYSETPOS=', '');
        selectedDay = values[2].replace('BYDAY=', '');
        repeatEvery = Number(values[3].replace('INTERVAL=', ''));
      } else {
        selectedDay = values[1].replace('BYMONTHDAY=', '');
        repeatEvery = Number(values[2].replace('INTERVAL=', ''));
      }
      break;
    case 'FREQ=YEARLY':
      repeatType = CalendarRepetition.YEARLY;
      if (hasPosition) {
        position = values[1].replace('BYSETPOS=', '');
        selectedDay = values[2].replace('BYDAY=', '');
        selectedMonth = Number(values[3].replace('BYMONTH=', ''));
      } else {
        selectedDay = values[2].replace('BYMONTHDAY=', '');
        selectedMonth = Number(values[1].replace('BYMONTH=', ''));
      }
      break;
    default:
      break;
  }

  if (values.includes('COUNT')) {
    return {
      repeatType,
      repeatEvery,
      selectedDays,
      selectedDay,
      selectedMonth,
      position,
      iterations: Number(values[values.indexOf('COUNT')].replace('COUNT=', '')),
    };
  }

  if (values.includes('UNTIL')) {
    return {
      repeatType,
      repeatEvery,
      selectedDays,
      selectedDay,
      selectedMonth,
      position,
      endDate: new Date(values[values.indexOf('UNTIL')].replace('UNTIL=', '')),
    };
  }

  return { repeatType, repeatEvery, selectedDays, selectedDay, selectedMonth, position };
};
