import {
  considerThisFilePrompt,
  emptyAreaInitialMessage,
  emptyProcessInitialMessage,
  emptyServiceInitialMessage,
  expandAreaInitialMessage,
  firstTimeUserInitialMessage,
  generalExplanation,
  noAreasInitialMessage,
  resetFTUEInitialMessage,
} from '@assets/ai/prompts';
import wolfie from '@assets/wolfie.png';
import WolfAvatar from '@components/ui/WolfAvatar';
import WolfButton from '@components/ui/WolfButton';
import WolfInput from '@components/ui/WolfInput';
import WolfTooltip from '@components/ui/WolfTooltip';
import { VisuallyHiddenInput } from '@components/VisuallyHiddenInput';
import { getTokenLocalStorage, getTokenSessionStorage } from '@config/storage';
import { useTranslation } from '@hooks/useTranslation';
import { UserRoles } from '@models/user.model';
import { PreProcessActionType, WolfieMode, WolfieStatus, WolfieSuggestions } from '@models/wolfie.model';
import { AttachFileOutlined, Close, Minimize, VideoCall, VoiceChat } from '@mui/icons-material';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAppSelector } from '@redux/hooks';
import {
  confirmAllWolfieElements,
  disableWolfie,
  permanentlyDisableWolfie,
  setWolfieData,
  setWolfieLoading,
  setWolfieMessages,
  setWolfieMinimized,
  setWolfieSpeaking,
  setWolfieStatus,
  setWolfieUnableToConfirmElement,
  setWolfieVideoMode,
} from '@redux/reducers/wolfie.reducer';
import { RootState } from '@redux/store';
import {
  ContextInfo,
  generateAddedEntityMessage,
  generateAddedEntityMessageResponse,
  generateAddedEntitySystemMessage,
  generateBlankAreaContextMessage,
  generateBlankProcessContextMessage,
  generateBlankServiceContextMessage,
  generateExpandAreaContextMessage,
  generateFirstTimeContextMessage,
  generateNoAreasContextMessage,
  generateResetFtueContextMessage,
} from '@utils/ai/generateContextMessage';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InteractiveAvatar from './InteractiveAvatar';
import WolfieMicInput from './WolfieMicInput';

export interface WolfieProps {}

const Wolfie: React.FC<WolfieProps> = ({}) => {
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const localeCommon = useTranslation('common');
  const localeWolfie = useTranslation('wolfie');

  const dispatch = useDispatch();
  const history = useHistory();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const ALLOWED_WOLFIE_REDIRECTS = ['processes', 'areas', 'services'];
  const enabledWolfieVoice = process.env.REACT_APP_ENABLE_VOICE_WOLFIE === 'true';
  const enabledWolfieVideo = process.env.REACT_APP_ENABLE_VIDEO_WOLFIE === 'true';
  const [input, setInput] = useState('');
  const [fileError, setFileError] = useState<string | null>(null);
  const {
    name: organizationName,
    description: organizationDescription,
    context: organizationContext,
  } = useAppSelector((state: RootState) => state.organization);
  const { avatar, rememberMe, role: userRole } = useAppSelector((state: RootState) => state.user);
  const {
    context: contextInfo,
    enabled: wolfieEnabled,
    mode,
    showAttach,
    showSuggestions,
    minimized,
    confirmedElements,
    messages: wolfieMessages,
    loading: wolfieLoading,
    status: currentStatus,
    data: wolfieData,
    suggestions: wolfieSuggestions,
    currentlyEditing: currentlyEditingWolfie,
    videoMode: wolfieVideoMode,
    unableToConfirmElement,
    speaking: wolfieSpeaking,
  } = useAppSelector((state: RootState) => state.wolfie);

  // So that we scroll when new messages are added
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [wolfieMessages]);

  useEffect(() => {
    if (confirmedElements.length > 0 && currentStatus === WolfieStatus.RESEARCHING) {
      const messageId = Math.random().toString(36).slice(2, 20);
      const id = confirmedElements[confirmedElements.length - 1];
      const name =
        wolfieData?.find((element: any) => element.id === confirmedElements[confirmedElements.length - 1])?.name || '';
      dispatch(
        setWolfieMessages([
          ...wolfieMessages,
          generateAddedEntitySystemMessage(messageId, id, name),
          generateAddedEntityMessage(messageId + '2', id, name),
          generateAddedEntityMessageResponse(messageId + '3', id, name),
        ]),
      );
    }
  }, [confirmedElements, currentStatus]);

  // Identify the "mode" of the conversation
  useEffect(() => {
    if (mode && contextInfo) {
      sendInitialMessage(mode, contextInfo);
    }
  }, [mode, contextInfo]);

  useEffect(() => {
    if (unableToConfirmElement) {
      const updatedMessages = [
        ...wolfieMessages,
        {
          role: 'assistant',
          content: 'I am sorry, I was unable to save that. Please try again.',
          id: Math.random().toString(36).slice(2, 20),
        },
      ];
      dispatch(setWolfieMessages(updatedMessages));
      dispatch(setWolfieUnableToConfirmElement(false));
    }
  }, [unableToConfirmElement]);

  const onClose = () => {
    dispatch(setWolfieMinimized(true));
  };

  const open = () => {
    dispatch(setWolfieMinimized(false));
  };

  const handleDisableWolfie = () => {
    dispatch(permanentlyDisableWolfie());
  };

  const onVideoToggle = () => {
    if (wolfieSpeaking) {
      onSpeakToggle();
    }
    dispatch(setWolfieVideoMode(!wolfieVideoMode));
  };

  const onSpeakToggle = () => {
    if (wolfieVideoMode) {
      onVideoToggle();
    }
    if (wolfieSpeaking) {
      speechSynthesis.cancel();
    } else {
      const lastAssistantMessage = [...wolfieMessages].reverse().find((message) => message.role === 'assistant');

      const speech = new SpeechSynthesisUtterance(lastAssistantMessage?.content);
      speech.lang = 'en-US';
      speechSynthesis.speak(speech);
    }
    dispatch(setWolfieSpeaking(!wolfieSpeaking));
  };

  const handleSuggestionSelection = async (suggestion: WolfieSuggestions) => {
    // We treat the suggestion as a user message and add it to the conversation
    const newMessage = {
      role: 'user',
      content: suggestion,
      id: Math.random().toString(36).slice(2, 20),
    };
    const updatedMessages = [...wolfieMessages, newMessage];
    dispatch(setWolfieMessages(updatedMessages));
    sendMessage(updatedMessages);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!input.trim()) return;
    dispatch(setWolfieLoading(true));

    // Add the user's message to the conversation history
    const newMessage = {
      role: 'user',
      content: input,
      id: Math.random().toString(36).slice(2, 20),
    };
    const updatedMessages = [...wolfieMessages, newMessage];
    dispatch(setWolfieMessages(updatedMessages));
    setInput('');
    if (mode && [WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode)) {
      sendMessage(updatedMessages);
      return;
    }
    const preProcessedMessage = await preProcessMessage(input);
    if (!preProcessedMessage) {
      dispatch(setWolfieLoading(false));
      return;
    } else if (preProcessedMessage === 'Clarify') {
      const updatedMessagesClarify = [
        ...updatedMessages,
        {
          role: 'assistant',
          content: 'I am sorry, I did not understand your request. Could you please clarify?',
          id: Math.random().toString(36).slice(2, 20),
        },
      ];
      dispatch(setWolfieMessages(updatedMessagesClarify));
      dispatch(setWolfieLoading(false));
      return;
    } else if (preProcessedMessage === 'Multiple') {
      const updatedMessagesClarify = [
        ...updatedMessages,
        {
          role: 'assistant',
          content:
            'I am sorry, I did not understand your request. There seems to be more than one instruction, could we go over them one by one?',
          id: Math.random().toString(36).slice(2, 20),
        },
      ];
      dispatch(setWolfieMessages(updatedMessagesClarify));
      dispatch(setWolfieLoading(false));
      return;
    }

    const auxNewMessage = {
      role: 'user',
      content: preProcessedMessage,
      id: Math.random().toString(36).slice(2, 20),
    };
    const auxUpdatedMessages = [...wolfieMessages, auxNewMessage];
    sendMessage(auxUpdatedMessages, updatedMessages);
  };

  // TODO: Check message workflow
  // TODO: Should also get preprocessed?
  const handleSubmitAudio = (transcription: string) => {
    const newMessage = {
      role: 'user',
      content: transcription,
      id: Math.random().toString(36).slice(2, 20),
    };
    const updatedMessages = [...wolfieMessages, newMessage];
    dispatch(setWolfieMessages(updatedMessages));
    sendMessage(updatedMessages);
  };

  const preProcessMessage = async (message: string) => {
    try {
      const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();

      const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/preProcessMessage', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch message response');
      }

      const result = await response.json();

      const action = result.action as PreProcessActionType;

      if (!action || action === 'Clarify') {
        return 'Clarify';
      }
      // TODO: Here we should upgrade the message to a more detailed one according to the action
      switch (action) {
        case 'Conversational':
          return 'Conversational';
        case 'Multiple':
          return 'Multiple';
        case 'Restart':
          return `The user has requested to restart the process. Discard the data array and start over.`;
        case 'Edit':
          return `The user has requested the following edit:${message}. 
          Return a new array of data considering that edit. 
          Make as little changes as possible to the previous data array
          Here is the previous Data array: ${JSON.stringify(wolfieData)}`;
        // TODO: Check if sending the data array is always needed
        case 'Add':
          return `The user has requested the following addition:${message}. 
          Return a new array of data considering that addition. 
          Make as little changes as possible to the previous data array
          Here is the previous Data array: ${JSON.stringify(wolfieData)}`;
        case 'Delete':
          return `The user has requested the following removal:${message}. 
          Return a new array of data considering that removal. 
          Make as little changes as possible to the previous data array
          Here is the previous Data array: ${JSON.stringify(wolfieData)}`;
        case 'Conversational':
          return message;
        case 'Done':
          return 'THe user has requested the following, seems to be done, if so, change the status to Done' + message;
        case 'Declined':
          return (
            'The user has requested the following, seems to be declined, if so, change the status to Declined' + message
          );

        case 'Navigate':
          const destination = result?.destination;
          // If we need to navigate away, we clear the status and context, and move to the new page if it is on an expected direction
          if (ALLOWED_WOLFIE_REDIRECTS.includes(destination)) {
            const newMessage = {
              role: 'assisstant',
              // TODO: We should translate this
              content: 'Navigating to ' + destination,
              id: Math.random().toString(36).slice(2, 20),
            };
            const updatedMessages = [...wolfieMessages, newMessage];
            dispatch(setWolfieMessages(updatedMessages));
            dispatch(disableWolfie());
            history.push('/' + destination);
          } else {
            return 'Clarify';
          }

          return null;
      }
    } catch (error) {
      return {
        action: null,
        success: false,
        response: 'An error occurred while processing your request.',
        error: error instanceof Error ? error.message : String(error),
      };
    }
  };

  const sendMessage = async (updatedMessages: any, updatedMessagesForState?: any) => {
    dispatch(setWolfieLoading(true));
    const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();
    let response;
    try {
      if (mode && [WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode)) {
        response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/sendMessageFTUE', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ messages: updatedMessages }),
        });
      } else {
        response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/sendMessage', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ messages: updatedMessages }),
        });
      }
      if (!response || !wolfieEnabled) {
        return;
      }

      const aiResponse = await response.json();

      const messageContent =
        aiResponse?.choices &&
        aiResponse?.choices.length > 0 &&
        aiResponse.choices[0].message?.tool_calls[0].function.arguments;

      if (!messageContent) {
        dispatch(setWolfieLoading(false));

        return;
      }

      const functionCall: any =
        aiResponse.choices[0].message?.tool_calls && aiResponse.choices[0].message?.tool_calls[0];
      let parsedContent;
      if (functionCall.function.name === 'generate_response') {
        parsedContent = JSON.parse(functionCall.function.arguments);
        let newMessages = [];
        // TODO: CHECK IF IS AND ARRAY
        if (parsedContent.shortMessages) {
          newMessages = parsedContent.shortMessages.map((message: any) => ({
            role: 'assistant',
            content: message,
            id: Math.random().toString(36).slice(2, 20),
          }));
        }
        if (updatedMessagesForState) {
          dispatch(
            setWolfieMessages([
              ...updatedMessagesForState,
              ...newMessages,
              // { content: parsedContent.verbalResponse, role: 'assistant', id: Math.random().toString(36).slice(2, 20) },
            ]),
          );
        } else {
          dispatch(
            setWolfieMessages([
              ...updatedMessages,
              ...newMessages,
              // { content: parsedContent.verbalResponse, role: 'assistant', id: Math.random().toString(36).slice(2, 20) },
            ]),
          );
        }

        // Speak the response if enabled. Using basic browser speech synthesis
        if (wolfieSpeaking) {
          parsedContent.shortMessages.forEach((message: any) => {
            const speech = new SpeechSynthesisUtterance(message);
            speech.lang = 'en-US';
            speechSynthesis.speak(speech);
          });
        }

        // Add isWolfieGenerated flag to each element of the data array
        if (
          parsedContent?.data &&
          parsedContent.data.length > 0 &&
          !(mode && [WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode))
        ) {
          parsedContent.data.forEach((element: any) => {
            element.isWolfieGenerated = true;
          });
        }

        if (
          parsedContent?.data &&
          parsedContent.data.length > 0 &&
          !(mode && [WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode))
        ) {
          dispatch(setWolfieData(parsedContent.data));
          // onDataGenerated(parsedContent.data || []);
        }

        if (parsedContent?.data && mode && [WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode)) {
          dispatch(setWolfieData(parsedContent.data));
        }

        if (
          parsedContent?.status.toLowerCase() === 'done' &&
          mode &&
          ![WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode)
        ) {
          dispatch(setWolfieStatus(WolfieStatus.DONE));
          if (wolfieData && wolfieData.length > 0) {
            dispatch(confirmAllWolfieElements());
          }
        }
        if (parsedContent?.status.toLowerCase() === 'declined') {
          dispatch(setWolfieStatus(WolfieStatus.DECLINED));
        }

        if (
          parsedContent?.status.toLowerCase() === 'done' &&
          mode &&
          [WolfieMode.FTUE, WolfieMode.RESET_FTUE].includes(mode)
        ) {
          dispatch(setWolfieStatus(WolfieStatus.DONE));
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
    dispatch(setWolfieLoading(false));
  };

  const sendInitialMessage = (mode: string, contextInfo: ContextInfo) => {
    if (contextInfo && mode === WolfieMode.BLANK_PROCESS) {
      sendMessage([
        generalExplanation,
        emptyProcessInitialMessage,
        generateBlankProcessContextMessage({
          ...contextInfo,
          orgContext: {
            name: organizationName || '',
            description: organizationDescription || '',
            mainAreas: organizationContext?.mainAreas || [],
          },
        }),
      ]);
    }
    if (contextInfo && mode === WolfieMode.BLANK_AREA) {
      sendMessage([
        generalExplanation,
        emptyAreaInitialMessage,
        generateBlankAreaContextMessage({
          ...contextInfo,
          orgContext: {
            name: organizationName || '',
            description: organizationDescription || '',
            mainAreas: organizationContext?.mainAreas || [],
          },
        }),
      ]);
    }
    if (contextInfo && mode === WolfieMode.EXPAND_AREA) {
      sendMessage([
        generalExplanation,
        expandAreaInitialMessage,
        generateExpandAreaContextMessage({
          ...contextInfo,
          orgContext: {
            name: organizationName || '',
            description: organizationDescription || '',
            mainAreas: organizationContext?.mainAreas || [],
          },
        }),
      ]);
    }
    if (contextInfo && mode === WolfieMode.BLANK_SERVICE) {
      sendMessage([
        generalExplanation,
        emptyServiceInitialMessage,
        generateBlankServiceContextMessage({
          ...contextInfo,
          orgContext: {
            name: organizationName || '',
            description: organizationDescription || '',
            mainAreas: organizationContext?.mainAreas || [],
          },
        }),
      ]);
    }
    if (contextInfo && mode === WolfieMode.NO_AREAS) {
      sendMessage([generalExplanation, noAreasInitialMessage, generateNoAreasContextMessage(contextInfo)]);
    }
    if (contextInfo && mode === WolfieMode.FTUE) {
      sendMessage([firstTimeUserInitialMessage, generateFirstTimeContextMessage(contextInfo)]);
    }
    if (contextInfo && mode === WolfieMode.RESET_FTUE) {
      sendMessage([resetFTUEInitialMessage, generateResetFtueContextMessage(contextInfo)]);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setWolfieLoading(true));
    setFileError(null);
    const selectedFile = e.target.files?.[0];

    const maxSizeInBytes = 5 * 1024 * 1024;
    if (selectedFile) {
      if (selectedFile.size > maxSizeInBytes) {
        setFileError('File size exceeds 5MB. Please select a smaller file.');
        return;
      }

      const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();
      const reader = new FileReader();
      reader.onloadend = async () => {
        const result = reader.result;
        if (typeof result !== 'string') {
          return;
        }
        const base64File = result.split(',')[1];
        const payload = {
          processId: currentlyEditingWolfie,
          fileName: selectedFile.name,
          fileType: selectedFile.type,
          fileContent: base64File,
        };

        try {
          const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/uploadAIDocument', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });

          const result = await response.json();

          if (result?.message?.content) {
            const newMessages = [
              ...wolfieMessages.filter((message: any) => message.id !== 'contextInfo'),
              {
                role: 'system',
                content: considerThisFilePrompt + result?.message?.content,
                id: Math.random().toString(36).slice(2, 20),
              },
            ];
            // We parse the response and add it to the conversation
            dispatch(setWolfieLoading(false));
            dispatch(setWolfieMessages(newMessages));
            sendMessage(newMessages);
            dispatch(setWolfieData(result.data));
          }
        } catch (error) {
          dispatch(setWolfieLoading(false));
          console.error('File upload error:', error);
        }
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setFileError('Please upload a file.');
      return;
    }
  };

  const getCurrentLoadingMessage = () => {
    if (currentStatus === 'researching') {
      switch (mode) {
        case WolfieMode.FTUE:
          if (wolfieMessages.length > 5) {
            return localeWolfie['loadingFirstTimeUserInitialized'];
          } else {
            return localeWolfie['loadingFirstTimeUser'];
          }
        case WolfieMode.RESET_FTUE:
          return localeWolfie['loading'];
        case WolfieMode.NO_AREAS:
          if (wolfieMessages.length > 5) {
            return localeWolfie['loadingNoAreasInitialized'];
          } else {
            return localeWolfie['loadingNoAreas'];
          }
        case WolfieMode.BLANK_AREA || WolfieMode.EXPAND_AREA:
          if (wolfieMessages.length > 5) {
            return localeWolfie['loadingBlankAreaInitialized'];
          } else {
            return localeWolfie['loadingBlankArea'];
          }

        case WolfieMode.BLANK_PROCESS:
          if (wolfieMessages.length > 5) {
            return localeWolfie['loadingBlankProcessInitialized'];
          } else {
            return localeWolfie['loadingBlankProcess'];
          }
        case WolfieMode.BLANK_SERVICE:
          if (wolfieMessages.length > 5) {
            return localeWolfie['loadingBlankServiceInitialized'];
          } else {
            return localeWolfie['loadingBlankService'];
          }
        default:
          return localeWolfie['loading'];
      }
    }
    return localeWolfie['loading'];
  };

  if (!wolfieEnabled) {
    return null;
  }

  if (minimized) {
    return (
      <Box onClick={open} className="cursor-pointer">
        <WolfAvatar size="56px" src={wolfie} />
      </Box>
    );
  }

  return (
    <>
      <Box>{wolfieVideoMode && <InteractiveAvatar messages={wolfieMessages} />}</Box>
      <Box
        className="bg-white border-utilityInfo border-4 border-solid p-4 rounded-lg overflow-y-auto w-full"
        sx={{ width: '650px', height: '450px' }}>
        <Box className="flex items-center gap-4 p-1 mb-3 border justify-between">
          <Box className="flex items-center gap-2">
            <WolfAvatar size="40px" src={wolfie} />
            <Typography variant="h4semibold">{localeWolfie['title']}</Typography>
          </Box>
          {(userRole === UserRoles.SUPER_ADMIN || userRole === UserRoles.OWNER) && enabledWolfieVideo && (
            <WolfTooltip title={localeWolfie['enableVideo']}>
              <IconButton onClick={onVideoToggle}>
                {wolfieVideoMode ? <VideoCall /> : <VideoCall color="disabled" />}
              </IconButton>
            </WolfTooltip>
          )}
          {(userRole === UserRoles.SUPER_ADMIN || userRole === UserRoles.OWNER) && enabledWolfieVoice && (
            <WolfTooltip title={localeWolfie['enableSpeak']}>
              <IconButton onClick={onSpeakToggle}>
                {wolfieSpeaking ? <VoiceChat /> : <VoiceChat color="disabled" />}
              </IconButton>
            </WolfTooltip>
          )}
          <WolfTooltip title={localeWolfie['closeDisable']}>
            <IconButton onClick={handleDisableWolfie}>
              <Close />
            </IconButton>
          </WolfTooltip>

          <WolfTooltip title={localeWolfie['minimize']}>
            <IconButton onClick={onClose}>
              <Minimize />
            </IconButton>
          </WolfTooltip>
        </Box>
        <Box className="flex flex-wrap gap-1 max-h-64 overflow-y-auto">
          {wolfieMessages
            .filter((message: any) => message.role !== 'system' && message.id !== 'contextInfo')
            .map((message: any, index: number) => {
              let displayMessage = message.content;
              return (
                <Box className="w-full flex" key={index}>
                  <Box className={'flex gap-1 ' + (message.role === 'user' ? 'ml-auto' : '')}>
                    {message.role === 'user' ? (
                      <WolfAvatar size="24px" src={avatar || ''} />
                    ) : (
                      <WolfAvatar size="24px" src={wolfie} />
                    )}
                    {message.role === 'user' ? (
                      <Box className="bg-gray-400 p-1 rounded">
                        <Typography variant="body14">{displayMessage}</Typography>
                      </Box>
                    ) : (
                      <Box className="bg-gray-700 text-white p-1 rounded">
                        <Typography variant="body14">{displayMessage}</Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          <Box ref={bottomRef} sx={{ scrollMargin: 100 }} />
        </Box>
        <Box className="flex justify-center">
          {wolfieLoading && (
            <Box className="flex items-center gap-3">
              <CircularProgress size={16} color="inherit" thickness={5} />
              <Typography variant="body16">{getCurrentLoadingMessage()}</Typography>
            </Box>
          )}
        </Box>
        {currentStatus === WolfieStatus.RESEARCHING && (
          <Box className="flex gap-2 mt-2 justify-around items-center absolute bottom-16 max-h-7 left-4 right-4">
            {showSuggestions &&
              wolfieSuggestions.length > 0 &&
              wolfieSuggestions.map((suggestion, index) => (
                <WolfButton
                  onClick={() => handleSuggestionSelection(suggestion)}
                  variant="outlined"
                  key={index}
                  color="secondary"
                  disabled={wolfieLoading}>
                  {suggestion}
                </WolfButton>
              ))}
          </Box>
        )}

        {fileError && <Typography color="error">{fileError}</Typography>}

        <form onSubmit={handleSubmit}>
          <Box className="flex items-center gap-2 absolute bottom-4 left-4 right-4">
            <WolfInput
              label=""
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder=""
              fullWidth
              autoFocus
              disabled={wolfieLoading}
              inputRef={inputRef}
              keepFocus={true}
            />
            <WolfButton type="submit" disabled={wolfieLoading} color="primary" variant="outlined">
              {localeCommon['send']}
            </WolfButton>
            {(userRole === UserRoles.SUPER_ADMIN || userRole === UserRoles.OWNER) && enabledWolfieVoice && (
              <WolfieMicInput onSubmit={handleSubmitAudio} disabled={wolfieLoading} />
            )}
            {showAttach && (
              <WolfTooltip title={localeCommon['clickToAttachWolfie']}>
                <span>
                  <WolfButton
                    color="secondary"
                    variant="outlined"
                    component="label"
                    tabIndex={-1}
                    disabled={wolfieLoading}>
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                      accept=".doc,.docx,.txt,.pdf"
                      disabled={wolfieLoading}
                    />
                    <AttachFileOutlined />
                  </WolfButton>
                </span>
              </WolfTooltip>
            )}
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Wolfie;
