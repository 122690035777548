import { graphQlClient } from '@config/graphqlClient';
import UserGraphQL from '@graphql/user.queries';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { getUserByEmailRequest, User } from '@models/user.model';
import { Box, Typography } from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface UserInfoModalProps {
  userToShow: string;
  organization: string;
}

const UserInfoModal: React.FC<UserInfoModalProps> = ({ userToShow, organization }) => {
  const localeCommon = useTranslation('common');
  const localeUser = useTranslation('users');
  const dispatch = useDispatch();
  const [userData, setUserData] = useState<User | null>(null);

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    try {
      const data: getUserByEmailRequest = await graphQlClient.request(UserGraphQL.queries.getUserByEmail, {
        email: userToShow,
        emailOrganizationId: organization,
      });
      setUserData(data.getUserByEmail);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  return (
    <Box className="flex flex-wrap">
      <Box className="w-full !my-4">
        <Typography variant="body16semibold">
          {localeUser['firstName']} {': '}
        </Typography>
        <Typography variant="body16">{userData?.firstName}</Typography>
      </Box>

      <Box className="w-full !my-4">
        <Typography variant="body16semibold">
          {localeUser['lastName']}
          {': '}
        </Typography>
        <Typography variant="body16">{userData?.lastName}</Typography>
      </Box>

      <Box className="w-full !my-4">
        <Typography variant="body16semibold">
          {localeCommon['email']}
          {': '}
        </Typography>
        <Typography variant="body16">{userData?.email}</Typography>
      </Box>

      <Box className="w-full !my-4">
        <Typography variant="body16semibold">
          {localeUser['role']}
          {': '}
        </Typography>
        <Typography variant="body16">{userData?.role}</Typography>
      </Box>

      <Box className="w-full !my-4">
        <Typography variant="body16semibold">
          {localeUser['status']}
          {': '}
        </Typography>
        <Typography variant="body16">{userData?.status}</Typography>
      </Box>
    </Box>
  );
};

export default UserInfoModal;
