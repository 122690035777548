import { useTranslation } from '@hooks/useTranslation';
import { TuneOutlined } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

type DropdownMenuProps = {
  items: { value: string; label: string }[];
  filter: string;
  applyFilter: (filter: string) => void;
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ items, filter, applyFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const localeCommon = useTranslation('common');

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="pl-2 w-40 flex justify-center">
      <Button
        className="text-white"
        aria-controls="filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<TuneOutlined className="icon" />}>
        {items.filter((item) => item.value === filter)[0]?.label || localeCommon['all']}
      </Button>
      <Menu id="filter-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {items.map((item, index) => (
          <MenuItem
            key={item.value}
            onClick={() => {
              applyFilter(item.value);
              handleClose();
            }}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
export default DropdownMenu;
