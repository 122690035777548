import GoogleLogo from '@assets/google.png';
import logoText from '@assets/logos/mrwolf-black-completo-160@2x.png';
import WolfButton from '@components/ui/WolfButton';
import WolfCheckbox from '@components/ui/WolfCheckbox';
import WolfInput from '@components/ui/WolfInput';
import WolfTextLink from '@components/ui/WolfTextLink';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import WolfTooltip from './ui/WolfTooltip';

export interface LoginFormProps {
  showReset?: boolean;
  showConfirm?: boolean;
  showInitial?: boolean;
  errorMessage?: string;
  googleLogin: () => void;
  signIn: (email: string, password: string, rememberMe: boolean) => void;
  forgotPassword: (email: string) => void;
  resetPassword: (email: string, confirmationCode: string, password: string, rememberMe: boolean) => void;
  confirmUserPassword: (email: string, password: string, rememberMe: boolean) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  googleLogin,
  signIn,
  forgotPassword,
  resetPassword,
  confirmUserPassword,
  showReset,
  showConfirm,
  showInitial,
  errorMessage,
}) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [rememberMe, setRememberMe] = useState(true);

  const localeLogin = useTranslation('login');
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleEnterKey = () => {
    showReset
      ? resetPassword(email, confirmationCode, password, rememberMe)
      : showConfirm
        ? confirmUserPassword(email, password, rememberMe)
        : signIn(email, password, rememberMe);
  };

  useEffect(() => {
    if (showReset || showConfirm || showInitial) {
      setShowForgotPassword(false);
    }
  }, [showReset, showConfirm, showInitial]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleEnterKey();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [email, password, confirmationCode, showReset, showConfirm]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = () => {
    if ((showReset || showConfirm) && password !== confirmPassword) {
      return dispatch(appendActionMessage({ message: localeLogin['passwordsDoNotMatch'], type: SnackType.ERROR }));
    }

    if (showReset) {
      resetPassword(email, confirmationCode, password, rememberMe);
    } else if (showConfirm) {
      confirmUserPassword(email, password, rememberMe);
    } else if (showForgotPassword) {
      forgotPassword(email);
    } else {
      signIn(email, password, rememberMe);
    }
  };

  const goToLanding = () => history.push('/');

  return (
    <Box>
      <div onClick={goToLanding} style={{ cursor: 'pointer' }}>
        <img
          src={logoText}
          alt="logo"
          width="110"
          height="32"
          className={`${isSmallScreen ? 'relative top-auto left-1/2 mt-5 mx-auto block' : 'absolute top-5 left-5'}`}
        />
        <WolfTextLink size="24px" align="right" className="absolute top-5 right-5" onClick={goToLanding}>
          {localeCommon['back']}
        </WolfTextLink>
      </div>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 4,
          maxWidth: isSmallScreen ? 300 : 400,
          minWidth: isSmallScreen ? 300 : 400,
          width: isSmallScreen ? 300 : 400,
        }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          {localeLogin['welcomeBack']}
        </Typography>
        <Button
          onClick={googleLogin}
          variant="outlined"
          sx={{ textTransform: 'none', border: '1px solid #DADFE7' }}
          className="!text-body14semibold !font-semibold w-full !mb-4 !p-2">
          <img src={GoogleLogo} alt="Google" className="mx-3" />
          {localeLogin['signInGoogle']}
        </Button>
        <Typography color="primary" variant="body14" sx={{ mb: 2 }}>
          {localeLogin['or']}
        </Typography>
        {(showConfirm || showForgotPassword || showReset) && (
          <Typography variant="body14" sx={{ mb: 2 }}>
            {showConfirm && localeLogin['confirmUserMessage']}
            {showForgotPassword && localeLogin['forgotPasswordMessage']}
            {showReset && localeLogin['resetPasswordMessage']}
          </Typography>
        )}
        {showInitial && (
          <Typography variant="body14" sx={{ mb: 2 }}>
            {localeLogin['initialLoginMessage']}
          </Typography>
        )}
        {(showConfirm || showReset || showForgotPassword) && (
          <WolfButton
            color="secondary"
            className="!my-4 !ml-auto"
            onClick={() => {
              window.location.reload();
            }}>
            {localeCommon['back']}
          </WolfButton>
        )}
        <WolfInput
          placeholder={localeLogin['enterEmail']}
          label={localeLogin['email']}
          variant="outlined"
          required
          fullWidth
          className="!mb-4"
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
          disabled={showReset || showConfirm}
        />
        {!(showReset || showConfirm || showForgotPassword) && (
          <WolfInput
            placeholder={localeLogin['writePassword']}
            label={localeLogin['password']}
            variant="outlined"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            className="!mb-4"
            autoComplete="off"
            showIcon={true}
            icon={showPassword ? <VisibilityOff /> : <Visibility />}
            iconAction={handleClickShowPassword}
            onChange={(e) => setPassword(e.target.value)}
          />
        )}
        {(showReset || showConfirm) && (
          <>
            {showReset && (
              <WolfInput
                placeholder={showConfirm ? localeLogin['enterTempPassword'] : localeLogin['enterPasswordResetCode']}
                label={showConfirm ? localeLogin['tempPassword'] : localeLogin['passwordResetCode']}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className="!mb-4"
                onChange={(e) => setConfirmationCode(e.target.value)}
              />
            )}
            <WolfInput
              placeholder={localeLogin['writeNewPassword']}
              label={localeLogin['newPassword']}
              variant="outlined"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              className="!mb-4"
              showIcon={true}
              autoComplete="off"
              icon={showPassword ? <VisibilityOff /> : <Visibility />}
              iconAction={handleClickShowPassword}
              onChange={(e) => setPassword(e.target.value)}
            />
            <WolfInput
              placeholder={localeLogin['confirmNewPassword']}
              label={localeLogin['confirmPassword']}
              variant="outlined"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              className="!mb-4"
              showIcon={true}
              autoComplete="off"
              icon={showPassword ? <VisibilityOff /> : <Visibility />}
              iconAction={handleClickShowPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </>
        )}
        {!showReset && !showForgotPassword && (
          <Box className="flex justify-between items-center w-full">
            <WolfTooltip title={localeLogin['rememberMeMessage']}>
              <span>
                <WolfCheckbox
                  label={localeLogin['rememberMe']}
                  value={rememberMe}
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
              </span>
            </WolfTooltip>
            <WolfTextLink size="24px" onClick={() => setShowForgotPassword(true)} align="right">
              {localeLogin['forgotPassword']}
            </WolfTextLink>
          </Box>
        )}
        <WolfButton color="primary" fullWidth className="!my-4" onClick={handleSubmit}>
          {showReset
            ? localeLogin['resetPassword']
            : showForgotPassword
              ? localeLogin['sendResetCode']
              : localeLogin['signIn']}
        </WolfButton>
        {errorMessage && (
          <Typography color="error" variant="body14" sx={{ mb: 2 }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LoginForm;
