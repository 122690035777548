import WolfAddStep from '@components/ui/WolfAddStep';
import WolfCardsStep from '@components/ui/WolfCardsStep';
import { EditIcon24 } from '@components/ui/WolfIcons';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { Step, StepType } from '@models/step.model';
import { Trigger, TriggerType } from '@models/trigger.model';
import { BoltOutlined, CalendarMonthOutlined, CloseOutlined, GroupWorkOutlined } from '@mui/icons-material';
import { Box, Divider, Icon, Typography } from '@mui/material';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { filterStepsInsideSwitchRepeat } from '@utils/filterStepsInsideSwitchRepeat';
import { getStepsInsideSwitchRepeat } from '@utils/getStepsInsideSwitchRepeat';
import { repeatRuleToText } from '@utils/repeat-rule-to-text';
import { useCallback, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import WolfTextLink from './ui/WolfTextLink';
import WolfTooltip from './ui/WolfTooltip';

interface ProcessSidebarProps {
  steps: any[];
  goal: string;
  description: string;
  generatedFromName: string;
  triggers: Trigger[];
  triggeredBy: Step[];
  handleSelectStep: (stepId: string) => void;
  handleEditStep: (stepId: string) => void;
  handleEditProcess: () => void;
  handleClearGeneratedFrom: () => void;
  handleNewStep: (
    item: { text: string; value: string },
    index?: number,
    context?: { id: string; stepsIds: string; name: string },
  ) => void;
  dragEndHandler: (source: number, destination: number, loopId?: string) => void;
  selectedStep: string;
  editMode: boolean;
  isGenerating?: boolean;
  // items: {
  //   name: string;
  //   type: StepType;
  //   position: string;
  // }[];
}

const ProcessSidebar: React.FC<ProcessSidebarProps> = ({
  selectedStep,
  goal,
  description,
  generatedFromName,
  triggers = [],
  triggeredBy = [],
  steps,
  editMode,
  isGenerating = false,
  // items,
  dragEndHandler,
  handleEditProcess,
  handleSelectStep,
  handleEditStep,
  handleNewStep,
  handleClearGeneratedFrom,
}) => {
  const localeProcesses = useTranslation('processes');
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');
  const dispatch = useAppDispatch();

  const [isHoveringGoal, setIsHoveringGoal] = useState(false);
  const [isHoveringDescription, setIsHoveringDescription] = useState(false);
  const [isHoveringTriggers, setIsHoveringTriggers] = useState(false);
  const [isHoveringGeneratedFrom, setIsHoveringGeneratedFrom] = useState(false);

  const handleMouseEnterGoal = useCallback(() => setIsHoveringGoal(true), []);
  const handleMouseLeaveGoal = useCallback(() => setIsHoveringGoal(false), []);
  const handleMouseEnterDescription = useCallback(() => setIsHoveringDescription(true), []);
  const handleMouseLeaveDescription = useCallback(() => setIsHoveringDescription(false), []);
  const handleMouseEnterTriggers = useCallback(() => setIsHoveringTriggers(true), []);
  const handleMouseLeaveTriggers = useCallback(() => setIsHoveringTriggers(false), []);
  const handleMouseEnterGeneratedFrom = useCallback(() => setIsHoveringGeneratedFrom(true), []);
  const handleMouseLeaveGeneratedFrom = useCallback(() => setIsHoveringGeneratedFrom(false), []);

  const onDragEndSub = (result: DropResult, stepId: string, stepsIds: string) => {
    if (!result.destination) return;
    dragEndHandler(result.source.index, result.destination.index, stepId);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    dragEndHandler(result.source.index, result.destination.index);
  };

  const handleShare = (stepId: string) => {
    navigator.clipboard.writeText(window.location.href.split('?')[0] + '?' + 'stepId=' + stepId);
    dispatch(appendActionMessage({ message: localeActionMessages['linkCopied'], type: SnackType.DARK }));
  };

  const mainSteps = filterStepsInsideSwitchRepeat(steps).map((step: Step, index: number) => ({
    stepId: step?.stepId,
    name: step?.name,
    type: step?.stepType,
    stepsIds: step?.stepData?.stepsIds,
    isWolfieGenerated: step?.isWolfieGenerated,
    position: (index + 1).toString(),
  }));

  const getSubsteps = (stepsIds: string, position: number, type: StepType) => {
    return getStepsInsideSwitchRepeat(stepsIds, steps).map((step: Step, idx: number) => ({
      stepId: step?.stepId,
      name: step?.name,
      type: type,
      stepsIds: step?.stepData?.stepsIds || '',
      position:
        type === StepType.SWITCH ? position + 1 + '.' + String.fromCharCode(idx + 65) : position + 1 + '.' + (idx + 1),
    }));
  };

  const calendarTriggerList = triggers
    ?.filter((item) => item.triggerType === TriggerType.CALENDAR)
    .map((item) => (
      <Box className="flex items-center w-full my-1" key={item.triggerId}>
        <CalendarMonthOutlined />
        <WolfTooltip
          title={item?.triggerData?.description + ' / ' + repeatRuleToText(item?.triggerData?.recurrence)}
          placement="right">
          <Typography variant="body14" className="!ml-1">
            {item.name}
          </Typography>
        </WolfTooltip>
      </Box>
    ));

  const eventTriggerList = triggers
    ?.filter((item) => item.triggerType === TriggerType.EVENT)
    .map((item) => (
      <Box className="flex items-center w-full my-1" key={item.triggerId}>
        <BoltOutlined />
        <WolfTooltip title={item?.triggerData?.description} placement="right">
          <Typography variant="body14" className="!ml-1">
            {item.name}
          </Typography>
        </WolfTooltip>
      </Box>
    ));

  const triggeredFrom = triggeredBy?.map((item) => (
    <Box className="flex items-center w-full my-1" key={item?.stepId + 'triggeredBy'}>
      <GroupWorkOutlined />
      <WolfTooltip title={item?.process?.goal} placement="right">
        <Typography variant="body14" className="!ml-1">
          {item?.process?.name}
        </Typography>
      </WolfTooltip>
    </Box>
  ));

  const stepsList = mainSteps.map((item, index, array) => {
    const isSwitchRepeat = item.type === StepType.SWITCH || item.type === StepType.REPEAT;
    return (
      <div key={item?.stepId}>
        <WolfCardsStep
          selected={item?.stepId === selectedStep}
          item={item}
          index={index}
          onClick={() => handleSelectStep(item?.stepId)}
          onEdit={() => handleEditStep(item?.stepId)}
          dragDisabled={false}
          isEditing={editMode}
          onShare={() => handleShare(item?.stepId)}
          isWolfieGenerated={item.isWolfieGenerated}
        />
        {index !== array.length - 1 && !isGenerating && (
          <WolfAddStep
            orientation="horizontal"
            hideline={true}
            insideLoopSwitch={isSwitchRepeat}
            handleNewStep={(newStep) =>
              handleNewStep(
                newStep,
                index,
                isSwitchRepeat
                  ? {
                      // When adding a new step inside a switch or repeat, we need to pass the context
                      id: item?.stepId,
                      stepsIds: item?.stepsIds,
                      name: item?.name,
                    }
                  : undefined,
              )
            }
          />
        )}
        {isSwitchRepeat && (
          <DragDropContext onDragEnd={(result) => onDragEndSub(result, item?.stepId, item?.stepsIds)}>
            <Droppable droppableId={'Substep' + item?.stepId}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Box className="ml-6">
                    {getSubsteps(item?.stepsIds, index, item?.type).map(
                      (subStep: any, subIndex: number, array: any) => (
                        <div key={subStep?.stepId}>
                          <WolfCardsStep
                            selected={subStep?.stepId === selectedStep}
                            key={subStep.name}
                            item={subStep}
                            index={subIndex}
                            onClick={() => handleSelectStep(subStep?.stepId)}
                            onEdit={() => handleEditStep(subStep?.stepId)}
                            onShare={() => handleShare(item?.stepId)}
                            drawLine={item.type === StepType.SWITCH}
                            substep={true}
                            isLast={subIndex === array.length - 1}
                            dragDisabled={false}
                            isEditing={editMode}
                            isWolfieGenerated={subStep.isWolfieGenerated}
                          />
                          {!isGenerating && (
                            <WolfAddStep
                              orientation="horizontal"
                              hideline={true}
                              handleNewStep={(newStep) =>
                                handleNewStep(newStep, subIndex, {
                                  // When adding a new step inside a switch or repeat, we need to pass the context
                                  id: item.stepId,
                                  stepsIds: item.stepsIds,
                                  name: item.name,
                                })
                              }
                              insideLoopSwitch={true}
                            />
                          )}
                        </div>
                      ),
                    )}
                    {provided.placeholder}
                  </Box>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    );
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="ProcessSidebar">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Box className="px-4 bg-backgroundUI overflow-y-auto max-h-screen pb-32">
              <Box className="flex flex-wrap">
                {goal && (
                  <>
                    {' '}
                    <Box
                      className="flex items-center w-full justify-start h-6"
                      onMouseEnter={handleMouseEnterGoal}
                      onMouseLeave={handleMouseLeaveGoal}>
                      <Typography variant="body14semibold">{localeCommon['goal']}</Typography>
                      {isHoveringGoal && (
                        <Icon onClick={handleEditProcess} className="ml-1 cursor-pointer p-0 text-sm">
                          <EditIcon24 />
                        </Icon>
                      )}
                    </Box>
                    <Typography variant="body14" className="!mb-3 w-full">
                      {goal}
                    </Typography>
                  </>
                )}
                {description && (
                  <>
                    <Box
                      className="flex items-center w-full justify-start h-6"
                      onMouseEnter={handleMouseEnterDescription}
                      onMouseLeave={handleMouseLeaveDescription}>
                      <Typography variant="body14semibold">{localeCommon['description']}</Typography>
                      {isHoveringDescription && (
                        <Icon onClick={handleEditProcess} className="ml-1 cursor-pointer p-0 text-sm ">
                          <EditIcon24 />
                        </Icon>
                      )}
                    </Box>
                    <Typography variant="body14" className="!mb-3 w-full">
                      {description}
                    </Typography>
                  </>
                )}
                {generatedFromName && (
                  <>
                    <Box
                      className="flex items-center w-full justify-start h-6"
                      onMouseEnter={handleMouseEnterGeneratedFrom}
                      onMouseLeave={handleMouseLeaveGeneratedFrom}>
                      <Typography variant="body14semibold">{localeProcesses['generatedFrom']}</Typography>
                      {isHoveringGeneratedFrom && (
                        <WolfTooltip title={localeProcesses['generatedFromClear']} placement="right">
                          <Icon className="ml-1 cursor-pointer p-0 text-sm" onClick={handleClearGeneratedFrom}>
                            <CloseOutlined />
                          </Icon>
                        </WolfTooltip>
                      )}
                    </Box>
                    <Typography variant="body14" className="!mb-3 w-full break-words break-all">
                      {generatedFromName}
                    </Typography>
                  </>
                )}
                <>
                  <Box
                    className="flex items-center w-full justify-start h-6"
                    onMouseEnter={handleMouseEnterTriggers}
                    onMouseLeave={handleMouseLeaveTriggers}>
                    <Typography variant="body14semibold">{localeCommon['triggers']}</Typography>
                    {isHoveringTriggers && (
                      <Icon onClick={handleEditProcess} className="ml-1 cursor-pointer p-0 text-sm">
                        <EditIcon24 />
                      </Icon>
                    )}
                  </Box>
                  <Box className="flex flex-wrap w-full !mb-3">
                    {calendarTriggerList}
                    {eventTriggerList}
                    {triggeredFrom}
                    {triggers.length === 0 && (
                      <>
                        <Typography variant="body14" className="!mt-3 block cursor-pointer" onClick={handleEditProcess}>
                          {localeProcesses['noTriggersYet'] + ' '}{' '}
                          <WolfTextLink component="span" size="14px" color="link" className="underline">
                            {' '}
                            {localeCommon['clickToAddOne']}
                          </WolfTextLink>
                        </Typography>
                      </>
                    )}
                  </Box>
                </>
                {/* ) : } */}
              </Box>
              <Box className="flex items-center w-full justify-start h-6">
                <Typography variant="body14semibold">{localeCommon['steps']}</Typography>
              </Box>
              {stepsList}
              {provided.placeholder}
              <Box className="w-full max-w-80">
                <Divider />
                {!isGenerating && steps?.length > 0 ? (
                  <WolfAddStep
                    orientation="horizontal"
                    forceExpandAndHover={true}
                    hideline={true}
                    handleNewStep={(newStep) => handleNewStep(newStep, steps.length - 1)}
                  />
                ) : (
                  <Typography variant="body14" className="!mt-3 block">
                    {localeProcesses['noStepsYet']}
                  </Typography>
                )}
              </Box>
            </Box>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ProcessSidebar;
