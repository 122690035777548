import { Typography, TypographyProps } from '@mui/material';
import { styled, SxProps } from '@mui/system';

interface WolfTextLinkProps extends TypographyProps {
  size?: '24px' | '16px' | '14px';
  background?: 'light' | 'dark';
  color?: 'primary' | 'secondary' | 'error' | 'link';
  onClick?: () => void;
  sx?: SxProps;
}

const StyledTextLink = styled(Typography)<WolfTextLinkProps>(({ theme, color, background, size }) => ({
  '&.MuiTypography-root': {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    fontSize: size,
    textDecoration: color === 'link' ? 'underline' : 'none',
    color:
      color === 'error'
        ? theme.palette.feedbackError.main
        : color === 'link'
          ? theme.palette.callToAction.main
          : background === 'light'
            ? theme.palette.primaryDark.main
            : theme.palette.common.white,
    backgroundColor: 'transparent',
    '&:hover': {
      textDecoration: 'underline',
      color: background === 'light' ? theme.palette.frozenGrayShades4.main : theme.palette.frozenGrayShades6.main,
    },
  },
}));

const WolfTextLink: React.FC<WolfTextLinkProps> = ({
  background = 'light',
  onClick,
  color,
  children,
  size,
  ...props
}) => (
  <StyledTextLink {...props} onClick={onClick} background={background} size={size} color={color}>
    {children}
  </StyledTextLink>
);

export default WolfTextLink;
