import { Checkbox, CheckboxProps } from '@mui/material';
import { SxProps, styled } from '@mui/system';

interface WolfCheckboxProps extends CheckboxProps {
  label?: string;
  sx?: SxProps;
  background?: 'light' | 'dark';
}

const StyledCheckbox = styled(Checkbox)<WolfCheckboxProps>(({ theme, background = 'light' }) => ({
  '&.MuiButtonBase-root': {
    paddingLeft: 0,
  },
  color: background === 'light' ? theme.palette.frozenGrayShades5.main : theme.palette.common.white,
  '&.Mui-checked': {
    color: background === 'light' ? theme.palette.primaryDark.main : theme.palette.common.white,
  },
  '&.MuiCheckbox-indeterminate': {
    color: background === 'light' ? theme.palette.primaryDark.main : theme.palette.common.white,
  },
  '&.Mui-disabled': {
    color: background === 'light' ? theme.palette.frozenGrayShades2.main : theme.palette.frozenGrayShades7.main,
  },
}));

const Label = styled('span')<WolfCheckboxProps>(({ theme, background = 'light' }) => ({
  color: background === 'light' ? theme.palette.primaryDark.main : theme.palette.common.white,
  '&.disabled': {
    color: background === 'light' ? theme.palette.frozenGrayShades4.main : theme.palette.frozenGrayShades7.main,
  },
}));

const WolfCheckbox: React.FC<WolfCheckboxProps> = ({ label, background = 'light', ...checkboxProps }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <StyledCheckbox background={background} {...checkboxProps} />
    {label && (
      <Label background={background} className={checkboxProps.disabled ? 'disabled' : ''}>
        {label}
      </Label>
    )}
  </div>
);

export default WolfCheckbox;
