import { Search, WarningAmber } from '@mui/icons-material';
import {
  BaseTextFieldProps,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInputBase-root': {
    boxSizing: 'border-box',
    padding: 0,
    flexGrow: 1,
    height: '40px',
    '& .MuiInputBase-input': {
      padding: '8px',
      height: '40px',
      boxSizing: 'border-box',
    },
  },
  '& .MuiInputAdornment-root': {
    margin: 0,
  },
  '& .MuiInputLabel-root': {
    display: 'none', // Hide the label by default
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${theme.palette.additionalBorder.main}`,
    transition: 'border-color 0.3s ease',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
      '&.Mui-error': {
        border: `2px solid ${theme.palette.feedbackError.main}`,
      },
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${theme.palette.frozenGrayShades6.main}`,
    },
    '&.Mui-error fieldset': {
      border: `2px solid ${theme.palette.feedbackError.main}`,
    },
    '&.Mui-disabled fieldset': {
      color: theme.palette.frozenGrayShades4.main,
      border: `2px solid ${theme.palette.frozenGrayShades2.main}`,
      background: 'transparent',
    },
  },
}));

interface WolfSearchProps extends BaseTextFieldProps {
  label?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const WolfSearch: React.FC<WolfSearchProps> = ({
  label,
  placeholder,
  error = false,
  errorMessage,
  disabled,
  onChange,
  ...props
}) => {
  return (
    <Box className="flex flex-wrap flex-grow">
      {label && (
        <Typography variant="body14semibold" gutterBottom className="w-full">
          {label}
        </Typography>
      )}
      <StyledTextField
        fullWidth
        placeholder={placeholder}
        error={error}
        variant="outlined"
        onChange={onChange}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end">
                <Search sx={{ width: 18, height: 18 }} className="text-primaryGray" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
      {error && errorMessage && (
        <Typography variant="body14" gutterBottom className="w-full text-feedbackError">
          <IconButton size="small">
            <WarningAmber sx={{ width: 16, height: 14 }} className="text-feedbackError" />
          </IconButton>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default WolfSearch;
