import { useTranslation } from '@hooks/useTranslation';
import { User } from '@models/user.model';
import { Box, Typography } from '@mui/material';

interface DisableUserModalProps {
  user: User | undefined;
}

const DisableUserModal: React.FC<DisableUserModalProps> = ({ user }) => {
  const localeUsers = useTranslation('users');

  return (
    <Box>
      <Typography variant="body16">{localeUsers['confirmDisableMessage']}</Typography>
      <Typography variant="body16semibold">{user?.email}</Typography>
    </Box>
  );
};

export default DisableUserModal;
