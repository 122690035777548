import { useLocationQuery } from '@hooks/useLocationQuery';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const IntegrationsCallbackPage: React.FC = () => {
  const query = useLocationQuery();
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  useEffect(() => {
    const code = query.get('code');
    const scope = query.get('scope');
    const state = query.get('state');
    if (code && scope && state) {
      redeemCode(code, scope, state);
    }
  }, [query]);

  // This function calls the backend to activate the integration
  const redeemCode = async (code: string, scope: string, state: string) => {
    const url =
      // TODO: REACT_APP_PUBLIC_AUTH_URL IS LEGACY, remove when vars updated in all environments
      (process.env.REACT_APP_BACKEND_URL + 'auth/' || process.env.REACT_APP_PUBLIC_AUTH_URL) + 'redeemIntegrationCode';
    const payload = {
      code,
      scope,
      state,
    };
    if (url) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        const data = await response.json();

        if (data.messages[0] === 'OK') {
          setSuccess(true);
        }

        if (data?.statusCode === 500) {
          setFailure(true);
          console.log('unable to redeem code');
        }
      } catch {
        console.log('unable to redeem code');
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: 'linear-gradient(45deg, #ECEFF1, #FFFFFF)',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Container maxWidth="sm" className="text-center">
        {!success && !failure && (
          <Typography variant="h4">
            Hold on, enabling the integration
            <CircularProgress size={24} />
          </Typography>
        )}

        {success && (
          <Typography variant="h4">Integration successfully activated, you can now close this tab</Typography>
        )}

        {failure && <Typography variant="h4">Integration error, please retry later</Typography>}
      </Container>
    </Box>
  );
};

export default IntegrationsCallbackPage;
