import { getTokenLocalStorage, getTokenSessionStorage } from '@config/storage';
import { Configuration, NewSessionData, StreamingAvatarApi } from '@heygen/streaming-avatar';

// import InteractiveAvatarPlaceholder from '@assets/interactive_avatar_placeholder.png';

import { Box, CircularProgress } from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';
import { useEffect, useRef, useState } from 'react';
//   import OpenAI from "openai";

// This should be in the backend

interface InteractiveAvatarProps {
  onlyPlaceholder?: boolean;
  agent?: string;
  messages?: any[];
}

const InteractiveAvatar = ({ messages, agent = '', onlyPlaceholder = false }: InteractiveAvatarProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isLoadingSession, setIsLoadingSession] = useState(false);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [debug, setDebug] = useState<string>();
  const [data, setData] = useState<NewSessionData>();
  const [text, setText] = useState<string>('');
  const [initialized, setInitialized] = useState(false); // Track initialization
  const [sessionStarted, setSessionStarted] = useState(false); // Track session start
  const mediaStream = useRef<HTMLVideoElement>(null);
  const avatar = useRef<StreamingAvatarApi | null>(null);
  const [avatarId, setAvatarId] = useState('');
  const [voiceId, setVoiceId] = useState('');

  const { rememberMe } = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    async function init() {
      const newToken = await fetchAccessToken();
      avatar.current = new StreamingAvatarApi(new Configuration({ accessToken: newToken, jitterBuffer: 200 }));
      const { avatarId, voiceId } = await fetchAvatarAndVoiceId();
      setAvatarId(avatarId);
      setVoiceId(voiceId);
      setInitialized(true);
    }

    init();

    return () => {
      endSession();
    };
  }, []);

  useEffect(() => {
    if (initialized) {
      startSession();
    }
  }, [initialized]);

  useEffect(() => {
    if (debug) {
      console.log('debug', debug);
    }
  }, [debug]);

  useEffect(() => {
    if (!initialized || !avatar.current || !sessionStarted) {
      setDebug('Avatar API not initialized');
      return;
    }

    if (messages && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      handleLastMessage(lastMessage, messages.length - 1);
    }
  }, [messages, initialized, avatar, sessionStarted]);

  // This effect replaces the default green background
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx) return;
    ctx.getContextAttributes().willReadFrequently = true;

    const img = new Image();
    img.src = '';

    img.onload = () => {
      if (ctx && canvas) {
        canvas.width = 200;
        canvas.height = 200;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];

          // Detect green pixels (adjust threshold as needed)
          if (r < 100 && g > 150 && b < 100) {
            data[i + 3] = 0; // Make the pixel transparent
          }
        }

        ctx.putImageData(imageData, 0, 0);
      }
    };

    if (stream && mediaStream.current) {
      const video = mediaStream.current;
      mediaStream.current.srcObject = stream;
      mediaStream.current.onloadedmetadata = () => {
        mediaStream.current!.play();
        setDebug('Playing');

        const drawVideo = () => {
          if (ctx && canvas && video) {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;

            for (let i = 0; i < data.length; i += 4) {
              const r = data[i];
              const g = data[i + 1];
              const b = data[i + 2];

              // Detect green pixels
              if (r < 100 && g > 150 && b < 100) {
                data[i + 3] = 0; // Make the pixel transparent
              }
            }

            ctx.putImageData(imageData, 0, 0);

            requestAnimationFrame(drawVideo);
          }
        };

        drawVideo();
      };
    }
  }, [mediaStream, stream]);

  const handleLastMessage = async (lastMessage: any, position: number) => {
    if (!initialized || !avatar.current || !sessionStarted) {
      setDebug('Avatar API not initialized');
      return;
    } else if (messages && lastMessage.role === 'assistant') {
      let readMessages = [];
      console.log('messages', messages);
      for (let i = position; i >= 0; i--) {
        const message = messages[i];
        // We stop reading if we find a message from another role
        if (message.role !== 'assistant') {
          break;
        }
        readMessages.push(message.content);
      }
      // We reverse the messages to get them in the correct order
      readMessages.reverse();

      for (const message of readMessages) {
        await avatar.current
          .speak({
            taskRequest: { text: message, sessionId: data?.sessionId },
          })
          .catch((e: any) => {
            console.log('error', e);
            setDebug(e.message);
          });
      }
      setIsLoadingChat(false);
    }
  };

  // We get the token from the backend that has the apiKey
  const fetchAccessToken = async () => {
    try {
      const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();

      const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/heygenToken', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const heyGenToken = data.token;
      return heyGenToken;
    } catch (error) {
      console.error('Error fetching Heygen token:', error);
    }
  };

  // We get the current voice and avatar ID (that define how the avatar looks and talks like) from the backend
  const fetchAvatarAndVoiceId = async () => {
    try {
      const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();

      const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/heygenVoiceAvatarId', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Heygen avatar and vocieId:', error);
    }
  };

  async function startSession() {
    setIsLoadingSession(true);
    await updateToken();
    if (!avatar.current) {
      setDebug('Avatar API is not initialized');
      return;
    }
    console.log('Starting avatar session');
    try {
      const res = await avatar.current.createStartAvatar(
        {
          newSessionRequest: {
            quality: 'low',
            avatarName: avatarId,
            voice: { voiceId },
          },
        },
        // This is the callback for debugging messages, it MUST be set
        setDebug,
      );
      console.log('res', res);
      setData(res);
      setStream(avatar.current.mediaStream);
      // We need to have a small delay before we can start talking due to heygen's limitations
      setTimeout(() => {
        setSessionStarted(true);
      }, 1000);
    } catch (error) {
      console.error('Error starting avatar session:', error);
    }
    setIsLoadingSession(false);
  }

  async function updateToken() {
    const newToken = await fetchAccessToken();
    console.log('Updating Access Token:', newToken?.slice(0, 10)); // Log token for debugging
    avatar.current = new StreamingAvatarApi(new Configuration({ accessToken: newToken }));

    const startTalkCallback = (e: any) => {
      console.log('Avatar started talking', e);
    };

    const stopTalkCallback = (e: any) => {
      console.log('Avatar stopped talking', e);
    };

    console.log('Adding event handlers:');
    avatar.current.addEventHandler('avatar_start_talking', startTalkCallback);
    avatar.current.addEventHandler('avatar_stop_talking', stopTalkCallback);

    setInitialized(true);
  }

  async function endSession() {
    if (!initialized || !avatar.current || !sessionStarted) {
      setDebug('Avatar API not initialized');
      return;
    }
    await avatar.current.stopAvatar({ stopSessionRequest: { sessionId: data?.sessionId } }, setDebug);
    setStream(undefined);
  }

  return (
    <>
      <Box className="ml-auto flex flex-col">
        {stream ? (
          <Box sx={{ display: 'flex' }}>
            <Box className="justify-center items-center flex rounded-lg overflow-hidden absolute right-0 bottom-[450px]">
              <video
                crossOrigin="anonymous"
                ref={mediaStream}
                autoPlay
                playsInline
                style={{
                  display: 'none',
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}>
                <track kind="captions" />
              </video>
              <canvas
                ref={canvasRef}
                style={{
                  width: 200,
                  height: 200,
                }}
              />
              {/* {debug} */}
            </Box>
          </Box>
        ) : (
          <Box className="justify-end h-10 w-10 ml-auto items-center flex rounded-lg overflow-hidden bg-white">
            <CircularProgress />
          </Box>
        )}

        <Box className="flex flex-col gap-3 mt-1"></Box>
      </Box>
    </>
  );
};

export default InteractiveAvatar;
