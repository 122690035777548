import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

interface WolfHelperNavigationProps {
  type: 'title' | 'section';
  label: string;
  selected: boolean;
  onSelect: () => void;
}

interface NavigationItemProps {
  selected: boolean;
}

const NavigationItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<NavigationItemProps>(({ theme, selected }) => ({
  height: 32,
  maxHeight: 32,
  width: 'fit-content',
  backgroundColor: selected ? theme.palette.frozenGrayShades2.main : theme.palette.backgroundUI.main,
  padding: theme.spacing(2),
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: selected ? theme.palette.frozenGrayShades3.main : theme.palette.frozenGrayShades3.main,
  },
  display: 'flex',
  alignItems: 'center',
}));

const WolfHelperNavigation: React.FC<WolfHelperNavigationProps> = ({ type = 'title', label, selected, onSelect }) => {
  return (
    <Box className="flex flex-wrap m-1">
      {type === 'section' && <Box className="w-4"></Box>}
      <NavigationItem selected={selected} onClick={onSelect}>
        <Typography
          color="primary"
          variant={
            selected ? (type === 'title' ? 'body14semibold' : 'body12semibold') : type === 'title' ? 'body14' : 'body12'
          }>
          {label}
        </Typography>
      </NavigationItem>
    </Box>
  );
};

export default WolfHelperNavigation;
