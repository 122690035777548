import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const WolfLoader: React.FC = () => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="rgba(255, 255, 255, 0.8)"
      zIndex={9999}>
      <CircularProgress />
    </Box>
  );
};

export default WolfLoader;
