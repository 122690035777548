import { ActionMessage } from '@models/common.model';
import { v4 as uuidv4 } from 'uuid';

export const ActionMessageTypes = {
  APPEND_ACTION_MESSAGE: 'APPEND_ACTION_MESSAGE',
  CLEAR_ACTION_MESSAGE: 'CLEAR_ACTION_MESSAGE',
};

export const appendActionMessage = (actionMessage: ActionMessage) => ({
  type: ActionMessageTypes.APPEND_ACTION_MESSAGE,
  data: actionMessage,
});

export const clearActionMessage = (actionMessage: ActionMessage) => ({
  type: ActionMessageTypes.CLEAR_ACTION_MESSAGE,
  data: actionMessage,
});

export type ActionMessagesState = {
  actionMessages: ActionMessage[];
};

// INITIAL STATE
const actionMessagesInitialState: ActionMessagesState = {
  actionMessages: [],
};

export type ActionMessagesAction = {
  type: string;
  data: ActionMessage;
};

const localeReducer = (state = actionMessagesInitialState, action: ActionMessagesAction) => {
  switch (action.type) {
    case ActionMessageTypes.APPEND_ACTION_MESSAGE:
      const id = uuidv4();
      return {
        ...state,
        actionMessages: [...state.actionMessages, { ...action.data, id }],
      };

    case ActionMessageTypes.CLEAR_ACTION_MESSAGE:
      return {
        ...state,
        actionMessages: state.actionMessages.filter((actionMessage) => actionMessage.id !== action.data.id),
      };

    default:
      return state;
  }
};

export default localeReducer;
