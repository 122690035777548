import { graphQlClient } from '@config/graphqlClient';
import PoliciesGraphQL from '@graphql/policy.queries';
import ProcessesGraphQL from '@graphql/process.queries';
import { useLoader } from '@hooks/useLoader';
import { useTranslation } from '@hooks/useTranslation';
import { Area } from '@models/area.model';
import { getPolicyByIdRequest, Policy } from '@models/policy.model';
import { getProcessesWithAreaServiceRequest, Process } from '@models/process.model';
import { Service } from '@models/service.model';
import { Box, Container, List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import sanitizeHTML from '@utils/sanitize-html';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const PolicyDescription: React.FC = () => {
  const [policyData, setPolicyData] = useState<Policy>();
  const [processes, setProcesses] = useState<Process[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);
  const [services, setServices] = useState<Service[]>([]);

  const localePolicies = useTranslation('policies');
  const localeCommon = useTranslation('common');

  const { setLoading } = useLoader();
  const { policyId } = useParams<{ policyId: string }>(); // TODO BRIAN types
  // Get params & get services by areaId or all services if no ID
  useEffect(() => {
    getPolicyDetails(policyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId]);

  const getPolicyDetails = async (policyId: string) => {
    try {
      setLoading(true);
      const data: getPolicyByIdRequest = await graphQlClient.request(PoliciesGraphQL.queries.getPolicyById, {
        id: policyId,
      });
      setPolicyData(data?.getPolicyById);
      getProcesses(data?.getPolicyById);
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  //TODO This call could be made to a new separate endpoint
  const getProcesses = async (policy: Policy) => {
    try {
      const data: getProcessesWithAreaServiceRequest = await graphQlClient.request(
        ProcessesGraphQL.queries.getProcessesWithAreaService,
      );

      const relatedProcesses = policy?.processes?.map((item: { processId: string }) => item.processId);

      const newProcesses: Process[] = data.getProcessesWithAreaService.filter((process: Process) =>
        relatedProcesses?.includes(process.processId),
      );
      const newServices: Service[] = [];
      newProcesses.forEach((process: Process) => {
        if (process.service && !newServices.some((service) => process?.service?.serviceId === service.serviceId)) {
          newServices.push(process.service);
        }
      });
      const newAreas: Area[] = [];
      newServices.forEach((service: Service) => {
        if (service.area && !newAreas.some((area) => service?.area?.areaId === area.areaId)) {
          newAreas.push(service.area);
        }
      });

      setAreas(newAreas);
      setServices(newServices);
      setProcesses(newProcesses);
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box className="flex flex-wrap justify-between items-center">
        <Box className="w-full">
          <Typography variant="h4">{policyData?.name}</Typography>
          <Typography variant="h6" className="my-5">
            <span className="font-bold">{localeCommon['category']}:</span> {policyData?.category}
          </Typography>
        </Box>

        <Box className="w-100">
          <div dangerouslySetInnerHTML={sanitizeHTML(policyData?.content)}></div>
        </Box>
      </Box>

      <Box className="my-10 border-black border-t-2 border-0 border-solid">
        <Typography className="w-full font-bold my-10" variant="h5">
          {localePolicies['usedIn']}
        </Typography>

        <List>
          {areas.map((area: Area) => (
            <>
              <ListItem key={area.areaId} className="mb-0 p-0">
                <p className="mb-0 text-lg">{area.name} </p>
              </ListItem>
              {services
                .filter((service) => service.area?.areaId === area.areaId)
                .map((service: Service) => (
                  <>
                    <ListItem key={service.serviceId} className="ml-5 text-base">
                      <p className="text-sm">{service.name} </p>
                    </ListItem>
                    {processes
                      .filter((process) => process.service?.serviceId === service.serviceId)
                      .map((process: Process) => (
                        <ListItem key={process.processId} className="ml-10">
                          <p className="font-bold text-xs">{'* ' + process.name}</p>
                        </ListItem>
                      ))}
                  </>
                ))}
            </>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default PolicyDescription;
