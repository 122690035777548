import { Handle, Position } from 'reactflow';

export default function ProcessStepInsideNode({ data }: any) {
  return (
    <>
      <div className={'process-step-inside-node flex flex-wrap items-center'}>
        {data.condition && (
          <p title={data.condtion}>Condition: {data.condition.slice(0, 15) + (data.label.length > 20 ? '...' : '')}</p>
        )}
        <p title={data.label}>{data.label.slice(0, 25) + (data.label.length > 25 ? '...' : '')}</p>
      </div>
      <Handle type="source" position={Position.Right} id="a" />
    </>
  );
}
