import { Box, BoxProps, Chip } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import React from 'react';

interface WolfChipProps extends BoxProps {
  selected: boolean;
  label: string;
  //chipSize to avoid conflict with ChipProps so we just have our custom sizes
  chipsize?: '40px' | '32px';
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  sx?: SxProps;
  customPadding?: string;
  customBgColor?: string;
  customColor?: string;
}

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
}));

const StyledBox = styled(Box)<WolfChipProps>(
  ({ theme, selected, chipsize, customColor, customBgColor, customPadding }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: customBgColor || (selected ? theme.palette.primaryDark.main : theme.palette.common.white),
    color: customColor || (selected ? theme.palette.common.white : theme.palette.primaryDark.main),
    height: chipsize,
    '&:hover': {
      backgroundColor: selected ? theme.palette.frozenGrayShades7.main : theme.palette.frozenGrayShades2.main,
    },
    borderRadius: '100px',
    width: 'fit-content',
    paddingLeft: customPadding || theme.spacing(1),
    paddingRight: customPadding || theme.spacing(1),
    margin: theme.spacing(0),
    fontSize: '14px',
    fontWeight: '400',
    cursor: 'pointer',
  }),
);

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: 'inherit',
  '& .MuiChip-label': {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const WolfChip: React.FC<WolfChipProps> = ({
  selected,
  customPadding,
  customColor,
  customBgColor,
  chipsize = '32px',
  leftIcon,
  rightIcon,
  label,
  ...props
}) => {
  return (
    <StyledBox
      selected={selected}
      customPadding={customPadding}
      customBgColor={customBgColor}
      customColor={customColor}
      chipsize={chipsize}
      label={label}
      {...props}>
      {leftIcon && <IconContainer>{leftIcon}</IconContainer>}
      <StyledChip label={label} />
      {rightIcon && <IconContainer>{rightIcon}</IconContainer>}
    </StyledBox>
  );
};

export default WolfChip;
