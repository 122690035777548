import { Step, StepType } from '@models/step.model';
import { filterStepsInsideSwitchRepeat } from '@utils/filterStepsInsideSwitchRepeat';
import { getStepsInsideSwitchRepeat } from '@utils/getStepsInsideSwitchRepeat';
import { useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';

export default function StepsNode({ data }: any) {
  const [steps, setSteps] = useState<Step[]>();
  const [filteredSteps, setFilteredSteps] = useState<Step[]>();

  useEffect(() => {
    if (data.label) {
      const steps = JSON.parse(data.label);
      setSteps(steps);
      setFilteredSteps(filterStepsInsideSwitchRepeat(steps));
    }
  }, [data]);
  return (
    <>
      <Handle type="target" position={Position.Left} />
      <div className="step-node">
        {filteredSteps &&
          steps &&
          filteredSteps.map((step, index) => {
            // Add inside steps inside switch or repeats
            if ([StepType.REPEAT, StepType.SWITCH].includes(step.stepType)) {
              const auxSteps = getStepsInsideSwitchRepeat(step.stepData.stepsIds, steps);
              return (
                <div key={index} className="step">
                  {step.name}
                  {auxSteps &&
                    auxSteps.map((step, index) => {
                      return (
                        <div key={index} className="step">
                          {'--' + step.name}
                        </div>
                      );
                    })}
                </div>
              );
            } else {
              return (
                <div key={index} className="step">
                  {step.name}
                </div>
              );
            }
          })}
      </div>
    </>
  );
}
