import { useTranslation } from '@hooks/useTranslation';
import { Organization } from '@models/organization.model';
import { Box, Typography } from '@mui/material';

interface EnableOrganizationModalProps {
  organization: Organization | undefined;
}

const EnableOrganizationModal: React.FC<EnableOrganizationModalProps> = ({ organization }) => {
  const localeOrganizations = useTranslation('organizations');

  return (
    <Box>
      <Typography variant="body16">{localeOrganizations['confirmEnableMessage']}</Typography>
      <Typography variant="body16semibold">{organization?.name}</Typography>
    </Box>
  );
};

export default EnableOrganizationModal;
