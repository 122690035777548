import { Process } from '@models/process.model';

export interface Step {
  stepId: string;
  processId: string;
  name: string;
  notes: string;
  stepType: StepType;
  stepData?: any;
  order: number;
  process?: Process;
  isWolfieGenerated?: boolean;
}

export enum StepType {
  PLAIN_TEXT = 'PLAIN_TEXT',
  // PROCESS = 'PROCESS', We unified this as just a plain text that has executeProcessId inside StepData
  SWITCH = 'SWITCH',
  // CONDITION = 'CONDITION', We unified this as just a plain text that has a condition inside stepdata
  REPEAT = 'REPEAT',
}

export enum TimingType {
  IMMEDIATE = 'IMMEDIATE',
  CALENDAR = 'CALENDAR',
  EMAIL = 'EMAIL',
  OTHER = 'OTHER',
}

export interface createOrUpdateStepRequest {
  createStep?: Step;
  updateStep?: Step;
}

export interface reorderStepsRequest {
  reorderSteps: {
    messages: string[];
  };
}

export interface updateStepsIdsRequest {
  updateStep: Partial<Step>;
}

export interface deleteStepRequest {
  deleteStep: {
    affected: number;
  };
}
