import { CaseIcon24, LoopIcon24, StepIcon24 } from '@components/ui/WolfIcons';
import { useTranslation } from '@hooks/useTranslation';
import { StepType } from '@models/step.model';
import { Add as AddIcon } from '@mui/icons-material';
import { Box, Icon, IconButton, Menu, MenuItem, styled, Typography } from '@mui/material';
import React, { useState } from 'react';

interface WolfAddStepProps {
  orientation: 'horizontal' | 'vertical';
  handleNewStep: (item: { text: string; value: string }) => void;
  insideLoopSwitch?: boolean;
  hideline?: boolean;
  forceExpandAndHover?: boolean;
}

const AddStepContainer = styled(Box)<{ orientation: 'horizontal' | 'vertical'; hovered: string }>(
  ({ theme, orientation, hovered }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: orientation === 'horizontal' ? 'row' : 'column',
    position: 'relative',
    height: hovered === 'true' ? (orientation === 'horizontal' ? '30px' : '100%') : '100%',
    maxWidth: '20rem',
  }),
);

const Line = styled(Box)<{ orientation: 'horizontal' | 'vertical'; hideline: string }>(
  ({ theme, orientation, hideline }) => ({
    width: orientation === 'horizontal' ? '100px' : '1px',
    height: orientation === 'horizontal' ? '1px' : '50px',
    backgroundColor: hideline === 'true' ? 'transparent' : theme.palette.frozenGrayShades2.main,
    transition: 'all 0.3s ease',
    position: 'relative',
    '&:hover': {
      width: orientation === 'horizontal' ? '200px' : '4px',
      height: orientation === 'horizontal' ? '4px' : '60px',
      backgroundColor: theme.palette.frozenGrayShades2.main,
    },
  }),
);

const AddStepBox = styled(Box)<{ expanded: string; orientation: 'horizontal' | 'vertical' }>(
  ({ theme, expanded, orientation }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: expanded === 'true' ? (orientation === 'horizontal' ? '24px' : '32px') : '0',
    height: expanded === 'true' ? (orientation === 'horizontal' ? '24px' : '32px') : '0',
    backgroundColor: theme.palette.frozenGrayShades2.main,
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease',
    overflow: 'hidden',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
);

const WolfAddStep: React.FC<WolfAddStepProps> = ({
  orientation,
  insideLoopSwitch = false,
  forceExpandAndHover = false,
  hideline = false,
  handleNewStep,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hovered, setHovered] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [forceOpen, setForceOpen] = useState(false);
  const localeCommon = useTranslation('common');

  const handleHover = () => {
    setHovered(true);
    setExpanded(true);
    // setTimeout(() => setExpanded(true), 300);
  };

  const handleLeave = () => {
    if (!forceOpen) {
      setHovered(false);
      setExpanded(false);
    }
  };

  const handlePlusClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setForceOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setHovered(false);
    setExpanded(false);
    setForceOpen(false);
  };

  const handleOptionClick = (stepType: StepType) => {
    handleClose();
    handleNewStep({ text: '', value: stepType });
  };

  return (
    <AddStepContainer
      orientation={orientation}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      hovered={(hovered || forceExpandAndHover).toString()}>
      <Line orientation={orientation} hideline={hideline.toString()} />
      <AddStepBox expanded={(expanded || forceExpandAndHover).toString()} orientation={orientation}>
        <IconButton onClick={handlePlusClick} size="small">
          <AddIcon />
        </IconButton>
      </AddStepBox>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleOptionClick(StepType.PLAIN_TEXT)} className="h-12 w-52 !p-0">
          <Icon className="m-4">
            <StepIcon24 />
          </Icon>
          <Typography variant="body14" className="w-full">
            {localeCommon['add']}
            {' '}
            {localeCommon['step']}
            {insideLoopSwitch && ` (${localeCommon['inside']})`}
          </Typography>
        </MenuItem>
        {!insideLoopSwitch && (
          <MenuItem onClick={() => handleOptionClick(StepType.SWITCH)} className="h-12 w-52 !p-0">
            <Icon className="m-4">
              <CaseIcon24 />
            </Icon>
            <Typography variant="body14" className="w-full">
              {localeCommon['add']}
              {' '}
              {localeCommon['case']}
            </Typography>
          </MenuItem>
        )}
        {!insideLoopSwitch && (
          <MenuItem onClick={() => handleOptionClick(StepType.REPEAT)} className="h-12 w-52 !p-0">
            <Icon className="m-4">
              <LoopIcon24 />
            </Icon>
            <Typography variant="body14" className="w-full">
              {localeCommon['add']}
              {' '}
              {localeCommon['loop']}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </AddStepContainer>
  );
};

export default WolfAddStep;
