import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';

export function useTranslation(key: string) {
  const { locale }: { [key: string]: any } = useAppSelector((state: RootState) => state.locale);

  if (key) {
    return locale[key];
  }

  return locale;
}
