import WolfButton from '@components/ui/WolfButton';
import WolfInput from '@components/ui/WolfInput';
import WolfTextarea from '@components/ui/WolfTextarea';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { CalendarTrigger, EventTrigger } from '@models/trigger.model';
import { Box } from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

interface AddEventTriggerFormProps {
  processName: string;
  initialData?: EventTrigger;
  saving: boolean;
  onClose: () => void;
  onSave: (calTrigger?: CalendarTrigger, eventTrigger?: EventTrigger) => void;
}

const AddEventTriggerForm: React.FC<AddEventTriggerFormProps> = ({
  processName,
  initialData,
  saving,
  onClose,
  onSave,
}) => {
  const localeProcessForm = useTranslation('processForm');
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');

  const dispatch = useDispatch();

  const [eventTrigger, setEventTrigger] = useState<EventTrigger | undefined>(initialData);

  const handleEventTriggerChange = (
    event: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEventTrigger({
      ...eventTrigger,
      [event.target.name]: event.target.value,
    });
  };

  const submit = () => {
    if (!eventTrigger?.name) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['triggerNameRequired'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    onSave(undefined, eventTrigger);
    onClose();
  };

  return (
    <Box>
      <WolfInput fullWidth label={localeProcessForm['processName']} value={processName} disabled className="!mb-8" />

      <WolfInput
        fullWidth
        label={localeProcessForm['evTrigName'] + ' *'}
        secondaryLabel={localeCommon['required']}
        placeholder={localeProcessForm['evTrigNamePlaceholder']}
        name="name"
        className="!mb-8"
        value={eventTrigger?.name || ''}
        onChange={handleEventTriggerChange}
      />
      <WolfTextarea
        label={localeProcessForm['description']}
        placeholder={localeProcessForm['description']}
        name="description"
        className="w-full resize-y"
        value={eventTrigger?.description || ''}
        onChange={handleEventTriggerChange}
      />
      <Box className="absolute border-solid border-l-0 border-r-0 border-b-0 border-primaryFrozen !h-16 bottom-0 left-0 w-full">
        <Box
          className="mt-auto bg-white flex justify-between items-center"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={2}>
          <WolfButton variant="outlined" color="secondary" onClick={onClose}>
            {localeCommon['cancel']}
          </WolfButton>
          <WolfButton variant="contained" color="primary" disabled={saving} onClick={submit}>
            {localeCommon['save']}
          </WolfButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEventTriggerForm;
