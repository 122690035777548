import { graphQlClient } from '@config/graphqlClient';
import UserGraphQL from '@graphql/user.queries';
import { useLocationQuery } from '@hooks/useLocationQuery';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { getSelfWithOrganizationRequest, UserLocale } from '@models/user.model';
import LandingPage from '@pages/landing';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { setLocale } from '@redux/reducers/locale.reducer';
import { setOrganizationData } from '@redux/reducers/organization.reducer';
import { logoutUser, setUserData, setUserDetails } from '@redux/reducers/user.reducer';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const AuthPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const query = useLocationQuery();
  const localeCommon = useTranslation('common');
  const history = useHistory();

  useEffect(() => {
    const code = query.get('code');
    if (code) {
      redeemCode(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const redeemCode = async (code: string) => {
    const url = process.env.REACT_APP_BACKEND_URL + 'auth/redeemCode';
    if (url) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code }),
        });
        const data = await response.json();
        if (data.token) {
          dispatch(setUserData(data.token, true));
          const userData: getSelfWithOrganizationRequest = await graphQlClient.request(
            UserGraphQL.queries.getSelfWithOrganization,
          );
          dispatch(setOrganizationData(userData.getSelfWithOrganization.organization));
          dispatch(setUserDetails(userData.getSelfWithOrganization.user));
          dispatch(setLocale(UserLocale.EN));
          const from = sessionStorage.getItem('redirectUrl') || '/';
          sessionStorage.removeItem('redirectUrl');
          if (from && from !== '/logout') {
            history.push(from);
          }
        } else {
          dispatch(logoutUser());
        }
      } catch {
        dispatch(appendActionMessage({ message: localeCommon['requestError'], type: SnackType.ERROR }));
        dispatch(logoutUser());
      }
    }
  };

  return <LandingPage />;
};

export default AuthPage;
