import WolfSectionColor from '@components/ui/WolfSectionColor';
import { GroupWorkOutlined, Visibility } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { Handle, Position } from 'reactflow';

interface ProcessNodeProps {
  data: ProcessNodeData;
  id: string;
}

interface ProcessNodeData {
  label: string;
  preLabel: string;
  color: string;
  isExternal: boolean;
}

export default function ProcessNode({ data, id }: ProcessNodeProps) {
  return (
    <div className="relative flex items-center p-4 shadow-lg rounded-lg border border-additionalBorder bg-white">
      <Handle type="target" id="left" position={Position.Left} />
      <Handle type="source" position={Position.Right} id="right" />

      {/* Node Content */}
      <Box className="flex items-center justify-between w-full">
        <Box className="flex items-center">
          <Box className="w-10 h-10 rounded-full flex items-center justify-center">
            <WolfSectionColor icon={<GroupWorkOutlined />} color={data.color} />
          </Box>
          {
            <Typography
              className="!font-bold"
              data-type="process"
              data-id={id}
              data-origin="process-name"
              title={data.label}>
              {data.label.slice(0, 30) + (data.label.length > 30 ? '...' : '')}
            </Typography>
          }
        </Box>

        {/* End Icon */}
        {!data.isExternal && (
          <IconButton className="!p-2">
            <Visibility />
          </IconButton>
        )}
      </Box>
    </div>
  );
}
