import { gql } from 'graphql-request';

export const SERVICE_FIELDS = gql`
  fragment ServiceFields on ServiceDto {
    serviceId
    name
    description
    color
    calendar
    order
    storageLocation
    areaId
    area {
      areaId
      name
    }
    processes {
      processId
      category
      name
      description
      goal
      order
      location
      triggers {
        triggerId
        name
        triggerType
        triggerData
      }
      steps {
        stepId
        name
        order
        notes
        stepType
        stepData
      }
    }
  }
`;

export const getServices = gql`
  query getServices {
    getServices {
      ...ServiceFields
    }
  }

  ${SERVICE_FIELDS}
`;

export const getServiceByID = gql`
  query getServiceByID($id: String!) {
    getServiceByID(id: $id) {
      ...ServiceFields
      area {
        areaId
        name
      }
    }
  }

  ${SERVICE_FIELDS}
`;

export const getServicesNameIdWArea = gql`
  query getServices {
    getServices {
      serviceId
      name
      area {
        name
      }
    }
  }
`;

export const createService = gql`
  mutation createService($service: ServiceCreateInput!) {
    createService(service: $service) {
      ...ServiceFields
    }
  }
  ${SERVICE_FIELDS}
`;

export const updateService = gql`
  mutation updateService($service: ServiceUpdateInput!) {
    updateService(service: $service) {
      ...ServiceFields
    }
  }
  ${SERVICE_FIELDS}
`;

export const reorderServices = gql`
  mutation reorderServices($services: ServiceReorderInput!) {
    reorderServices(services: $services) {
      error
      messages
    }
  }
`;

export const deleteService = gql`
  mutation deleteService($id: String!) {
    deleteService(id: $id) {
      affected
      messages
    }
  }
`;

const ServicesGraphQL = {
  queries: {
    getServices,
    getServiceByID,
    getServicesNameIdWArea,
  },
  mutations: {
    createService,
    updateService,
    reorderServices,
    deleteService,
  },
};

export default ServicesGraphQL;
