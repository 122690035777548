import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/system';

interface WolfTooltipProps extends TooltipProps {
  type?: 'meta' | 'content';
}

const WolfTooltip = styled(({ className, ...props }: WolfTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, type = 'meta' }: { theme: any; type?: 'meta' | 'content' }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: type === 'meta' ? theme.palette.primary.main : theme.palette.common.white,
    color: type === 'meta' ? theme.palette.common.white : theme.palette.text.primary,
    boxShadow: theme.shadows[2],
    fontSize: 14,
    borderRadius: 4,
    padding: theme.spacing(1, 2),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: type === 'meta' ? theme.palette.grey[800] : theme.palette.common.white,
  },
}));

export default WolfTooltip;
