import CustomModal from '@components/CustomModal';
import JsonExportModal from '@components/JsonExport';
import JsonImportTextarea from '@components/JsonImportTextarea';
import { getTokenLocalStorage, getTokenSessionStorage } from '@config/storage';
import { Step } from '@models/step.model';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';

interface ImportExportProcessHandlerProps {
  processId: string;
  steps: Step[];
  showJsonImport: boolean;
  showJsonExport: boolean;
  handleCloseJsonImport: () => void;
  handleCloseJsonExport: () => void;
  refresh: (id: string) => void;
}

const ImportExportProcessHandler: React.FC<ImportExportProcessHandlerProps> = ({
  processId,
  steps,
  showJsonImport,
  showJsonExport,
  handleCloseJsonImport,
  handleCloseJsonExport,
  refresh,
}) => {
  const { rememberMe } = useAppSelector((state: RootState) => state.user);

  const exportSteps = async (stepIds: string) => {
    const payload = {
      processId: processId,
      stepIds,
    };
    const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();

    const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'admin/' + 'exportStepsFromProcess', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    const dataStr = JSON.stringify(data, null, 2);
    const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);
    const exportFileDefaultName = 'export.json';

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  const importSteps = async (jsonData: string) => {
    const jsonDataWithParent = JSON.parse(jsonData);
    jsonDataWithParent.options.stepPositionParent = processId;

    const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();

    try {
      await fetch(process.env.REACT_APP_BACKEND_URL + 'admin/import', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(jsonDataWithParent),
      });
      refresh && refresh(processId);
    } catch {
      console.log('unable to import steps');
    }
  };
  return (
    <>
      <CustomModal isOpen={showJsonImport} onClose={handleCloseJsonImport} maxWidth="1200px">
        <JsonImportTextarea elementList={steps || []} onClose={handleCloseJsonImport} onSubmit={importSteps} />
      </CustomModal>

      <CustomModal isOpen={showJsonExport} onClose={handleCloseJsonExport} maxWidth="1200px">
        <JsonExportModal elementList={steps || []} onClose={handleCloseJsonExport} onSubmit={exportSteps} />
      </CustomModal>
    </>
  );
};

export default ImportExportProcessHandler;
