import { Step, StepType } from '@models/step.model';

// Loop though repeat or switch steps and remove from the list the steps included within the repeat
export const filterStepsInsideSwitchRepeat = (steps: Step[] | undefined) => {
  if (!steps) return [];

  let auxSteps = [...steps];

  steps.forEach((step: Step) => {
    if (step?.stepType === StepType.REPEAT || step?.stepType === StepType.SWITCH) {
      const repeatStep = step;
      auxSteps = auxSteps.filter((item) => !repeatStep.stepData?.stepsIds?.includes(item?.stepId));
    }
  });

  return auxSteps.sort((a, b) => a.order - b.order);
};
