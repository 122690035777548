import WolfButton from '@components/ui/WolfButton';
import WolfInput from '@components/ui/WolfInput';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

type EditableChipProps = {
  initialValue: string;
  onSave: (value: string) => void;
  onDelete: () => void;
};

const CustomChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: '9999px',
  height: '32px',
  '& .MuiChip-label': {
    fontWeight: 'bold',
  },
  '& .MuiChip-deleteIcon': {
    color: theme.palette.common.white,
  },
  '&:hover': {
    backgroundColor: theme.palette.frozenGrayShades7.main,
  },
}));

const EditableChip: React.FC<EditableChipProps> = ({ initialValue, onSave, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(initialValue);

  const localeCommon = useTranslation('common');

  const handleEdit = () => {
    setIsEditing(true);
    setEditedValue(initialValue);
  };

  const handleSave = () => {
    onSave(editedValue);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedValue(initialValue);
    setIsEditing(false);
  };

  const handleDelete = () => {
    onDelete();
  };

  return isEditing ? (
    <Box className="flex items-center justify-between mx-2">
      <WolfInput label="" value={editedValue} autoComplete="off" onChange={(e) => setEditedValue(e.target.value)} />
      <Box className="mx-2">
        <WolfButton variant="contained" color="primary" onClick={handleSave}>
          {localeCommon['save']}
        </WolfButton>
      </Box>
      <Box className="mx-2">
        <WolfButton variant="contained" color="secondary" onClick={handleCancel}>
          {localeCommon['cancel']}
        </WolfButton>
      </Box>
    </Box>
  ) : (
    <CustomChip label={initialValue} onDelete={handleDelete} onClick={handleEdit} />
  );
};

export default EditableChip;
