export const CALENDAR_URL = 'http://calendar.google.com';
export const CALENDAR_EVENT_LINK_URL = 'http://calendar.google.com/calendar/event?eid=';
export const DRIVE_FOLDER_URL = 'https://drive.google.com/drive/folders/';
export const BASE_DOCS_LINK_URL = 'https://docs.google.com/document/d/';

export const MONTHS_ARRAY = [
  { value: 1, text: 'Jan' },
  { value: 2, text: 'Feb' },
  { value: 3, text: 'Mar' },
  { value: 4, text: 'Apr' },
  { value: 5, text: 'May' },
  { value: 6, text: 'Jun' },
  { value: 7, text: 'Jul' },
  { value: 8, text: 'Aug' },
  { value: 9, text: 'Sep' },
  { value: 10, text: 'Oct' },
  { value: 11, text: 'Nov' },
  { value: 12, text: 'Dec' },
];
export const DAYS_ARRAY = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

export const DAYS_NUMBER_ARRAY = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];
