import { CalendarMonthOutlined, InfoOutlined, WarningAmber } from '@mui/icons-material';
import {
  BaseTextFieldProps,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import WolfTooltip from './WolfTooltip';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInputBase-root': {
    boxSizing: 'border-box',
    height: '40px',
    '& .MuiInputBase-input': {
      padding: '8px 16px',
      height: '40px',
      boxSizing: 'border-box',
    },
  },
  '& .MuiInputLabel-root': {
    display: 'none', // Hide the label by default
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${theme.palette.primaryIcy.main}`,
    transition: 'border-color 0.3s ease',
    '&:hover': {
      border: `1px solid ${theme.palette.primaryIcy.main}`,
    },
    '&.Mui-focused': {
      outline: 'none', // Remove default focus outline
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-error fieldset': {
      border: `1px solid ${theme.palette.feedbackError.main}`,
    },
    '&.Mui-disabled fieldset': {
      color: theme.palette.primaryIcy.main,
      border: `1px solid ${theme.palette.primaryIcy.main}`,
      background: theme.palette.action.disabledBackground,
    },
  },
}));

interface WolfInputProps extends BaseTextFieldProps {
  label: string;
  secondaryLabel?: string;
  name?: string;
  placeholder?: string;
  tooltipText?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  iconAction?: () => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  boxFullWidth?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  keepFocus?: boolean;
}

const WolfInput: React.FC<WolfInputProps> = ({
  label,
  secondaryLabel,
  name,
  placeholder,
  tooltipText,
  showIcon = false,
  icon,
  iconAction,
  error = false,
  errorMessage,
  disabled,
  boxFullWidth = true,
  autoComplete = 'off',
  autoFocus = false,
  onChange,
  inputRef,
  keepFocus = false,
  ...props
}) => {
  return (
    <Box className={'flex flex-wrap justify-center ' + (boxFullWidth ? 'w-full' : '')}>
      {label && (
        <Box className={secondaryLabel ? 'flex w-full justify-between' : 'flex w-full justify-start'}>
          <Typography variant="body14semibold" gutterBottom className="w-full">
            {label}
            {tooltipText && (
              <WolfTooltip title={tooltipText}>
                <IconButton size="small">
                  <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
                </IconButton>
              </WolfTooltip>
            )}
          </Typography>
          {secondaryLabel && (
            <Typography variant="body14" className="text-additionalPlaceholder" color="additionalPlaceholder">
              {secondaryLabel}
            </Typography>
          )}
        </Box>
      )}
      <StyledTextField
        inputRef={(input) => keepFocus && input?.focus()}
        placeholder={placeholder}
        error={error}
        variant="outlined"
        onChange={onChange}
        disabled={disabled}
        name={name}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        InputProps={
          showIcon
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={iconAction}>
                      {icon ? (
                        icon
                      ) : (
                        <CalendarMonthOutlined sx={{ width: 22, height: 24 }} className="text-primaryDark" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : undefined
        }
        {...props}
      />
      {error && errorMessage && (
        <Typography variant="body2" gutterBottom className="w-full text-feedbackError">
          <IconButton size="small">
            <WarningAmber sx={{ width: 16, height: 14 }} className="text-feedbackError" />
          </IconButton>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default WolfInput;
