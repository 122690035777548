import { ReactElement } from 'react';

export interface EntityData {
  name: string;
  description: string;
  color?: string;
  areaId?: string;
  serviceId?: string;
  processId?: string;
}

export enum SnackType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  DARK = 'dark',
  WARNING = 'warning',
}

export enum DrawerType {
  ADD = 'add',
  EDIT = 'edit',
}

export enum MenuItemKind {
  OPTION = 'OPTION',
  SEPARATOR = 'SEPARATOR',
}

export type MenuItemType = {
  type: MenuItemKind;
  text: string;
  link?: string;
  letter?: string;
  icon?: ReactElement<any, any>;
  action?: () => void;
};

export type ActionMessage = {
  id?: string;
  title?: string;
  message: string;
  type: SnackType;
};
