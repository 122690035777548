import WolfStepIndicator from '@components/ui/WolfStepIndicator';
import { useTranslation } from '@hooks/useTranslation';
import { StepType } from '@models/step.model';
import { CheckCircleOutline, DragIndicatorOutlined, ShareOutlined } from '@mui/icons-material';
import { Box, Fade, Icon, Paper, styled, Typography } from '@mui/material';
import { confirmWolfieElement } from '@redux/reducers/wolfie.reducer';
import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { EditIcon20 } from './WolfIcons';
import WolfTooltip from './WolfTooltip';

interface WolfCardsStepProps {
  item: {
    stepId: string;
    name: string;
    position: string;
    type: StepType;
    isWolfieGenerated?: boolean;
  };
  index: number;
  substep?: boolean;
  isLast?: boolean;
  selected?: boolean;
  drawLine?: boolean;
  dragDisabled?: boolean;
  isEditing?: boolean;
  isWolfieGenerated?: boolean;
  onClick?: (step: any) => void;
  onEdit?: () => void;
  onShare?: () => void;
}

interface StyledPaperProps {
  selected: boolean;
  step?: 'sub' | '';
  last?: 'last' | '';
  draw?: 'draw' | '';
}

const StyledPaper = styled(Paper)<StyledPaperProps>(({ theme, selected, step, last, draw }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: step === 'sub' ? '18.5rem' : '20rem',
  minWidth: step === 'sub' ? '18.5rem' : '20rem',
  maxWidth: step === 'sub' ? '18.5rem' : '20rem',
  height: '3.5rem',
  maxHeight: '3.5rem',
  padding: '0.75rem 1rem',
  borderRadius: '0.375rem',
  backgroundColor: 'white',
  border: `${selected ? 3 : 1}px solid ${selected ? theme.palette.callToAction.main : theme.palette.primaryFrozen.main}`,
  boxShadow: theme.shadows[1],
  margin: '0.5rem 0',
  position: 'relative',
  cursor: 'pointer',
  '&:hover': {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  transition: 'border-color 0.3s ease',
  '&::before': {
    content: step === 'sub' && draw === 'draw' ? '""' : 'none',
    position: 'absolute',
    left: '-1rem',
    top: -10,
    bottom: 0,
    width: '1px',
    height: last === 'last' ? '70%' : '140%',
    backgroundColor: theme.palette.primaryIcy.main,
  },
  '&::after': {
    content: step === 'sub' && draw === 'draw' ? '""' : 'none',
    position: 'absolute',
    left: '-1rem',
    top: '50%',
    width: '16px',
    height: '1px',
    backgroundColor: theme.palette.primaryIcy.main,
  },
}));

const TextContainer = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  display: 'block',
}));

const WolfCardsStep: React.FC<WolfCardsStepProps> = ({
  item,
  index,
  substep = false,
  isLast = false,
  selected = false,
  drawLine = false,
  dragDisabled = false,
  isEditing = false,
  isWolfieGenerated = false,
  onClick,
  onEdit,
  onShare,
}) => {
  const [activeHover, setActiveHover] = useState(false);
  const localeCommon = useTranslation('common');
  const dispatch = useDispatch();

  const handleWolfieClick = (id: string) => {
    dispatch(confirmWolfieElement(id));
  };

  const handleHover = () => {
    setActiveHover(true);
  };

  const handleLeave = () => {
    setActiveHover(false);
  };

  return (
    <Draggable draggableId={item.stepId || index.toString()} index={index} isDragDisabled={dragDisabled}>
      {(provided) => (
        <Fade in={true} timeout={item?.isWolfieGenerated ? 700 : 0}>
          <Box position="relative">
            <StyledPaper
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              selected={selected}
              className={isWolfieGenerated ? 'rounded-sm bg-primaryDark !opacity-30' : ''}
              last={isLast ? 'last' : ''}
              draw={drawLine ? 'draw' : ''}
              step={substep ? 'sub' : ''}>
              <Box className="w-1/5 flex items-center justify-center mr-4 p-2 rounded-s my-2" onClick={onClick}>
                <WolfStepIndicator text={item?.position} type={item.type} isSub={substep} />
              </Box>
              <Box className="w-3/5" onClick={onClick}>
                <TextContainer variant="body12" className="w-full py-3">
                  {item?.name?.slice(0, 25).trim() + (item?.name?.length > 25 ? '...' : '').trim()}
                </TextContainer>
              </Box>
              <Box className="w-1/5 flex justify-end">
                {!isWolfieGenerated &&
                  activeHover &&
                  (dragDisabled ? (
                    <Box className="flex justify-between items-center gap-0.5 text-sm">
                      <Icon onClick={onEdit} className="!w-5 !h-5 hover:bg-primaryFrozen">
                        <EditIcon20 sx={{ fontSize: 20 }} />
                      </Icon>
                      <Icon onClick={onShare} className="!w-5 !h-5 hover:bg-primaryFrozen">
                        <ShareOutlined sx={{ fontSize: 20 }} />
                      </Icon>
                    </Box>
                  ) : (
                    <Box className="flex justify-between items-center gap-0.5 text-sm">
                      <Icon onClick={onShare} className="!w-5 !h-5 hover:bg-primaryFrozen">
                        <ShareOutlined sx={{ fontSize: 20 }} />
                      </Icon>
                      <Icon onClick={onEdit} className="!w-5 !h-5 hover:bg-primaryFrozen">
                        <EditIcon20 sx={{ fontSize: 20 }} />
                      </Icon>
                      <Icon className="!w-5 !h-5 hover:bg-primaryFrozen">
                        <DragIndicatorOutlined sx={{ fontSize: 20 }} />
                      </Icon>
                    </Box>
                  ))}
              </Box>
            </StyledPaper>
            {isWolfieGenerated && (
              <Box
                className="absolute top-3 right-4 bg-feedbackSuccess text-white p-1 rounded-full cursor-pointer w-8 h-8 opacity-100 z-10"
                onClick={() => handleWolfieClick(item.stepId)}>
                <WolfTooltip title={localeCommon['wolfieGenerated']}>
                  <span>
                    {/* Span Required by tooltip to work */}
                    <CheckCircleOutline />
                  </span>
                </WolfTooltip>
              </Box>
            )}
          </Box>
        </Fade>
      )}
    </Draggable>
  );
};

export default WolfCardsStep;
