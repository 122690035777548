import WolfButton from '@components/ui/WolfButton';
import WolfInput from '@components/ui/WolfInput';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { Box } from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { validEmail } from '@utils/validEmail';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface CreateEditOrganizationModalProps {
  modalClose: () => void;
  createOrganization?: (
    name: string,
    email: string,
    firstName: string,
    lastName: string,
    organizationId?: string,
    description?: string,
  ) => void;
  editOrganization?: (
    name: string,
    email: string,
    firstName: string,
    lastName: string,
    organizationId: string,
    description?: string,
  ) => void;
  saving: boolean;
  editing?: boolean;
  initialData?: { name: string; organizationId: string; description?: string } | null | undefined;
}

const CreateEditOrganizationModal: React.FC<CreateEditOrganizationModalProps> = ({
  modalClose,
  createOrganization,
  editOrganization,
  saving,
  editing = false,
  initialData,
}) => {
  const localeCommon = useTranslation('common');
  const localeUsers = useTranslation('users');
  const localeActionMessages = useTranslation('actionMessages');
  const localeOrganizations = useTranslation('organizations');
  const [organizationName, setOrganizationName] = useState<string | null>(null);
  const [organizationDescription, setOrganizationDescription] = useState<string | null>(null);
  const [ownerEmail, setOwnerEmail] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (editing && initialData) {
      setOrganizationName(initialData?.name);
      setOrganizationDescription(initialData?.description || '');
    }
  }, [editing, initialData]);

  const handleNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setOrganizationName(event.target.value);
  };

  const handleDescriptionChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setOrganizationDescription(event.target.value);
  };

  const handleEmailChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setOwnerEmail(event.target.value);
  };

  const handleFirstNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setLastName(event.target.value);
  };

  const submit = () => {
    if (!organizationName || (!editing && (!ownerEmail || !firstName || !lastName || !validEmail(ownerEmail)))) {
      dispatch(
        appendActionMessage({ message: localeActionMessages['invalidOrganizationData'], type: SnackType.ERROR }),
      );
      return;
    }
    if (editing && initialData) {
      editOrganization &&
        editOrganization(
          organizationName || '',
          '',
          '',
          '',
          initialData.organizationId || '',
          organizationDescription || '',
        );
    } else {
      createOrganization &&
        createOrganization(
          organizationName || '',
          ownerEmail || '',
          firstName || '',
          lastName || '',
          '',
          organizationDescription || '',
        );
    }
    modalClose();
  };

  return (
    <Box>
      <Box className={editing ? 'min-h-32' : ''}>
        <WolfInput
          fullWidth
          autoComplete="organization"
          label={localeCommon['organization'] + ' ' + localeCommon['name']}
          placeholder={localeCommon['organization'] + ' ' + localeCommon['name']}
          onChange={handleNameChange}
          value={organizationName}
        />
      </Box>
      <Box className={editing ? 'min-h-32' : ''}>
        <WolfInput
          fullWidth
          autoComplete="description"
          label={localeCommon['organization'] + ' ' + localeCommon['description']}
          placeholder={localeCommon['organization'] + ' ' + localeCommon['description']}
          onChange={handleDescriptionChange}
          value={organizationDescription}
        />
      </Box>

      {!editing && (
        <>
          <Box>
            <WolfInput
              fullWidth
              autoComplete="email"
              label={localeCommon['owner'] + ' ' + localeCommon['email']}
              placeholder={localeCommon['organization'] + ' ' + localeCommon['email']}
              onChange={handleEmailChange}
              value={ownerEmail}
              type="email"
              required
            />
          </Box>
          <Box>
            <WolfInput
              fullWidth
              autoComplete="given-name"
              label={localeCommon['owner'] + ' ' + localeUsers['firstName']}
              placeholder={localeCommon['user'] + ' ' + localeUsers['firstName']}
              onChange={handleFirstNameChange}
              value={firstName}
            />
          </Box>
          <Box>
            <WolfInput
              fullWidth
              autoComplete="family-name"
              label={localeCommon['owner'] + ' ' + localeUsers['lastName']}
              placeholder={localeCommon['user'] + ' ' + localeUsers['lastName']}
              onChange={handleLastNameChange}
              value={lastName}
            />
          </Box>
        </>
      )}
      <Box className="flex justify-between my-4 ">
        <WolfButton onClick={modalClose} color="secondary">
          {localeCommon['cancel']}
        </WolfButton>
        <WolfButton onClick={submit} color="primary" autoFocus disabled={saving}>
          {editing ? localeCommon['edit'] : localeCommon['create']}
        </WolfButton>
      </Box>
    </Box>
  );
};

export default CreateEditOrganizationModal;
