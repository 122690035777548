import { useTranslation } from '@hooks/useTranslation';
import { Step } from '@models/step.model';
import { Alert, Box, Checkbox, Dialog, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useState } from 'react';

// TODO: This component needs to be refactored when import/Export is expanded
function JsonImportTextarea({
  elementList,
  onClose,
  onSubmit,
}: {
  elementList: Step[];
  onClose: () => void;
  onSubmit: (jsonData: string) => void;
}) {
  const [jsonData, setJsonData] = useState('');
  const [resetIds, setResetIds] = useState(true);
  const [resetIntegrationData, setResetIntergrationData] = useState(true);
  const [skipReorder, setSkipReorder] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [exportPosition, setExportPosition] = useState('beginning');
  const [customPosition, setCustomPosition] = useState('0');

  const localeCommon = useTranslation('common');
  const localeImpExp = useTranslation('impExp');

  const handleChange = (event: any) => {
    setJsonData(event.target.value);
  };

  const handleResetIdsChange = (event: any) => {
    setResetIds(event.target.checked);
  };

  const handleResetIntergrationDataChange = (event: any) => {
    setResetIntergrationData(event.target.checked);
    if (!event.target.checked) {
      setOpenAlertModal(true);
    }
  };

  const handleSkipReorderChange = (event: any) => {
    setSkipReorder(event.target.checked);
  };

  const handleSubmit = () => {
    const options = {
      resetIds,
      resetIntegrationData,
      skipReorder,
      stepPositionIdx: exportPosition === 'beginning' ? 0 : exportPosition === 'end' ? -1 : customPosition,
    };

    const jsonDataWithOptions = {
      ...JSON.parse(jsonData),
      options,
    };
    onSubmit(JSON.stringify(jsonDataWithOptions));
    onClose();
  };

  const handleAlertModalClose = () => {
    setOpenAlertModal(false);
  };

  const handleExportPositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExportPosition(event.target.value);
  };

  const handleCustomPositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomPosition(event.target.value);
  };

  return (
    <div>
      <DialogTitle>{localeImpExp['enterData']}</DialogTitle>
      <DialogContent>
        <Typography>{localeCommon['options']}</Typography>
        <Box>
          <Typography>{localeCommon['importPos']}</Typography>

          <TextField
            select
            label={localeImpExp['exportPosition']}
            value={exportPosition}
            onChange={handleExportPositionChange}
            fullWidth>
            <MenuItem value="beginning">{localeCommon['beginning']}</MenuItem>
            <MenuItem value="end">{localeCommon['end']}</MenuItem>
            <MenuItem value="custom">{localeCommon['custom']}</MenuItem>
          </TextField>
          {exportPosition === 'custom' && (
            <>
              <Typography>{localeImpExp['beforeStep']}</Typography>

              <TextField
                select
                label={localeImpExp['exportPosition']}
                value={customPosition}
                onChange={handleCustomPositionChange}
                fullWidth>
                {' '}
                {elementList.map((element, idx) => (
                  <MenuItem value={idx} key={element.stepId}>
                    {element.name}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
        </Box>
        <Box className="">
          <FormControlLabel
            control={<Checkbox checked={resetIds} onChange={handleResetIdsChange} />}
            label={localeImpExp['resetIds']}
          />
          <FormControlLabel
            control={<Checkbox checked={resetIntegrationData} onChange={handleResetIntergrationDataChange} />}
            label={localeImpExp['resetInteg']}
          />
          <FormControlLabel
            control={<Checkbox checked={skipReorder} onChange={handleSkipReorderChange} />}
            label={localeImpExp['skipReorder']}
          />
        </Box>
        <TextareaAutosize
          aria-label="JSON Input"
          placeholder="Enter JSON here"
          value={jsonData}
          onChange={handleChange}
          style={{ width: '100%', minHeight: 300, minWidth: 400 }}
        />
        <Dialog open={openAlertModal}>
          <DialogTitle id="child-modal-title">{localeCommon['alert']}</DialogTitle>
          <DialogContent>
            <Alert severity="error">{localeImpExp['integDataMessage']}</Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAlertModalClose}>{localeCommon['close']}</Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
      <DialogActions className="flex justify-between m-6">
        <Button color="warning" disabled={openAlertModal} onClick={onClose}>
          {localeCommon['cancel']}
        </Button>
        <Button variant="contained" disabled={openAlertModal} onClick={handleSubmit}>
          {localeCommon['submit']}
        </Button>
      </DialogActions>
    </div>
  );
}

export default JsonImportTextarea;
