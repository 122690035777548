import { useTranslation } from '@hooks/useTranslation';
import { CheckCircle, ExpandLess, ExpandMore, RadioButtonUnchecked } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { Box, Collapse, IconButton, LinearProgress, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { useState } from 'react';
import CustomModal from './CustomModal';
import WolfButton from './ui/WolfButton';

interface Task {
  label: string;
  completed: boolean;
}

interface CollapsibleProgressProps {
  tasks: Task[];
  title: string;
  dismissTitle: string;
  dismissTitleSecondary: string;
  modalTitle: string;
  modalText: string;
  handleDismiss: (type: 'primary' | 'secondary') => void;
}

const CollapsibleProgress: React.FC<CollapsibleProgressProps> = ({
  tasks,
  title,
  dismissTitle,
  dismissTitleSecondary,
  modalTitle,
  modalText,
  handleDismiss,
}) => {
  const localeCommon = useTranslation('common');
  const [open, setOpen] = useState(() => {
    const savedState = localStorage.getItem('open-ob-progress');
    return savedState ? JSON.parse(savedState) : true;
  });
  const [showDismissModal, setShowDismissModal] = useState(false);

  const handleToggle = () => {
    const collapsedState = !open;
    setOpen(collapsedState);
    localStorage.setItem('open-ob-progress', JSON.stringify(collapsedState));
  };

  const _handleDismiss = () => {
    handleDismiss('primary');
  };

  const _handleDismissSecondary = () => {
    handleDismiss('secondary');
  };

  const toggleDismissModal = () => {
    setShowDismissModal(!showDismissModal);
  };

  const completedTasks = tasks.filter((task) => task.completed).length;
  const progress = (completedTasks / tasks.length) * 100;

  if (tasks.length === 0 || !tasks.some((task) => !task.completed)) {
    return null;
  }

  return (
    <>
      <Box className="mx-6">
        <Box className="flex items-center justify-end"></Box>
        <Box className="flex items-center justify-between">
          <Box className="flex justify-between  items-center w-full">
            <Typography variant="body16semibold">{title}</Typography>
            <IconButton onClick={handleToggle}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </Box>
          <Box className="w-1/4 flex justify-end">
            <IconButton className="!pr-0" onClick={toggleDismissModal}>
              {<Close />}
            </IconButton>
          </Box>
        </Box>
        <Box className="flex items-center justify-between">
          <LinearProgress variant="determinate" value={progress} className="block w-full" />
          <Typography variant="body14semibold" component="span" className="block w-1/4 text-right">
            {completedTasks} / {tasks.length}
          </Typography>
        </Box>

        <Collapse in={open}>
          <List>
            {tasks.map((task, index) => (
              <ListItem key={index} className="!pl-0 !py-1">
                <ListItemIcon className="!min-w-5 mr-2">
                  {task.completed ? <CheckCircle color="success" /> : <RadioButtonUnchecked />}
                </ListItemIcon>
                <Typography variant="body14">{task.label}</Typography>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Box>

      <CustomModal isOpen={showDismissModal} onClose={toggleDismissModal} showButtons={false} title={modalTitle}>
        <Box>
          <Typography variant="body16">{modalText}</Typography>
        </Box>
        <Box className="flex justify-between mt-8">
          <WolfButton variant="contained" color="secondary" onClick={toggleDismissModal}>
            {localeCommon['cancel']}
          </WolfButton>

          {dismissTitle && (
            <WolfButton variant="contained" color="primary" onClick={_handleDismiss} endIcon={<Close />}>
              {dismissTitle}
            </WolfButton>
          )}

          {dismissTitleSecondary && (
            <WolfButton variant="contained" color="primary" onClick={_handleDismissSecondary} endIcon={<Close />}>
              {dismissTitleSecondary}
            </WolfButton>
          )}
        </Box>
      </CustomModal>
    </>
  );
};

export default CollapsibleProgress;
