import avatarPlaceholder from '@assets/avatar_placeholder.png';
import Avatar from '@mui/material/Avatar';

interface WolfAvatarProps {
  size?: '104px' | '56px' | '48px' | '40px' | '24px' | 'default';
  src?: string;
  alt?: string;
}

const WolfAvatar: React.FC<WolfAvatarProps> = ({ size = 'default', src, alt }) => {
  const getSize = () => {
    switch (size) {
      case '104px':
        return 104;
      case '56px':
        return 56;
      case '48px':
        return 48;
      case '40px':
        return 40;
      case '24px':
        return 24;
      case 'default':
      default:
        return 40;
    }
  };

  return (
    <Avatar
      alt={alt || 'Avatar'}
      src={src || avatarPlaceholder}
      sx={{
        width: getSize(),
        height: getSize(),
        borderRadius: '10px',
        objectFit: 'cover',
        clipPath:
          'polygon( 0% 20%,0% 20%,0.2% 16.2%,0.8% 12.8%,1.8% 9.8%,3.2% 7.2%,5% 5%,7.2% 3.2%,9.8% 1.8%,12.8% 0.8%,16.2% 0.2%,20% 0%,80% 0%,80% 0%,83.8% 0.2%,87.2% 0.8%,90.2% 1.8%,92.8% 3.2%,95% 5%,96.8% 7.2%,98.2% 9.8%,99.2% 12.8%,99.8% 16.2%,100% 20%,100% 80%,100% 80%,99.8% 83.8%,99.2% 87.2%,98.2% 90.2%,96.8% 92.8%,95% 95%,92.8% 96.8%,90.2% 98.2%,87.2% 99.2%,83.8% 99.8%,80% 100%,20% 100%,20% 100%,16.2% 99.8%,12.8% 99.2%,9.8% 98.2%,7.2% 96.8%,5% 95%,3.2% 92.8%,1.8% 90.2%,0.8% 87.2%,0.2% 83.8%,0% 80% )',
      }}
    />
  );
};

export default WolfAvatar;
