import { useTranslation } from '@hooks/useTranslation';
import { Document } from '@models/document.model';
import { Box, Typography } from '@mui/material';

interface DeleteDocumentModalProps {
  document?: Document;
}

const DeleteDocumentModal: React.FC<DeleteDocumentModalProps> = ({ document }) => {
  const localeDocuments = useTranslation('documents');

  return (
    <Box>
      <Typography variant="body16">{localeDocuments['confirmDeleteMessage']}</Typography>
      <Typography variant="body16semibold">{document?.name}</Typography>
    </Box>
  );
};

export default DeleteDocumentModal;
