import WolfAvatar from '@components/ui/WolfAvatar';
import WolfButton from '@components/ui/WolfButton';
import { UploadIcon24 } from '@components/ui/WolfIcons';
import { VisuallyHiddenInput } from '@components/VisuallyHiddenInput';
import { useTranslation } from '@hooks/useTranslation';
import { Box } from '@mui/material';

type ProfilePictureProps = {
  src?: string;
  name?: string;
  handleImageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ProfilePicture: React.FC<ProfilePictureProps> = ({ src, name, handleImageChange }) => {
  const localeSettings = useTranslation('settings');

  return (
    <Box className="flex flex-wrap justify-center">
      <WolfAvatar src={src || ''} alt={name || 'User'} size="104px" />{' '}
      {handleImageChange && (
        <Box className="w-full flex justify-center my-3">
          <WolfButton
            component="label"
            role={undefined}
            variant="outlined"
            color="primary"
            tabIndex={-1}
            startIcon={<UploadIcon24 />}>
            {localeSettings['changeAvatar']}
            <VisuallyHiddenInput type="file" accept="image/png, image/jpeg, image/gif" onChange={handleImageChange} />
          </WolfButton>
        </Box>
      )}
    </Box>
  );
};
export default ProfilePicture;
