import { StepType } from '@models/step.model';
import { drawGenericNode } from '@utils/processMap/drawGenericNode';
import { drawProcessHorizontal } from '@utils/processMap/drawProcessHorizontal';

export function drawRowItem(
  originItem: any,
  originNode: any,
  rowIndex: number,
  data: any,
  serviceName: string,
  areaName?: string,
) {
  const hasSwitch = originItem?.steps?.find((item: any) => item.stepType === StepType.SWITCH);
  const hasLinked = originItem?.steps?.find((item: any) => item.stepData?.executeProcessId);
  // If it has a switch, we need to add the switch and the linked processes
  if (hasSwitch) {
    return drawGenericNode(
      'switch-' + originItem.processId,
      200 * rowIndex + originNode.position.x + 250,
      originNode.position.y,
      hasSwitch.name,
      'Switch',
      originNode.id,
      originNode.id,
    );
  }
  if (hasLinked) {
    const linkedProcesses = [hasLinked.stepData?.executeProcessId];
    return linkedProcesses
      .map((id: any, index: number) => {
        const auxProcess = data.filter((process: any) => process.processId === id)[0];
        if (!auxProcess) {
          return null;
        }
        return drawProcessHorizontal(auxProcess, rowIndex, index, areaName || '', serviceName, originNode);
      })
      .filter((item: any) => item)[0];
  }
}
