import { Policy } from '@models/policy.model';
import { Process } from '@models/process.model';

// Also used for categorizing policies
export const categorizeProcesses = (processes: Process[] | Policy[]) => {
  const categorizedProcesses: any = {};
  const categories: string[] = [];

  processes.forEach((process) => {
    const category = process?.category || 'Uncategorized';

    if (!categorizedProcesses[category]) {
      categorizedProcesses[category] = [];
    }

    categorizedProcesses[category].push(process);

    if (!categories.includes(category)) {
      categories.push(category);
    }
  });
  return { categorizedProcesses, categories };
};
