import { CalendarMonthOutlined, InfoOutlined, WarningAmber } from '@mui/icons-material';
import {
  BaseTextFieldProps,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import WolfTooltip from './WolfTooltip';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInputBase-root': {
    boxSizing: 'border-box',
    padding: '8px 0',
    '& .MuiInputBase-input': {
      padding: '8px 16px',
      boxSizing: 'border-box',
    },
  },
  '& .MuiInputLabel-root': {
    display: 'none', // Hide the label by default
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${theme.palette.grey[300]}`,
    transition: 'border-color 0.3s ease',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[400]}`, // Hover state
    },
    '&.Mui-focused': {
      outline: 'none', // Remove default focus outline
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.main}`, // Focused state
    },
    '&.Mui-error fieldset': {
      border: `1px solid ${theme.palette.error.main}`, // Error state
    },
    '&.Mui-disabled fieldset': {
      color: theme.palette.grey[500],
      border: `1px solid ${theme.palette.grey[200]}`, // Disabled state
      background: theme.palette.action.disabledBackground,
    },
  },
}));

interface WolfTextareaProps extends BaseTextFieldProps {
  label: string;
  secondaryLabel?: string;
  placeholder?: string;
  tooltipText?: string;
  detailText?: string;
  detailTextWidth?: number;
  customRows?: number;
  showIcon?: boolean;
  icon?: React.ReactNode;
  iconAction?: () => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const WolfTextarea: React.FC<WolfTextareaProps> = ({
  label,
  secondaryLabel,
  placeholder,
  detailText,
  detailTextWidth = 300,
  customRows = 2,
  tooltipText,
  showIcon = false,
  icon,
  iconAction,
  error = false,
  errorMessage,
  disabled,
  onChange,
  ...props
}) => {
  return (
    <Box className="flex flex-wrap">
      {label && (
        <Box className={secondaryLabel ? 'flex w-full justify-between' : 'justify-start'}>
          <Typography variant="body14semibold" gutterBottom className="w-full">
            {label}
            {tooltipText && (
              <WolfTooltip title={tooltipText}>
                <IconButton size="small">
                  <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
                </IconButton>
              </WolfTooltip>
            )}
          </Typography>
          {secondaryLabel && (
            <Typography variant="body14" className="text-additionalPlaceholder" color="additionalPlaceholder">
              {secondaryLabel}
            </Typography>
          )}
        </Box>
      )}
      {detailText && (
        <Box sx={{ width: detailTextWidth, maxWidth: detailTextWidth }}>
          <Typography variant="body2" gutterBottom className="w-full text-primaryGray">
            {detailText}
          </Typography>
        </Box>
      )}
      <StyledTextField
        fullWidth
        multiline
        rows={customRows}
        placeholder={placeholder}
        error={error}
        variant="outlined"
        onChange={onChange}
        disabled={disabled}
        InputProps={
          showIcon
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={iconAction}>
                      {icon ? (
                        icon
                      ) : (
                        <CalendarMonthOutlined sx={{ width: 22, height: 24 }} className="text-primaryDark" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : undefined
        }
        {...props}
      />
      {error && errorMessage && (
        <Typography variant="body2" gutterBottom className="w-full text-feedbackError">
          <IconButton size="small">
            <WarningAmber sx={{ width: 16, height: 14 }} className="text-feedbackError" />
          </IconButton>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default WolfTextarea;
