import { useEffect } from 'react';
import { useTranslation } from './useTranslation';

interface UseUnsavedChangesWarningProps {
  showWarning: boolean;
}

// ****
// Note that this custom hook is complemented by using

// <Prompt
// when={isGenerating}
// message="Leaving message?"
// />

// to also handle the "navigating away case"
// ****

const useUnsavedChangesWarning = ({ showWarning }: UseUnsavedChangesWarningProps) => {
  const localeCommon = useTranslation('common');
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): string | void => {
      const message = localeCommon['changesNotSaved'];
      event.returnValue = message; // Necessary for Chrome to show the dialog
      return message; // Necessary for other browsers
    };

    if (showWarning) {
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Cleanup on unmount or when `isGenerating` changes
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [showWarning]);
};

export default useUnsavedChangesWarning;
