import fileLink from '@assets/file_link.png';
import fileMissing from '@assets/file_missing.png';
import { graphQlClient } from '@config/graphqlClient';
import DocumentsGraphQL from '@graphql/document.queries';

export const enhanceHTML = async (html: any) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html.__html, 'text/html');

  const linkedDocs = doc.querySelectorAll('.linked-document');

  // Collect all promises in an array (to use promise all later)
  const fetchPromises = Array.from(linkedDocs).map((lnkdoc) => {
    const id = lnkdoc.getAttribute('id');
    if (!id) {
      return Promise.resolve(); // Immediately resolve for missing ids
    }

    return graphQlClient
      .request(DocumentsGraphQL.queries.getDocument, { id })
      .then((res: any) => {
        const auxData = res.getDocument;
        lnkdoc.innerHTML = tagGenerator(auxData?.fileType, auxData?.uri, auxData?.name, auxData?.documentId);
      })
      .catch((err: any) => {
        console.error(err);
        lnkdoc.innerHTML = BROKEN_LINK_TAG;
      });
  });

  // Await all the fetch and update operations
  await Promise.all(fetchPromises);

  const serializer = new XMLSerializer();
  const htmlString = serializer.serializeToString(doc);

  return { __html: htmlString };
};

export default enhanceHTML;

export function tagGenerator(fileType: string, uri: string, name: string, documentId: string): string {
  if (fileType.includes('image') && !fileType.includes('heic')) {
    return `<img src="${uri}" alt="${name}" style="max-width: 300px; height: auto;">`;
  } else if (fileType.includes('video')) {
    // We are not dynamically setting the type  because it is not always accurate
    return `<video width="320" height="240" controls>
              <source src="${uri}" type="video/mp4">
              Your browser does not support the video tag.
            </video>`;
  } else {
    return `
      <a href="${uri}" id="${documentId}" target="_blank">
        <div>
          <img src=${fileLink} alt="FILE" style="max-width: 100px; height: max-width: 100px;">
          <p>${name}</p>
        </div>
      </a>`;
  }
}

export const BROKEN_LINK_TAG = ` 
    <div>
      <img src=${fileMissing} alt="BROKEN" style="max-width: 100px; height: max-width: 100px;">
      <p>The linked file was deleted</p>
    </div>`;
