export function calculateTotalHeight(nodes: any[]) {
  if (nodes.length === 0) return 0;

  let minY = Infinity;
  let maxY = -Infinity;

  nodes.forEach((node) => {
    const nodeTop = node.position.y; // Top position of the node
    const nodeBottom = node.position.y + (node.data.height || 0); // Bottom position, accounting for node height

    if (nodeTop < minY) minY = nodeTop; // Track the highest point (topmost)
    if (nodeBottom > maxY) maxY = nodeBottom; // Track the lowest point (bottommost)
  });

  console.log('minY', minY);
  console.log('maxY', maxY);

  // TODO: We add a margin of 150px to account for the node size
  return maxY - minY + 150;
}
