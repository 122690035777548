import CustomModal from '@components/CustomModal';
import AddPolicyForm from '@components/drawers/AddPolicyForm';
import GridListSubheader from '@components/GridListSubheader';
import ConfirmLeaveModal from '@components/modals/ConfirmLeaveModal';
import DeletePolicyModal from '@components/modals/DeletePolicyModal';
import SummaryCardPolicies from '@components/SummaryCardPolicies';
import WolfHelperNavigation from '@components/ui/WolfHelperNavigation';
import { graphQlClient } from '@config/graphqlClient';
import PoliciesGraphQL from '@graphql/policy.queries';
import { useLoader } from '@hooks/useLoader';
import { useTranslation } from '@hooks/useTranslation';
import { DrawerType, SnackType } from '@models/common.model';
import { createOrUpdatePolicyRequest, deletePoliciesRequest, getPoliciesRequest, Policy } from '@models/policy.model';
import { Box, Grid, Typography } from '@mui/material';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { categorizeProcesses } from '@utils/categorizeProcesses';
import { useEffect, useRef, useState } from 'react';

const PolicyList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setLoading } = useLoader();

  const [policyCategories, setPolicyCategories] = useState<string[]>([]);
  const [categorizedPolicies, setCategorizedPolicies] = useState<any>({});
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState<DrawerType>(DrawerType.ADD);
  const [initialData, setInitialData] = useState<Policy>();
  const [selectedPolicyForDeletion, setSelectedPolicyForDeletion] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isGridView, setIsGridView] = useState(true);
  const cardRefs = useRef<{ [key: string]: any | null }>({});
  const [selectedNavigationItem, setSelectedNavigationItem] = useState<any>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);

  useEffect(() => {
    getPolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localePolicies = useTranslation('policies');
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');

  const getPolicies = async () => {
    try {
      setLoading(true);
      const data: getPoliciesRequest = await graphQlClient.request(PoliciesGraphQL.queries.getPolicies);
      const { categories, categorizedProcesses } = categorizeProcesses(data?.getPolicies || []);
      setPolicyCategories(categories);
      setCategorizedPolicies(categorizedProcesses);
      setPolicies(data?.getPolicies || []);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleToggleView = () => {
    setIsGridView(!isGridView);
  };

  // DRAWER HANDLE *********************
  const drawerNewOpen = () => {
    setDrawerType(DrawerType.ADD);
    setShowDrawer(true);
  };

  const drawerEditOpen = (id: string) => {
    setDrawerType(DrawerType.EDIT);
    setShowDrawer(true);
    const policyToEdit = policies.find((policy: Policy) => policy.policyId === id);
    if (policyToEdit) {
      setInitialData(policyToEdit);
    }
  };

  const drawerClose = (): void => {
    if (hasUnsavedChanges) {
      setShowConfirmLeaveModal(true);
    } else {
      setShowDrawer(false);
    }
  };

  const closeConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
  };

  const closeAndConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
    setShowDrawer(false);
  };

  const handlePolicyFormChanges = (changes: boolean) => {
    setHasUnsavedChanges(changes);
  };

  const handleDrawerSave = async (policy: Policy) => {
    setSaving(true);

    if (drawerType === DrawerType.EDIT && !policy.policyId) {
      return appendActionMessage({
        message: localeCommon['somethingWentWrong'],
        type: SnackType.ERROR,
      });
    }

    const query =
      drawerType === DrawerType.EDIT && policy.policyId
        ? PoliciesGraphQL.mutations.updatePolicy
        : PoliciesGraphQL.mutations.createPolicy;
    const params = {
      policy: {
        policyId: drawerType === DrawerType.EDIT ? policy.policyId : undefined,
        name: policy.name,
        content: policy.content,
        category: policy.category,
        description: policy.description,
      },
    };

    try {
      const data: createOrUpdatePolicyRequest = await graphQlClient.request(query, params);
      if (data?.updatePolicy?.policyId || data?.createPolicy?.policyId) {
        getPolicies();
        dispatch(
          appendActionMessage({
            message: DrawerType.ADD
              ? localeActionMessages['policyCreatedSuccessfully']
              : localeActionMessages['policyUpdatedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        setShowDrawer(false);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setSaving(false);
    }
  };

  const deletePolicy = async (id: string) => {
    setSelectedPolicyForDeletion(id);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowDeleteModal(false);
    try {
      const data: deletePoliciesRequest = await graphQlClient.request(PoliciesGraphQL.mutations.deletePolicy, {
        id: selectedPolicyForDeletion,
      });
      if (data?.deletePolicy) {
        getPolicies();
        dispatch(
          appendActionMessage({ message: localeActionMessages['policyDeletedSuccessfully'], type: SnackType.SUCCESS }),
        );
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setSelectedPolicyForDeletion('');
    }
  };

  const cancelDeletePolicy = () => {
    setShowDeleteModal(false);
    setSelectedPolicyForDeletion('');
  };

  const scrollToCard = (id: string) => {
    setSelectedNavigationItem(id);
    if (cardRefs.current[id]) {
      cardRefs.current[id]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const policyList = policyCategories.map((category, index: number) => (
    <div key={index}>
      <Box
        className="flex items-center my-8"
        sx={{ scrollMargin: 100 }}
        ref={(el) => (cardRefs.current[category] = el)}>
        <Typography variant="h4semibold" className="mx-2">
          {category}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {/* // TODO: THis may be further refactored to cleanup and make it with the same component as area/service */}
        {categorizedPolicies[category].map((policy: Policy, idx: number) => (
          <Grid item xs={12} md={isGridView ? 4 : 12} xl={isGridView ? 3 : 12} key={idx}>
            <Box sx={{ scrollMargin: 100 }} ref={(el) => (cardRefs.current[policy.policyId] = el)}>
              <SummaryCardPolicies
                key={idx}
                color="#CCCCCC"
                name={policy.name}
                description={policy.description}
                content={policy.content}
                location={policy.location}
                handleEdit={() => drawerEditOpen(policy.policyId || '')}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  ));

  const navigationPolicyList = policyCategories.map((category, index: number) => (
    <div key={index}>
      <WolfHelperNavigation
        type={'title'}
        label={category}
        selected={selectedNavigationItem === category}
        onSelect={() => {
          scrollToCard(category);
        }}
      />

      {categorizedPolicies[category].map((policy: Policy, idx: number) => (
        <WolfHelperNavigation
          key={policy.policyId}
          type={'section'}
          label={policy.name}
          selected={selectedNavigationItem === policy.policyId}
          onSelect={() => {
            scrollToCard(policy?.policyId);
          }}
        />
      ))}
    </div>
  ));

  return (
    <>
      <Box className="w-full">
        <Box className="sticky top-0 z-30 bg-backgroundUI">
          <GridListSubheader
            title={localeCommon['policies']}
            buttonText={localePolicies['addPolicy']}
            isGridView={isGridView}
            onToggleView={handleToggleView}
            onDrawerOpen={drawerNewOpen}
            icon="policy"
          />
        </Box>{' '}
        <Box className="flex">
          <Box className="w-3/4">{policyList}</Box>
          <Box className="w-1/4 fixed -right-16 overflow-y-scroll h-5/6">{navigationPolicyList}</Box>
        </Box>
      </Box>

      <CustomModal isOpen={showDrawer} onClose={drawerClose}>
        <AddPolicyForm
          onClose={drawerClose}
          handleSave={handleDrawerSave}
          handleDelete={deletePolicy}
          onChanges={handlePolicyFormChanges}
          drawerType={drawerType}
          initialData={initialData}
          saving={saving}
        />
      </CustomModal>

      <CustomModal
        showButtons={true}
        isOpen={showDeleteModal}
        onClose={cancelDeletePolicy}
        onConfirm={handleConfirmDelete}
        maxWidth="600px"
        title={localePolicies['confirmDeleteTitle']}
        customConfirmColor="error"
        customConfirmText={localeCommon['delete']}>
        <DeletePolicyModal open={showDeleteModal} policyId={selectedPolicyForDeletion} />
      </CustomModal>

      {showConfirmLeaveModal && (
        <CustomModal
          isOpen={!!showConfirmLeaveModal}
          title={localeCommon['confirmLeave']}
          customConfirmColor="error"
          maxWidth="600px"
          showButtons={true}
          onClose={closeConfirmLeaveModal}
          onConfirm={closeAndConfirmLeaveModal}>
          <ConfirmLeaveModal />
        </CustomModal>
      )}
    </>
  );
};

export default PolicyList;
