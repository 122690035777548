import { gql } from 'graphql-request';

export const USER_FIELDS = gql`
  fragment UserFields on UserDto {
    userId
    firstName
    lastName
    email
    avatar
    role
    organizationId
    lastLogin
    loginMethod
    status
    locale
  }
`;

export const getSelf = gql`
  query getSelf {
    getSelf {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const getSelfWithOrganization = gql`
  query getSelfWithOrganization {
    getSelfWithOrganization {
      user {
        ...UserFields
      }
      organization {
        name
        description
        onboardingDetails
        lastProfileUpdate
        organizationId
      }
    }
  }
  ${USER_FIELDS}
`;

export const getAllowedRoles = gql`
  query getAllowedRoles {
    getAllowedRoles
  }
`;

export const getUsers = gql`
  query getUsers {
    getUsers {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const getUserByEmail = gql`
  query getUserByEmail($email: String!, $emailOrganizationId: String!) {
    getUserByEmail(email: $email, emailOrganizationId: $emailOrganizationId) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const getPaginatedUsers = gql`
  query getPaginatedUsers($users: GetPaginatedUsersInput!) {
    getPaginatedUsers(users: $users) {
      users {
        ...UserFields
        failedLoginAttempts
      }
      count
    }
  }
  ${USER_FIELDS}
`;

export const createUser = gql`
  mutation createUser($user: UserCreateInput!) {
    createUser(user: $user) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const updateOwnUser = gql`
  mutation updateOwnUser($user: UserUpdateInput!) {
    updateOwnUser(user: $user) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const updateOtherUser = gql`
  mutation updateOtherUser($user: UserUpdateInput!) {
    updateOtherUser(user: $user) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const disableUser = gql`
  mutation disableUser($id: String!) {
    disableUser(id: $id) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const enableUser = gql`
  mutation enableUser($id: String!) {
    enableUser(id: $id) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

const UserGraphQL = {
  queries: {
    getSelf,
    getSelfWithOrganization,
    getUsers,
    getUserByEmail,
    getPaginatedUsers,
    getAllowedRoles,
  },
  mutations: {
    createUser,
    updateOwnUser,
    updateOtherUser,
    disableUser,
    enableUser,
  },
};

export default UserGraphQL;
