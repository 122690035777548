export interface Trigger {
  triggerId: string;
  processId: string;
  name: string;
  triggerType: TriggerType;
  triggerData?: any;
}

export interface EventTrigger {
  name?: string;
  description?: string;
}

export interface CalendarTrigger {
  name?: string;
  description?: string;
  startTime?: string;
  endTime?: string;
  guests?: string;
  timezone?: string;
  rrule?: string;
}

export enum TriggerType {
  EVENT = 'EVENT',
  CALENDAR = 'CALENDAR',
}

export interface createOrUpdateTriggerRequest {
  createTrigger?: Trigger;
  updateTrigger?: Trigger;
}

export interface deleteTriggerRequest {
  deleteTrigger: {
    affected: number;
  };
}
