import DOMPurify from 'dompurify';

const sanitizeHTML = (htmlString: string | undefined | null) => {
  if (!htmlString) {
    return {
      __html: '',
    };
  }
  return {
    __html: DOMPurify.sanitize(htmlString),
  };
};

export default sanitizeHTML;
