import {
  getTokenLocalStorage,
  getTokenSessionStorage,
  setTokenLocalStorage,
  setTokenSessionStorage,
} from '@config/storage';
import { RootState, store } from '@redux/store';
import { GraphQLClient, RequestMiddleware, ResponseMiddleware } from 'graphql-request';

const responseMiddleware: ResponseMiddleware = async (response: any) => {
  const refreshed = response?.headers?.get('Refreshed');
  if (refreshed) {
    const state: RootState = store.getState();
    const rememberMe = state.user?.rememberMe;
    if (rememberMe) {
      setTokenLocalStorage(refreshed);
    } else {
      setTokenSessionStorage(refreshed);
    }
  }
  if (
    response?.response?.errors &&
    ['Forbidden resource', 'NOT AUTHORIZED'].includes(response?.response?.errors[0]?.message)
  ) {
    //Redirect to process logout and then to start page
    window.location.href = '/logout';
  }
};

let currentHeaders = {};

const requestMiddleware: RequestMiddleware = async (request) => {
  const state: RootState = store.getState();
  const rememberMe = state.user?.rememberMe;
  let token = '';
  if (rememberMe) {
    token = getTokenLocalStorage();
  } else {
    token = getTokenSessionStorage();
  }
  return {
    ...request,
    headers: {
      ...request.headers,
      ...currentHeaders,
      Authorization: 'Bearer ' + token,
    },
  };
};

export { gql } from 'graphql-request';

// TODO: REACT_APP_PUBLIC_GRAPHQL_API_URL IS LEGACY, remove when vars updated in all environments
export const graphQlClient = new GraphQLClient(
  process.env.REACT_APP_BACKEND_URL + 'graphql' || (process.env.REACT_APP_PUBLIC_GRAPHQL_API_URL as string),
  {
    fetch,
    requestMiddleware,
    responseMiddleware,
  },
);

// we might need to migrate to a different graphql library so that we don't need to use this separately
// and that the mutation requests are more descriptive. This will eventually reach a limitation.
export const graphQlUpload = async (file: File, entity: string, query: string, variables?: {}) => {
  const MAX_FILE_SIZE_MB = 50;
  if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
    throw new Error('File size exceeds the maximum allowed size');
  }
  const state: RootState = store.getState();
  const rememberMe = state.user?.rememberMe;
  let token = '';
  if (rememberMe) {
    token = getTokenLocalStorage();
  } else {
    token = getTokenSessionStorage();
  }

  const operations = {
    query,
    variables: {
      [entity]: {
        ...variables,
        file: null, // This will be replaced in the 'map' with the actual file location.
      },
    },
  };

  const map = {
    '0': [`variables.${entity}.file`],
  };

  const formData = new FormData();
  formData.append('operations', JSON.stringify(operations));
  formData.append('map', JSON.stringify(map));
  formData.append('0', file); // The file should be appended last.

  // TODO: REACT_APP_PUBLIC_GRAPHQL_API_URL IS LEGACY, remove when vars updated in all environments
  const response = await fetch(
    process.env.REACT_APP_BACKEND_URL + 'graphql' || (process.env.REACT_APP_PUBLIC_GRAPHQL_API_URL as string),
    {
      method: 'POST',
      body: formData,
      headers: {
        'apollo-require-preflight': 'true',
        Authorization: 'Bearer ' + token,
      },
    },
  );
  const responseData = await response.json();
  return responseData.data;
};

type Headers = {
  [key: string]: string;
};
// This function is used to update the headers of the graphql client for the impersonation feature
export const updateHeaders = (newHeaders: Headers) => {
  currentHeaders = {
    ...newHeaders,
  };
};

export const getCurrentHeaders = (): Headers => {
  return { ...currentHeaders };
};
