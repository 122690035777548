export default function StepInsideNode({ data }: any) {
  return (
    <>
      <div title={data.label} className="step-inside-node flex flex-wrap items-center">
        {data.label.slice(0, 25) + (data.label.length > 25 ? '...' : '')}
      </div>
      {/* <Handle type="source" position={Position.Right} id="a" /> */}
    </>
  );
}
