import { gql } from 'graphql-request';

export const AREA_FIELDS = gql`
  fragment AreaFields on AreaDto {
    areaId
    name
    description
    color
    storageLocation
    order
  }
`;

export const SERVICE_FIELDS = gql`
  fragment ServiceFields on ServiceDto {
    serviceId
    name
    description
    color
    order
    calendar
    areaId
    storageLocation
    processes {
      serviceId
      processId
      category
      name
      description
      goal
      order
      triggers {
        triggerId
        name
        triggerType
        triggerData
      }
      steps {
        stepId
        name
        order
        notes
        stepType
        stepData
      }
    }
  }
`;

export const getAreas = gql`
  query getAreas {
    getAreas {
      ...AreaFields
      services {
        ...ServiceFields
      }
    }
  }

  ${AREA_FIELDS}
  ${SERVICE_FIELDS}
`;

export const getAreasNameIdColorWServices = gql`
  query getAreas {
    getAreas {
      name
      areaId
      color
      services {
        name
        color
      }
    }
  }
`;

export const getAreasNameWServProc = gql`
  query getAreas {
    getAreas {
      name
      color
      areaId
      order
      services {
        name
        color
        serviceId
        areaId
        processes {
          name
          processId
          serviceId
        }
      }
    }
  }
`;

export const getAreaByID = gql`
  query getAreaByID($id: String!) {
    getAreaByID(id: $id) {
      ...AreaFields
      services {
        ...ServiceFields
      }
    }
  }

  ${AREA_FIELDS}
  ${SERVICE_FIELDS}
`;

export const createArea = gql`
  mutation createArea($area: AreaCreateInput!) {
    createArea(area: $area) {
      ...AreaFields
    }
  }
  ${AREA_FIELDS}
`;

export const updateArea = gql`
  mutation updateArea($area: AreaUpdateInput!) {
    updateArea(area: $area) {
      ...AreaFields
    }
  }
  ${AREA_FIELDS}
`;

export const reorderAreas = gql`
  mutation reorderAreas($areas: AreaReorderInput!) {
    reorderAreas(areas: $areas) {
      error
      messages
    }
  }
`;

export const deleteArea = gql`
  mutation deleteArea($id: String!) {
    deleteArea(id: $id) {
      affected
      messages
    }
  }
`;
const AreasGraphQL = {
  queries: {
    getAreas,
    getAreasNameIdColorWServices,
    getAreasNameWServProc,
    getAreaByID,
  },
  mutations: {
    createArea,
    updateArea,
    reorderAreas,
    deleteArea,
  },
};

export default AreasGraphQL;
