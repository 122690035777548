import { Box, styled, Theme, useTheme } from '@mui/material';
import { isColorDark } from '@utils/isColorDark';

interface WolfColorSelectorProps {
  icon: React.ReactNode;
  color: string;
  selected: boolean;
  isDisabled?: boolean;
  onClick: (color: string) => void;
}

interface SelectorItemProps {
  selected: boolean;
  disabled: boolean;
}

interface IconWrapperProps {
  color: string;
  foregroundColor: string;
}

const SelectorItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'disabled',
})<SelectorItemProps>(({ theme, selected, disabled }) => ({
  backgroundColor: disabled ? theme.palette.primaryFrozen.main : theme.palette.common.white,
  border: `${selected ? '2px' : '1px'} solid ${selected ? theme.palette.primary.main : theme.palette.primaryFrozen.main}`,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  cursor: disabled ? 'not-allowed' : 'pointer',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: disabled ? theme.palette.primaryFrozen.main : theme.palette.common.white,
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '48px',
  width: '48px',
  boxSizing: 'border-box',
}));

const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'foregroundColor',
})<IconWrapperProps>(({ color, foregroundColor }) => ({
  backgroundColor: color,
  borderRadius: '50%',
  padding: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    fill: foregroundColor,
  },
}));

const WolfColorSelector: React.FC<WolfColorSelectorProps> = ({
  icon,
  color,
  selected,
  isDisabled = false,
  onClick,
}) => {
  const theme: Theme = useTheme();
  const foregroundColor = isColorDark(color) ? theme.palette.common.white : theme.palette.primary.main;

  const handleColorClick = () => {
    if (!isDisabled) {
      onClick(color);
    }
  };

  return (
    <SelectorItem selected={selected} disabled={isDisabled} onClick={handleColorClick}>
      <IconWrapper color={color} foregroundColor={foregroundColor}>
        {icon}
      </IconWrapper>
    </SelectorItem>
  );
};

export default WolfColorSelector;
