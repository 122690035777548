import { graphQlClient } from '@config/graphqlClient';
import PoliciesGraphQL from '@graphql/policy.queries';
import ProcessesGraphQL from '@graphql/process.queries';
import { useTranslation } from '@hooks/useTranslation';
import { Area } from '@models/area.model';
import { getPolicyByIdRequest, Policy } from '@models/policy.model';
import { getProcessesWithAreaServiceRequest, Process } from '@models/process.model';
import { Service } from '@models/service.model';
import { Box, List, ListItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface DeletePolicyModalProps {
  open: boolean;
  policyId: string;
}

const DeletePolicyModal: React.FC<DeletePolicyModalProps> = ({ open, policyId }) => {
  useEffect(() => {
    if (open && policyId) {
      getPolicyDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, policyId]);

  const localePolicies = useTranslation('policies');
  const localeCommon = useTranslation('common');
  const [policyData, setPolicyData] = useState<Policy>();
  const [processes, setProcesses] = useState<Process[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);
  const [services, setServices] = useState<Service[]>([]);

  const getPolicyDetails = async () => {
    try {
      const data: getPolicyByIdRequest = await graphQlClient.request(PoliciesGraphQL.queries.getPolicyById, {
        id: policyId,
      });
      setPolicyData(data?.getPolicyById);
      getProcesses(data?.getPolicyById);
    } catch (e: any) {
      console.log(e);
    }
  };

  //TODO This call could be made to a new separate endpoint
  const getProcesses = async (policy: Policy) => {
    try {
      const data: getProcessesWithAreaServiceRequest = await graphQlClient.request(
        ProcessesGraphQL.queries.getProcessesWithAreaService,
      );

      const relatedProcesses = policy?.processes?.map((item: { processId: string }) => item.processId);

      const newProcesses: Process[] = data.getProcessesWithAreaService.filter((process: Process) =>
        relatedProcesses?.includes(process.processId),
      );
      const newServices: Service[] = [];
      newProcesses.forEach((process: Process) => {
        if (process.service && !newServices.some((service) => process?.service?.serviceId === service.serviceId)) {
          newServices.push(process.service);
        }
      });
      const newAreas: Area[] = [];
      newServices.forEach((service: Service) => {
        if (service.area && !newAreas.some((area) => service?.area?.areaId === area.areaId)) {
          newAreas.push(service.area);
        }
      });

      setAreas(newAreas);
      setServices(newServices);
      setProcesses(newProcesses);
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <Box>
      <Box>
        <Typography paragraph>
          {localePolicies['confirmDeleteMessage'].replace('{count}', policyData?.processes?.length || 0)}
        </Typography>
        <List>
          {areas.map((area: Area) => (
            <>
              <ListItem key={area.areaId} className="mb-0 p-0">
                <p className="mb-0 text-lg">{area.name} </p>
              </ListItem>
              {services
                .filter((service) => service.area?.areaId === area.areaId)
                .map((service: Service) => (
                  <>
                    <ListItem key={service.serviceId} className="ml-5 text-base">
                      <p className="text-sm">{service.name} </p>
                    </ListItem>
                    {processes
                      .filter((process) => process.service?.serviceId === service.serviceId)
                      .map((process: Process) => (
                        <ListItem key={process.processId} className="ml-10">
                          <p className="font-bold text-xs">{'* ' + process.name}</p>
                        </ListItem>
                      ))}
                  </>
                ))}
            </>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default DeletePolicyModal;
