import WolfTooltip from '@components/ui/WolfTooltip';
import { Box, ListItemButton, ListItemButtonProps, Typography, styled } from '@mui/material';

interface SidebarButtonProps extends ListItemButtonProps {
  icon: React.ReactNode;
  label?: string;
  tooltip?: string;
}

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.primaryFrozen.main,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primaryFrozen.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primaryFrozen.dark,
    },
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const SidebarButton: React.FC<SidebarButtonProps> = ({ icon, label, tooltip, ...props }) => (
  <WolfTooltip title={tooltip || ''} placement="right">
    <StyledListItemButton {...props}>
      <Box className="flex flex-wrap justify-start items-center !h-8 !md-h-12 !lg:h-14 !px-6">
        {icon}
        {label && (
          <Box className="ml-2">
            <Label variant="body14" color="primary">
              {label}
            </Label>
          </Box>
        )}
      </Box>
    </StyledListItemButton>
  </WolfTooltip>
);

export default SidebarButton;
