/* eslint-disable react/no-unescaped-entities */
import CustomModal from '@components/CustomModal';
import AddCalendarTriggerForm from '@components/drawers/AddCalendarTriggerForm';
import AddEventTriggerForm from '@components/drawers/AddEventTriggerForm';
import CustomDrawer from '@components/drawers/CustomDrawer';
import ConfirmLeaveModal from '@components/modals/ConfirmLeaveModal';
import WolfButton from '@components/ui/WolfButton';
import WolfDropdown from '@components/ui/WolfDropdown';
import { CalendarTriggerIcon20 } from '@components/ui/WolfIcons';
import WolfInput from '@components/ui/WolfInput';
import WolfTextarea from '@components/ui/WolfTextarea';
import WolfTooltip from '@components/ui/WolfTooltip';
import WolfTriggerBox from '@components/ui/WolfTriggerBox';
import { graphQlClient } from '@config/graphqlClient';
import PoliciesGraphQL from '@graphql/policy.queries';
import ServicesGraphQL from '@graphql/service.queries';
import TriggersGraphQL from '@graphql/trigger.queries';
import { useTranslation } from '@hooks/useTranslation';
import useUnsavedChangesWarning from '@hooks/useUnsavedChangesWarning';
import { DrawerType, SnackType } from '@models/common.model';
import { getPoliciesRequest } from '@models/policy.model';
import { Process } from '@models/process.model';
import { getServiceByIDRequest, getServicesNameIdWAreaRequest } from '@models/service.model';
import {
  CalendarTrigger,
  createOrUpdateTriggerRequest,
  deleteTriggerRequest,
  EventTrigger,
  Trigger,
  TriggerType,
} from '@models/trigger.model';
import { AddOutlined, ElectricBoltOutlined, InfoOutlined } from '@mui/icons-material';
import { Autocomplete, Box, CircularProgress, Icon, IconButton, List, TextField, Typography } from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';

interface AddProcessFormProps {
  onClose: () => void;
  onChanges?: (changes: boolean) => void;
  handleSave: (entity: Process, policies: { text: string; value: string }[]) => void;
  handleDelete: (processId: string) => void;
  drawerType: DrawerType;
  parentService?: { name: string; serviceId: string };
  initialData?: Process;
  saving: boolean;
  saveBtnText?: string;
}

// CHECK: This component is likely going to become obsolete once we do the new process edit flow
const AddProcessForm: React.FC<AddProcessFormProps> = ({
  onClose,
  onChanges,
  handleSave,
  handleDelete,
  parentService,
  drawerType,
  initialData,
  saving = false,
  saveBtnText,
}) => {
  const localeProcessForm = useTranslation('processForm');
  const localeCommon = useTranslation('common');
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [entityData, setEntityData] = useState<Partial<Process>>({
    name: '',
    goal: '',
    description: '',
    category: '',
    serviceId: '',
  });
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const [triggers, setTriggers] = useState<Trigger[]>([]);
  const [step, setStep] = useState<TriggerType | null>(null);
  const [policyList, setPolicyList] = useState<{ text: string; value: string }[]>([]);
  const [services, setServices] = useState<{ text: string; value: string }[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedService, setSelectedService] = useState<{
    text: string;
    value: string;
  } | null>(null);
  const [selectedPolicies, setSelectedPolicies] = useState<{ text: string; value: string }[]>([]);
  const [addTrigger, setAddTrigger] = useState<TriggerType | null>(null);
  const [selectedEventTrigger, setSelectedEventTrigger] = useState<EventTrigger>();
  const [selectedCalendarTrigger, setSelectedCalendarTrigger] = useState<Trigger>();
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [triggerSaving, setTriggerSaving] = useState<boolean>(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState<boolean>(false);
  useUnsavedChangesWarning({ showWarning: hasUnsavedChanges });

  useEffect(() => {
    if (drawerType === DrawerType.EDIT && initialData) {
      setEntityData(initialData);
      setSelectedPolicies(initialData.policies?.map((policy) => ({ text: policy.name, value: policy.policyId })) || []);
      if (initialData.triggers) {
        setTriggers(initialData.triggers);
      }
      getServicesAreasNameId();
      getPolicies();
    } else if (drawerType === DrawerType.ADD) {
      getServicesAreasNameId();
      getPolicies();
    }
  }, [initialData, drawerType]);

  useEffect(() => {
    if (onChanges) {
      onChanges(hasUnsavedChanges);
    }
  }, [hasUnsavedChanges]);

  const getPolicies = async () => {
    try {
      const data: getPoliciesRequest = await graphQlClient.request(PoliciesGraphQL.queries.getPolicies);
      setPolicyList(
        data?.getPolicies.map((item) => {
          return { text: item.name, value: item.policyId };
        }) || [],
      );
    } catch (e: any) {
      console.log(e);
    }
  };

  const populateService = async (serviceId: string | undefined, serviceList: { text: string; value: string }[]) => {
    if (!serviceId) {
      return;
    }
    const auxService = serviceList.find((service) => service.value === serviceId);
    if (auxService) {
      setSelectedService(auxService);
      setEntityData((prev) => ({
        ...prev,
        serviceId: auxService.value,
      }));
    }
    getSelectedService(serviceId || '');
  };

  const handlePolicyChange = (policies: { text: string; value: string }[]) => {
    if (policies.length === 0) {
      setHasUnsavedChanges(true);
      setSelectedPolicies([]);
    } else {
      setHasUnsavedChanges(true);
      setSelectedPolicies([...policies] || []);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasUnsavedChanges(true);
    setEntityData({
      ...entityData,
      [event.target.name]: event.target.value,
    });
  };

  const _onClose = () => {
    if (hasUnsavedChanges) {
      setShowConfirmLeaveModal(true);
    } else {
      setHasUnsavedChanges(false);
      onClose();
    }
  };

  const submit = () => {
    setHasUnsavedChanges(false);
    handleSave(entityData as Process, selectedPolicies);
  };

  const closeConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
  };

  const closeAndConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
    onClose();
  };

  // TODO: Call this from components
  const handleAddTrigger = async (calendarTrigger?: CalendarTrigger, eventTrigger?: EventTrigger) => {
    setTriggerSaving(true);
    if (!calendarTrigger && !eventTrigger) {
      return;
    }
    try {
      let trigger: any;
      if (eventTrigger) {
        trigger = {
          name: eventTrigger?.name,
          //TODO: Should handle case when no processID (new Process)
          processId: entityData?.processId,
          triggerType: TriggerType.EVENT,
          triggerData: {
            description: eventTrigger?.description,
          },
        };
      } else if (calendarTrigger) {
        trigger = {
          name: calendarTrigger?.name,
          //TODO: Should handle case when no processID (new Process)
          processId: entityData?.processId,
          triggerType: TriggerType.CALENDAR,
          triggerData: {
            description: calendarTrigger?.description,
            startTime:
              DateTime.fromISO(calendarTrigger?.startTime || '').toISO({ includeOffset: false }) ||
              DateTime.now().toISO({ includeOffset: false }),
            endTime:
              DateTime.fromISO(calendarTrigger?.endTime || '').toISO({ includeOffset: false }) ||
              DateTime.now().plus({ minutes: 30 })?.toISO({ includeOffset: false }),
            timezone: calendarTrigger?.timezone,
            recurrence: calendarTrigger.rrule,
            attendees: calendarTrigger?.guests
              ? calendarTrigger?.guests.split(',').map((guest) => ({
                  email: guest.trim(),
                }))
              : [],
            calendarId: initialData?.service?.calendar?.id || '',
          },
        };
      }
      const query = isEditing ? TriggersGraphQL.mutations.updateTrigger : TriggersGraphQL.mutations.createTrigger;
      if (isEditing && trigger) {
        trigger.triggerId = isEditing;
      }
      const data: createOrUpdateTriggerRequest = await graphQlClient.request(query, {
        trigger,
      });
      if (data?.createTrigger?.triggerId) {
        setTriggers([...triggers, data.createTrigger]);
      }
      if (data?.updateTrigger?.triggerId) {
        setTriggers([
          ...triggers.filter((item) => item.triggerId !== data?.updateTrigger?.triggerId),
          data.updateTrigger,
        ]);
      }
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setStep(null);
      setIsEditing(null);
      setTriggerSaving(false);
    }
  };

  const handleRemoveTrigger = async (id: string) => {
    setTriggerSaving(true);
    try {
      const deleteResult: deleteTriggerRequest = await graphQlClient.request(TriggersGraphQL.mutations.deleteTrigger, {
        id,
      });
      if (deleteResult?.deleteTrigger?.affected > 0) {
        setTriggers(triggers.filter((trigger: Trigger) => trigger.triggerId !== id));
        setStep(null);
      }
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setTriggerSaving(false);
    }
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHasUnsavedChanges(true);
    setEntityData({
      ...entityData,
      [event.target.name]: event.target.value,
    });
  };

  const subDrawerClose = () => {
    setAddTrigger(null);
  };

  const handleSelectService = (event: any, service: any) => {
    if (!service) {
      return;
    }
    // Change category list based on selected service
    setSelectedService(service);
    getSelectedService(service.value);
    setEntityData({
      ...entityData,
      serviceId: service.value,
    });
  };

  const handleCategoryInputChange = (event: any, newInputValue: string) => {
    setSelectedCategory(newInputValue);
    setEntityData((prev) => ({
      ...prev,
      category: newInputValue,
    }));
  };

  const addNewTrigger = (type: TriggerType) => {
    if (!initialData?.processId) {
      dispatch(
        appendActionMessage({
          message: localeProcessForm['saveBeforeTriggers'],
          type: SnackType.WARNING,
        }),
      );
      return;
    }
    setAddTrigger(type);
  };

  const getServicesAreasNameId = async () => {
    try {
      const data: getServicesNameIdWAreaRequest = await graphQlClient.request(
        ServicesGraphQL.queries.getServicesNameIdWArea,
      );
      setServices(
        data.getServices
          .sort((a, b) => {
            if (a.area.name > b.area.name) {
              return 1;
            } else if (a.area.name < b.area.name) {
              return -1;
            } else {
              // If a.area.name and b.area.name are the same, then compare by a.name and b.name
              return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
            }
          })
          .map((service) => ({
            text: service?.area?.name + ' - ' + service.name,
            value: service.serviceId,
          })),
      );
      if ((!selectedService?.value && initialData?.service?.serviceId) || parentService?.serviceId) {
        populateService(
          initialData?.service?.serviceId || parentService?.serviceId,
          data.getServices.map((service) => ({
            text: service?.area?.name + ' - ' + service.name,
            value: service.serviceId,
          })),
        );
      }
      setLoading(false);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const getSelectedService = async (serviceId: string) => {
    if (!serviceId) {
      return;
    }
    try {
      const data: getServiceByIDRequest = await graphQlClient.request(ServicesGraphQL.queries.getServiceByID, {
        id: serviceId,
      });
      setCategories(Array.from(new Set(data.getServiceByID.processes.map((process) => process.category))));
      setSelectedCategory(initialData?.category || '');
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const calendarTriggerList = triggers
    ?.filter((item) => item.triggerType === TriggerType.CALENDAR)
    .map((item) => (
      <WolfTriggerBox
        key={item.triggerId}
        title={item.name}
        dateFrom={
          new Date(item.triggerData?.startTime).toLocaleDateString() +
          ' ' +
          new Date(item.triggerData?.startTime).toLocaleTimeString()
        }
        dateTo={
          new Date(item.triggerData?.endTime).toLocaleDateString() +
          ' ' +
          new Date(item.triggerData?.endTime).toLocaleTimeString()
        }
        description={item.triggerData?.description}
        recurrence={item.triggerData?.recurrence}
        onEdit={() => {
          setIsEditing(item.triggerId);
          setSelectedCalendarTrigger(item);
          setAddTrigger(TriggerType.CALENDAR);
        }}
        onDelete={() => handleRemoveTrigger(item.triggerId)}
      />
    ));

  const eventTriggerList = triggers
    ?.filter((item) => item.triggerType === TriggerType.EVENT)
    .map((item) => (
      <WolfTriggerBox
        key={item.triggerId}
        title={item.name}
        description={item.triggerData?.description}
        onEdit={() => {
          setIsEditing(item.triggerId);
          setSelectedEventTrigger(item);
          setAddTrigger(TriggerType.EVENT);
        }}
        onDelete={() => handleRemoveTrigger(item.triggerId)}
      />
    ));

  const triggeredFrom = initialData?.triggeredBy?.map((item) => (
    <WolfTriggerBox
      key={item.process?.processId}
      title={item.process?.name || ''}
      description={''}
      slim={true}
      onEdit={() => {}}
      onDelete={() => {}}
    />
  ));

  if (drawerType === 'edit' && loading) {
    return (
      <Box className="flex justify-center items-center h-full">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="w-full h-full">
      <Prompt when={hasUnsavedChanges} message={localeCommon['changesNotSaved']} />
      <List>
        <Box className="flex gap-8 h-full">
          <Box className="w-1/2">
            {
              <Box>
                <Box className="flex items-center justify-between w-full">
                  <Typography variant="body14semibold" className="w-full">
                    {localeProcessForm['serviceForProcess'] + ' *'}
                  </Typography>
                  <Typography variant="body14" className="w-full text-right text-additionalPlaceholder">
                    {localeCommon['required']}
                  </Typography>
                </Box>
                <Box className="mb-6">
                  <Autocomplete
                    className="mb-8"
                    value={selectedService}
                    onChange={handleSelectService}
                    options={services}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    getOptionLabel={(option) => (option?.text || option?.text || '').trim().replace(/\s+/g, ' ')}
                    renderInput={(params) => <TextField {...params} variant="outlined" className="!h-10" />}
                    filterOptions={(options, { inputValue }) => {
                      return options.filter((option) => option?.text?.toLowerCase().includes(inputValue.toLowerCase()));
                    }}
                    sx={{
                      '& .MuiAutocomplete-inputRoot': {
                        padding: '2px !important',
                        height: '40px',
                        minHeight: '40px',
                      },
                      '& .MuiOutlinedInput-root': {
                        height: '40px',
                      },
                    }}
                  />
                </Box>
              </Box>
            }
            <WolfInput
              label={localeProcessForm['name'] + ' *'}
              placeholder={localeProcessForm['name']}
              secondaryLabel={localeCommon['required']}
              name="name"
              value={entityData?.name}
              onChange={handleInputChange}
              disabled={!!step}
              className="!mb-4"
              fullWidth
            />
            <WolfTextarea
              tooltipText={localeProcessForm['processGoalMessage']}
              label={localeCommon['goal'] + ' *'}
              secondaryLabel={localeCommon['required']}
              placeholder={localeCommon['goal']}
              name="goal"
              className="!mb-4"
              value={entityData?.goal || ''}
              onChange={handleTextAreaChange}
            />
            {!step && initialData?.triggeredBy && initialData?.triggeredBy?.length > 0 && (
              <Box>
                <Box className="flex items-center border-solid border-l-0 border-r-0 border-t-0 border-primaryFrozen justify-between w-full">
                  <Box className="flex items-center">
                    <Typography variant="body14semibold">{localeProcessForm['triggeredFrom']}</Typography>
                  </Box>
                </Box>
                {triggeredFrom}
              </Box>
            )}
          </Box>
          <Box className="w-1/2">
            <Box>
              <Box>
                <Typography variant="body14semibold">{localeProcessForm['category']}</Typography>
                <WolfTooltip title={localeProcessForm['processCategoryMessage']}>
                  <IconButton size="small">
                    <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
                  </IconButton>
                </WolfTooltip>

                <Box className="mb-6">
                  <Autocomplete
                    freeSolo
                    className="mb-8"
                    value={selectedCategory}
                    onInputChange={handleCategoryInputChange}
                    options={categories}
                    renderInput={(params) => <TextField {...params} variant="outlined" className="!h-10" />}
                    isOptionEqualToValue={(option, value) => option === value}
                    filterOptions={(options, { inputValue }) => {
                      return options.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()));
                    }}
                    sx={{
                      '& .MuiAutocomplete-inputRoot': {
                        padding: '2px !important',
                        height: '40px',
                        minHeight: '40px',
                      },
                      '& .MuiOutlinedInput-root': {
                        height: '40px',
                      },
                    }}
                  />
                </Box>
              </Box>
              <WolfTextarea
                label={localeProcessForm['description']}
                tooltipText={localeProcessForm['processDescriptionMessage']}
                placeholder={localeProcessForm['description']}
                name="description"
                className="!mb-4"
                value={entityData?.description || ''}
                onChange={handleTextAreaChange}
              />
              <Box className="pb-8">
                <Typography variant="body14semibold">{localeProcessForm['linkedPolicy']}</Typography>
                <WolfTooltip title={localeProcessForm['linkedPolicyMessage']}>
                  <IconButton size="small">
                    <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
                  </IconButton>
                </WolfTooltip>
                <WolfDropdown
                  showClearButton={true}
                  label={
                    !selectedPolicies
                      ? localeProcessForm['selectPolicy']
                      : selectedPolicies.map((item) => item.text).join(', ')
                  }
                  items={policyList}
                  onChange={handlePolicyChange}
                  initialSelectedItems={selectedPolicies.map((item) => item.value)}
                  showBorder={true}
                />
                <Box className=""></Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {initialData?.processId && (
          <Box className="flex justify-between gap-8">
            <Box className="w-full">
              <Box className="flex items-center border-solid border-l-0 border-r-0 border-t-0 border-primaryFrozen justify-between pb-4 w-full">
                <Box className="flex items-center">
                  <Icon color="primary">
                    <CalendarTriggerIcon20 />
                  </Icon>
                  <Typography variant="body14semibold">{localeProcessForm['calTrig']}</Typography>
                </Box>
                <WolfButton
                  onClick={() => addNewTrigger(TriggerType.CALENDAR)}
                  endIcon={<AddOutlined />}
                  variant="outlined"
                  color="tertiary"
                  className="!border-frozenGrayShades2 border-2">
                  {localeCommon['add']}
                </WolfButton>
              </Box>
              {calendarTriggerList}
            </Box>
            <Box className="w-full">
              <Box className="flex items-center border-solid border-l-0 border-r-0 border-t-0 border-primaryFrozen justify-between pb-4 w-full">
                <Box className="flex items-center">
                  <Icon color="primary">
                    <ElectricBoltOutlined />
                  </Icon>
                  <Typography variant="body14semibold">{localeProcessForm['evTrig']}</Typography>
                </Box>
                <WolfButton
                  onClick={() => addNewTrigger(TriggerType.EVENT)}
                  endIcon={<AddOutlined />}
                  variant="outlined"
                  color="tertiary"
                  className="!border-frozenGrayShades2 border-2">
                  {localeCommon['add']}
                </WolfButton>
              </Box>
              {eventTriggerList}
            </Box>
          </Box>
        )}
      </List>
      <Box className="absolute border-solid border-l-0 border-r-0 border-b-0 border-primaryFrozen !h-16 bottom-0 left-0 w-full">
        {/* NOTE: border-l-0 border-r-0 border-b-0 is a workaround to avoid a conflict between mui material and tailwind */}
        <Box
          className="mt-auto bg-white flex justify-between items-center"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={2}>
          <WolfButton variant="outlined" color="secondary" onClick={_onClose}>
            {localeCommon['cancel']}
          </WolfButton>
          {drawerType === DrawerType.EDIT && (
            <WolfButton color="error" variant="outlined" onClick={() => handleDelete(entityData?.processId || '')}>
              {localeCommon['delete']}
            </WolfButton>
          )}
          <WolfButton variant="contained" color="primary" disabled={saving} onClick={submit}>
            {saveBtnText || localeCommon['save']}
          </WolfButton>
        </Box>
      </Box>

      <CustomDrawer
        showBack={true}
        showClose={false}
        title={localeProcessForm['evTrig']}
        isOpen={addTrigger === TriggerType.EVENT}
        onClose={subDrawerClose}
        size="normal">
        <AddEventTriggerForm
          processName={entityData?.name || ''}
          onClose={subDrawerClose}
          onSave={handleAddTrigger}
          initialData={selectedEventTrigger}
          saving={triggerSaving}
        />
      </CustomDrawer>

      <CustomDrawer
        showBack={true}
        showClose={false}
        title={localeProcessForm['calTrig']}
        isOpen={addTrigger === TriggerType.CALENDAR}
        onClose={subDrawerClose}
        size="normal">
        <AddCalendarTriggerForm
          processName={entityData?.name || ''}
          onClose={subDrawerClose}
          onSave={handleAddTrigger}
          initialData={selectedCalendarTrigger}
          saving={triggerSaving}
        />
      </CustomDrawer>

      {showConfirmLeaveModal && (
        <CustomModal
          isOpen={!!showConfirmLeaveModal}
          title={localeCommon['confirmLeave']}
          customConfirmColor="error"
          maxWidth="600px"
          showButtons={true}
          onClose={closeConfirmLeaveModal}
          onConfirm={closeAndConfirmLeaveModal}>
          <ConfirmLeaveModal />
        </CustomModal>
      )}
    </Box>
  );
};

export default AddProcessForm;
