import { Process } from '@models/process.model';
import { Service } from '@models/service.model';

export interface ContextInfo {
  name?: string;
  description?: string;
  goal?: string;
  category?: string;
  related?: Partial<Process>[];
  areaContext?: {
    name?: string;
    description?: string;
  };
  serviceContext?: {
    name?: string;
    description?: string;
  };
  orgContext?: {
    name?: string;
    description?: string;
    mainAreas?: string[];
    troublesomeProcesses?: string[];
  };
  existingServices?: Service[];
}

export const generateFirstTimeContextMessage = (contextInfo: ContextInfo) => ({
  id: 'contextInfo',
  role: 'system',
  content: `
	  Use this information as Context to help you, you MUST keep it in focus and cannot change it.
	  ORGANIZATION NAME: ${contextInfo?.name}`,
});

export const generateResetFtueContextMessage = (contextInfo: ContextInfo) => {
  return {
    id: 'contextInfo',
    role: 'system',
    content: `
    The user has ALREADY completed the FTUE, and provided the following information.
    You MUST give that information as an introduction to the user
    and ask him if it is still relevant and if he wants to change it.

	  ORGANIZATION NAME: ${contextInfo?.name}
    ORGANIZATION DESCRIPTION: ${contextInfo?.description}
    ORGANIZATION MAIN AREAS: ${contextInfo?.orgContext?.mainAreas?.map((area) => area).join(', ')}
    ORGANIZATION TOUGHLY PROCESSES: ${contextInfo?.orgContext?.troublesomeProcesses?.map((process) => process).join(', ')}`,
  };
};

export const generateNoAreasContextMessage = (contextInfo: ContextInfo) => ({
  id: 'contextInfo',
  role: 'system',
  content: `
	  Use this information as Context to help you, you MUST keep it in focus and cannot change it, it should provide the context for suggesting areas.
	  ORGANIZATION NAME: ${contextInfo?.name}
    ORGANIZATION DESCRIPTION: ${contextInfo?.description}
    ORGANIZATION CONTEXT: ${contextInfo?.orgContext?.mainAreas?.map((area) => area).join(', ')}`,
});

export const generateBlankAreaContextMessage = (contextInfo: ContextInfo) => ({
  id: 'contextInfo',
  role: 'system',
  content: `
	  Use this information as Context to help you, you MUST keep it in focus and cannot change it, it should provide the context for suggesting services.
	  AREA NAME: ${contextInfo?.areaContext?.name}
	  AREA DESCRIPTION: ${contextInfo?.areaContext?.description}
    ORGANIZATION NAME: ${contextInfo?.orgContext?.name}
    ORGANIZATION DESCRIPTION: ${contextInfo?.orgContext?.description}
    ORGANIZATION CONTEXT: ${contextInfo?.orgContext?.mainAreas?.map((area) => area).join(', ')}`,
});

export const generateExpandAreaContextMessage = (contextInfo: ContextInfo) => ({
  id: 'contextInfo',
  role: 'system',
  content: `
    Use this information as Context to help you, you MUST keep it in focus and cannot change it, it should provide the context for suggesting services.
    AREA NAME: ${contextInfo?.areaContext?.name}
    AREA DESCRIPTION: ${contextInfo?.areaContext?.description}
    ORGANIZATION NAME: ${contextInfo?.orgContext?.name}
    ORGANIZATION DESCRIPTION: ${contextInfo?.orgContext?.description}
    ORGANIZATION CONTEXT: ${contextInfo?.orgContext?.mainAreas?.map((area) => area).join(', ')}
    
    These existing services are part of the area, so you can use them as reference for the services you suggest.
    ${contextInfo?.existingServices?.map((service) => `SERVICE NAME: ${service.name} SERVICE DESCRIPTION: ${service.description}`).join('')}
    `,
});

export const generateBlankServiceContextMessage = (contextInfo: ContextInfo) => ({
  id: 'contextInfo',
  role: 'system',
  content: `
	  Use this information as Context to help you, you MUST keep it in focus and cannot change it, it should provide the context for suggesting processes.
	  SERVICE NAME: ${contextInfo?.name}
	  SERVICE DESCRIPTION: ${contextInfo?.description}
    AREA NAME: ${contextInfo?.areaContext?.name}
    AREA DESCRIPTION: ${contextInfo?.areaContext?.description}
    ORGANIZATION NAME: ${contextInfo?.name}
    ORGANIZATION DESCRIPTION: ${contextInfo?.description}
    ORGANIZATION CONTEXT: ${contextInfo?.orgContext?.mainAreas?.map((area) => area).join(', ')}`,
});

export const generateBlankProcessContextMessage = (contextInfo: ContextInfo) => {
  const relatedProcessesInfo =
    contextInfo?.related
      ?.filter((item) => item.category === contextInfo.category || (!item.category && !contextInfo.category))
      ?.map(
        (process) => `
      - RELATED NAME: ${process.name}
      - RELATED DESCRIPTION: ${process.description || 'N/A'}
      - RELATED GOAL: ${process.goal || 'N/A'}
      - RELATED CATEGORY: ${process.category || 'N/A'}
      - RELATED STEPS: ${
        process.steps && Array.isArray(process.steps) ? process.steps.map((step) => step.name).join(', ') : 'N/A'
      }
    `,
      )
      .join('') || 'No related processes available.';

  const serviceProcessesInfo =
    contextInfo?.related
      ?.filter((item) => item.category !== contextInfo.category)
      ?.map(
        (process) => `
        - SEMI-RELATED NAME: ${process.name}
        - SEMI-RELATED DESCRIPTION: ${process.description || 'N/A'}
        - SEMI-RELATED CATEGORY: ${process.category || 'N/A'}
        - SEMI-RELATED GOAL: ${process.goal || 'N/A'}
        - SEMI-RELATED STEPS: ${
          process.steps && Array.isArray(process.steps) ? process.steps.map((step) => step.name).join(', ') : 'N/A'
        }
      `,
      )
      .join('') || 'No related processes available.';

  return {
    id: 'contextInfo',
    role: 'system',
    content: `
    Use this information as Context to help you; you MUST keep it in focus and cannot change it. The processes need to be related to the process name and should 
    move towards achieving the PROCESS GOAL using the PROCESS DESCRIPTION way (if available).
    PROCESS NAME: ${contextInfo?.name}
    PROCESS DESCRIPTION: ${contextInfo?.description || 'N/A'}
    PROCESS CATEGORY: ${contextInfo?.category || 'N/A'}
    PROCESS GOAL: ${contextInfo?.goal || 'N/A'}
    
    THIS PROCESS IS INSIDE THIS SERVICE
    SERVICE NAME: ${contextInfo?.serviceContext?.name}
    SERVICE DESCRIPTION: ${contextInfo?.serviceContext?.description}

    THE SERVICE IS A PART OF THIS AREA

    AREA NAME: ${contextInfo?.areaContext?.name}
    AREA DESCRIPTION: ${contextInfo?.areaContext?.description}

    AND THE AREA IS INSIDE THIS ORGANIZATION
    ORGANIZATION NAME: ${contextInfo?.name}
    ORGANIZATION DESCRIPTION: ${contextInfo?.description}
    ORGANIZATION CONTEXT: ${contextInfo?.orgContext?.mainAreas?.map((area) => area).join(', ')}

    This other processes are part of the same service and category, so it can be a good reference for the steps you suggest.
    IF there are mentions for SPECIFIC TOOLS, YOU MUST INCLUDE THEM (ie a CMS, a CRM, a specific software, etc)
    ${relatedProcessesInfo}

    To A LESSER EXTENT! You can also consider these realted processes, but they are not as important as the ones above.
    These one just share the service but NOT the category:
    ${serviceProcessesInfo}

    `,
  };
};

export const generateAddedEntityMessageResponse = (messageId: string, id: string, name: string) => ({
  id: messageId,
  role: 'assistant',
  content: `
  Roger that, we 'll keep ${name}. 
  `,
});

export const generateAddedEntityMessage = (messageId: string, id: string, name: string) => ({
  id: messageId,
  role: 'user',
  content: `
  I'll keep ${name}. 
  `,
});

export const generateAddedEntitySystemMessage = (messageId: string, id: string, name: string) => ({
  id: messageId,
  role: 'system',
  content: `
 you MUST NOT include he element with id ${id} and name ${name} in the data array OR any other with the name ${name}!. 
  `,
});
