import { gql } from 'graphql-request';

export const POLICY_FIELDS = gql`
  fragment PolicyFields on PolicyDto {
    policyId
    name
    category
    description
    content
    order
    location
    storageLocation
  }
`;

export const getPolicies = gql`
  query getPolicies {
    getPolicies {
      ...PolicyFields
    }
  }
  ${POLICY_FIELDS}
`;

export const getPolicyById = gql`
  query getPolicyById($id: String!) {
    getPolicyById(id: $id) {
      ...PolicyFields
      processes {
        processId
      }
    }
  }
  ${POLICY_FIELDS}
`;

export const createPolicy = gql`
  mutation createPolicy($policy: PolicyCreateInput!) {
    createPolicy(policy: $policy) {
      ...PolicyFields
    }
  }
  ${POLICY_FIELDS}
`;

export const updatePolicy = gql`
  mutation updatePolicy($policy: PolicyUpdateInput!) {
    updatePolicy(policy: $policy) {
      ...PolicyFields
    }
  }
  ${POLICY_FIELDS}
`;

export const reorderPolicies = gql`
  mutation reorderPolicies($policies: [PolicyReorderInput!]!) {
    reorderPolicies(policies: $policies) {
      ...PolicyFields
    }
  }
  ${POLICY_FIELDS}
`;

export const deletePolicy = gql`
  mutation deletePolicy($id: String!) {
    deletePolicy(id: $id) {
      affected
      messages
    }
  }
`;

const PoliciesGraphQL = {
  queries: {
    getPolicies,
    getPolicyById,
  },
  mutations: {
    createPolicy,
    updatePolicy,
    reorderPolicies,
    deletePolicy,
  },
};

export default PoliciesGraphQL;
