import { useTranslation } from '@hooks/useTranslation';
import { Step } from '@models/step.model';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';

// TODO: This component needs to be refactored when import/Export is expanded

const JsonExportModal = ({
  elementList,
  onClose,
  onSubmit,
}: {
  elementList: Step[];
  onClose: () => void;
  onSubmit: (enabledIds: string) => void;
}) => {
  const [enabledElements, setEnabledElements] = useState<{ [key: string]: boolean }>({});

  const localeCommon = useTranslation('common');
  const localeImpExp = useTranslation('impExp');

  const handleCheckboxChange = (id: string) => {
    setEnabledElements((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    const initialEnabledElements: { [key: string]: boolean } = {};
    elementList.forEach((element) => {
      initialEnabledElements[element.stepId] = true;
    });
    setEnabledElements(initialEnabledElements);
  }, []);

  const handleExport = () => {
    const enabledIds = Object.keys(enabledElements).filter((id) => enabledElements[id]);
    onSubmit(enabledIds.join(','));
    onClose();
  };

  return (
    <>
      <DialogTitle>{localeImpExp['exportElem']}</DialogTitle>
      <DialogContent>
        {elementList.map((element) => (
          <div key={element.stepId}>
            <Checkbox
              checked={enabledElements[element.stepId] || false}
              onChange={() => handleCheckboxChange(element.stepId)}
            />
            {element.name}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{localeCommon['cancel']}</Button>
        <Button onClick={handleExport}>{localeCommon['export']}</Button>
      </DialogActions>
    </>
  );
};

export default JsonExportModal;
