// TODO: If no longer used, remove
export function getRandomHexColor() {
  const randomColor = Math.floor(Math.random() * 16777215);
  const hexColor = '#' + randomColor.toString(16).padStart(6, '0');
  return hexColor;
}

export const AREA_COLORS = [
  '#ac725e',
  '#d06b64',
  '#f83a22',
  '#fa573c',
  '#ff7537',
  '#ffad46',
  '#42d692',
  '#16a765',
  '#7bd148',
  '#b3dc6c',
  '#fbe983',
  '#fad165',
  '#92e1c0',
  '#9fe1e7',
  '#9fc6e7',
  '#4986e7',
  '#9a9cff',
  '#b99aff',
  '#c2c2c2',
  '#cabdbf',
  '#cca6ac',
  '#f691b2',
  '#cd74e6',
  '#a47ae2',
];

export function getRandomColorFromList(): string {
  return AREA_COLORS[Math.floor(Math.random() * AREA_COLORS.length)];
}
