import { useTranslation } from '@hooks/useTranslation';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface WolfProgressBarProps {
  fileName?: string;
  onCancel?: () => void;
  duration?: number; // duration in milliseconds for the progress bar to fill, smaller number means faster
  autoReset?: boolean;
}

const WolfProgressBar: React.FC<WolfProgressBarProps> = ({ fileName, onCancel, duration = 3000, autoReset = true }) => {
  const [progress, setProgress] = useState(0);
  const localeCommon = useTranslation('common');

  useEffect(() => {
    // We increment depending on value of duration
    const increment = 100 / (duration / 100);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          // We reset to 0 if autoReset is true
          if (autoReset) {
            return 0;
          } else {
            clearInterval(interval);
            return 100;
          }
        }
        return prevProgress + increment;
      });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [duration]);

  return (
    <Box display="flex" alignItems="center" width="100%" p={4} height={200}>
      <Box flexGrow={1} mr={2}>
        <Typography variant="body14semibold" color="textSecondary">
          {fileName || localeCommon['uploading'] + '...'}
        </Typography>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      {!autoReset && (
        <Box minWidth="35px">
          <Typography variant="body16semibold" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
        </Box>
      )}
      {onCancel && (
        <Button onClick={onCancel} color="secondary" size="small" sx={{ marginLeft: '8px' }}>
          {localeCommon('cancel')}
        </Button>
      )}
    </Box>
  );
};

export default WolfProgressBar;
