export default function RepeatStepInsideNode({ data }: any) {
  return (
    <>
      <div className="switch-step-inside-node flex flex-wrap items-center">
        <p title={data.label}>{data.label.slice(0, 20) + (data.label.length > 20 ? '...' : '') + ' (loop!)'}</p>
        <p>{data.condition}</p>
      </div>
    </>
  );
}
