import { Process } from '@models/process.model';
import { StepType } from '@models/step.model';
import { drawEdge } from '@utils/processMap/drawEdge';
import { drawGenericNode } from '@utils/processMap/drawGenericNode';
import { drawProcessHorizontal } from '@utils/processMap/drawProcessHorizontal';
import { drawRowItem } from '@utils/processMap/drawRowItem';

export function drawElementsHorizontal(data: Process[], serviceName: string, areaName: string, lineStyle?: string) {
  const linkedProcesses: string[] = data.flatMap(
    (process) =>
      (process.steps &&
        process.steps
          .filter((step) => step.stepData.executeProcessId !== undefined)
          .map((step) => step.stepData.executeProcessId as string)) ||
      '',
  );

  const switchSteps = data
    .map((process) => {
      if (!process.steps) {
        return [];
      }
      return process.steps.filter((step) => step.stepType === StepType.SWITCH);
    })
    .flat();

  const allLinkedProcessesIds: string[] = [...linkedProcesses, ...switchSteps.map((step) => step.stepId)];

  //Linked nodes should be removed from head nodes
  let auxHeadNodes = data.filter((process) => !allLinkedProcessesIds.includes(process.processId));

  // Draw the head nodes of the flows
  const headNodes = auxHeadNodes.map((process, index) => {
    return drawProcessHorizontal(process, 0, index, areaName, serviceName);
  });

  const secondaryNodes: any[] = [];

  const linkedEdges: any[] = [];
  // TODO: Add the external (ie requests from other services)
  // Should go for each row of the flows
  for (let i = 0; i < headNodes.length; i++) {
    const first: any = data.find((item) => (item.processId = headNodes[i].id));
    // TODO: Deal with more than one switch or linked process
    // TODO: Switch only matters if it has a linked process
    let hasSwitch = first?.steps?.some((item: any) => item.stepType === StepType.SWITCH);
    let hasLinked = first?.steps?.some((item: any) => item.stepData?.executeProcessId);
    // If it does not have any More, proceed to next "row"
    if (!hasSwitch && !hasLinked) {
      continue;
    }

    let origin = first;
    let originNode: any = headNodes[i];
    let a = 0;
    while ((hasSwitch || hasLinked) && a < 10) {
      const itemToAdd: any = drawRowItem(origin, originNode, i, data, serviceName, areaName);
      console.log('pushing', itemToAdd);
      // TODO: Draw with helpers
      if (itemToAdd) {
        secondaryNodes.push(itemToAdd);
        if (itemToAdd.type === 'Switch') {
          linkedEdges.push(drawEdge(`${itemToAdd.id}-edgeIn`, itemToAdd.id, origin?.processId));

          //Here we draw all the branches of the switch
          const branches = origin?.steps?.find((item: any) => item.stepType === StepType.SWITCH)?.stepData.stepsIds;

          console.log('branches', branches);

          // TODO: Also clear inside of each of these to helper functions
          if (branches) {
            branches.split(',').forEach((branch: string, branchIdx: number) => {
              const auxStep: any = data
                .find((item) => item.processId === origin.processId)
                ?.steps?.find((item) => item.stepId === branch);
              const linkType = auxStep?.stepData?.timingType;
              const linkName = auxStep?.stepData?.timingName;

              if (auxStep && linkType === 'IMMEDIATE' && auxStep.stepType === 'PROCESS') {
                const target = auxStep?.stepData?.executeProcessId;

                linkedEdges.push(drawEdge(`${target}-TimingEdgeInAux+${branchIdx}`, target, itemToAdd.id));
              } else if (auxStep) {
                let auxStepNode;
                if (auxStep.stepType === 'PLAIN_TEXT') {
                  // auxStepNode = drawGenericNode(
                  //   'auxStep-' + auxStep.stepId,
                  //   originNode.position.x + 350 + i * 200,
                  //   originNode.position.y - 50 + branchIdx * 100,
                  //   '',
                  //   'Finish',
                  //   originNode.id,
                  // );
                } else {
                  auxStepNode = drawGenericNode(
                    'auxStep-' + auxStep.stepId,
                    200 * i + itemToAdd.position.x + 350,
                    originNode.position.y - 50 + branchIdx * 100,
                    'Type:' + linkType + ' - ' + (linkName ? linkName : ''),
                    'default',
                    originNode.id,
                    originNode.id,
                  );
                }
                secondaryNodes.push(auxStepNode);

                linkedEdges.push(drawEdge(`${auxStepNode?.id}-edgeInAux`, auxStepNode?.id || '', itemToAdd.id));

                if (auxStep.stepType === 'PROCESS') {
                  const target = auxStep?.stepData?.executeProcessId;
                  linkedEdges.push(drawEdge(`${auxStepNode?.id}-edgeOutAux`, target, 'auxStep-' + auxStep.stepId));
                }
              }
            });
          }
          let auxOrigin: any = data?.find((item) => item?.processId === itemToAdd.data.parentId);
          origin = {
            ...auxOrigin,
            steps: auxOrigin?.steps?.filter((step: any) => step.stepType !== StepType.SWITCH),
          };
        } else if (
          data
            .find((item) => item.processId === origin?.processId)
            ?.steps?.find((item: any) => item.stepType === StepType.SWITCH) === undefined
        ) {
          linkedEdges.push(drawEdge(`${itemToAdd.id}-edge`, itemToAdd.id, origin?.processId));
          origin = data.find((item) => item.processId === itemToAdd.id);
        } else {
          origin = data.find((item) => item.processId === itemToAdd.id);
        }
        originNode = itemToAdd;
        hasSwitch = origin?.steps?.some((item: any) => item.stepType === StepType.SWITCH);
        hasLinked = origin?.steps?.some((item: any) => item.stepData?.executeProcessId);
      }
      a++;
    }
  }

  return { nodes: [...headNodes, ...secondaryNodes], edges: linkedEdges };
}
