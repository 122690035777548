import { Process } from '@models/process.model';
import { Service } from '@models/service.model';

const getCategoriesNames = (service: Service): string[] => {
  const uniqueCatArray: string[] = [];
  const uniqueCat: { [key: string]: boolean } = {};

  if (!service || !service.processes || service.processes.length === 0) return uniqueCatArray;

  service.processes.forEach((process: Process) => {
    if (!process.category) {
      process.category = 'Uncategorized';
    }
    if (!uniqueCat[process.category]) {
      uniqueCat[process.category] = true;
      uniqueCatArray.push(process.category);
    }
  });

  if (uniqueCatArray.length > 0) {
    uniqueCatArray.push('All');
  }

  return uniqueCatArray.sort();
};

export default getCategoriesNames;
