import { Step } from '@models/step.model';

export interface Document {
  steps: Step[];
  documentId: string;
  name: string;
  categoryId: string;
  owner: string;
  uri: string;
  status: DocumentStatus;
  fileType: string;
  createdAt: Date;
  lastUpdatedAt: Date;
}

export enum DocumentStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}

export interface getDocumentsRequest {
  getDocuments: {
    documents: Document[];
    count: number;
  };
}

export interface getDocumentRequest {
  getDocument: Document;
}

export interface deleteDocumentRequest {
  deleteDocument: {
    affected: number;
  };
}

export interface updateDocumentMetadataRequest {
  updateDocumentMetadata: {
    affected: number;
  };
}

export interface updateDocumentContentRequest {
  updateDocumentContent: {
    affected: number;
  };
}
