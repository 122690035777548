import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Handle, Position } from 'reactflow';

export default function CalendarNode({ data }: any) {
  return (
    <>
      <div className="trigger-node flex flex-wrap items-center">
        <CalendarMonthOutlinedIcon />

        {data.label}
      </div>
      <Handle type="source" position={Position.Right} id="a" />
    </>
  );
}
