import rootReducer from '@redux/index';
import { ActionMessagesState } from '@redux/reducers/actionMessages.reducer';
import { LocaleState } from '@redux/reducers/locale.reducer';
import { OrganizationState } from '@redux/reducers/organization.reducer';
import { UserState } from '@redux/reducers/user.reducer';
import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { ImpersonateState } from './reducers/impersonate.reducer';
import { WolfieState } from './reducers/wolfie.reducer';

// Configuration object for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'organization', 'impersonate', 'wolfie'], // List of reducers you want to persist
};

const DEBUG = ['development', 'test'].includes(process.env.NODE_ENV) && !process.env.REACT_APP_HIDE_DEBUG;

let middlewares: any = [thunk];

if (DEBUG) {
  middlewares = [...middlewares, logger];
}

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create a store
const store = createStore(persistedReducer, applyMiddleware(...middlewares));

// Create the store with the persisted reducer
const persistor = persistStore(store);

export { persistor, store };

export interface RootState {
  locale: LocaleState & PersistPartial;
  user: UserState;
  organization: OrganizationState;
  actionMessages: ActionMessagesState;
  impersonate: ImpersonateState;
  wolfie: WolfieState & PersistPartial;
}

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
