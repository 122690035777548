import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { styled, SxProps } from '@mui/system';

interface WolfButtonProps extends ButtonProps {
  loading?: boolean;
  sx?: SxProps;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  height?: '32px' | '40px';
  onClick?: () => void;
}

const StyledButton = styled(Button)<WolfButtonProps>(({ theme, color, loading, height }) => ({
  '&.MuiButtonBase-root': {
    padding: loading ? '16px' : '8px 16px',
    height: height || '32px',
    minHeight: height || '32px',
    minWidth: '32px',
    fontWeight: '600',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    backgroundColor:
      color === 'primary'
        ? theme.palette.callToAction.main
        : color === 'secondary'
          ? theme.palette.frozenGrayShades2.main
          : color === 'tertiary'
            ? theme.palette.common.white
            : color === 'error'
              ? theme.palette.feedbackError.main
              : theme.palette.callToAction.main,
    color:
      color === 'primary'
        ? theme.palette.common.white
        : color === 'error'
          ? theme.palette.common.white
          : theme.palette.primaryDark.main,
  },
  '&.Mui-disabled': {
    color:
      color === 'primary'
        ? theme.palette.common.white
        : color === 'secondary'
          ? theme.palette.common.white
          : color === 'error'
            ? theme.palette.common.white
            : theme.palette.primaryDark.main,

    backgroundColor:
      color === 'primary'
        ? theme.palette.ctaShades3.main
        : color === 'secondary'
          ? theme.palette.frozenGrayShades2.main
          : color === 'tertiary'
            ? theme.palette.frozenGrayShades1.main
            : color === 'error'
              ? theme.palette.frozenGrayShades2.main
              : theme.palette.ctaShades8.main,
  },
  '&:hover': {
    opacity: color === 'error' ? 0.8 : 1,
    backgroundColor:
      color === 'primary'
        ? theme.palette.ctaShades5.main
        : color === 'secondary'
          ? theme.palette.frozenGrayShades1.main
          : color === 'tertiary'
            ? theme.palette.common.white
            : color === 'error'
              ? theme.palette.feedbackError.main
              : theme.palette.ctaShades5.main,
  },
}));

const IconContainer = styled('span')({
  display: 'inherit',
  marginLeft: '8px',
  marginRight: '8px',
});

const WolfButton: React.FC<WolfButtonProps> = ({
  loading,
  children,
  leftIcon,
  rightIcon,
  height,
  onClick,
  ...props
}) => (
  <StyledButton {...props} disabled={loading || props.disabled} height={height} onClick={onClick}>
    {leftIcon && !loading && <IconContainer>{leftIcon}</IconContainer>}
    {loading ? <CircularProgress size={24} color="inherit" thickness={5} /> : children}
    {rightIcon && !loading && <IconContainer>{rightIcon}</IconContainer>}
  </StyledButton>
);

export default WolfButton;
