import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { MoreVertOutlined } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { BASE_DOCS_LINK_URL } from '@utils/constants';
import { useState } from 'react';

interface ProcessHeaderDropdownProps {
  location: string;
  onDelete: () => void;
  onEdit: () => void;
  handleShowJsonImport: () => void;
  handleShowJsonExport: () => void;
}

const ProcessHeaderDropdown: React.FC<ProcessHeaderDropdownProps> = ({
  location,
  onDelete,
  onEdit,
  handleShowJsonImport,
  handleShowJsonExport,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');

  const options = [
    // { text: localeCommon['share'], action: () => handleShare() },
    // TODO: Add these when enabled and functionality is available
    { text: localeCommon['import'], action: handleShowJsonImport },
    { text: localeCommon['export'], action: handleShowJsonExport },
    // { text: localeCommon['report'], action: () => {} },
    { text: localeCommon['help'], action: () => {} },
    {
      text: localeCommon['edit'] + ' ' + localeCommon['process'],
      action: onEdit,
    },
    {
      text: localeCommon['delete'] + ' ' + localeCommon['process'],
      action: onDelete,
    },
  ];

  if (location) {
    options.push({ text: localeCommon['showInDocs'], action: () => navigateToDoc() });
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToDoc = () => {
    window.open(BASE_DOCS_LINK_URL + location + '/edit', '_blank');
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    dispatch(appendActionMessage({ message: localeActionMessages['linkCopied'], type: SnackType.DARK }));
  };

  const handleMenuClick = (action: Function) => {
    action();
    handleClose();
  };

  return (
    <>
      <Box className="flex items-center">
        <IconButton onClick={handleClick}>
          <MoreVertOutlined />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {options.map((option) => (
            <MenuItem
              key={option.text}
              onClick={() => {
                handleMenuClick(option.action);
              }}>
              {option.text}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

export default ProcessHeaderDropdown;
