import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography } from '@mui/material';

interface ConfirmLeaveModalProps {}

const ConfirmLeaveModal: React.FC<ConfirmLeaveModalProps> = () => {
  const localeCommon = useTranslation('common');

  return (
    <Box className="flex justify-center items-center mt-4">
      <Typography variant="body16">{localeCommon['changesNotSaved']}</Typography>
    </Box>
  );
};

export default ConfirmLeaveModal;
