import { createTheme, ThemeOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { ResponsiveStyleValue, SystemStyleObject } from '@mui/system';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    mega40: true;
    h3semibold: true;
    h4semibold: true;
    link: true;
    body16semibold: true;
    body16: true;
    body14semibold: true;
    body14: true;
    body12: true;
    body12semibold: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  mega40: React.CSSProperties;
  h3semibold: React.CSSProperties;
  h4semibold: React.CSSProperties;
  link: React.CSSProperties;
  body16semibold: React.CSSProperties;
  body16: React.CSSProperties;
  body14semibold: React.CSSProperties;
  body14: React.CSSProperties;
  body12: React.CSSProperties;
  body12semibold: React.CSSProperties;
}

declare module '@mui/material/styles' {
  interface Palette {
    // tertiary: Palette['primary'];
    primaryDark: Palette['primary'];
    primaryGray: Palette['primary'];
    primaryIcy: Palette['primary'];
    primaryFrozen: Palette['primary'];
    callToAction: Palette['primary'];
    additionalPlaceholder: Palette['primary'];
    additionalBorder: Palette['primary'];
    backgroundUI: Palette['primary'];
    backgroundBlue: Palette['primary'];
    feedbackSuccess: Palette['primary'];
    feedbackWarning: Palette['primary'];
    feedbackError: Palette['primary'];
    utilityLemon: Palette['primary'];
    utilityInfo: Palette['primary'];
    utilityGreen: Palette['primary'];
    utilityYellow: Palette['primary'];
    utilityRed: Palette['primary'];
    utilityPink: Palette['primary'];
    frozenGrayShades1: Palette['primary'];
    frozenGrayShades2: Palette['primary'];
    frozenGrayShades3: Palette['primary'];
    frozenGrayShades4: Palette['primary'];
    frozenGrayShades5: Palette['primary'];
    frozenGrayShades6: Palette['primary'];
    frozenGrayShades7: Palette['primary'];
    frozenGrayShades8: Palette['primary'];
    frozenGrayShades9: Palette['primary'];
    frozenGrayShades10: Palette['primary'];
    ctaShades1: Palette['primary'];
    ctaShades2: Palette['primary'];
    ctaShades3: Palette['primary'];
    ctaShades4: Palette['primary'];
    ctaShades5: Palette['primary'];
    ctaShades6: Palette['primary'];
    ctaShades7: Palette['primary'];
    ctaShades8: Palette['primary'];
    ctaShades9: Palette['primary'];
    ctaShades10: Palette['primary'];
    sectionMediumGreen: Palette['primary'];
    sectionYellowGreen: Palette['primary'];
    sectionKhaki: Palette['primary'];
    sectionAquamarine: Palette['primary'];
    sectionPaleTurquoise: Palette['primary'];
    sectionLightBlue: Palette['primary'];
    sectionMediumPurple: Palette['primary'];
    sectionOrchid: Palette['primary'];
    sectionSlateBlue: Palette['primary'];
    sectionLightGray: Palette['primary'];
    sectionDarkGray: Palette['primary'];
    sectionRosyBrown: Palette['primary'];
    sectionLightPink: Palette['primary'];
    sectionSienna: Palette['primary'];
    sectionIndianRed: Palette['primary'];
    sectionOrangeRed: Palette['primary'];
    sectionChocolate: Palette['primary'];
    sectionPeru: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
    primaryDark?: PaletteOptions['primary'];
    primaryGray?: PaletteOptions['primary'];
    primaryIcy?: PaletteOptions['primary'];
    primaryFrozen?: PaletteOptions['primary'];
    callToAction?: PaletteOptions['primary'];
    additionalPlaceholder?: PaletteOptions['primary'];
    additionalBorder?: PaletteOptions['primary'];
    backgroundUI?: PaletteOptions['primary'];
    backgroundBlue?: PaletteOptions['primary'];
    feedbackSuccess?: PaletteOptions['primary'];
    feedbackWarning?: PaletteOptions['primary'];
    feedbackError?: PaletteOptions['primary'];
    utilityLemon?: PaletteOptions['primary'];
    utilityInfo?: PaletteOptions['primary'];
    utilityGreen?: PaletteOptions['primary'];
    utilityYellow?: PaletteOptions['primary'];
    utilityRed?: PaletteOptions['primary'];
    utilityPink?: PaletteOptions['primary'];
    frozenGrayShades1?: PaletteOptions['primary'];
    frozenGrayShades2?: PaletteOptions['primary'];
    frozenGrayShades3?: PaletteOptions['primary'];
    frozenGrayShades4?: PaletteOptions['primary'];
    frozenGrayShades5?: PaletteOptions['primary'];
    frozenGrayShades6?: PaletteOptions['primary'];
    frozenGrayShades7?: PaletteOptions['primary'];
    frozenGrayShades8?: PaletteOptions['primary'];
    frozenGrayShades9?: PaletteOptions['primary'];
    frozenGrayShades10?: PaletteOptions['primary'];
    ctaShades1?: PaletteOptions['primary'];
    ctaShades2?: PaletteOptions['primary'];
    ctaShades3?: PaletteOptions['primary'];
    ctaShades4?: PaletteOptions['primary'];
    ctaShades5?: PaletteOptions['primary'];
    ctaShades6?: PaletteOptions['primary'];
    ctaShades7?: PaletteOptions['primary'];
    ctaShades8?: PaletteOptions['primary'];
    ctaShades9?: PaletteOptions['primary'];
    ctaShades10?: PaletteOptions['primary'];
    sectionMediumSeaGreen?: PaletteOptions['primary'];
    sectionMediumGreen?: PaletteOptions['primary'];
    sectionYellowGreen?: PaletteOptions['primary'];
    sectionKhaki?: PaletteOptions['primary'];
    sectionAquamarine?: PaletteOptions['primary'];
    sectionPaleTurquoise?: PaletteOptions['primary'];
    sectionLightBlue?: PaletteOptions['primary'];
    sectionMediumPurple?: PaletteOptions['primary'];
    sectionOrchid?: PaletteOptions['primary'];
    sectionSlateBlue?: PaletteOptions['primary'];
    sectionLightGray?: PaletteOptions['primary'];
    sectionDarkGray?: PaletteOptions['primary'];
    sectionRosyBrown?: PaletteOptions['primary'];
    sectionLightPink?: PaletteOptions['primary'];
    sectionSienna?: PaletteOptions['primary'];
    sectionIndianRed?: PaletteOptions['primary'];
    sectionOrangeRed?: PaletteOptions['primary'];
    sectionChocolate?: PaletteOptions['primary'];
    sectionPeru?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    dark: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

export type SxStyleProp =
  | SystemStyleObject
  | (SystemStyleObject &
      Record<
        string,
        | SystemStyleObject
        | ResponsiveStyleValue<number | string>
        | Record<string, SystemStyleObject | ResponsiveStyleValue<number | string>>
      >);

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
    fontWeightRegular: 400,
    fontWeightBold: 700,
    fontWeightSemiBold: 600,
    mega40: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '40px',
      lineHeight: 1.2,
    },
    h1: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '36px',
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: 1.3,
    },
    h3semibold: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: 1.3,
    },
    h4semibold: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: 1.4,
    },
    h4: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: 1.4,
    },
    link: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: 1.3,
      textDecoration: 'underline',
    },
    body16semibold: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: 1.5,
    },
    body16: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: 1.5,
    },
    body14semibold: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: 1.6,
    },
    body14: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.6,
    },
    body12: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: 1.7,
    },
    body12semibold: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: 1.7,
    },
  } as ExtendedTypographyOptions,
  shadows: [
    'none',
    '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1)', // shadow1cards
    '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1)', // shadow2dropdowns
    '0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)', // shadow3modals
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  palette: {
    text: {
      primary: '#272D37',
      secondary: '#657182',
    },
    primary: {
      main: '#272D37',
    },
    secondary: {
      main: '#DADFE7',
    },
    tertiary: {
      main: '#FFFFFF',
    },
    primaryDark: {
      main: '#272D37',
    },
    primaryGray: {
      main: '#657182',
    },
    primaryIcy: {
      main: '#C8D0DC',
    },
    primaryFrozen: {
      main: '#EDEFF3',
    },
    callToAction: {
      main: '#3641D9',
    },
    additionalPlaceholder: {
      main: '#657182',
    },
    additionalBorder: {
      main: '#A3B0C4',
    },
    backgroundUI: {
      main: '#F6F7F9',
    },
    backgroundBlue: {
      main: '#D8E5E9',
    },
    feedbackSuccess: {
      main: '#00A76D',
    },
    feedbackWarning: {
      main: '#F5AB23',
    },
    feedbackError: {
      main: '#DE0A01',
    },
    utilityLemon: {
      main: '#FEC10D',
    },
    utilityInfo: {
      main: '#C5DBF2',
    },
    utilityGreen: {
      main: '#D8EACC',
    },
    utilityYellow: {
      main: '#FBEECA',
    },
    utilityRed: {
      main: '#F8CFBD',
    },
    utilityPink: {
      main: '#F9E0FA',
    },
    frozenGrayShades1: {
      main: '#F6F7F9',
    },
    frozenGrayShades2: {
      main: '#DADFE7',
    },
    frozenGrayShades3: {
      main: '#C8D0DC',
    },
    frozenGrayShades4: {
      main: '#B5C0D0',
    },
    frozenGrayShades5: {
      main: '#A3B0C4',
    },
    frozenGrayShades6: {
      main: '#8793A5',
    },
    frozenGrayShades7: {
      main: '#6C7685',
    },
    frozenGrayShades8: {
      main: '#505866',
    },
    frozenGrayShades9: {
      main: '#353B46',
    },
    frozenGrayShades10: {
      main: '#272D37',
    },
    ctaShades1: {
      main: '#EBECFB',
    },
    ctaShades2: {
      main: '#D7D9F7',
    },
    ctaShades3: {
      main: '#AFB3F0',
    },
    ctaShades4: {
      main: '#868DE8',
    },
    ctaShades5: {
      main: '#5E67E0',
    },
    ctaShades6: {
      main: '#3641D9',
    },
    ctaShades7: {
      main: '#2B34AE',
    },
    ctaShades8: {
      main: '#202782',
    },
    ctaShades9: {
      main: '#161A57',
    },
    ctaShades10: {
      main: '#101341',
    },
    sectionMediumSeaGreen: {
      main: '#42D692',
    },
    sectionMediumGreen: {
      main: '#4DBE8A',
    },
    sectionYellowGreen: {
      main: '#B3DC6C',
    },
    sectionKhaki: {
      main: '#FBE983',
    },
    sectionAquamarine: {
      main: '#92E1C0',
    },
    sectionPaleTurquoise: {
      main: '#9FE1E7',
    },
    sectionLightBlue: {
      main: '#9FC6E7',
    },
    sectionMediumPurple: {
      main: '#B99AFF',
    },
    sectionOrchid: {
      main: '#CD74E6',
    },
    sectionSlateBlue: {
      main: '#7D52BE',
    },
    sectionLightGray: {
      main: '#D3D3D3',
    },
    sectionDarkGray: {
      main: '#BABABA',
    },
    sectionRosyBrown: {
      main: '#CCA6AC',
    },
    sectionLightPink: {
      main: '#F691B2',
    },
    sectionSienna: {
      main: '#935C49',
    },
    sectionIndianRed: {
      main: '#AC4942',
    },
    sectionOrangeRed: {
      main: '#E12C16',
    },
    sectionChocolate: {
      main: '#BE592C',
    },
    sectionPeru: {
      main: '#D3831F',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
        },
      },
    },
  },
} as ThemeOptions);

export default theme;
