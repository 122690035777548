import { removeToken, setTokenLocalStorage, setTokenSessionStorage } from '@config/storage';
import { User } from '@models/user.model';

export const UserTypes = {
  SET_USER_DATA: 'SET_USER_DATA',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  LOGOUT_USER: 'LOGOUT_USER',
  UPDATE_SIDEBAR_STATUS: 'UPDATE_SIDEBAR_STATUS',
};

export const setUserData = (token: string, rememberMe: boolean) => {
  if (rememberMe) {
    setTokenLocalStorage(token);
  } else {
    setTokenSessionStorage(token);
  }
  return {
    type: UserTypes.SET_USER_DATA,
    data: { token, rememberMe },
  };
};

export const setUserDetails = (details: Partial<User>) => {
  return {
    type: UserTypes.SET_USER_DETAILS,
    data: details,
  };
};

export const updateSidebarStatus = () => {
  return {
    type: UserTypes.UPDATE_SIDEBAR_STATUS,
    data: {},
  };
};

export const logoutUser = () => {
  removeToken();
  //TODO: Check if we want to implement it here
  window.open(process.env.REACT_APP_LOGOUT_URL, '_self');
  return {
    type: UserTypes.LOGOUT_USER,
    data: {},
  };
};

export type UserState = {
  isLoggedIn: boolean;
  collapsedSidebar?: boolean;
  firstName?: string | undefined | null;
  lastName?: string | undefined | null;
  role: string | null;
  avatar?: string | null;
  rememberMe?: boolean;
};

// INITIAL STATE
const userInitialState: UserState = {
  isLoggedIn: false,
  collapsedSidebar: false,
  role: null,
  avatar: '',
  firstName: '',
  rememberMe: false,
};

export type UserAction = {
  type: string;
  data: User;
};

const userReducer = (state = userInitialState, action: UserAction) => {
  switch (action.type) {
    case UserTypes.SET_USER_DATA:
      return {
        ...state,
        isLoggedIn: true,
        rememberMe: action.data.rememberMe,
      };

    case UserTypes.SET_USER_DETAILS:
      return {
        ...state,
        ...action.data,
        isLoggedIn: true,
      };

    case UserTypes.UPDATE_SIDEBAR_STATUS:
      return {
        ...state,
        collapsedSidebar: !state.collapsedSidebar,
      };

    case UserTypes.LOGOUT_USER:
      return {
        ...state,
        ...userInitialState,
      };

    default:
      return state;
  }
};

export default userReducer;
