import { Process } from '@models/process.model';
import { drawDetailedProcess } from '@utils/processMapDetailed/drawDetailedProcess';

export function drawElementsDetailed(
  allData: Process[],
  data: Process[],
  serviceName: string,
  areaName: string,
  lineStyle?: string,
  startingVerticalOffset?: number,
  idPrefix?: string,
): { nodes: any[]; edges: any[] } {
  const linkedProcesses: string[] = data.flatMap(
    (process) =>
      (process.steps &&
        process.steps
          .filter((step) => step.stepData.executeProcessId !== undefined)
          .map((step) => step.stepData.executeProcessId as string)) ||
      '',
  );

  const allLinkedProcessesIds: string[] = [...linkedProcesses];
  //Linked nodes should be removed from head nodes
  let auxHeadNodesDetailed = data.filter((process) => !allLinkedProcessesIds.includes(process.processId));
  // Get the rest of the flow
  const secondaryNodes: any[] = [];
  const linkedEdges: any[] = [];
  let continues = true;
  // This goes over every head node
  for (let headIdx = 0; headIdx < auxHeadNodesDetailed.length; headIdx++) {
    let head: Process | undefined = data.find((item) => item?.processId === auxHeadNodesDetailed[headIdx]?.processId);
    if (!head) {
      return { nodes: [], edges: [] };
    }
    let subCount = 0;
    let nextProcesses: Process[] = [];
    // This goes over every process in the flow started from the head node
    // TODO: remove the 10 limit
    while (continues && subCount < 10) {
      if (!head) {
        // For para recorrer cada branch
        for (let branchIdx = 0; branchIdx < nextProcesses.length; branchIdx++) {
          const { nodes, edges } = drawDetailedProcess(
            allData,
            nextProcesses[branchIdx],
            subCount,
            serviceName,
            areaName,
            branchIdx,
            data,
            lineStyle,
            startingVerticalOffset,
            idPrefix,
          );
          secondaryNodes.push(...nodes);
          linkedEdges.push(...edges);
        }
        // Handles the head (first) node of the flow
      } else {
        const { nodes, edges } = drawDetailedProcess(
          allData,
          head,
          subCount,
          serviceName,
          areaName,
          0,
          data,
          lineStyle,
          startingVerticalOffset,
          idPrefix,
        );
        secondaryNodes.push(...nodes);
        linkedEdges.push(...edges);
      }

      // Check if there is a next process or if the flow has ended
      // And a different one when it comes from multiple
      let following: any = [];
      if (head) {
        following = head?.steps
          ?.filter((item: any) => item.stepData?.executeProcessId)
          .map((item) => item?.stepData?.executeProcessId);
      } else {
        following = nextProcesses
          .map((item) => {
            const branchingProcesses = item?.steps
              ?.filter((item: any) => item.stepData?.executeProcessId)
              .map((item) => item?.stepData?.executeProcessId);
            return branchingProcesses;
          })
          .flat();
      }
      nextProcesses = data.filter((item) => following?.includes(item.processId));
      continues = nextProcesses.length > 0;
      head = undefined;
      subCount++;
    }
  }

  return { nodes: secondaryNodes, edges: linkedEdges };
}
