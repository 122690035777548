import WolfButton from '@components/ui/WolfButton';
import WolfCheckbox from '@components/ui/WolfCheckbox';
import WolfDropdown from '@components/ui/WolfDropdown';
import WolfSearch from '@components/ui/WolfSearch';
import { useTranslation } from '@hooks/useTranslation';
import { Discrepancy, ShownDiscrepancy } from '@models/discrepancy.model';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

interface DiscrepancyListProps {
  discrepancies: Discrepancy[];
  handleOptionSelected: (selected: string, discrepancy: Discrepancy, ignoreType?: string, ignoreDate?: string) => void;
  handleShowInactive: (show: boolean) => void;
}

const DiscrepancyList: React.FC<DiscrepancyListProps> = ({
  discrepancies,
  handleOptionSelected,
  handleShowInactive,
}) => {
  const [shownDiscrepancies, setShownDiscrepancies] = useState<ShownDiscrepancy[]>([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc', active: false });
  const [filterValue, setFilterValue] = useState('');
  const [showInactive, setShowInactive] = useState(false);
  const [ignorePeriod, setIgnorePeriod] = useState('day');
  const [customDate, setCustomDate] = useState(new Date());
  const locale = useTranslation('integrations');

  const ignoreDropdownItems = [
    { value: 'permanent', text: locale['ignorePermanently'] },
    { value: 'month', text: locale['ignoreMonth'] },
    { value: 'day', text: locale['ignoreDay'] },
    { value: 'custom', text: locale['ignoreCustom'] },
  ];

  const handleIgnorePeriodChange = (item: any) => {
    setIgnorePeriod(item[0].value);
  };

  const handleCustomDateChange = (date: any) => {
    setCustomDate(date);
  };

  useEffect(() => {
    setShownDiscrepancies(
      discrepancies.map((discrepancy) => ({
        ...discrepancy,
        title: `${discrepancy.discrepancyData.entity} - ${discrepancy.discrepancyData.entityName}`,
      })),
    );
    sortAndFilterValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discrepancies]);

  const handleSort = (key: any) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction, active: true });
    sortAndFilterValues();
  };

  const handleShowInactiveChange = () => {
    handleShowInactive(!showInactive);
    setShowInactive(() => !showInactive);
  };

  const sortAndFilterValues = (filter?: string) => {
    setShownDiscrepancies(
      discrepancies
        .map((discrepancy) => ({
          ...discrepancy,
          title: `${discrepancy.discrepancyData.entity} - ${discrepancy.discrepancyData.entityName}`,
        }))
        .filter((item) => item.title.toLowerCase().includes(filter || ''))
        .sort((a: any, b: any) => {
          if (sortConfig.active && sortConfig.key !== null) {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          }
          return 0;
        }),
    );
  };

  const handleChangeFilter = (e: any) => {
    setFilterValue(e.target.value);
    sortAndFilterValues(e.target.value.toLowerCase());
  };

  const discrepancySubtable = shownDiscrepancies?.map((discrepancy, index) => {
    return (
      <TableRow key={discrepancy.discrepancyId}>
        <TableCell align="center">{discrepancy.integrationType}</TableCell>
        <TableCell align="center">{new Date(discrepancy?.createdAt || '').toLocaleDateString()}</TableCell>
        <TableCell align="center">{discrepancy.title}</TableCell>
        <TableCell align="center">{discrepancy.discrepancyData.discrepancyTypeExplanation}</TableCell>
        <TableCell align="center">{discrepancy.resolution}</TableCell>
        <TableCell align="center" sx={{ minWidth: 200 }}>
          <WolfButton
            className="w-full"
            variant="outlined"
            color="primary"
            title={discrepancy?.discrepancyData?.optionPMessage}
            onClick={() => handleOptionSelected(discrepancy?.discrepancyData?.optionP, discrepancy)}>
            {locale['trustWolf']}
          </WolfButton>
          <Typography variant="body16">{discrepancy?.discrepancyData?.optionIMessage}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ minWidth: 200 }}>
          <WolfButton
            className="w-full"
            variant="outlined"
            title={discrepancy?.discrepancyData?.optionIMessage}
            color="secondary"
            onClick={() => handleOptionSelected(discrepancy?.discrepancyData?.optionI, discrepancy)}>
            {locale['trustIntegration']}
          </WolfButton>
          <Typography variant="body16">{discrepancy?.discrepancyData?.optionIMessage}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ minWidth: 200 }}>
          <Box className="w-full mb-3">
            <WolfButton
              className="w-full"
              variant="outlined"
              color="secondary"
              onClick={() => handleOptionSelected('IGNORE', discrepancy, ignorePeriod, String(customDate))}>
              {locale['ignore']}
            </WolfButton>
          </Box>

          <WolfDropdown
            label={ignoreDropdownItems.filter((item) => item.value === ignorePeriod)[0]?.text || ''}
            onChange={handleIgnorePeriodChange}
            singleSelect={true}
            items={ignoreDropdownItems}></WolfDropdown>
          {ignorePeriod === 'custom' && (
            <TextField
              id="custom-date-picker"
              label="Custom Ignore End Date"
              type="date"
              className="mt-5"
              value={customDate}
              onChange={handleCustomDateChange}
              InputLabelProps={{ shrink: true }}
            />
          )}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <Box className="flex flex-wrap items-start justify-between w-100 my-4">
        <Box className="w-100 h-16">
          <WolfSearch label={locale['filterDocument']} value={filterValue} onChange={handleChangeFilter} />
        </Box>
        <Box className="w-100 h-16">
          <WolfCheckbox
            checked={showInactive}
            onChange={handleShowInactiveChange}
            label={locale['showInactive']}
            className="w-100 h-16"
          />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {' '}
                <TableSortLabel
                  active={sortConfig.active && sortConfig.key === 'integrationType'}
                  // @ts-ignore
                  direction={sortConfig.active && sortConfig.key === 'integrationType' ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort('integrationType')}>
                  {locale['discrepancyType']}
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={sortConfig.active && sortConfig.key === 'createdAt'}
                  // @ts-ignore
                  direction={sortConfig.active && sortConfig.key === 'createdAt' ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort('createdAt')}>
                  {locale['createdAt']}
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={sortConfig.active && sortConfig.key === 'title'}
                  // @ts-ignore
                  direction={sortConfig.active && sortConfig.key === 'title' ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort('title')}>
                  {locale['involvedDocument']}
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                {' '}
                <TableSortLabel
                  active={sortConfig.active && sortConfig.key === 'resolution'}
                  // @ts-ignore
                  direction={sortConfig.active && sortConfig.key === 'resolution' ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort('resolution')}>
                  {locale['foundDiscrepancy']}
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                {' '}
                <TableSortLabel
                  active={sortConfig.active && sortConfig.key === 'resolution'}
                  // @ts-ignore
                  direction={sortConfig.active && sortConfig.key === 'resolution' ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort('resolution')}>
                  {locale['solution']}
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">{locale['optionPET']}</TableCell>
              <TableCell align="center">{locale['optionInteg']}</TableCell>
              <TableCell align="center">{locale['ignore']}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{discrepancySubtable}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DiscrepancyList;
