import EditableChip from '@components/EditableChip';
import WolfButton from '@components/ui/WolfButton';
import WolfInput from '@components/ui/WolfInput';
import { graphQlClient } from '@config/graphqlClient';
import DocumentCategoriesGraphQL from '@graphql/document.category.queries';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import {
  createOrUpdateDocumentCategoryRequest,
  deleteDocumentCategoryRequest,
  DocumentCategory,
  getDocumentCategoriesRequest,
} from '@models/document.categories.model';
import { Box, Divider } from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface DocumentCategoriesProps {
  refresh: () => void;
}

const DocumentCategories: React.FC<DocumentCategoriesProps> = ({ refresh }) => {
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [categories, setCategories] = useState<DocumentCategory[]>([]);
  const [newCategory, setNewCategory] = useState('');

  const localeCommon = useTranslation('common');
  const localeDocuments = useTranslation('documents');
  const dispatch = useDispatch();

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const data: getDocumentCategoriesRequest = await graphQlClient.request(
      DocumentCategoriesGraphQL.queries.getDocumentCategories,
    );
    try {
      setCategories(data.getDocumentCategories);
    } catch (e: any) {
      setCategories([]);
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const handleInputChange = (e: any) => {
    setNewCategory(e.target.value);
  };

  const toggleShowAdd = () => {
    setShowAdd(!showAdd);
  };

  const handleAddCategory = async (editedValue?: DocumentCategory) => {
    if (!editedValue?.name && !newCategory) {
      return;
    }
    const auxCategory: string = newCategory.trim() || editedValue?.name.trim() || '';
    const query = !!editedValue?.documentCategoryId
      ? DocumentCategoriesGraphQL.mutations.updateDocumentCategory
      : DocumentCategoriesGraphQL.mutations.createDocumentCategory;
    const variables: { category: Partial<DocumentCategory> } = {
      category: { documentCategoryId: editedValue?.documentCategoryId, name: auxCategory },
    };
    if (!editedValue?.documentCategoryId) {
      delete variables?.category?.documentCategoryId;
    }
    try {
      const data: createOrUpdateDocumentCategoryRequest = await graphQlClient.request(query, variables);
      const newOrUpdatedCategory: DocumentCategory | undefined =
        data?.createDocumentCategory || data?.updateDocumentCategory;
      if (newOrUpdatedCategory?.documentCategoryId) {
        setCategories([
          ...categories.filter((category) => category.documentCategoryId !== editedValue?.documentCategoryId),
          newOrUpdatedCategory,
        ]);
        setNewCategory('');
        setShowAdd(false);
        refresh();
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const handleRemoveCategory = async (id: string) => {
    try {
      const deleteResult: deleteDocumentCategoryRequest = await graphQlClient.request(
        DocumentCategoriesGraphQL.mutations.deleteDocumentCategory,
        { id },
      );
      if (deleteResult.deleteDocumentCategory.affected > 0) {
        setCategories(categories.filter((category) => category.documentCategoryId !== id));
      }
      refresh();
    } catch (e: any) {
      const errorMessage = e?.response?.errors[0]?.message.includes('violates foreign key constraint')
        ? localeDocuments['categoryInUse']
        : localeCommon['requestError'];

      dispatch(
        appendActionMessage({
          message: errorMessage,
          type: SnackType.ERROR,
        }),
      );
    }
  };

  return (
    <Box className="flex items-center justify-between">
      {showAdd ? (
        <Box className="mr-3 flex items-center">
          <WolfInput
            label=""
            placeholder={localeDocuments['categoryName']}
            type="text"
            autoComplete="off"
            value={newCategory}
            onChange={handleInputChange}
          />
          <WolfButton variant="contained" color="primary" onClick={handleAddCategory}>
            {localeCommon['add']}
          </WolfButton>
        </Box>
      ) : (
        <Box className="mr-3">
          <WolfButton variant="contained" color="primary" onClick={toggleShowAdd}>
            {localeCommon['add'] + ' ' + localeCommon['category']}
          </WolfButton>{' '}
          <Divider />
        </Box>
      )}
      <Box className="flex items-center">
        {categories
          .sort((a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1))
          .map((category) => (
            <Box className="mx-1" key={category.documentCategoryId}>
              <EditableChip
                initialValue={category.name}
                onSave={(editedValue) =>
                  handleAddCategory({ name: editedValue, documentCategoryId: category.documentCategoryId })
                }
                onDelete={() => handleRemoveCategory(category.documentCategoryId)}
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default DocumentCategories;
