function formatDate(originalDate?: string) {
  if (!originalDate) return '';

  const date = new Date(originalDate);
  // TODO: We may want a less locale specific or locale-aware variant of this
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  });
}

export default formatDate;
