export function drawProcessHorizontal(
  process: any,
  xOffset: number,
  yOffset: number,
  areaName: string,
  serviceName: string,
  originNode?: any,
) {
  return {
    id: process.processId,
    position: {
      //TODO: This position needs to be reviewed
      x: !originNode ? 0 : 200 * xOffset + originNode.position.x + (originNode.type === 'Process' ? 250 : 600),
      y: !originNode ? 200 * yOffset : 200 * yOffset + originNode.position.y,
    },
    data: { label: process.name, preLabel: areaName + ' - ' + serviceName + ' - ' },
    type: 'Process',
    onNodeClick: () => {
      console.log('clicked');
    },
  };
}
