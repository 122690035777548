import CustomModal from '@components/CustomModal';
import ConfirmLeaveModal from '@components/modals/ConfirmLeaveModal';
import TinyMCE from '@components/TinyMCE';
import WolfButton from '@components/ui/WolfButton';
import WolfInput from '@components/ui/WolfInput';
import WolfTooltip from '@components/ui/WolfTooltip';
import { useTranslation } from '@hooks/useTranslation';
import useUnsavedChangesWarning from '@hooks/useUnsavedChangesWarning';
import { DrawerType, SnackType } from '@models/common.model';
import { Policy } from '@models/policy.model';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Container, IconButton, Typography } from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';

interface AddPolicyFormProps {
  onClose: () => void;
  handleSave: (policy: Policy) => void;
  handleDelete: (id: string) => void;
  onChanges: (changes: boolean) => void;
  drawerType: DrawerType;
  initialData?: Policy;
  saving: boolean;
}

const AddPolicyForm: React.FC<AddPolicyFormProps> = ({
  onClose,
  handleSave,
  handleDelete,
  onChanges,
  drawerType,
  initialData,
  saving = false,
}) => {
  const [policyFormData, setPolicyFormData] = useState<Partial<Policy>>({
    name: '',
    category: '',
    description: '',
    content: '',
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);
  const editorRef: any = useRef(null);
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');
  const localePolicies = useTranslation('policies');
  const dispatch = useDispatch();
  useUnsavedChangesWarning({ showWarning: hasUnsavedChanges });

  useEffect(() => {
    if (drawerType === DrawerType.EDIT && initialData) {
      setPolicyFormData(initialData);
    }
  }, [initialData, drawerType]);

  useEffect(() => {
    onChanges(hasUnsavedChanges);
  }, [hasUnsavedChanges]);

  const _onClose = () => {
    if (hasUnsavedChanges) {
      setShowConfirmLeaveModal(true);
    } else {
      onClose();
    }
  };

  const closeConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
  };

  const closeAndConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
    onClose();
  };

  const handleSavePolicyForm = () => {
    if (!policyFormData.name || !policyFormData.content) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['fillPolicyFields'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    if (policyFormData?.name && policyFormData?.content) {
      handleSave(policyFormData as Policy);
      setHasUnsavedChanges(false); // Reset unsaved changes after saving
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPolicyFormData({
      ...policyFormData,
      [event.target.name]: event.target.value,
    });
    setHasUnsavedChanges(true); // Mark as changed
  };

  const handleContentChange = (content: string) => {
    setPolicyFormData({
      ...policyFormData,
      content,
    });
    setHasUnsavedChanges(true); // Mark as changed
  };

  return (
    <>
      <Prompt when={hasUnsavedChanges} message={localeCommon['changesNotSaved']} />
      <Container className="w-full h-full p-8">
        <Box>
          <WolfInput
            className="mb-4"
            label={localeCommon['name'] + ' *'}
            secondaryLabel={localeCommon['required']}
            name="name"
            autoComplete="off"
            value={policyFormData?.name}
            onChange={handleInputChange}
            fullWidth
          />

          <WolfInput
            className="mb-4"
            label={localeCommon['category']}
            tooltipText={localePolicies['categoryTooltip']}
            name="category"
            autoComplete="off"
            value={policyFormData?.category}
            onChange={handleInputChange}
            fullWidth
          />

          <WolfInput
            className="mb-4"
            label={localeCommon['description']}
            tooltipText={localePolicies['descriptionTooltip']}
            name="description"
            autoComplete="off"
            value={policyFormData?.description}
            onChange={handleInputChange}
            fullWidth
          />
        </Box>

        <Box className="mb-4">
          <Typography variant="body14semibold">{localeCommon['content']}</Typography>
          <WolfTooltip title={localePolicies['contentTooltip']}>
            <IconButton size="small">
              <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
            </IconButton>
          </WolfTooltip>
          <TinyMCE
            value={policyFormData?.content}
            editorRef={editorRef}
            handleDescriptionChange={handleContentChange}
            height={400}
          />
        </Box>
        <Box className="flex justify-between mb-2">
          <WolfButton variant="outlined" color="secondary" onClick={_onClose}>
            {localeCommon['cancel']}
          </WolfButton>
          {drawerType === 'edit' && (
            <WolfButton color="error" variant="outlined" onClick={() => handleDelete(initialData?.policyId || '')}>
              {localeCommon['delete']}
            </WolfButton>
          )}
          <WolfButton
            variant="contained"
            color="primary"
            disabled={saving}
            onClick={handleSavePolicyForm}
            className="mr-2">
            {localeCommon['save']}
          </WolfButton>
        </Box>
      </Container>

      {showConfirmLeaveModal && (
        <CustomModal
          isOpen={!!showConfirmLeaveModal}
          title={localeCommon['confirmLeave']}
          customConfirmColor="error"
          maxWidth="600px"
          showButtons={true}
          onClose={closeConfirmLeaveModal}
          onConfirm={closeAndConfirmLeaveModal}>
          <ConfirmLeaveModal />
        </CustomModal>
      )}
    </>
  );
};

export default AddPolicyForm;
