import CalendarNode from '@components/processMapAux/CalendarNode';
import DottedEdge from '@components/processMapAux/DottedEdge';
import EventNode from '@components/processMapAux/EventNode';
import ProcessLinkNode from '@components/processMapAux/ProcessLinkNode';
import ProcessNode from '@components/processMapAux/ProcessNode';
import DetailedProcessNode from '@components/processMapAux/ProcessNodeDetailed';
import ProcessStepInsideNode from '@components/processMapAux/ProcessStepInsideNode';
import RepeatStepInsideNode from '@components/processMapAux/RepeatStepInsideNode';
import StepInsideNode from '@components/processMapAux/StepInsideNode';
import StepsNode from '@components/processMapAux/StepsNode';
import SubStepInsideNode from '@components/processMapAux/SubStepInsideNode';
import SwitchNode from '@components/processMapAux/SwitchNode';
import SwitchStepInsideNode from '@components/processMapAux/SwitchStepInsideNode';
import ProcessNodeV2 from '@components/processMapAuxV2/ProcessNodeV2';
import { graphQlClient } from '@config/graphqlClient';
import AreasGraphQL from '@graphql/area.queries';
import ProcessesGraphQL from '@graphql/process.queries';
import ServicesGraphQL from '@graphql/service.queries';
import { useLocationQuery } from '@hooks/useLocationQuery';
import { useTranslation } from '@hooks/useTranslation';
import { Area, getAreasRequest } from '@models/area.model';
import { getProcessesWithAreaServiceRequest, Process } from '@models/process.model';
import { getServicesRequest } from '@models/service.model';
import { Step } from '@models/step.model';
import { Box, Container, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { calculateTotalHeight } from '@utils/processMap/calculateTotalHeight';
import { drawEdge } from '@utils/processMap/drawEdge';
import { drawElementsDetailed } from '@utils/processMap/drawElementsDetailed';
import { drawElementsHorizontal } from '@utils/processMap/drawElementsHorizontal';
import { drawGenericNode } from '@utils/processMap/drawGenericNode';
import drawElementsV2 from '@utils/processMap/v2/drawElementsV2';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactFlow, { Controls, MiniMap } from 'reactflow';
import 'reactflow/dist/style.css';

const ProcessMap: React.FC = () => {
  // const { isImpersonating, impersonateName, impersonateId } = useAppSelector((state: RootState) => state.impersonate);

  const [areas, setAreas] = useState<any[]>([]);
  const [selectedArea, setSelectedArea] = useState<Area | null>();
  const [services, setServices] = useState<any[]>([]);
  const [selectedAreaId, setSelectedAreaId] = useState<string>('');
  const [selectedServiceId, setSelectedServiceId] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [showDirection, setShowDirection] = useState<'horizontal' | 'detailed' | 'v2'>('v2');
  const [flowKey, setFlowKey] = useState<number>(0);
  const [openElement, setOpenElement] = useState<string>('');
  const [accumulate, setAccumulate] = useState<boolean>(true);
  const [drawnCategories, setDrawnCategories] = useState<{ id: string; height: number }[]>([]);
  const [flowMargin, setFlowMargin] = useState<number>(200);

  const [elements, setElements] = useState<any[]>([]);
  const [edges, setEdges] = useState<any[]>([]);
  const [processes, setProcesses] = useState<any[]>([]);
  const localeProcMap = useTranslation('processMap');
  const localeCommon = useTranslation('common');

  const query = useLocationQuery();
  const history = useHistory();

  const [graphType, setGraphType] = useState<'horizontal' | 'detailed' | 'v2'>(showDirection);
  const handleGraphTypeChange = (event: any) => {
    setGraphType(event.target.value);
    setShowDirection(event.target.value);
  };

  const [lineStyle, setLineStyle] = useState<'simplebezier' | 'straight' | 'step'>('step');
  const handleLineStyleChange = (event: any) => {
    setLineStyle(() => event.target.value);
  };

  useEffect(() => {
    const areaId = query.get('areaId');
    const serviceId = query.get('serviceId');
    if (areaId) {
      setSelectedAreaId(areaId);
    }
    if (serviceId) {
      setSelectedServiceId(serviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    //Triger initial draw
    if (processes.length > 0 && services.length > 0 && selectedArea && selectedServiceId && selectedCategory)
      drawElementsGlobal(
        showDirection,
        processes,
        services.filter((service) => service.serviceId === selectedServiceId)[0]?.name,
        selectedServiceId,
        selectedCategory,
        selectedArea?.name,
        lineStyle,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processes, services, selectedArea, selectedServiceId, selectedCategory, showDirection, lineStyle, graphType]);

  useEffect(() => {
    // CHECK We may be able to just use the full areas request and extract from there
    getAreas();
    getServices();
    getAllProcesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAreas = async () => {
    try {
      const data: getAreasRequest = await graphQlClient.request(AreasGraphQL.queries.getAreas);
      setAreas(data.getAreas.sort((a, b) => (a.order > b.order ? 1 : -1)));
      const auxAreaId = selectedAreaId || query.get('areaId');
      if (auxAreaId && !selectedArea) {
        setSelectedArea(data.getAreas.filter((area) => area.areaId === auxAreaId)[0]);
      }
      setSelectedAreaId('1e7e511f-9118-4a80-ae18-c9f3cb940ff3');
      setSelectedArea(data.getAreas.filter((area) => area.areaId === '1e7e511f-9118-4a80-ae18-c9f3cb940ff3')[0]);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const getServices = async () => {
    try {
      const data: getServicesRequest = await graphQlClient.request(ServicesGraphQL.queries.getServices);
      setServices(data.getServices);
      setSelectedServiceId('7c5a740d-cb24-4bc4-98a1-5b89d66a0545');
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const getAllProcesses = async () => {
    const data: getProcessesWithAreaServiceRequest = await graphQlClient.request(
      ProcessesGraphQL.queries.getProcessesWithAreaService,
    );
    setProcesses(data?.getProcessesWithAreaService);
    setSelectedCategory('Offers');
  };

  const resetFlow = () => {
    setFlowKey((prevKey) => prevKey + 1);
  };

  const nodeTypes = useMemo(
    () => ({
      Step: StepsNode,
      CALENDAR: CalendarNode,
      EVENT: EventNode,
      Process: ProcessNode,
      Switch: SwitchNode,
      DetailedProcess: DetailedProcessNode,
      SubStepInside: SubStepInsideNode,
      StepInside: StepInsideNode,
      SwitchStepInside: SwitchStepInsideNode,
      RepeatStepInside: RepeatStepInsideNode,
      ProcessStepInside: ProcessStepInsideNode,
      ProcessLink: ProcessLinkNode,
      ProcessV2: ProcessNodeV2,
    }),
    [],
  );

  const edgeTypes = useMemo(
    () => ({
      Dotted: DottedEdge,
    }),
    [],
  );

  const handleAreaChange = (event: any) => {
    setSelectedAreaId(event.target.value);
    setSelectedArea(areas.filter((area) => area.areaId === event.target.value)[0]);
    setSelectedServiceId('');
  };

  const handleServiceChange = (event: any) => {
    setSelectedServiceId(event.target.value);
  };

  const handleCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
  };

  const handleCheckboxChange = (event: any) => {
    setAccumulate(event.target.checked);
  };

  const handleFlowMarginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marginValue = parseInt(event.target.value, 10);
    if (!isNaN(marginValue)) {
      setFlowMargin(marginValue);
    }
  };

  const drawElementsGlobal = async (
    direction: 'horizontal' | 'detailed' | 'v2',
    allData: Process[],
    serviceName: string,
    selectedServiceId: string,
    selectedCategory: string,
    areaName?: string,
    auxLineStyle?: string,
  ) => {
    // This is needed to cleary the drawing
    resetFlow();

    // We first get the core data, the processes that are part of the selected service and are in one of the selected categories
    const data = allData
      .filter((item) => item?.service?.serviceId === selectedServiceId)
      .filter((item) => item?.category === selectedCategory);

    const identifier = selectedServiceId + '---' + selectedCategory;

    // If we go this route to accumulate categories, we need to check if we are already drawing that category
    if (accumulate && drawnCategories.some((item) => item.id === identifier)) {
      return;
    }

    // If we are accumulating categories, we add the identifier to the list. We start with a height of 1 that will be updated
    if (accumulate) {
      setDrawnCategories((prev) => [...prev, { id: identifier, height: 1 }]);
    }

    // We calculate the starting vertical offset based roughly on the height of each row and we add
    // The user defined flow-margin
    const startingVerticalOffset = drawnCategories?.reduce((acc, curr) => acc + curr.height, 0) + 100;

    if (direction === 'v2') {
      console.log('v2');
      console.log('drawnCategories', drawnCategories);
      console.log('startingVerticalOffset', startingVerticalOffset);
      console.log('data', data);
      const drawnCategory = data;
      if (!drawnCategory || drawnCategory.length === 0) {
        return;
      }
      const { nodes, edges } = drawElementsV2(
        drawnCategory,
        startingVerticalOffset || 0,
        identifier, // We pass the identifier to the drawing to use as id to prefix ids within that drawing
      );
      setElements((prevElements) => [...prevElements, ...nodes.filter((item: any) => item !== undefined)]);
      setEdges((prevEdges) => [...prevEdges, ...edges.filter((item: any) => item !== undefined)]);
      const totalHeight = calculateTotalHeight(nodes);
      setDrawnCategories((prev) => [...prev, { id: identifier, height: totalHeight }]);
    } else {
      if (accumulate) {
        if (direction === 'horizontal') {
          const { nodes, edges } = drawElementsHorizontal(data, serviceName, areaName || '', auxLineStyle);
          setElements((prevElements) => [...prevElements, ...nodes.filter((item) => item !== undefined)]);
          setEdges((prevEdges) => [...prevEdges, ...edges.filter((item) => item !== undefined)]);
        }
        if (direction === 'detailed') {
          const { nodes, edges } = drawElementsDetailed(
            allData,
            data,
            serviceName,
            areaName || '',
            auxLineStyle,
            startingVerticalOffset,
            identifier, // We pass the identifier to the drawing to use as id to prefix ids within that drawing
          );
          const totalHeight = calculateTotalHeight(nodes);

          setDrawnCategories((prev) => [...prev, { id: identifier, height: totalHeight }]);

          setElements((prevElements) => [...prevElements, ...nodes.filter((item) => item !== undefined)]);
          setEdges((prevEdges) => [...prevEdges, ...edges.filter((item) => item !== undefined)]);
        }
      } else {
        if (direction === 'horizontal') {
          const { nodes, edges } = drawElementsHorizontal(data, serviceName, areaName || '', auxLineStyle);
          setElements(() => nodes.filter((item) => item !== undefined));
          setEdges(() => edges.filter((item) => item !== undefined));
        }
        if (direction === 'detailed') {
          const { nodes, edges } = drawElementsDetailed(allData, data, serviceName, areaName || '', auxLineStyle);
          setElements(() => nodes.filter((item) => item !== undefined));
          setEdges(() => edges.filter((item) => item !== undefined));
        }
      }
    }
  };

  // **** CLICK HANDLERS ****

  const onNodeClick = (event: any) => {
    // On second click, close and remove the node
    if (event.target.dataset.id === openElement) {
      //TODO: Remove also other kinds of nodes
      let idToClear = '';
      switch (event.target.dataset.type) {
        case 'process-link':
          idToClear = event.target.dataset.id + 'link';
          break;
        case 'process':
          idToClear = event.target.dataset.id + 'expandSteps';
          break;
      }

      setElements(elements.filter((item) => item.id !== idToClear));
      setEdges(edges.filter((item) => item.source !== idToClear && item.target !== idToClear));
      setOpenElement('');
      return;
    }

    if (event.target.dataset.type === 'process-link') {
      const id = event.target.dataset.id;
      const currentPosition = elements.filter((item) => item.id === id)[0];
      const newX = currentPosition?.position?.x;
      const newY = currentPosition?.position?.y + 150;
      const description = event?.target?.dataset?.description || 'This link has no description';

      const node = drawGenericNode(id + 'link', newX, newY, description, 'default');
      setElements([...elements, node]);
      setEdges([...edges, drawEdge(`${id}-edge-link`, id, id + 'link')]);
      setOpenElement(id);
    }

    if (event.target.dataset.type !== 'process') {
      return;
    }

    if (event.target.dataset.origin === 'process-name') {
      history.push(`/processes/${event.target.dataset.id.replace('main', '')}`);
      return;
    }
    if (event.target.dataset.origin === 'question-mark') {
      const id = event.target.dataset.id;
      const currentPosition = elements.filter((item) => item.id === id)[0];
      const newX = currentPosition?.position?.x + 100;
      const newY = currentPosition?.position?.y + 100;
      const newData: Step[] = processes.filter((item) => item.processId === id.replace('main', ''))[0]?.steps;
      if (newData?.length === 0) {
        return;
      }
      const node = drawGenericNode(id + 'expandSteps', newX, newY, JSON.stringify(newData), 'Step');
      setElements([...elements, node]);
      setEdges([...edges, drawEdge(`${id}-edge-${newData[0].stepId}`, id, newData[0].stepId)]);
      setOpenElement(id);
    }
  };

  const onEdgeClick = (event: any, edge: any) => {
    const connectedNodes = [edge.source, edge.target];

    const newNodes = elements.map((node) => ({
      ...node,
      style: connectedNodes.includes(node.id) ? { border: '2px solid red' } : {},
    }));

    setElements(newNodes);
  };

  const onPaneClick = () => {
    const newNodes = elements.map((node) => ({
      ...node,
      style: {},
    }));
    setElements(newNodes);
  };

  const areaList = areas.map((area) => (
    <MenuItem key={area.areaId} value={area.areaId}>
      {area.name}
    </MenuItem>
  ));

  const serviceList = selectedArea?.services.map((service) => (
    <MenuItem key={service.serviceId} value={service.serviceId}>
      {service.name}
    </MenuItem>
  ));

  const uniqueCategories = Array.from(
    new Set(
      processes.filter((process) => process.service.serviceId === selectedServiceId).map((process) => process.category),
    ),
  );

  const categoryList = uniqueCategories.map((category) => (
    <MenuItem key={category} value={category}>
      {category}
    </MenuItem>
  ));

  // if (isImpersonating) {
  //   return <Typography variant="h4">You need to stop impersonating before using the processMap</Typography>;
  // }

  return (
    <Container maxWidth={false}>
      <Typography variant="h4">{localeProcMap['title']}</Typography>
      <Box className="flex flex-wrap items-center mt-3">
        <Typography variant="h6" className="w-20">
          {localeCommon['area'] + ': '}
        </Typography>
        <Select
          id="area-select"
          className="border rounded border-gray-400 w-80"
          value={selectedAreaId}
          onChange={handleAreaChange}>
          {areaList}
        </Select>
      </Box>
      <Box className="flex flex-wrap items-center mt-3">
        <Typography variant="h6" className="w-20">
          {localeCommon['service'] + ': '}
        </Typography>
        <Select
          id="service-select"
          className="border rounded border-gray-400 w-80"
          value={selectedServiceId}
          onChange={handleServiceChange}>
          {serviceList}
        </Select>
      </Box>
      <Box className="flex flex-wrap items-center mt-3">
        <Typography variant="h6" className="w-20">
          {localeCommon['category'] + ': '}
        </Typography>
        <Select
          id="service-select"
          className="border rounded border-gray-400 w-80"
          value={selectedCategory}
          onChange={handleCategoryChange}>
          {categoryList}
        </Select>
      </Box>

      <Box className="mt-3">
        <FormControl fullWidth>
          <InputLabel id="chartTypeLabel">{localeProcMap['chartDetail']}</InputLabel>
          <Select labelId="chartTypeLabel" id="chartType" value={graphType} onChange={handleGraphTypeChange}>
            <MenuItem value={'detailed'}>{localeProcMap['detailedProcessLevel']}</MenuItem>
            <MenuItem value={'horizontal'}>{localeProcMap['roughOutlineLevel']}</MenuItem>
            <MenuItem value={'v2'}>{localeProcMap['v2']}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {/* TODO: Remove all the below when we have the v2 drawing */}
      {/* <Box className="mt-3">
        <FormControl fullWidth>
          <InputLabel id="lineStyleLabel">{localeProcMap['lineStyle']}</InputLabel>
          <Select labelId="lineStyleLabel" id="lineStyle" value={lineStyle} onChange={handleLineStyleChange}>
            <MenuItem value={'simplebezier'}>{localeProcMap['bezier']}</MenuItem>
            <MenuItem value={'straight'}>{localeProcMap['straight']}</MenuItem>
            <MenuItem value={'step'}>{localeProcMap['step']}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box className="mt-3">
        <FormControlLabel
          control={<Checkbox checked={accumulate} onChange={handleCheckboxChange} color="primary" />}
          label="Accumulate successive selected categories"
        />
      </Box>
      <Box sx={{ margin: '16px 0' }}>
        <TextField
          label={localeProcMap['flowMargin']}
          type="number"
          value={flowMargin}
          onChange={handleFlowMarginChange}
          inputProps={{ min: 0 }}
        />
      </Box> */}

      <div className="m-5 h-screen whitespace-break-spaces border-black border-solid">
        <ReactFlow
          key={flowKey}
          nodes={elements}
          edges={edges}
          onNodeClick={onNodeClick}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onPaneClick={onPaneClick}
          onEdgeClick={onEdgeClick}
          fitView>
          <MiniMap />
          <Controls showInteractive={false} />
        </ReactFlow>
      </div>
    </Container>
  );
};

export default ProcessMap;
