import CustomModal from '@components/CustomModal';
import { graphQlClient } from '@config/graphqlClient';
import DocumentsGraphQL from '@graphql/document.queries';
import DocumentsPage from '@pages/documents';
import { Editor } from '@tinymce/tinymce-react';
import { BROKEN_LINK_TAG, tagGenerator } from '@utils/enhanceHtml';
import { useState } from 'react';

export interface TinyMCEProps {
  value: string | undefined;
  editorRef: any;
  height?: number;
  handleDescriptionChange: (description: string) => void;
}

const TinyMCE: React.FC<TinyMCEProps> = ({ value, editorRef, height, handleDescriptionChange }) => {
  const [documentsModal, setDocumentsModal] = useState(false);
  const [initialFileForUpload, setInitialFileForUpload] = useState<File | null>(null);

  const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/`;

  const toggleDocumentsModal = () => {
    setDocumentsModal(!documentsModal);
  };

  const updatedFile = (id: string) => {
    setInitialFileForUpload(null);
    toggleDocumentsModal();
    editorRef.current.insertContent(
      '<p>&nbsp;</p><div class="linked-document" id=' + id + '>' + id + '</div><p>&nbsp;</p>',
    );
  };

  const addCustomButton = (editor: any) => {
    editor.ui.registry.addButton('customInsertButton', {
      text: 'Files',
      icon: 'paste',
      onAction: () => {
        toggleDocumentsModal();
      },
    });
  };

  const processCustomTags = (editor: any) => {
    const linkedDocs = editor.getDoc().querySelectorAll('.linked-document');
    const linkedDocsArray = Array.from(linkedDocs);
    if (!linkedDocsArray.length) {
      return;
    }
    linkedDocsArray.map((lnkdoc: any) => {
      const id = lnkdoc.getAttribute('id');
      // TODO: Here get data for each id and replace the content MAYBE an array of ids?
      if (!id) return;
      graphQlClient
        .request(DocumentsGraphQL.queries.getDocument, { id })
        .then((res: any) => {
          const auxData = res.getDocument;
          lnkdoc.innerHTML = tagGenerator(auxData?.fileType, auxData?.uri, auxData?.name, auxData?.documentId);
        })
        .catch((err: any) => {
          console.error(err);
          lnkdoc.innerHTML = BROKEN_LINK_TAG;
        });
    });
  };

  function cleanLinkedDocumentContent(content: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    // Find all linked-document class and empty their inner HTML
    const linkedDocs = doc.querySelectorAll('.linked-document');
    linkedDocs.forEach((el) => {
      if (el.innerHTML.trim() !== '') {
        el.innerHTML = '';
      } else {
        el?.parentNode?.removeChild(el);
      }
    });
    return doc.body.innerHTML;
  }

  return (
    <>
      <Editor
        tinymceScriptSrc={baseURL + 'tinymce/tinymce.min.js'}
        // CHECK: If the implementation needs further licensing
        licenseKey="gpl"
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value}
        init={{
          height: height || '80vh',
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bullist numlist outdent indent | customInsertButton | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | link unlink | ' +
            'removeformat | help',
          image_class_list: [{ title: 'Small', value: 'small-image' }],
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } img { max-width: 300px; max-height: 300px; }',
          setup: function (editor) {
            editor.on('SetContent', function (e) {
              processCustomTags(editor);
              editor.focus();
              const body = editor.getBody();
              const lastParagraph = body.querySelectorAll('p:last-of-type')[0];
              editor.selection.setCursorLocation(lastParagraph, 0);
            });
            // To handle pasting of files
            editor.on('paste', function (e) {
              const clipboardData = e.clipboardData;
              const items = clipboardData?.items;
              if (!items) {
                return;
              }
              let containsFile = false;
              for (let i = 0; i < items.length; i++) {
                if (items[i].kind === 'file') {
                  containsFile = true;
                  const blob = items[i].getAsFile();
                  toggleDocumentsModal();
                  setInitialFileForUpload(blob);
                }
              }
              // This block the rest if the clipboard contains a file, otherwise proceed (so we can paste text)
              if (containsFile) {
                e.preventDefault();
              }
            });
            // To handle drag and drop of files
            editor.on('drop', function (e) {
              e.preventDefault();
              const dataTransfer = e.dataTransfer;
              if (dataTransfer && dataTransfer.files && dataTransfer.files.length > 0) {
                const file = dataTransfer.files[0];
                toggleDocumentsModal();
                setInitialFileForUpload(file);
              }
            });

            // This event is triggered before the content is saved
            editor.on('SaveContent', function (e) {
              e.content = cleanLinkedDocumentContent(e.content);
            });
            addCustomButton(editor);
          },
        }}
        onEditorChange={handleDescriptionChange}
      />
      <CustomModal isOpen={documentsModal} onClose={toggleDocumentsModal}>
        <DocumentsPage isModal={true} updatedFile={updatedFile} initialFile={initialFileForUpload} />
      </CustomModal>
    </>
  );
};

export default TinyMCE;
