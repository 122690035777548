import CustomModal from '@components/CustomModal';
import AddProcessForm from '@components/drawers/AddProcessForm';
import CustomDrawer from '@components/drawers/CustomDrawer';
import ImportExportProcessHandler from '@components/ImportExportHandler';
import ConfirmLeaveModal from '@components/modals/ConfirmLeaveModal';
import ProcessContent from '@components/ProcessContent';
import ProcessContentStatic from '@components/ProcessContentStatic';
import ProcessHeader from '@components/ProcessHeader';
import ProcessSidebar from '@components/ProcessSidebar';
import { graphQlClient } from '@config/graphqlClient';
import OrganizationsGraphQL from '@graphql/organization.queries';
import ProcessesGraphQL from '@graphql/process.queries';
import StepsGraphQL from '@graphql/step.queries';
import { useLoader } from '@hooks/useLoader';
import { useLocationQuery } from '@hooks/useLocationQuery';
import { useTranslation } from '@hooks/useTranslation';
import useUnsavedChangesWarning from '@hooks/useUnsavedChangesWarning';
import { Area } from '@models/area.model';
import { DrawerType, SnackType } from '@models/common.model';
import { createOrUpdateOrganizationRequest } from '@models/organization.model';
import {
  createOrUpdateProcessRequest,
  deleteProcessRequest,
  getProcessesByServiceIdRequest,
  getProcessWithTriggeredByRequest,
  LinkedProcess,
  Process,
  processPolicyLinkRequest,
} from '@models/process.model';
import { Service } from '@models/service.model';
import {
  createOrUpdateStepRequest,
  deleteStepRequest,
  reorderStepsRequest,
  Step,
  StepType,
  TimingType,
  updateStepsIdsRequest,
} from '@models/step.model';
import { WolfieMode, WolfieStatus } from '@models/wolfie.model';
import { Box } from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { setOrganizationData } from '@redux/reducers/organization.reducer';
import {
  clearConfirmedWolfieElements,
  disableWolfie,
  removeFromWolfieConfirmedElements,
  removeFromWolfieData,
  setWolfieContextInfo,
  setWolfieCurrentlyEditing,
  setWolfieEnabled,
  setWolfieLoading,
  setWolfieMinimized,
  setWolfieMode,
  setWolfieShowAttach,
  setWolfieStatus,
  setWolfieUnableToConfirmElement,
} from '@redux/reducers/wolfie.reducer';
import { RootState } from '@redux/store';
import { filterStepsInsideSwitchRepeat } from '@utils/filterStepsInsideSwitchRepeat';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';

const ProcessDescription: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setLoading } = useLoader();

  const localeProcesses = useTranslation('processes');
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');

  const {
    data: wolfieData,
    status: wolfieStatus,
    confirmedElements,
    permanentlyDisabled,
    enabled: softEnabledWolfie,
    mode: wolfieSelectedMode,
    loading: wolfieLoading,
  } = useAppSelector((state: RootState) => state.wolfie);
  const { organizationId, onboardingDetails } = useAppSelector((state: RootState) => state.organization);
  const enabledWolfie = process.env.REACT_APP_ENABLE_WOLFIE === 'true' && !permanentlyDisabled;

  const [currentProcess, setCurrentProcess] = useState<Process>();
  // TODO CLEANUP IF NO LONGER NEEDED
  // const [displayedSteps, setDisplayedSteps] = useState<Step[]>([]);
  // const [displayedProcess, setDisplayedProcess] = useState<any>();
  const [selectedService, setSelectedService] = useState<Partial<Service>>();
  const [selectedArea, setSelectedArea] = useState<Area>();
  const [stepName, setStepName] = useState('');
  const [linkedProcess, setLinkedProcess] = useState({ processId: '', name: '' });
  const [notes, setNotes] = useState('');
  const [condition, setCondition] = useState('');
  const [description, setDescription] = useState('');
  const [selectedStep, setSelectedStep] = useState('');
  const [selectedStepData, setSelectedStepData] = useState<any>();
  const [stepType, setStepType] = useState<StepType | null>(StepType.PLAIN_TEXT);
  const [timingDescription, setTimingDescription] = useState('');
  const [timingName, setTimingName] = useState('');
  const [timingType, setTimingType] = useState(TimingType.IMMEDIATE);
  const [creationContext, setCreationContext] = useState<{ id: string; stepsIds: string; name: string }>();
  const [newStepType, setNewStepType] = useState<StepType | null>(null);
  const [updatingStep, setUpdatingStep] = useState<Partial<Step>>();
  const [updatingLinkedProcess, setUpdatingLinkedProcess] = useState<LinkedProcess>();
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [positionForNewStep, setPositionForNewStep] = useState<number | null>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const stepsRefs = useRef<{ [key: string]: any | null }>({});
  const [isGenerating, setIsGenerating] = useState(false);
  const [processFormChanges, setProcessFormChanges] = useState(false);
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);
  const [showJsonImport, setShowJsonImport] = useState(false);
  const [showJsonExport, setShowJsonExport] = useState(false);

  const { processId } = useParams<{ processId: string }>();
  const query = useLocationQuery();
  useUnsavedChangesWarning({ showWarning: isGenerating || editMode });

  useEffect(() => {
    dispatch(disableWolfie());
    return () => {
      dispatch(disableWolfie());
    };
  }, []);

  useEffect(() => {
    getProcessById(processId, true);
  }, [processId]);

  useEffect(() => {
    const stepId = query.get('stepId');
    if (stepId) {
      handleSelectStep(stepId);
    }
  }, [query]);

  useEffect(() => {
    if (currentProcess?.steps && !selectedStep) {
      handleSelectStep(currentProcess?.steps?.sort((a, b) => (a.order > b.order ? 1 : -1))[0]?.stepId || '');
    }
  }, [currentProcess]);

  useEffect(() => {
    if (wolfieData && softEnabledWolfie && wolfieSelectedMode === WolfieMode.BLANK_PROCESS) {
      handleSuggestedProcess(wolfieData);
    }
  }, [wolfieData, softEnabledWolfie, wolfieSelectedMode]);

  useEffect(() => {
    if (softEnabledWolfie && wolfieStatus === WolfieStatus.RESEARCHING && confirmedElements.length > 0) {
      setIsGenerating(true);
      handleConfirmProcessById(confirmedElements[confirmedElements.length - 1]);
    }
    if (softEnabledWolfie && wolfieStatus === WolfieStatus.DONE) {
      setIsGenerating(false);
    }
    if (softEnabledWolfie && wolfieStatus === WolfieStatus.DONE && confirmedElements.length > 0 && !wolfieLoading) {
      multipleElementsConfirmWrapper(confirmedElements);
    }
    if ((softEnabledWolfie && wolfieStatus === WolfieStatus.DECLINED) || permanentlyDisabled) {
      setIsGenerating(false);
      if (currentProcess && currentProcess.steps) {
        setCurrentProcess((prevCurr) => {
          if (!prevCurr || !prevCurr.steps) return prevCurr;

          return {
            ...prevCurr,
            steps: [...prevCurr.steps.filter((step) => !step.isWolfieGenerated)],
          };
        });
      }
    }
    if (!softEnabledWolfie) {
      setIsGenerating(false);
    }
  }, [confirmedElements, wolfieStatus, softEnabledWolfie, wolfieLoading, permanentlyDisabled]);

  const getProcessById = async (id: string, selectFirst = false) => {
    try {
      setLoading(true);
      const variables = { id };
      const data: getProcessWithTriggeredByRequest = await graphQlClient.request(
        ProcessesGraphQL.queries.getProcessWithTriggeredBy,
        variables,
      );
      // TODO: Clean up this request to avoid duplication
      const auxData: getProcessesByServiceIdRequest = await graphQlClient.request(
        ProcessesGraphQL.queries.getProcessesByServiceId,
        { id: data?.getProcessWithTriggeredBy?.service?.serviceId },
      );
      setCurrentProcess(data?.getProcessWithTriggeredBy);
      setSelectedService(data?.getProcessWithTriggeredBy?.service);
      setSelectedArea(data?.getProcessWithTriggeredBy?.service?.area);
      if (data?.getProcessWithTriggeredBy?.steps?.length === 0) {
        if (enabledWolfie) {
          setIsGenerating(true);
          dispatch(setWolfieMode(WolfieMode.BLANK_PROCESS));
          dispatch(setWolfieStatus(WolfieStatus.RESEARCHING));
          dispatch(setWolfieCurrentlyEditing(data?.getProcessWithTriggeredBy.processId));
          dispatch(setWolfieEnabled(true));
          dispatch(setWolfieShowAttach(true));
          dispatch(
            setWolfieContextInfo({
              ...data?.getProcessWithTriggeredBy,
              related: auxData.getProcessesByServiceId.filter(
                (proc) => proc.processId !== data?.getProcessWithTriggeredBy?.processId,
              ),
              areaContext: { name: selectedArea?.name || '', description: selectedArea?.description || '' },
              serviceContext: { name: selectedService?.name || '', description: selectedService?.description || '' },
            }),
          );
          dispatch(setWolfieMinimized(false));
        } else {
          setIsGenerating(false);
          setEditMode(true);
          const newStep = {
            stepId: 'in-progress',
            name: localeProcesses['aNewStep'],
            stepType: StepType.PLAIN_TEXT,
            processId: processId,
            notes: '',
            order: 1,
          };
          setCurrentProcess({
            ...data?.getProcessWithTriggeredBy,
            steps: [newStep],
          });
          clearStepData();
          setStepName(localeProcesses['aNewStep']);
          setUpdatingStep({ name: localeProcesses['aNewStep'] });
          setSelectedStepData({ name: localeProcesses['aNewStep'] });
          setSelectedStep('in-progress');
        }
      }
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (
    addNewAfter: boolean = false,
    item?: { text: string; value: string },
    position?: number,
    context?: { id: string; stepsIds: string; name: string },
  ) => {
    if (!updatingStep?.name) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['stepNameRequired'],
          type: SnackType.WARNING,
        }),
      );
      return;
    }
    const isEdit = !!selectedStep && selectedStep !== 'in-progress';
    try {
      const query = isEdit ? StepsGraphQL.mutations.updateStep : StepsGraphQL.mutations.createStep;
      const auxStep = currentProcess?.steps?.find((step) => step?.stepId === selectedStep);
      const params: { step: Partial<Step> } = {
        step: {
          stepId: (selectedStep !== 'in-progress' ? selectedStep : undefined) || undefined,
          name: updatingStep?.name,
          stepType: newStepType || StepType.PLAIN_TEXT,
          notes: updatingStep?.notes || '',
          order: positionForNewStep || undefined,
          stepData: {
            description: updatingStep?.stepData?.description,
            condition: updatingStep?.stepData?.condition,
            executeProcessId: updatingLinkedProcess?.processId,
            timingType: updatingStep?.stepData?.timingType,
            timingName: updatingStep?.stepData?.timingName,
            timingDescription: updatingStep?.stepData?.timingDescription,
            stepsIds: isEdit ? auxStep?.stepData?.stepsIds : null,
          },
          processId: processId,
        },
      };
      if (!isEdit) {
        delete params.step.stepId;
      }
      const data: createOrUpdateStepRequest = await graphQlClient.request(query, params);
      if (data?.createStep?.stepId || data?.updateStep?.stepId) {
        if (!onboardingDetails?.processWritten) {
          updateOrganizationProcessWritten();
        }
        // Get the step and add the new step to the stepIds of the context if we have it (because it is inside a switch or repeat)
        if (creationContext?.id) {
          try {
            const updatedIds =
              (creationContext?.stepsIds ? creationContext?.stepsIds + ',' : '') + data?.createStep?.stepId;
            const updateStepsIdsResult: updateStepsIdsRequest = await graphQlClient.request(
              StepsGraphQL.mutations.updateStepsIds,
              {
                step: {
                  stepId: creationContext.id,
                  stepData: {
                    name: creationContext?.name,
                    stepsIds: updatedIds,
                  },
                },
              },
            );
          } catch (e: any) {
            showErrorSnack(e);
          }
        }
        if (!addNewAfter) {
          getProcessById(processId);
        } else {
          setCurrentProcess((prevCurr) => {
            if (!prevCurr) return prevCurr; // This is to handle case where prevCurr is undefined
            return {
              ...prevCurr,
              steps: [
                ...(prevCurr?.steps?.filter((item) => item.stepId !== selectedStep) || []),
                isEdit ? data?.updateStep : data?.createStep,
              ] as Step[],
            };
          });
        }
        dispatch(
          appendActionMessage({
            message: isEdit
              ? localeActionMessages['stepUpdatedSuccessfully']
              : localeActionMessages['stepCreatedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        clearStepData();
        setCreationContext(undefined);
        if (addNewAfter && item) {
          auxAddNewStep(item, position, context);
        }
      }
    } catch (e: any) {
      showErrorSnack(e);
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    clearStepData();
    setCreationContext(undefined);
    if (currentProcess?.steps) {
      setCurrentProcess((prevCurr) => {
        if (!prevCurr || !prevCurr.steps) return prevCurr; // This is to handle case where prevCurr is undefined

        return {
          ...prevCurr,
          steps: currentProcess?.steps?.filter((step) => step?.stepId !== 'in-progress'),
        };
      });
    }
  };

  const showErrorSnack = (e: any) => {
    setSaving(false);
    dispatch(
      appendActionMessage({
        message: e?.response?.errors[0]?.message || localeCommon['requestError'],
        type: SnackType.ERROR,
      }),
    );
  };

  const handleEditStep = (id: string) => {
    if (editMode && selectedStep === id) {
      setEditMode(false);
      clearStepData();
      setCreationContext(undefined);
      return;
    }
    handleSelectStep(id || currentProcess?.steps?.sort((a, b) => (a.order > b.order ? 1 : -1))[0]?.stepId || '');
    setEditMode(true);
  };

  const toggleEdit = () => {
    setShowDrawer(true);
  };

  const handleDelete = async () => {
    if (selectedStep === 'in-progress') {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['youMustFirstSaveTheStep'],
          type: SnackType.WARNING,
        }),
      );
      return;
    }
    if (selectedStep) {
      try {
        const deleteResult: deleteStepRequest = await graphQlClient.request(StepsGraphQL.mutations.deleteStep, {
          id: selectedStep,
        });
        if (deleteResult?.deleteStep?.affected > 0) {
          getProcessById(processId);
          dispatch(
            appendActionMessage({ message: localeActionMessages['stepDeletedSuccessfully'], type: SnackType.SUCCESS }),
          );
          clearStepData();
          setCreationContext(undefined);
        }
      } catch (e: any) {
        showErrorSnack(e);
      }
    }
  };

  const addNewStep = (
    item: { text: string; value: string },
    position?: number,
    context?: { id: string; stepsIds: string; name: string },
  ) => {
    // If we are currently editing, we save the step being edited and add a new one
    if (editMode) {
      handleSave(true, item, position, context);
    } else {
      auxAddNewStep(item, position, context);
    }
  };

  const auxAddNewStep = (
    item: { text: string; value: string },
    position?: number,
    context?: { id: string; stepsIds: string; name: string },
  ) => {
    if (!currentProcess) {
      return;
    }
    setEditMode(true);
    if (context) {
      setCreationContext(context);
    }
    const stepType = item.value as StepType;
    setStepType(null);
    setNewStepType(stepType);
    if (position) {
      setPositionForNewStep(position || null);
    }
    const newStepName = localeProcesses['aNewStep'];
    // We remove existing steps
    setCurrentProcess((prevCurr) => {
      if (!prevCurr) return prevCurr; // This is to handle case where prevCurr is undefined
      return {
        ...prevCurr,
        steps: prevCurr.steps?.filter((step) => step?.stepId !== 'in-progress' && step !== undefined),
      };
    });
    let auxOrder = 0;
    if (context?.id && context?.stepsIds && position !== undefined) {
      auxOrder =
        currentProcess?.steps?.find((item) => item?.stepId === context.stepsIds.split(',')[position])?.order || 0;
    } else {
      auxOrder =
        (currentProcess?.steps && position !== undefined && currentProcess.steps[position]?.order !== undefined
          ? currentProcess.steps[position].order
          : position) || 0;
    }
    const newStep = {
      stepId: 'in-progress',
      name: newStepName,
      stepType: item.value as StepType,
      processId: processId,
      notes: '',
      order: auxOrder,
    };
    const index = position && position > 0 ? position : 0;
    if (!currentProcess?.steps) {
      currentProcess.steps = [];
    }
    if (context?.id && currentProcess?.steps) {
      const includedIn = currentProcess.steps.find((step) => step.stepId === context.id);
      if (includedIn) {
        includedIn.stepData.stepsIds = includedIn?.stepData.stepsIds + ',' + newStep.stepId;
        const updatedSteps = [
          ...currentProcess.steps.filter((step) => step?.stepId !== context.id),
          includedIn,
          newStep,
        ];
        setCurrentProcess((prevCurrentProcess) => {
          if (!prevCurrentProcess) return prevCurrentProcess;
          return { ...prevCurrentProcess, steps: updatedSteps };
        });
      }
    } else {
      setCurrentProcess((prevCurr) => {
        if (!prevCurr) return prevCurr; // This is to handle case where prevCurr is undefined
        const updatedSteps = prevCurr.steps
          ? [...prevCurr.steps.slice(0, index), newStep, ...prevCurr.steps.slice(index)].filter(
              (step) => step !== undefined,
            )
          : [newStep];
        return { ...prevCurr, steps: updatedSteps };
      });
    }
    clearStepData();
    setSelectedStep('in-progress');
    // setStepName(() => newStep);
    setSelectedStepData({ name: newStepName });
    setUpdatingStep({ name: localeProcesses['aNewStep'] });
  };

  // TODO: Now we also need to link the policy after saving the process
  const handleDrawerSave = async (process: Process, policies: { text: string; value: string }[]) => {
    if (!process.name || !process.goal || !process.serviceId) {
      dispatch(
        appendActionMessage({
          message: localeActionMessages['processNameGoalServiceRequired'],
          type: SnackType.ERROR,
        }),
      );
      return;
    }
    setSaving(true);
    const isEdit = true; // Because we are editing the process
    if (isEdit && !process.processId) {
      return appendActionMessage({
        message: localeCommon['somethingWentWrong'],
        type: SnackType.ERROR,
      });
    }
    const auxPolicy = policies?.map((policy: { text: string; value: string }) => policy.value);
    try {
      if (isEdit) {
        //Remove policies, triggers, steps from the process object since they are updated separately
        delete process.policies;
        delete process.triggers;
        delete process.steps;
        delete process.service;
        delete process.location;
        delete process.triggeredBy;
        delete process.generatedFromName;
        delete process.generatedFrom;
      }
      const query =
        isEdit && process.processId
          ? ProcessesGraphQL.mutations.updateProcess
          : ProcessesGraphQL.mutations.createProcess;
      const params = {
        process: {
          ...process,
          serviceId: !isEdit ? selectedService?.serviceId || process.serviceId : process.serviceId,
        },
      };
      const data: createOrUpdateProcessRequest = await graphQlClient.request(query, params);
      if (data?.createProcess?.processId || data?.updateProcess?.processId) {
        if (auxPolicy) {
          handleLinkProcessPolicies(process?.processId, auxPolicy);
        } else {
          getProcessById(processId);
        }

        dispatch(
          appendActionMessage({
            message: isEdit
              ? localeActionMessages['updateSuccessful']
              : localeActionMessages['processCreatedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        setShowDrawer(false);
      }
    } catch (e: any) {
      showErrorSnack(e);
    } finally {
      setSaving(false);
    }
  };

  const handleDeleteProcess = async (id: string) => {
    try {
      const deleteResult: deleteProcessRequest = await graphQlClient.request(ProcessesGraphQL.mutations.deleteProcess, {
        id,
      });
      if (deleteResult?.deleteProcess?.affected > 0) {
        dispatch(appendActionMessage({ message: localeActionMessages['deleteSuccessful'], type: SnackType.SUCCESS }));
        setShowDrawer(false);
        history.push('/processes');
      }
    } catch (e: any) {
      showErrorSnack(e);
    }
  };

  const handleSelectStep = (stepId: string, fromScroll: boolean = false) => {
    setNewStepType(null);
    setSelectedStep(stepId);
    if (!fromScroll) {
      stepsRefs.current[stepId]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const auxStep = currentProcess?.steps?.find((step: Step) => step?.stepId === stepId);
    if (auxStep?.stepType === 'SWITCH' || auxStep?.stepType === 'REPEAT') {
      setCreationContext({
        id: auxStep?.stepId || '',
        stepsIds: auxStep.stepData?.stepsIds || '',
        name: auxStep.name || '',
      });
    } else {
      setCreationContext(undefined);
    }
    setPositionForNewStep(auxStep?.order || null);
    setStepName(auxStep?.name || '');
    setLinkedProcess({
      processId: auxStep?.stepData?.executeProcessId || '',
      name: '',
    });
    setNotes(auxStep?.notes || '');
    setStepType(auxStep?.stepType || StepType.PLAIN_TEXT);
    setCondition(auxStep?.stepData?.condition || '');
    setDescription(auxStep?.stepData?.description || '');
    setTimingDescription(auxStep?.stepData?.timingDescription || '');
    setTimingName(auxStep?.stepData?.timingName || '');
    setTimingType(auxStep?.stepData?.timingType || TimingType.IMMEDIATE);
  };

  const handleUpdateStep = (updated: any) => {
    if (!currentProcess) {
      return;
    }
    if (updated.hasOwnProperty('linked')) {
      setUpdatingLinkedProcess(updated.linked);
    } else {
      setUpdatingStep((prevUpdatingStep) => {
        // Create a new object that only includes updated properties
        const newUpdatingStep = { ...prevUpdatingStep };

        if (updated?.name) {
          newUpdatingStep.name = updated.name;
          const inProgressStep = currentProcess?.steps?.find((step) => step?.stepId === 'in-progress');
          if (inProgressStep && updated.name.length > 2) {
            inProgressStep.name = updated.name;
          } else if (inProgressStep) {
            inProgressStep.name = '...';
          }
        }
        if (updated.name === '') {
          newUpdatingStep.name = '';
        }
        if (updated?.notes) {
          newUpdatingStep.notes = updated.notes;
        }
        if (updated?.description || updated?.condition) {
          newUpdatingStep.stepData = {
            ...newUpdatingStep.stepData,
            ...(updated?.description && { description: updated.description }),
            ...(updated?.condition && { condition: updated.condition }),
          };
        }
        if (updated.timingType || updated.timingName || updated.timingDescription) {
          newUpdatingStep.stepData = {
            ...newUpdatingStep.stepData,
            ...(updated?.timingType && { timingType: updated.timingType }),
            ...(updated?.timingName && { timingName: updated.timingName }),
            ...(updated?.timingDescription && { timingDescription: updated.timingDescription }),
          };
        }

        return newUpdatingStep;
      });
    }
  };

  const handleStepReorder = async (source: number, destination: number, loopId?: string) => {
    // Custom logic is needed since reorder in the case of steps may not be sequential if there step loops/switches
    const updatedProcess = { ...currentProcess };
    let updatedProcSteps: Step[] = [];
    if (!updatedProcess.steps) {
      return;
    }

    if (loopId) {
      const loopStep = updatedProcess.steps.find((item) => item?.stepId === loopId) as Step;
      const loopedSteps = loopStep.stepData.stepsIds.split(',');
      updatedProcSteps = updatedProcess.steps.filter((item) => loopedSteps.includes(item?.stepId));
    } else {
      updatedProcSteps = filterStepsInsideSwitchRepeat(
        updatedProcess.steps.sort((a, b) => (a.order > b.order ? 1 : -1)),
      );
    }

    if (!updatedProcess || updatedProcSteps?.length === 0) return;

    const auxSrc = updatedProcess.steps.findIndex((item) => item?.stepId === updatedProcSteps[source]?.stepId);
    const auxDest = updatedProcess.steps.findIndex((item) => item?.stepId === updatedProcSteps[destination]?.stepId);
    const prev = updatedProcess.steps[auxDest];

    updatedProcess.steps[auxDest] = updatedProcess.steps[auxSrc];
    updatedProcess.steps[auxSrc] = prev;

    updatedProcess.steps = updatedProcess.steps.filter((step) => step !== undefined);

    // This array will be sent to the backend to update the order of the steps
    const stepsIds = updatedProcess.steps.map((item: Step) => item?.stepId);

    try {
      const data: reorderStepsRequest = await graphQlClient.request(StepsGraphQL.mutations.reorderSteps, {
        steps: { stepsIds },
      });
      if (data?.reorderSteps.messages[0] === 'Update successful') {
        dispatch(
          appendActionMessage({
            message: localeActionMessages['stepsReorderedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        getProcessById(processId);
      }
    } catch (e: any) {
      showErrorSnack(e);
    }
  };

  const handleSuggestedProcess = (newProc: any[]) => {
    if (!newProc || !Array.isArray(newProc) || (newProc.length === 0 && !(wolfieStatus === WolfieStatus.DONE))) {
      return;
    }
    newProc.forEach((step, idx) => {
      step.order = idx;
      step.stepData = {};
      step.stepId = step.id;
      step.notes = step.notes;
      step.stepData.description = step.descriptionHTML;
      step.stepData.condition = step.condition;
    });
    setCurrentProcess((prevCurr) => {
      if (!prevCurr) return prevCurr; // This is to handle case where prevCurr is undefined
      return {
        ...prevCurr,
        steps: [...(prevCurr?.steps?.filter((item) => !item.isWolfieGenerated) || []), ...newProc],
      };
    });
    setEditMode(false);
    clearStepData();
    setCreationContext(undefined);
  };

  const multipleElementsConfirmWrapper = async (ids: string[]) => {
    dispatch(setWolfieLoading(true));
    let currentId = '';
    let success = true;
    try {
      for (let id of ids) {
        currentId = id;
        success = (await handleConfirmProcessById(id, ids[0] === id, false)) || false;
        if (!success) {
          break;
        }
      }
      if (!success) {
        dispatch(
          appendActionMessage({
            message: localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
        dispatch(setWolfieUnableToConfirmElement(true));
        dispatch(clearConfirmedWolfieElements());
        dispatch(setWolfieLoading(false));
      }
      setIsGenerating(false);
      dispatch(setWolfieLoading(false));
    } catch {}
  };

  const handleConfirmProcessById = async (id: string, showSnack: boolean = true, showErrorSnack: boolean = true) => {
    if (!id) return;
    const auxStep = currentProcess?.steps?.find((step: Step) => step?.stepId === id);
    if (!auxStep) return;

    try {
      const query = StepsGraphQL.mutations.createStep;
      const params: { step: Partial<Step> } = {
        step: {
          name: auxStep?.name,
          stepType: StepType.PLAIN_TEXT,
          order: auxStep?.order,
          stepData: {
            description: auxStep?.stepData?.description,
          },
          processId: processId,
        },
      };
      const data: createOrUpdateStepRequest = await graphQlClient.request(query, params);
      if (!onboardingDetails?.processWritten) {
        updateOrganizationProcessWritten();
      }
      if (data?.createStep?.stepId) {
        if (showSnack) {
          dispatch(
            appendActionMessage({
              message: localeActionMessages['stepCreatedSuccessfully'],
              type: SnackType.SUCCESS,
            }),
          );
        }
        dispatch(removeFromWolfieData(id));
        dispatch(removeFromWolfieConfirmedElements(id));
        if (currentProcess?.steps?.filter((item) => item.isWolfieGenerated).length === 1) {
          setIsGenerating(false);
          setEditMode(true);
        }
        if (currentProcess && currentProcess.steps) {
          setCurrentProcess((prevCurr) => {
            if (!prevCurr || !prevCurr.steps) return prevCurr; // This is to handle case where prevCurr is undefined

            return {
              ...prevCurr,
              steps: [...prevCurr.steps, data?.createStep].filter(Boolean) as Step[],
            };
          });
        }
      }
      return true;
    } catch {
      if (showErrorSnack) {
        dispatch(
          appendActionMessage({
            message: localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
        dispatch(setWolfieUnableToConfirmElement(true));
        dispatch(removeFromWolfieConfirmedElements(id));
      }
      return false;
    }
  };

  const handleLinkProcessPolicies = async (processId: string, policies: string[]) => {
    try {
      const updateResult: processPolicyLinkRequest = await graphQlClient.request(
        ProcessesGraphQL.mutations.linkProcessPolicy,
        {
          processId,
          policyId: JSON.stringify(policies),
        },
      );
      if (updateResult?.linkProcessPolicy?.processId) {
        getProcessById(processId);
      }
    } catch (e: any) {}
  };

  const updateOrganizationProcessWritten = async () => {
    try {
      const data: createOrUpdateOrganizationRequest = await graphQlClient.request(
        OrganizationsGraphQL.mutations.updateOrganization,
        {
          organization: {
            organizationId: organizationId || '',
            onboardingDetails: {
              ...onboardingDetails,
              processWritten: true,
            },
          },
        },
      );
      if (data?.updateOrganization?.organizationId) {
        dispatch(setOrganizationData(data.updateOrganization));
      }
    } catch {}
  };

  const handleClearGeneratedFrom = async (processId: string) => {
    try {
      const query = ProcessesGraphQL.mutations.updateProcess;
      const params = {
        process: {
          processId,
          generatedFromName: '',
          generatedFrom: null,
        },
      };
      const data: createOrUpdateProcessRequest = await graphQlClient.request(query, params);
      if (data?.createProcess?.processId || data?.updateProcess?.processId) {
        setCurrentProcess((prevCurr) => {
          if (!prevCurr) return prevCurr; // This is to handle case where prevCurr is undefined
          return {
            ...prevCurr,
            generatedFromName: '',
            generatedFrom: '',
          };
        });
        dispatch(
          appendActionMessage({ message: localeActionMessages['generatedFromClearSuccess'], type: SnackType.SUCCESS }),
        );
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const clearStepData = () => {
    setSelectedStep('');
    setUpdatingLinkedProcess(undefined);
    setUpdatingStep(undefined);
    setStepName('');
    setLinkedProcess({ processId: '', name: '' });
    setNotes('');
    setCondition('');
    setDescription('');
  };

  const handleDrawerClose = () => {
    if (processFormChanges) {
      setShowConfirmLeaveModal(true);
    } else {
      setShowDrawer(false);
    }
  };

  const closeConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
  };

  const closeAndConfirmLeaveModal = () => {
    setShowConfirmLeaveModal(false);
    setProcessFormChanges(false);
    setShowDrawer(false);
  };

  const handleProcessFormChanges = (changes: boolean) => {
    setProcessFormChanges(changes);
  };

  const handleShowJsonImport = () => {
    setShowJsonImport(true);
  };

  const handleShowJsonExport = () => {
    setShowJsonExport(true);
  };

  const handleCloseJsonImport = () => {
    setShowJsonImport(false);
  };

  const handleCloseJsonExport = () => {
    setShowJsonExport(false);
  };

  return (
    <>
      <Prompt when={isGenerating || editMode} message={localeCommon['changesNotSaved']} />
      <Box className="flex flex-wrap h-full">
        <Box className="w-full sticky top-0 z-30 bg-backgroundUI -mt-6 pt-2">
          <ProcessHeader
            mode={isGenerating ? 'generate' : editMode ? 'edit' : 'view'}
            title={selectedArea?.name || ''}
            titleLink={'/services?areaId=' + selectedArea?.areaId}
            subTitle={selectedService?.name || ''}
            subTitleLink={'/processes?serviceId=' + selectedService?.serviceId}
            subSubTitle={currentProcess?.name || ''}
            onSave={handleSave}
            onCancel={handleCancel}
            onDelete={handleDelete}
            onProcessDelete={() => handleDeleteProcess(processId)}
            onEdit={toggleEdit}
            saving={saving}
            editMode={editMode}
            location={currentProcess?.location || ''}
            showRemove={selectedStep !== 'in-progress' && !isGenerating}
            handleShowJsonImport={handleShowJsonImport}
            handleShowJsonExport={handleShowJsonExport}
          />
        </Box>
        <Box className="flex w-full h-full">
          <Box className="fixed w-96 max-w-sm -mx-6 pt-32 bg-backgroundUI h-screen top-0">
            <Box className="px-4">
              <ProcessSidebar
                editMode={editMode}
                isGenerating={isGenerating}
                goal={currentProcess?.goal || ''}
                description={currentProcess?.description || ''}
                generatedFromName={currentProcess?.generatedFromName || ''}
                triggers={currentProcess?.triggers || []}
                triggeredBy={currentProcess?.triggeredBy || []}
                handleSelectStep={handleSelectStep}
                handleEditStep={handleEditStep}
                handleNewStep={addNewStep}
                handleEditProcess={toggleEdit}
                handleClearGeneratedFrom={() => handleClearGeneratedFrom(currentProcess?.processId || '')}
                selectedStep={selectedStep}
                steps={currentProcess?.steps || []}
                dragEndHandler={handleStepReorder}
                // items={
                //   filterStepsInsideSwitchRepeat(currentProcess?.steps).map((item, idx) => ({
                //     name: item.name,
                //     type: item.stepType,
                //     position: (idx + 1).toString(),
                //   })) || []
                // }
              />
            </Box>
          </Box>
          <Box className="ml-90 w-full h-full-minus-header overflow-y-auto">
            {editMode ? (
              <ProcessContent
                parentCategory={currentProcess?.category || ''}
                newStepType={stepType || newStepType}
                // TODO: Refactor all initial data
                initialData={selectedStepData}
                initialStepName={stepName}
                initialLinkedProcess={linkedProcess}
                initialNotes={notes}
                initialCondition={condition}
                initialDescription={description}
                initialTimingDescription={timingDescription}
                initialTimingName={timingName}
                initialTimingType={timingType}
                updatingStep={handleUpdateStep}
              />
            ) : (
              <ProcessContentStatic
                process={currentProcess}
                stepsRefs={stepsRefs}
                auxStep={currentProcess?.steps?.filter((step) => step?.stepId === selectedStep)[0]}
                handleSelectStep={handleSelectStep}
                handleEditStep={handleEditStep}
                isGenerating={isGenerating}
              />
            )}
          </Box>
        </Box>
      </Box>

      <CustomDrawer title={localeProcesses['edit']} isOpen={showDrawer} onClose={handleDrawerClose} size="large">
        <AddProcessForm
          parentService={{ name: selectedService?.name || '', serviceId: selectedService?.serviceId || '' }}
          onClose={handleDrawerClose}
          handleSave={handleDrawerSave}
          handleDelete={handleDeleteProcess}
          drawerType={DrawerType.EDIT}
          initialData={currentProcess}
          saveBtnText={localeProcesses['editProcess']}
          saving={saving}
          onChanges={handleProcessFormChanges}
        />
      </CustomDrawer>

      {showConfirmLeaveModal && (
        <CustomModal
          isOpen={!!showConfirmLeaveModal}
          title={localeCommon['confirmLeave']}
          customConfirmColor="error"
          maxWidth="600px"
          showButtons={true}
          onClose={closeConfirmLeaveModal}
          onConfirm={closeAndConfirmLeaveModal}>
          <ConfirmLeaveModal />
        </CustomModal>
      )}

      <ImportExportProcessHandler
        processId={processId}
        steps={currentProcess?.steps || []}
        showJsonImport={showJsonImport}
        showJsonExport={showJsonExport}
        handleCloseJsonImport={handleCloseJsonImport}
        handleCloseJsonExport={handleCloseJsonExport}
        refresh={getProcessById}
      />
    </>
  );
};

export default ProcessDescription;
