export interface Integration {
  integrationId?: string;
  integrationType: IntegrationType;
  integrationProvider?: IntegrationProvider;
  status?: IntegrationStatus;
  credentials?: any;
  description?: string;
  link?: string;
}

export enum IntegrationStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  GENERATED = 'GENERATED',
}

export enum IntegrationType {
  CALENDAR = 'CALENDAR',
  STORAGE = 'STORAGE',
  NOTES = 'NOTES',
  ISSUE_TRACKER = 'ISSUE_TRACKER',
  OTHER = 'OTHER',
}

export enum IntegrationProvider {
  GOOGLE = 'GOOGLE',
}

export interface getIntegrationRequest {
  getIntegrations: Integration[];
}

export interface createIntegrationRequest {
  createIntegration: Integration;
}

export interface deleteIntegrationRequest {
  deleteIntegration: {
    affected: number;
  };
}

export enum CalendarRepetition {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  NO_REPEAT = 'NO_REPEAT',
}
