import WolfButton from '@components/ui/WolfButton';
import { getTokenLocalStorage, getTokenSessionStorage } from '@config/storage';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MicIcon from '@mui/icons-material/Mic';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';
import { blobToBase64 } from '@utils/blobToBase64';
import { useRef, useState } from 'react';

interface WolfieMicInputProps {
  onSubmit: (message: string) => void;
  disabled?: boolean;
}

export default function WolfieMicInput({ onSubmit, disabled = false }: WolfieMicInputProps) {
  const [recording, setRecording] = useState(false);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const { rememberMe } = useAppSelector((state: RootState) => state.user);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };
      mediaRecorder.current.onstop = () => {
        const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
        audioChunks.current = [];
        transcribeAudio(audioBlob);
        // Stop all tracks to free up the mic
        stream.getTracks().forEach((track) => track.stop());
      };
      mediaRecorder.current.start();
      setRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setRecording(false);
    }
  };

  async function transcribeAudio(audioBlob: Blob) {
    try {
      const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();
      const base64Audio = await blobToBase64(audioBlob);
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'ai/transcribeAudio', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          audioBase64: base64Audio,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        const transcription = result.transcription;
        onSubmit(transcription);
      } else {
        console.error('Backend error:', result.message);
      }
    } catch (error) {
      console.error('Error transcribing audio:', error);
    }
  }

  return (
    <>
      {/* // TODO: CHECK Tooltip not working */}
      {/* <WolfTooltip title={!recording ? 'Start recording' : 'Stop recording'}> */}
      <WolfButton
        color="primary"
        variant="contained"
        onClick={!recording ? startRecording : stopRecording}
        disabled={disabled}
        className="">
        {!recording ? <MicIcon /> : <FiberManualRecordIcon />}
      </WolfButton>
      {/* </WolfTooltip> */}
    </>
  );
}
