import { MarkerType, Position } from 'reactflow';

export function drawProcessWithEdge(
  mainNode: any,
  step: any,
  i: number,
  lastNode: any,
  style: string = 'simplebezier',
  idPrefix: string = '',
) {
  let linkNode = null;
  let linkEdge = null;
  let edge = null;

  const node = {
    id: step.stepId,
    position: {
      x: mainNode.position.x + 50,
      y:
        lastNode && ['SwitchStepInside', 'ProcessStepInside', 'SubStepInside'].includes(lastNode.type)
          ? lastNode.position.y + 50
          : lastNode
            ? lastNode.position.y + 30
            : 35 * (i + 1) + 35,
    },
    parent: mainNode.id,
    data: {
      label: i + 1 + '. ' + step.name,
      parentId: mainNode.id,
    },
    type: 'ProcessStepInside',
    onNodeClick: () => {
      console.log('clicked');
    },
  };

  // If it has a link, we add the edge to the link, otherwise to the process
  if (step?.stepData?.timingType && step?.stepData?.timingType !== 'IMMEDIATE') {
    edge = {
      id: step?.stepId + 'procEdgeLinked',
      source: idPrefix + step?.stepId,
      target: idPrefix + step.stepId + 'linkNode',
      targetHandle: Position.Left,
      sourceHandle: Position.Right,
      zIndex: 100,
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      type: style,
      parent: mainNode.id,
    };
  } else {
    edge = {
      id: step?.stepId + 'procEdge',
      source: idPrefix + step?.stepId,
      target: idPrefix + 'main' + step?.stepData?.executeProcessId,
      targetHandle: Position.Left,
      sourceHandle: Position.Right,
      zIndex: 100,
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      type: style,
      parent: mainNode.id,
    };
  }

  //IF Has a link, we add the intermediate node and the linking edge
  if (step?.stepData?.timingType && step?.stepData?.timingType !== 'IMMEDIATE') {
    linkNode = {
      id: step.stepId + 'linkNode',
      position: {
        x: node.position.x + 250,
        y: node.position.y,
      },
      parent: mainNode.id,
      data: {
        label: step?.stepData?.timingName,
        parentId: mainNode.id,
        id: step.stepId + 'linkNode',
        type: step?.stepData?.timingType,
        description: step?.stepData?.timingDescription,
      },
      type: 'ProcessLink',
      onNodeClick: () => {
        console.log('clicked');
      },
    };
    linkEdge = {
      id: step?.stepId + 'procEdgeLinkedOut',
      source: idPrefix + step.stepId + 'linkNode',
      target: idPrefix + 'main' + step?.stepData?.executeProcessId,
      targetHandle: Position.Left,
      sourceHandle: Position.Right,
      zIndex: 100,
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      type: style,
      parent: mainNode.id,
    };
  }

  return { node, edge, linkNode, linkEdge };
}
