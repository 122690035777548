import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Handle, Position } from 'reactflow';

export default function EventNode({ data }: any) {
  return (
    <>
      <div className="trigger-node flex flex-wrap items-center">
        <NotificationsNoneIcon />

        {data.label}
      </div>
      <Handle type="source" position={Position.Right} id="a" />
    </>
  );
}
