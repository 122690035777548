import { IconButton, IconButtonProps } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import WolfTooltip from './WolfTooltip';

interface WolfIconButtonProps extends IconButtonProps {
  onClick?: () => void;
  icon: React.ReactNode;
  tooltip?: string;
  selected?: boolean;
  sx?: SxProps;
}

const IconContainer = styled('span')({
  display: 'inherit',
  marginLeft: '8px',
  marginRight: '8px',
});

const StyledIconButton = styled(IconButton)<WolfIconButtonProps>(({ theme, selected }) => ({
  '&.MuiIconButton-root': {
    backgroundColor: selected ? theme.palette.primaryDark.main : theme.palette.common.white,
    color: selected ? theme.palette.common.white : theme.palette.primaryDark.main,
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    margin: '2px',
  },
  '&.Mui-disabled': {
    color: '#b0bec5',
  },
  '&:hover': {
    backgroundColor: selected ? theme.palette.frozenGrayShades7.main : theme.palette.frozenGrayShades2.main,
    color: theme.palette.primaryDark.main,
  },
}));

const WolfIconButton: React.FC<WolfIconButtonProps> = ({ icon, onClick, selected = false, tooltip, ...props }) => (
  <StyledIconButton {...props} onClick={onClick} icon={icon} selected={selected}>
    {tooltip && (
      <WolfTooltip title={tooltip}>
        <IconContainer>{icon}</IconContainer>
      </WolfTooltip>
    )}
    {!tooltip && <IconContainer>{icon}</IconContainer>}
  </StyledIconButton>
);

export default WolfIconButton;
