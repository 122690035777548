import { useTranslation } from '@hooks/useTranslation';
import { User } from '@models/user.model';
import { Box, Typography } from '@mui/material';

interface EnableUserModalProps {
  user: User | undefined;
}

//TODO: Maybe this can be consolidated with the DisableUserModal
const EnableUserModal: React.FC<EnableUserModalProps> = ({ user }) => {
  const localeUsers = useTranslation('users');

  return (
    <Box>
      <Typography variant="body16">{localeUsers['confirmEnableMessage']}</Typography>
      <Typography variant="body16semibold">{user?.email}</Typography>
    </Box>
  );
};

export default EnableUserModal;
