import logo from '@assets/logos/mrwolf-black-completo-400@2x.png';
import { useTranslation } from '@hooks/useTranslation';
import { Container, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.css';

const LandingPage: React.FC = () => {
  const locale = useTranslation('landing');
  const history = useHistory();

  const navigateToAuth = async () => {
    history.push('/login');
  };

  return (
    <Container className="landing-container">
      <div className="landing-navigation">
        {/* <Typography className="home-link">{locale['home']}</Typography> */}
        {/* <Typography className="link">{locale['features']}</Typography> */}
        {/* <Typography className="link">{locale['integrations']}</Typography> */}
        {/* <Typography className="link">{locale['contact']}</Typography> */}
        <Typography className="login-link" style={{ marginLeft: '28px' }} onClick={navigateToAuth}>
          {locale['login']}
        </Typography>
        {/* <div className="link">{locale['try']}</div> */}
      </div>
      <div className="hero-spacer"></div>
      <div className="hero">
        <div className="hero-images">
          <div>
            <img src={logo} width="440" alt="logo" className="hero-logo-image" />
          </div>
          {/* <div className="hero-tagline">{locale['tagline']}</div> */}
          <Typography className="hero-tagline" style={{ fontSize: 24, marginTop: '32px' }}>
            {locale['tagline']}
          </Typography>
        </div>
      </div>

      <div className="highlights">
        <Typography className="highlights-left">{locale['highlight1']}</Typography>
        <Typography className="highlights-center">{locale['highlight2']}</Typography>
        <Typography className="highlights-right">{locale['highlight3']}</Typography>
      </div>
    </Container>
  );
};

export default LandingPage;
