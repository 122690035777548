import { useTranslation } from '@hooks/useTranslation';
import { Integration, IntegrationType } from '@models/integration.model';
import { Box, Typography } from '@mui/material';

interface DeleteIntegrationModalProps {
  integration: Integration;
}

const DeleteIntegrationModal: React.FC<DeleteIntegrationModalProps> = ({ integration }) => {
  const localeIntegrations = useTranslation('integrations');

  return (
    <Box>
      <Box>
        <Typography variant="body16semibold">
          {localeIntegrations['confirmDeleteMessage']
            .replace('{type}', integration.integrationType)
            .replace('{provider}', integration.integrationProvider)}
        </Typography>
        <Typography variant="body16">
          {integration.integrationType === IntegrationType.CALENDAR &&
            localeIntegrations['confirmDeleteMessageCalendar']}
          {integration.integrationType === IntegrationType.STORAGE && localeIntegrations['confirmDeleteMessageStorage']}
        </Typography>
      </Box>
    </Box>
  );
};

export default DeleteIntegrationModal;
