import { gql } from 'graphql-request';

export const DISCREPANCY_FIELDS = gql`
  fragment DiscrepancyFields on DiscrepancyDto {
    discrepancyId
    integrationType
    discrepancyData
    resolution
    createdAt
    lastUpdatedAt
    deletedAt
  }
`;

export const recheckDiscrepancies = gql`
  query recheckDiscrepancies {
    recheckDiscrepancies {
      messages
    }
  }
`;

export const getDiscrepancies = gql`
  query getDiscrepancies {
    getDiscrepancies {
      ...DiscrepancyFields
    }
  }
  ${DISCREPANCY_FIELDS}
`;

export const getActiveDiscrepancies = gql`
  query getActiveDiscrepancies {
    getActiveDiscrepancies {
      ...DiscrepancyFields
    }
  }
  ${DISCREPANCY_FIELDS}
`;

export const updateDiscrepancy = gql`
  mutation updateDiscrepancy($discrepancy: DiscrepancyUpdateInput!) {
    updateDiscrepancy(discrepancy: $discrepancy) {
      ...DiscrepancyFields
    }
  }
  ${DISCREPANCY_FIELDS}
`;

const DiscrepanciesGraphQL = {
  queries: {
    getDiscrepancies,
    getActiveDiscrepancies,
    recheckDiscrepancies,
  },
  mutations: {
    updateDiscrepancy,
  },
};

export default DiscrepanciesGraphQL;
