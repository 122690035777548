import { gql } from 'graphql-request';

export const TRIGGER_FIELDS = gql`
  fragment TriggerFields on TriggerDto {
    triggerId
    name
    triggerType
    triggerData
  }
`;

export const createTrigger = gql`
  mutation createTrigger($trigger: TriggerCreateInput!) {
    createTrigger(trigger: $trigger) {
      ...TriggerFields
    }
  }
  ${TRIGGER_FIELDS}
`;

export const updateTrigger = gql`
  mutation updateTrigger($trigger: TriggerUpdateInput!) {
    updateTrigger(trigger: $trigger) {
      ...TriggerFields
    }
  }
  ${TRIGGER_FIELDS}
`;

export const deleteTrigger = gql`
  mutation deleteTrigger($id: String!) {
    deleteTrigger(id: $id) {
      affected
      messages
    }
  }
`;

const TriggersGraphQL = {
  queries: {
    // getProcessByID,
  },
  mutations: {
    createTrigger,
    updateTrigger,
    deleteTrigger,
  },
};

export default TriggersGraphQL;
