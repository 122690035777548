import { Box, CircularProgress } from '@mui/material';
import { useAppDispatch } from '@redux/hooks';
import { logoutUser } from '@redux/reducers/user.reducer';
import React, { useEffect } from 'react';

const LogoutPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  return (
    <Box className="flex justify-center items-center h-full">
      <CircularProgress />
    </Box>
  );
};

export default LogoutPage;
