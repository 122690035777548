import { CategorizedProcess, Process } from '@models/process.model';
import { Service } from '@models/service.model';

export const categorizeProcessesByService = (processes: Process[], services: Service[]) => {
  const categorizedProcessesByService: { [serviceKey: string]: CategorizedProcess } = {};
  const categoriesByService: { [serviceKey: string]: string[] } = {};

  // Initialize categorizedProcessesByService and categoriesByService for each service
  services.forEach((service) => {
    const serviceKey = service.name;
    categorizedProcessesByService[serviceKey] = {};
    categoriesByService[serviceKey] = [];
  });

  processes.forEach((process) => {
    const service = process.service!;
    const category = process.category || 'Uncategorized';
    const serviceKey = service.name;

    if (!categorizedProcessesByService[serviceKey][category]) {
      categorizedProcessesByService[serviceKey][category] = [];
    }
    categorizedProcessesByService[serviceKey][category].push(process);

    if (!categoriesByService[serviceKey].includes(category)) {
      categoriesByService[serviceKey].push(category);
    }
  });

  return { categorizedProcessesByService, categoriesByService };
};
