const TOKEN_NAME = 'authToken';

export const setTokenLocalStorage = (token?: string) => {
  if (token) {
    window.localStorage.setItem(TOKEN_NAME, token);
  }
};

export const setTokenSessionStorage = (token?: string) => {
  if (token) {
    window.sessionStorage.setItem(TOKEN_NAME, token);
  }
};

export const getTokenLocalStorage = () => {
  return window.localStorage.getItem(TOKEN_NAME) || '';
};

export const getTokenSessionStorage = () => {
  return window.sessionStorage.getItem(TOKEN_NAME) || '';
};

export const removeToken = () => {
  window.sessionStorage.removeItem(TOKEN_NAME);
  window.localStorage.removeItem(TOKEN_NAME);
};
