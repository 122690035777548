import WolfCheckbox from '@components/ui/WolfCheckbox';
import WolfDropdown, { DropdownSelectedItem } from '@components/ui/WolfDropdown';
import WolfInput from '@components/ui/WolfInput';
import { useTranslation } from '@hooks/useTranslation';
import { CalendarRepetition } from '@models/integration.model';
import { Box, Typography } from '@mui/material';
import { DAYS_ARRAY, DAYS_NUMBER_ARRAY, MONTHS_ARRAY } from '@utils/constants';
import getRepeatRule, { translateRepeatRule } from '@utils/repeat-rule';
import { useEffect, useState } from 'react';

interface CalendarRecurrenceProps {
  initialData: any;
  refreshRrule: (rrule: string) => void;
}

// CHECK: We may have to refactor this component once we do the new process edit flow
const CalendarRecurrence: React.FC<CalendarRecurrenceProps> = ({ initialData, refreshRrule }) => {
  const localeCalTrig = useTranslation('calTrig');
  const localeCommon = useTranslation('common');

  const REPEAT_TYPE = [
    { text: localeCalTrig['noRepeat'], value: CalendarRepetition.NO_REPEAT },
    { text: localeCalTrig['daily'], value: CalendarRepetition.DAILY },
    { text: localeCalTrig['weekly'], value: CalendarRepetition.WEEKLY },
    { text: localeCalTrig['monthly'], value: CalendarRepetition.MONTHLY },
    { text: localeCalTrig['yearly'], value: CalendarRepetition.YEARLY },
  ];

  const FREQUENCY_DAYS = [
    { text: `${localeCalTrig['every']} ${localeCalTrig['day']}`, value: '1' },
    { text: `${localeCalTrig['every']} 2 ${localeCalTrig['days']}`, value: '2' },
    { text: `${localeCalTrig['every']} 3 ${localeCalTrig['days']}`, value: '3' },
    { text: `${localeCalTrig['every']} 4 ${localeCalTrig['days']}`, value: '4' },
    { text: `${localeCalTrig['every']} 5 ${localeCalTrig['days']}`, value: '5' },
    { text: `${localeCalTrig['every']} 6 ${localeCalTrig['days']}`, value: '6' },
  ];

  const FREQUENCY_WEEKS = [
    { text: `${localeCalTrig['every']} ${localeCalTrig['week']}`, value: '1' },
    { text: `${localeCalTrig['every']} 2 ${localeCalTrig['weeks']}`, value: '2' },
    { text: `${localeCalTrig['every']} 3 ${localeCalTrig['weeks']}`, value: '3' },
    { text: `${localeCalTrig['every']} 4 ${localeCalTrig['weeks']}`, value: '4' },
    { text: `${localeCalTrig['every']} 5 ${localeCalTrig['weeks']}`, value: '5' },
  ];

  const FREQUENCY_MONTHS = [
    { text: `${localeCalTrig['every']} ${localeCalTrig['month']}`, value: '1' },
    { text: `${localeCalTrig['every']} 2 ${localeCalTrig['months']}`, value: '2' },
    { text: `${localeCalTrig['every']} 3 ${localeCalTrig['months']}`, value: '3' },
    { text: `${localeCalTrig['every']} 4 ${localeCalTrig['months']}`, value: '4' },
    { text: `${localeCalTrig['every']} 5 ${localeCalTrig['months']}`, value: '5' },
    { text: `${localeCalTrig['every']} 6 ${localeCalTrig['months']}`, value: '6' },
  ];

  const WEEK_POSITION = [
    { text: localeCommon['first'], value: '1' },
    { text: localeCommon['second'], value: '2' },
    { text: localeCommon['third'], value: '3' },
    { text: localeCommon['fourth'], value: '4' },
    { text: localeCommon['last'], value: '-1' },
  ];

  const REPEAT_FOR = [
    { text: localeCalTrig['indefinitely'], value: 'never' },
    { text: localeCalTrig['afterTimes'], value: 'after' },
    { text: localeCalTrig['atDate'], value: 'atDate' },
  ];

  const MONTHLY_REPEAT_TYPES = [
    { text: localeCalTrig['byNumber'], value: 'number' },
    { text: localeCalTrig['byDay'], value: 'weekday' },
  ];

  const YEARLY_REPEAT_TYPES = [
    { text: localeCalTrig['byNumberMonth'], value: 'number' },
    { text: localeCalTrig['byDayMonth'], value: 'weekday' },
  ];

  const [repeatType, setRepeatType] = useState<Partial<DropdownSelectedItem>>({
    text: REPEAT_TYPE.find((item) => item.value === CalendarRepetition.NO_REPEAT)?.text,
    value: CalendarRepetition.NO_REPEAT,
  });
  const [repeatEvery, setRepeatEvery] = useState<number>(1);
  const [weekPosition, setWeekPosition] = useState<string | null>();
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [repeatOption, setRepeatOption] = useState('never');
  const [repeatTimes, setRepeatTimes] = useState(1);
  const [repeatDate, setRepeatDate] = useState('');
  const [monthlyType, setMonthlyType] = useState('weekday');
  const [yearlyType, setYearlyType] = useState('weekday');

  useEffect(() => {
    if (initialData) {
      const initial = translateRepeatRule(initialData);
      if (initial?.repeatType) {
        setRepeatType({
          value: initial?.repeatType,
          text: REPEAT_TYPE.find((item) => item.value === initial?.repeatType)?.text,
        });
      }
      if (initial?.repeatEvery) {
        setRepeatEvery(initial?.repeatEvery);
      }
      if (initial?.selectedDays) {
        setSelectedDays(initial?.selectedDays);
      }
      if (initial?.selectedDay) {
        setSelectedDay(initial?.selectedDay);
      }
      if (initial?.selectedMonth) {
        setSelectedMonth(initial?.selectedMonth);
      }
      if (initial?.position) {
        setWeekPosition(initial?.position);
      }
      if (initial?.iterations) {
        setRepeatOption('after');
        setRepeatTimes(initial?.iterations);
      }
      if (initial?.endDate) {
        setRepeatOption('atDate');
      }
    }
    if (!initialData) {
      setRepeatType({
        text: REPEAT_TYPE.find((item) => item.value === CalendarRepetition.NO_REPEAT)?.text,
        value: CalendarRepetition.NO_REPEAT,
      });
      setRepeatEvery(1);
      setWeekPosition(WEEK_POSITION[0].value);
      setSelectedMonth(1);
      setSelectedDays([]);
      setSelectedDay('1');
      setRepeatOption('never');
      setRepeatTimes(1);
      setRepeatDate('');
      setMonthlyType('weekday');
    }
  }, [initialData]);

  useEffect(
    () => {
      // Function to update repeat rule based on state variables
      const updateRepeatRule = () => {
        const rrule = getRepeatRule(
          (repeatType.value as CalendarRepetition) || CalendarRepetition.NO_REPEAT,
          repeatEvery,
          selectedDays,
          selectedDay,
          selectedMonth,
          weekPosition,
          repeatOption === 'after' ? repeatTimes : undefined,
          repeatOption === 'atDate' ? repeatDate : undefined,
        );
        refreshRrule(rrule || '');
      };

      updateRepeatRule();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      repeatType,
      repeatEvery,
      selectedDays,
      selectedDay,
      selectedMonth,
      weekPosition,
      repeatOption,
      repeatTimes,
      repeatDate,
    ],
  );

  const handleRepeatTypeChange = (item: any) => {
    setSelectedDays([]);
    setRepeatEvery(1);
    setSelectedMonth(1);
    setRepeatType(item);
    setWeekPosition(WEEK_POSITION[0].value);
    setSelectedDay('1');
  };

  const handleRepeatEveryChange = (item: any) => {
    setRepeatEvery(item.value);
  };

  const handleWeekPositionChange = (item: any) => {
    setWeekPosition(item.value);
  };

  const handleDayCheckboxChange = (day: any) => {
    setSelectedDays((prevSelectedDays: any) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((selectedDay: any) => selectedDay !== day)
        : [...prevSelectedDays, day],
    );
  };

  const handleDayChange = (item: any) => {
    setSelectedDay(item.value);
  };

  const handleMonthChange = (item: any) => {
    setSelectedMonth(item.value);
  };

  const handleMonthlyTypeChange = (item: any) => {
    setMonthlyType(item.value);
  };

  const handleYearlyTypeChange = (item: any) => {
    setYearlyType(item.value);
  };

  const handleRepeatChange = (item: any) => {
    setRepeatOption(item.value);
  };

  const handleRepeatTimesChange = (event: any) => {
    setRepeatTimes(event.target.value);
  };

  const handleRepeatDateChange = (event: any) => {
    setRepeatDate(event.target.value);
  };

  return (
    <>
      <Box className="flex gap-8">
        <Box className="w-full">
          <Typography variant="body14semibold">{localeCalTrig['repeatPattern']}</Typography>
        </Box>
        {repeatType.value === CalendarRepetition.MONTHLY && (
          <Box className="w-full">
            <Typography variant="body14semibold">{localeCalTrig['dayCriteria']}</Typography>
          </Box>
        )}
      </Box>
      <Box className="flex gap-8 mb-8">
        <WolfDropdown
          label={repeatType.text || ''}
          showBorder={true}
          singleSelect={true}
          items={REPEAT_TYPE}
          initialSelectedItems={[repeatType.value || '']}
          onChange={(item) => handleRepeatTypeChange(item[0])}
        />
        {repeatType.value === CalendarRepetition.MONTHLY && (
          <WolfDropdown
            label={MONTHLY_REPEAT_TYPES.find((item) => item.value === monthlyType)?.text || localeCalTrig['repeatBy']}
            showBorder={true}
            singleSelect={true}
            items={MONTHLY_REPEAT_TYPES}
            initialSelectedItems={[monthlyType]}
            onChange={(item) => handleMonthlyTypeChange(item[0])}
          />
        )}
      </Box>
      {repeatType.value === CalendarRepetition.DAILY && (
        <>
          <Box className="flex gap-8 mb-8">
            <Box className="flex flex-wrap w-full">
              <Typography variant="body14semibold">{localeCalTrig['frequency']}</Typography>
              <WolfDropdown
                label={
                  FREQUENCY_DAYS.find((item) => item.value === repeatEvery.toString())?.text ||
                  localeCalTrig['repeatEvery']
                }
                singleSelect={true}
                showBorder={true}
                items={FREQUENCY_DAYS}
                initialSelectedItems={[repeatEvery.toString()]}
                onChange={(item) => handleRepeatEveryChange(item[0])}
              />
            </Box>
          </Box>
          <Box className="flex gap-8 mb-8">
            <Box className="flex flex-wrap w-full">
              <Typography variant="body14semibold">{localeCalTrig['repeatUntil']}</Typography>
              <WolfDropdown
                label={REPEAT_FOR.find((item) => item.value === repeatOption)?.text || localeCalTrig['repeatFor']}
                showBorder={true}
                singleSelect={true}
                items={REPEAT_FOR}
                initialSelectedItems={[repeatOption]}
                onChange={(item) => handleRepeatChange(item[0])}
              />
            </Box>
            <Box className="w-full">
              {repeatOption === 'after' && (
                <WolfInput
                  fullWidth
                  type="number"
                  label="Times"
                  value={repeatTimes}
                  onChange={handleRepeatTimesChange}
                />
              )}
              {repeatOption === 'atDate' && (
                <WolfInput
                  fullWidth
                  type="date"
                  label="Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={repeatDate}
                  onChange={handleRepeatDateChange}
                />
              )}
            </Box>
          </Box>
        </>
      )}

      {repeatType.value === CalendarRepetition.WEEKLY && (
        <>
          <Box className="flex justify-between mb-8">
            {DAYS_ARRAY.map((day) => (
              <WolfCheckbox
                checked={selectedDays.includes(day)}
                onChange={() => handleDayCheckboxChange(day)}
                key={day}
                label={day}
              />
            ))}
          </Box>
          <Box className="flex gap-8 mb-8">
            <Box className="flex flex-wrap w-full">
              <Typography variant="body14semibold">{localeCalTrig['frequency']}</Typography>
              <WolfDropdown
                label={
                  FREQUENCY_WEEKS.find((item) => item.value === repeatEvery.toString())?.text ||
                  localeCalTrig['repeatEvery']
                }
                items={FREQUENCY_WEEKS}
                singleSelect={true}
                showBorder={true}
                initialSelectedItems={[repeatEvery.toString()]}
                onChange={(item) => handleRepeatEveryChange(item[0])}
              />
            </Box>
          </Box>
          <Box className="flex gap-8 mb-8 w-full">
            <Box className="flex flex-wrap w-full">
              <Typography variant="body14semibold">{localeCalTrig['repeatUntil']}</Typography>
              <WolfDropdown
                label={REPEAT_FOR.find((item) => item.value === repeatOption)?.text || localeCalTrig['repeatFor']}
                showBorder={true}
                singleSelect={true}
                items={REPEAT_FOR}
                initialSelectedItems={[repeatOption]}
                onChange={(item) => handleRepeatChange(item[0])}
              />
            </Box>
            <Box className="w-full">
              {repeatOption === 'after' && (
                <WolfInput
                  fullWidth
                  type="number"
                  label="Times"
                  value={repeatTimes}
                  onChange={handleRepeatTimesChange}
                />
              )}
              {repeatOption === 'atDate' && (
                <WolfInput
                  fullWidth
                  type="date"
                  label="Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={repeatDate}
                  onChange={handleRepeatDateChange}
                />
              )}
            </Box>
          </Box>
        </>
      )}

      {repeatType.value === CalendarRepetition.MONTHLY && (
        <Box>
          <Typography variant="body14semibold">{localeCalTrig['repeatPosition']}</Typography>
          {monthlyType === 'number' ? (
            <WolfDropdown
              label={localeCalTrig['day']}
              showBorder={true}
              singleSelect={true}
              items={DAYS_NUMBER_ARRAY.map((day) => ({
                text: day.toString(),
                value: day.toString(),
              }))}
              initialSelectedItems={[selectedDay]}
              onChange={(item) => handleDayChange(item[0])}
            />
          ) : (
            <Box className="flex gap-8">
              <WolfDropdown
                label={WEEK_POSITION.find((item) => item.value === weekPosition?.toString())?.text}
                showBorder={true}
                singleSelect={true}
                items={WEEK_POSITION}
                initialSelectedItems={[weekPosition ? weekPosition.toString() : '']}
                onChange={(item) => handleWeekPositionChange(item[0])}
              />

              <WolfDropdown
                label={selectedDay}
                showBorder={true}
                singleSelect={true}
                items={DAYS_ARRAY.map((day) => ({
                  text: day,
                  value: day,
                }))}
                initialSelectedItems={[selectedDay]}
                onChange={(item) => handleDayChange(item[0])}
              />
            </Box>
          )}
          <Box className="flex gap-8 my-8">
            <Box className="flex flex-wrap w-full">
              <Typography variant="body14semibold">{localeCalTrig['frequency']}</Typography>
              <WolfDropdown
                label={
                  FREQUENCY_MONTHS.find((item) => item.value === repeatEvery.toString())?.text ||
                  localeCalTrig['repeatEvery']
                }
                showBorder={true}
                singleSelect={true}
                items={FREQUENCY_MONTHS}
                initialSelectedItems={[repeatEvery.toString()]}
                onChange={(item) => handleRepeatEveryChange(item[0])}
              />
            </Box>
          </Box>
          <Box className="flex gap-8 my-8">
            <Box className="flex flex-wrap w-full">
              <Typography variant="body14semibold">{localeCalTrig['repeatUntil']}</Typography>
              <WolfDropdown
                label={REPEAT_FOR.find((item) => item.value === repeatOption)?.text || localeCalTrig['repeatFor']}
                showBorder={true}
                singleSelect={true}
                items={REPEAT_FOR}
                initialSelectedItems={[repeatOption]}
                onChange={(item) => handleRepeatChange(item[0])}
              />
            </Box>
            <Box className="w-full">
              {repeatOption === 'after' && (
                <WolfInput
                  fullWidth
                  type="number"
                  label="Times"
                  value={repeatTimes}
                  onChange={handleRepeatTimesChange}
                />
              )}
              {repeatOption === 'atDate' && (
                <WolfInput
                  fullWidth
                  type="date"
                  label="Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={repeatDate}
                  onChange={handleRepeatDateChange}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
      {repeatType.value === CalendarRepetition.YEARLY && (
        <>
          <Box className="flex flex-wrap w-full">
            <Typography variant="body14semibold">{localeCalTrig['dayCriteria']}</Typography>
            <WolfDropdown
              label={
                YEARLY_REPEAT_TYPES.find((item) => item.value === yearlyType)?.text || localeCalTrig['dayCriteria']
              }
              showBorder={true}
              singleSelect={true}
              items={YEARLY_REPEAT_TYPES}
              initialSelectedItems={[yearlyType]}
              onChange={(item) => handleYearlyTypeChange(item[0])}
            />
          </Box>
          {yearlyType === 'number' ? (
            <Box className="flex gap-8 mt-8">
              <Box className="flex flex-wrap w-full">
                <Typography variant="body14semibold">{localeCalTrig['number']}</Typography>
                <WolfDropdown
                  label={selectedDay}
                  showBorder={true}
                  singleSelect={true}
                  items={DAYS_NUMBER_ARRAY.map((day) => ({
                    text: day.toString(),
                    value: day.toString(),
                  }))}
                  initialSelectedItems={[selectedDay]}
                  onChange={(item) => handleDayChange(item[0])}
                />
              </Box>
              <Box className="flex flex-wrap w-full">
                <Typography variant="body14semibold">{localeCalTrig['month']}</Typography>
                <WolfDropdown
                  label={MONTHS_ARRAY.find((month) => month.value === selectedMonth)?.text || ''}
                  showBorder={true}
                  singleSelect={true}
                  items={MONTHS_ARRAY.map((month) => ({
                    text: month.text,
                    value: month.value.toString(),
                  }))}
                  initialSelectedItems={[selectedMonth.toString()]}
                  onChange={(item) => handleMonthChange(item[0])}
                />
              </Box>
            </Box>
          ) : (
            <>
              <Box className="w-full mt-8">
                <Typography variant="body14semibold">{localeCalTrig['repeatEvery']}</Typography>
              </Box>
              <Box className="flex gap-8">
                <WolfDropdown
                  label={WEEK_POSITION.find((item) => item.value === weekPosition?.toString())?.text}
                  showBorder={true}
                  singleSelect={true}
                  items={WEEK_POSITION}
                  initialSelectedItems={[weekPosition ? weekPosition.toString() : '']}
                  onChange={(item) => handleWeekPositionChange(item[0])}
                />

                <WolfDropdown
                  label={selectedDay}
                  showBorder={true}
                  singleSelect={true}
                  items={DAYS_ARRAY.map((day) => ({
                    text: day,
                    value: day,
                  }))}
                  initialSelectedItems={[selectedDay]}
                  onChange={(item) => handleDayChange(item[0])}
                />

                <WolfDropdown
                  label={MONTHS_ARRAY.find((item) => item.value === selectedMonth)?.text || ''}
                  showBorder={true}
                  singleSelect={true}
                  items={MONTHS_ARRAY.map((month) => ({
                    text: month.text,
                    value: month.value.toString(),
                  }))}
                  initialSelectedItems={[selectedMonth.toString()]}
                  onChange={(item) => handleMonthChange(item[0])}
                />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CalendarRecurrence;
