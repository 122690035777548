import { useTranslation } from '@hooks/useTranslation';
import { Organization } from '@models/organization.model';
import { Box, Typography } from '@mui/material';

interface DisableOrganizationModalProps {
  organization: Organization | undefined;
}

const DisableOrganizationModal: React.FC<DisableOrganizationModalProps> = ({ organization }) => {
  const localeOrganizations = useTranslation('organizations');

  return (
    <Box>
      <Typography variant="body16">{localeOrganizations['ConfirmDisableMessage']}</Typography>
      <Typography variant="body16semibold">{organization?.name}</Typography>
    </Box>
  );
};

export default DisableOrganizationModal;
