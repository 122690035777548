import { Process } from '@models/process.model';
import { generateMainProcesses } from './generateMainProcessesV2';

export default function drawElementsV2(drawnCategory: Process[], startingVerticalOffset: number, identifier: string) {
  console.log('drawnCategory', drawnCategory);

  // We take all the processes and draw them, as they are related to one another with edges and isolated at the end
  const { nodes: auxNodes, edges: auxEdges } = generateMainProcesses(drawnCategory, identifier, startingVerticalOffset);

  // TODO: We add triggers with their respective lines

  // We add requests (links to processes outside the flow) with their respective lines

  return {
    nodes: auxNodes,
    edges: auxEdges,
  };
}
