export const ImpersonateTypes = {
  SET_IMPERSONATE: 'SET_IMPERSONATE',
  CLEAR_IMPERSONATE: 'CLEAR_IMPERSONATE',
};

export const setImpersonate = (data: { name: string; id: string }) => ({
  type: ImpersonateTypes.SET_IMPERSONATE,
  data: data,
});

export const clearImpersonate = () => ({
  type: ImpersonateTypes.CLEAR_IMPERSONATE,
  data: '',
});

export type ImpersonateState = {
  impersonateName: string;
  impersonateId: string;
  isImpersonating: boolean;
};

export type ImpersonateAction = {
  type: string;
  data: { id: string; name: string };
};

const impersonateReducer = (
  state = { impersonateName: '', impersonateId: '', isImpersonating: false },
  action: ImpersonateAction,
) => {
  switch (action.type) {
    case ImpersonateTypes.SET_IMPERSONATE:
      return {
        ...state,
        impersonateName: action.data.name,
        impersonateId: action.data.id,
        isImpersonating: true,
      };

    case ImpersonateTypes.CLEAR_IMPERSONATE:
      return {
        ...state,
        impersonateName: '',
        impersonateId: '',
        isImpersonating: false,
      };

    default:
      return state;
  }
};

export default impersonateReducer;
