import { FlowEdge, FlowNode, FlowVisualization, NodePosition, ProcessConnection } from '@models/process-map.model';
import { Process } from '@models/process.model';

export function generateMainProcesses(
  processes: Process[],
  identifier: string = 'flow',
  startingVerticalOffset: number = 0,
  nodeHorizontalSpacing: number = 400,
  nodeBranchSpacing: number = 200,
): FlowVisualization {
  // Track all referenced processes
  const referencedProcessIds = new Set<string>();

  // Build connections array
  const processConnections: ProcessConnection[] = processes.map((process) => ({
    processId: process.processId,
    connections:
      process.steps?.reduce(
        (acc, step) => {
          if (step.stepData.executeProcessId) {
            referencedProcessIds.add(step.stepData.executeProcessId);
            acc.push({
              targetId: step.stepData.executeProcessId,
              sourceStep: step.name,
              condition: step.stepData.condition,
            });
          }
          return acc;
        },
        [] as Array<{ targetId: string; sourceStep: string; condition?: string }>,
      ) || [],
  }));

  // Find root process
  const rootProcesses = processes
    .filter((p) => !referencedProcessIds.has(p.processId))
    .sort((a, b) => a.order - b.order);

  if (rootProcesses.length === 0) {
    throw new Error('No root process found');
  }

  // Track layout information
  const nodePositions: NodePosition[] = [];
  const visitedNodes = new Set<string>();

  // Calculate node positions
  function calculatePositions(
    processId: string,
    level: number = 0,
    branch: number = 0,
    visited: Set<string> = new Set(),
  ): void {
    if (visited.has(processId)) return;

    visited.add(processId);
    const process = processes.find((p) => p.processId === processId);
    if (!process) return;

    // Store position
    nodePositions.push({
      processId,
      x: level * nodeHorizontalSpacing,
      y: startingVerticalOffset + branch * nodeBranchSpacing,
      level,
      branch,
    });

    // Process next nodes
    const processConnection = processConnections.find((pc) => pc.processId === processId);
    processConnection?.connections.forEach((connection, branchIndex) => {
      calculatePositions(connection.targetId, level + 1, branch + branchIndex, visited);
    });
  }

  // Start position calculation from root
  calculatePositions(rootProcesses[0].processId);

  // Generate nodes and edges
  const nodes: FlowNode[] = [];
  const edges: FlowEdge[] = [];
  let isolatedX = 0;

  // Add connected nodes
  nodePositions.forEach((position) => {
    const process = processes.find((p) => p.processId === position.processId)!;
    nodes.push({
      id: `${identifier}-${position.processId}`,
      position: {
        x: position.x,
        y: position.y,
      },
      data: {
        label: process.name,
        preLabel: `${process.service?.area?.name} - ${process.service?.name} - `,
        color: process.service?.color || '#000000',
        isExternal: false,
      },
      type: 'ProcessV2',
    });

    // Add edges
    const connections = processConnections.find((pc) => pc.processId === position.processId)?.connections || [];
    connections.forEach((connection, index) => {
      edges.push({
        id: `${identifier}-edge-${position.processId}-${connection.targetId}-${index}`,
        source: `${identifier}-${position.processId}`,
        target: `${identifier}-${connection.targetId}`,
        type: 'step',
      });
    });
  });

  // Add isolated nodes
  processes.forEach((process) => {
    if (!nodePositions.find((pos) => pos.processId === process.processId)) {
      nodes.push({
        id: `${identifier}-${process.processId}`,
        position: {
          x: isolatedX,
          y: startingVerticalOffset + nodeBranchSpacing * 2, // Place isolated nodes below
        },
        data: {
          label: process.name,
          preLabel: `${process.service?.area?.name} - ${process.service?.name} - `,
          color: process.service?.color || '#000000',
          isExternal: false,
        },
        type: 'ProcessV2',
      });
      isolatedX += nodeHorizontalSpacing;
    }
  });

  return { nodes, edges };
}
