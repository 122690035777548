export default function SwitchStepInsideNode({ data }: any) {
  return (
    <>
      <div className="switch-step-inside-node flex flex-wrap items-center">
        <p>{data.label.slice(0, 20) + (data.label.length > 25 ? '...' : '') + ' (switch)'}</p>
        <p>{data.condition}</p>
      </div>
      {/* <Handle type="source" position={Position.Right} id="a" /> */}
    </>
  );
}
